import React, { useState, useEffect, useContext, useRef } from "react";
import { MicContext } from "../../../providers/MicProvider";
import { UserContext } from "../../../providers/UserProvider";
import socket from "../../../server/socket-io";
import RoundContainer from "../../layout/RoundContainer";
import { ICON_DELETE, ICON_REPEAT } from '../../icons';
import { webmToWav } from '../../../server/audioFunctions';
import { database } from "../../../databases/firebase";

//const crunker = new Crunker({ sampleRate: 44100 * 1.0956 });

//1.5 => 930
//1.1 => 680 -> 690
//1.0 => 680 -> 620
//1.085 => 680 -> 670
//m = 700
//y = 700x - 80
//1.0857

const SoundRecord = () => {

    const [soundName, setSoundName] = useState('');
    const [recordStatus, setRecordStatus] = useState(0);
    const {micEnable} = useContext(MicContext);
    const {userName} = useContext(UserContext)
    const [soundArray, setSoundArray] = useState([]);
    const [isLoop, setLoop] = useState(false);
    const { getMicStream } = useContext(MicContext);

    //const [superBlob, setSuperBlob] = useState([]);
    //const [superAudio, setSuperAudio] = useState([]);
    //const {isPlaying} = useContext(PlayingContext);
    //const [showModal, setShowModal] = useState(false);
    //const [fileName, setFileName] = useState('')
    
    //const recorderNum = useRef(null);
    const recorderRef = useRef(null);
    const streamRef = useRef(null);
    const chunksRef = useRef([]);

    //const audioRef = useRef({});
    //const audioNumRef = useRef(0);
    
    useEffect(() => {
        receiveSound();
        return () => {
            setSoundArray([]);
            setSoundName('');
        }
    }, [])

    /*useEffect(() => {
        setTimeout(() => {
            if (recorderNum.current && superBlob && superBlob.length === recorderNum.current) {
                //let audio = document.getElementById('soundpage-super-audio');
                crunker.fetchAudio(...superBlob)
                    .then(buffers => crunker.mergeAudio(buffers))
                    .then(merged => crunker.export(merged, 'audio/mp3'))
                    .then(output => setSuperAudio(output))
                    //.then(output => audio.setAttribute('src', output.url))
                    .then(() => recorderNum.current = null)
                    .catch(e => console.log(e.message));
            }
        }, 1000);
        
    }, [superBlob])

    const refreshCrunker = () => {
        crunker.fetchAudio(...superBlob)
            .then(buffers => crunker.mergeAudio(buffers))
            .then(merged => crunker.export(merged, 'audio/mp3'))
            .then(output => setSuperAudio(output))
    }

    useEffect(() => {
        if (superAudio) document.getElementById('soundpage-super-audio').setAttribute('src', superAudio.url);
    }, [superAudio])

    useEffect(() => {
        if (isPlaying) {
            setTimeout(() => setSuperBlob([]), 100);
        }
    }, [isPlaying])*/

    const receiveSound = () => {
        socket.on('receiveSoundMessage', (u, n, s) => {
            const blob = new Blob([s], { 'type' : 'audio/mpeg' });
            const audioURL = window.URL.createObjectURL(blob);
            setSoundArray(a => [...a, [u, n, audioURL]]);
        });

        /*socket.on('receiveSuperBlob', b => {
            if (!recorderNum.current) database.ref('recorderNum2').get().then(v => {
                recorderNum.current = v.val();
            })
            
            const blob = new Blob([b], {type: 'audio/mpeg'})
            let url = URL.createObjectURL(blob);
            setSuperBlob(a => [...a, url]);
        });*/
    }

    useEffect(() => {
        const btnRecord = document.getElementById('soundpage-btn-record-sound');
        switch(recordStatus) {
            case 0: default://before record
                btnRecord.innerHTML = "เริ่มอัดเสียง";
                btnRecord.className = "btn btn-outline-success";
                break;
            case 1:
                btnRecord.innerHTML = "หยุดอัดเสียง";
                btnRecord.className = "btn btn-danger";
                break;
            case 2:
                btnRecord.innerHTML = "อัดเสียงใหม่";
                btnRecord.className = "btn btn-warning";
                break;
        }
    }, [recordStatus])

    useEffect(() => {
        const btnLoop = document.getElementById('soundpage-loop-btn');
        btnLoop.className = isLoop ? "btn btn-success" : "btn btn-outline-danger"
    }, [isLoop])

    const onSendSoundClick = async e => {
        e.preventDefault();
        if (!soundName) alert('โปรดใส่ชื่อของเสียง')
        else if (!chunksRef.current) alert('เกิดข้อผิดพลาดในการอัด กรุณาอัดใหม่')
        else {
            const mp3Mode = await database.ref('settings').child('mp3Mode').get().then(v => v.val());
            const chunksBlob = new Blob(chunksRef.current);
            if (mp3Mode) {
                const wavBlob = await webmToWav(chunksBlob);
                const buf = await wavBlob.arrayBuffer();
                socket.emit('sendSoundMessage', userName, soundName, buf);
            }
            else {
                const buf = await chunksBlob.arrayBuffer();
                socket.emit('sendSoundMessage', userName, soundName, buf);
            }
            
            alert('ส่งไฟล์เสียงสำเร็จ')
            setSoundName('');
            setRecordStatus(0);
            document.getElementById('soundpage-input-sound-name').value = "";
            setTimeout(() => chunksRef.current = [], 500);
        }
    }

    const onRecordClick = async e => {
        const btnRecord = document.getElementById('soundpage-btn-record-sound');
        switch (recordStatus) {
            case 0: case 2:
                //chunks = [];
                console.log(Date.now());
                btnRecord.disabled = true;
                getMicStream()
                .then(st => {
                    streamRef.current = st;
                    recorderRef.current = new MediaRecorder(st, {audioBitsPerSecond : 128000 /*,mimeType : 'audio/webm',*/ })
                })
                .then(() => {
                    recorderRef.current.onstart = () => console.log(Date.now());
                    recorderRef.current.ondataavailable = function(e) {
                        chunksRef.current.push(e.data);
                    }
                    recorderRef.current.onstop = async function(e) {
                        const blob = new Blob(chunksRef.current, { 'type' : 'audio/mpeg' });
                        const audioURL = window.URL.createObjectURL(blob);
                        const audio = document.getElementById('soundpage-audio');
                        audio.setAttribute('src', audioURL);
                    }
                    recorderRef.current.start();
                })
                .then(() => setRecordStatus(1))
                .catch(e => console.log(e))
                .finally(() => btnRecord.disabled = false)
                break;
            case 1:
                if (recorderRef.current ? recorderRef.current.state === "recording" : false) {
                    try {
                        console.log(recorderRef.current);
                        recorderRef.current.stop();
                        if (!micEnable) await streamRef.current.getTracks().forEach(t => t.stop());
                    }
                    catch (e) { 
                        console.log(e.message );
                    }
                    finally {
                        setRecordStatus(2);
                    }
                }
                break;
            default:
                break;
        }
    }

    const deleteSoundItem = e => {
        try {
            if (e.target.value) {
                //const [deleteUser, deleteName, deleteSound] = e.target.value.split("*");
                const deleteSound = e.target.value.split("*")[2]
                setSoundArray(() => [...soundArray.filter(item => item[2] !== deleteSound)])
            }
        }
        catch (e) {
            console.log(e.message);
        }
    }

    /*const onDownloadClick = () => {
        try {
            if (superAudio.blob) crunker.download(superAudio.blob, fileName || 'song');
        }
        catch (e) {
            console.log(e);
        }
        finally {
            let ip = document.getElementById('soundpage-input-filename');
            setTimeout(() => {
                setShowModal(false);
                setFileName('');
                if (ip) ip.value = '';
            }, 1000);
        }
    }*/

    const SoundBuilderForm = () => {
        return (
            <form className="content">
                <div className="form-group">
                    <div className="row">
                        <div className="col-2" style={{textAlign: 'right'}}>
                            <label>ชื่อของเสียง</label>
                        </div>
                        <div className="col-3 ">
                            <input type="text" id="soundpage-input-sound-name" placeholder="เช่น เสียงโด ขลุ่ย"  className="form-control" style={{marginTop: "-5px"}} autoComplete="off" 
                                onChange={e => setSoundName(e.target.value)}
                            />
                        </div>
                        
                        <div className="col-7">
                            <audio type="audio/mpeg" id="soundpage-audio" controls playsInline style={{width: "100%", marginTop: "-15px"}}/>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-3" />
                        <div className="col-3">
                            <button type="button" onClick={onRecordClick} id="soundpage-btn-record-sound" className="btn btn-outline-success">เริ่มอัดเสียง</button>
                        </div>
                        <div className="col-3">
                            <button type="button" onClick={onSendSoundClick} className="btn btn-primary" disabled={recordStatus !== 2}>ส่ง</button>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    const SoundTableHeader = () => {
        return (
            <li className="list-group-item" >
                <div className="row text-center" style={{alignItems: "center"}}>
                    <div className="col-2">เสียงจาก</div>      
                    <div className="col-3">ชื่อของเสียง</div>   
                    <div className="col-6">เสียง </div>       
                    <div className="col-1">
                        <button onClick={() => setLoop(!isLoop)} id="soundpage-loop-btn" className="btn btn-outline-danger">
                            {ICON_REPEAT}
                        </button>
                    </div>
                </div>
            </li>
        )
    }

    const recordedSound = () => {
        if (soundArray) return soundArray.map((data, index) => (
            <li key={index} className="list-group-item">
                <div id={`soundpage-list-${index}`} className="row text-center" style={{alignItems: "center"}}>
                    <div className="col-2">
                        {data[0]}
                    </div>   
                    <div className="col-3">
                        {data[1]}
                    </div> 
                    <div className="col-6">
                        <audio loop={isLoop} autoPlay={false} type="audio/mpeg" controls playsInline src={data[2]} style={{width: "100%", height: "40px"}} />
                    </div>  
                    <div className="col-1">
                        <button id={`soundpage-btn-delete-${index}`} className="btn btn-danger" value={`${data[0]}*${data[1]}*${data[2]}`} onClick={deleteSoundItem}>
                            {ICON_DELETE}
                        </button>
                    </div>
                </div>
            </li>
        ))
    }


    return(
        <div>

            {/*<Modal show={showModal} size="sm" onHide={() => setShowModal(false)}>
                <Modal.Body >
                    <form>
                        <label>ชื่อไฟล์</label>
                        <input type="text" id="soundpage-input-filename" className="form-control" onChange={e => setFileName(e.target.value)}></input>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn btn-outline-danger" onClick={() => setShowModal(false)}>ยกเลิก</div>
                    <div className="btn btn-primary" onClick={onDownloadClick}>ดาวน์โหลด</div>
                </Modal.Footer>
            </Modal>*/}

            <RoundContainer>
                <h3 className="containerHeader">เสียง</h3>
                สามารถใช้หน้านี้ในการเทียบเสียง โดยการอัดเสียง และแบ่งปันให้กับเพื่อน ๆ ได้
            </RoundContainer>

            <br />

            <RoundContainer>
                <h5 className="containerHeader">อัดเสียง</h5>
                {SoundBuilderForm()}
            </RoundContainer>

            <br />

            <RoundContainer>
                <h5 className="containerHeader">เสียงที่อัด</h5>
                <div className="list-group" style={{minHeight: "100px"}}>
                    <SoundTableHeader />
                    {recordedSound()}
                </div>
            </RoundContainer>
            
            {/*<br />

            <RoundContainer>
                <h5 className="containerHeader">เสียงที่เล่นรอบล่าสุด</h5>
                <button className="btn btn-primary" onClick={refreshCrunker} hidden>ref</button>
                <div className="row text-center">
                    <div className="col"><audio id="soundpage-super-audio" style={{width: '90%', marginTop: "-5px"}} autoPlay={false} type="audio/mpeg" controls playsInline /></div>
                    <div className="col-2"><button className="btn rounded-circle btn-primary" disabled={superAudio.url === undefined} onClick={() => setShowModal(true)}>{ICON_DOWNLOAD}</button></div>
                </div>
            </RoundContainer>*/}

        </div>
    )
}

export default SoundRecord;

/*const recorderOptions = {
    type: 'audio',
    recorderType: StereoAudioRecorder, 
    mimeType: 'audio/webm', 
    desiredSampRate: 44100,
}*/


//https://webkit.org/blog/7734/auto-play-policy-changes-for-macos/