import { useContext, useEffect, useState } from 'react';
import RoundContainer from '../layout/RoundContainer';
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { FOCUS_COMMENT, FOCUS_NOTE, initialModalData, CAN_EDIT, CAN_VIEW, NO_RIGHT, TANG_OPTIONS } from './const';

import RenderNote from './RenderNote';

import OnArrowKeyDown from './events/OnArrowKeyDown';
import OnCommentKeyDown from './events/OnCommentKeyDown';
import OnKeyDown from './events/OnKeyDown';
import RenderKeyboard from './RenderKeyboard';

import { useSearchParams } from 'react-router-dom';
import { getSheetData } from '../../databases/software/getData';
import { auth } from '../../databases/firebase';
import { addShareUser, onSaveCallback } from '../../databases/software/addData';
import { AuthContext } from '../../providers/AuthContext';
import { SheetContext } from '../../providers/SheetContext';
import { KeyPressContext } from '../../providers/KeyPressContext';
import RenderHeader from './RenderHeader';
import RenderFreqBar from './RenderFreqBar';
import { SheetPlayingContext } from '../../providers/SheetPlayingContext';
import { AllModal } from './RenderModals';

const gInitial = {
    //gFreq : 60,
    //gFocusRoom : {},
    //gSelectRoom : [0],
    //gIsPlaying : false,
    //gIsSelectPress : false,
    //gIsShowComment : false,
    //gClipboard : {},
    //gIsKeyboardShow : false,
    /*gSoundSettings : {
        allSound : 1,
        fluteSound : 0.25,
        kongSound : 0.25,
    },
    gRhythm : {
        gChing : {
            isSame : true,
            chan : 0,
        },
        gTone : {
            isSame : true,
            natab : 'NO',
        }
    },*/
    //gPlayingContext : {},
    gSheetID : '',
    gSheetName : '',
    gSheetType : null,
    //gRight : CAN_EDIT,
}


/*const tInitial = {
    1648788237709 : {
        tName : 'ท่อน 1',
        tLineNum : 4,
        tTang : 1,
        tNote : {},
        tFixedSpeed : {},
        tComment : {
            0 : '', 1 : '', 2 : '', 3 : '', 4 : '', 5 : '', 6 : '', 7 : '',
        },
        tRule : [],
    }
}*/

//const orderInitial = [{tId : 1648788237709, tName : 'ท่อน 1', tRound : 2, tChing : 0, tTone : 'NO'}];



const SoundSoftware = () => {

    const [tData, setTData] = useState({}/*tInitial*/);
    const [gNoteData, setGNoteData] = useState(gInitial);
    const [modalData, setModalData] = useState({...initialModalData});
    const [orderItem, setOrderItem] = useState(/*orderInitial*/[]);
    const { isLogin } = useContext(AuthContext);
    
    const sheetContext = useContext(SheetContext);
    const keyPressContext = useContext(KeyPressContext)
    const { gFocusRoom, gRight, setRight, gIsShowKeyboard } = sheetContext || {};
    const { fType } = gFocusRoom

    const { pRhythm, pFrequency, setFreq } = useContext(SheetPlayingContext);
    const { controlPress } = useContext(KeyPressContext);

    //const globalFrequency = useRef(FREQ.DEFAULT)
    //const [freq, setFreq] = useState(FREQ.DEFAULT);
    //useEffect(() => globalFrequency.current = freq, [freq])

    useEffect(() => {
        return () => {
            setOrderItem([]);
            setTData({});
            setGNoteData(gInitial);
            setModalData({...initialModalData});
        }
    }, []);

    const [searchparams] = useSearchParams();
    const sheetID = searchparams.get('id');
    const navigate = useNavigate()
    
    useEffect(() => {
        if (sheetID) {
            setGNoteData(g => ({...g, gSheetID : sheetID}))
            getSheetData(sheetID).then(result => {
                if ((result.shareType === 0 && auth.currentUser.uid === result.user) || result.shareType !== 0) {
                    setTData(result.tData || {});
                    setOrderItem(result.orderItem || []);
                    setGNoteData(g => ({...g, 
                        gSheetName : result.sheetName,
                        gSheetType : isNaN(result.sheetType) ? null : parseInt(result.sheetType), 
                    }))
                    setRight(auth.currentUser && auth.currentUser.uid === result.user ? CAN_EDIT : result.shareType !== 0 ? CAN_VIEW : NO_RIGHT)
                    if (result.pRhythm) pRhythm.current = {...result.pRhythm}
                    if (result.pFrequency) setFreq(result.pFrequency)
                    console.log('start render!')
                }
                else {
                    setRight(NO_RIGHT)
                }
            })
            .catch(e => {
                console.log(e)
                setRight(NO_RIGHT)
            })
        }
    }, [sheetID, setRight, pFrequency, pRhythm, setFreq])
        
    useEffect(() => {
        if (sheetID && isLogin) {
            getSheetData(sheetID).then(result => {
                if (result.user !== auth.currentUser.uid && result.shareType === 1) {
                    addShareUser(sheetID, auth.currentUser.uid)
                }
            })
        }
    }, [isLogin, sheetID]);

    useEffect(() => {
        const { gFocusRoom } = sheetContext || {};
        const { fId, fType } = gFocusRoom;
        const setRoom = (room, val) => {
            const d = {...tData}
            d[fId].tNote[room] = val;
            setTData(d)
        }

        
        
        const keyboardHandle = e => {
            let t = Date.now() * -1;
            const arrowKey = ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown']
            if (fType === FOCUS_NOTE && arrowKey.includes(e.key)) {
                OnArrowKeyDown({ event : e, tData, setTData, sheetContext, keyPressContext})
            }
            else if (e.key !== 'Control' && e.key !== 'Shift' && tData[fId] && fType === FOCUS_NOTE) {
                OnKeyDown({
                    event : e,
                    key : e.key,
                    sheetContext,
                    tData, setTData,
                    setRoom,
                    keyPressContext,
                })
                t += Date.now()
                console.log(t)
            }
            else if (['s', 'S', 'ห', 'ฆ'].includes(e.key) && controlPress.current) {
                e.preventDefault();
                onSaveCallback(gNoteData.gSheetID, {tData, pRhythm : pRhythm.current, pFrequency : pFrequency.current, orderItem})
            }
        }

        if (gRight === CAN_EDIT) document.addEventListener('keydown', keyboardHandle);
        
        return () => {
            document.removeEventListener('keydown', keyboardHandle);
        }
    })

    useEffect(() => {
        const CommentKeyboardHandle = e => {
            const actionKey = ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', 'Enter', 'Tab', 'Delete', 'Backspace']
            if (actionKey.includes(e.key)) OnCommentKeyDown({ event : e, tData, sheetContext })
        }
        if (gFocusRoom.fType === FOCUS_COMMENT) document.addEventListener('keydown', CommentKeyboardHandle);
        return () => document.removeEventListener('keydown', CommentKeyboardHandle);
    })

    return (
        <>
        <RenderHeader 
            gNoteData={gNoteData} setGNoteData={setGNoteData}
            tData={tData} setTData={setTData} orderItem={orderItem} setOrderItem={setOrderItem} 
            setModalData={setModalData}
        />
        <div hidden={gRight === NO_RIGHT}>
            <AllModal 
                tData={tData} setTData={setTData} 
                gSheetType={gNoteData.gSheetType} 
                modalData={modalData} setModalData={setModalData}
                orderItem={orderItem} setOrderItem={setOrderItem}
            />
            <div className="container" style={{maxWidth: '1000px', paddingInline: 0}}>
                <RoundContainer res={true}>
                    {/*<RenderMenu 
                        tData={tData} setTData={setTData} 
                        gNoteData={gNoteData}
                        modalData={modalData} setModalData={setModalData}
                        orderItem={orderItem} setOrderItem={setOrderItem}
                    />*/}
                    <br />
                    <RenderFreqBar />
                    <hr />
                    <br />
                    <h5 className='text-center'>{gNoteData.gSheetName}</h5>
                    <div className='text-end'>{TANG_OPTIONS[gNoteData.gSheetType] ? 'ทาง' + TANG_OPTIONS[gNoteData.gSheetType] : ''}</div>
                    <RenderNote 
                        orderItem={orderItem}
                        tData={tData} setTData={setTData}
                        gNoteData={gNoteData} setGNoteData={setGNoteData} 
                    />
                </RoundContainer>
            </div>
            {gIsShowKeyboard ? <><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /></> : <br />}
            <br /><br />
            {fType === FOCUS_NOTE && !isNaN(gNoteData.gSheetType) && gNoteData.gSheetType !== null ? 
                <RenderKeyboard 
                    tData={tData} setTData={setTData} 
                    tang={parseInt(gNoteData.gSheetType)}
                /> 
            : <div />}
        </div>
        <div hidden={gRight !== NO_RIGHT}>
            <div className="container" style={{maxWidth: '1000px'}}>
                <RoundContainer>
                    <h5>ขออภัย หาเอกสารไม่พบ หรือเจ้าของเอกสารกำหนดเป็นเอกสารส่วนตัว</h5>
                    <br /><br />
                    <Button variant='danger' onClick={() => navigate('/software')}>กลับ</Button>
                </RoundContainer>
            </div>
        </div>
        </>
    )
}

export default SoundSoftware;