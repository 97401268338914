import { FOCUS_COMMENT, FOCUS_NONE } from "../const";



const OnCommentKeyDown = props => {

    const { event, tData, sheetContext } = props;
    const { gFocusRoom, setFType } = sheetContext;
    const { fId, fRoom, fType } = gFocusRoom || {};
    const roomPerLine = 2;
    const lastRoom = tData[fId].tLineNum * roomPerLine - 1;

    if (fType === FOCUS_COMMENT) {
        switch (event.key) {
            case 'ArrowRight' :
                if (fRoom < lastRoom) {
                    const nextElement = document.getElementById(`comment-${fRoom + 1}-${fId}`);
                    if (nextElement) nextElement.focus();
                }
                break;
            case 'ArrowLeft':
                if (fRoom > 0) {
                    const nextElement = document.getElementById(`comment-${fRoom - 1}-${fId}`);
                    if (nextElement) nextElement.focus();
                }
                break;
            case 'ArrowUp':
                if (fRoom >= roomPerLine) {
                    const nextElement = document.getElementById(`comment-${fRoom - roomPerLine}-${fId}`);
                    if (nextElement) nextElement.focus();
                }
                break;
            case 'ArrowDown':
                if (fRoom <= lastRoom - roomPerLine) {
                    const nextElement = document.getElementById(`comment-${fRoom + roomPerLine}-${fId}`);
                    if (nextElement) nextElement.focus();
                }
                break;
            case 'Tab':
                event.preventDefault();
                if (fRoom < lastRoom) {
                    const nextElement = document.getElementById(`comment-${fRoom + 1}-${fId}`);
                    if (nextElement) nextElement.focus();
                }
                break;
            case 'Enter':
                const nextElement = document.getElementById(`comment-${fRoom}-${fId}`);
                if (nextElement) nextElement.blur();
                //setFocusRoom({...gFocusRoom, fType : FOCUS_NONE})
                setFType(FOCUS_NONE)
                break;
            case "Backspace": case "Delete":
                if (!tData[fId].tComment[fRoom] && fRoom > 0) {
                    const nextElement = document.getElementById(`comment-${fRoom - 1}-${fId}`);
                    if (nextElement) nextElement.focus();
                }
                break;
            default :
                break;
        }
    }
}

export default OnCommentKeyDown;