import React, { createContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export const KeyPressContext = createContext({});

/*const initialState = {
    isShiftPress : false,
    isControlPress : false,
}*/

const KeyPressPage = ['/ss']

/*const reducer = (state, action) => {
    switch (action.type) {
        case "SET_SHIFT":
            return {...state, isShiftPress : action.payload}
        case "SET_CONTROL":
            return {...state, isControlPress : action.payload}
        default:
            break;
    }
}*/

export const KeyPressProvider = ({ children }) => {
    //const [state, dispatch] = useReducer(reducer, initialState)
    //const { isShiftPress, isControlPress } = state;

    //const setShiftPress = payload => dispatch({ type : 'SET_SHIFT', payload })
    //const setControlPress = payload => dispatch({ type : 'SET_CONTROL', payload })

    /*useEffect(() => {
        return () => {
            setShiftPress(false);
            setControlPress(false);
        }
    }, []);*/

    const location = useLocation();

    const shiftPress = useRef(false);
    const controlPress = useRef(false);

    useEffect(() => {
        const keydownHandle = e => {
            if (e.key === 'Shift' && !shiftPress.current) shiftPress.current = true;
            if (e.key === 'Control' && !controlPress.current) controlPress.current = true;
        }
        const keyupHandle = e => {
            if (e.key === 'Shift') shiftPress.current = false;
            if (e.key === 'Control') controlPress.current = false;
        }
        if (KeyPressPage.includes(location.pathname)) {
            document.addEventListener('keydown', keydownHandle);
            document.addEventListener('keyup', keyupHandle)
        }
        
        return () => {
            document.removeEventListener('keydown', keydownHandle);
            document.removeEventListener('keyup', keyupHandle);
        }
    })
    
    return (
        <KeyPressContext.Provider value={{ 
            shiftPress, controlPress,
        }}>
            {children}
        </KeyPressContext.Provider>
    )
}