import React from "react";

const RoundContainer = (props) => {
    
    const { h, res, hidden } = props

    const padding = res ? {} : { padding : '20px' }
    const className = res ? "container-fluid padding-inline-responsive" : "container-fluid"

    return (
        <div className={className} hidden={hidden || false} style={{ ...padding, background: 'white', borderRadius: "20px", height: h ? `${h}px` : 'auto'}}>
            {props.children}
        </div>
    )
}

export default RoundContainer;