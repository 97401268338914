import { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { addSheet, deleteSheet, updateSheetData } from "../../databases/software/addData";
import { SheetsContext } from "../../providers/SheetsContext";
import { SelectForm, TextForm } from "../layout/FormItem";
import RoundContainer from "../layout/RoundContainer";
import SuperHeader from "../layout/SuperHeader";
import { ShareOptions, TANG_OPTIONS } from "../SoundSoftware/const";
import FormModal from "../SoundSoftware/layouts/FormModal";
import { Link } from 'react-router-dom';
import { ICON_PP_SETTING } from "../icons";

const initialModalData = {
    addSheetModal : {
        show : false,
        sheetName : '',
        sheetContent : '',
        sheetType : 0,
    },
    editSheetModal : {
        show : false,
        sheetID : '',
        sheetName : '',
        sheetContent : '',
        shareType : 0,
    }
}

const shareColor = {
    0 : '#0d6efd',
    1 : '#5b8c00',
    2 : '#9e1068'
}

const SheetItem = props => {
    const { sheets, id, isSetting, onSettingClick } = props;
    return (
        <>
            <Row bsPrefix='row align-items-center text-center' >
                <Col sm='8' xs='6'>
                    <div className="text-start">
                        <Link to={`/ss?id=${id}`} style={{color : shareColor[sheets[id].shareType]}}>{sheets[id].sheetName}</Link>
                    </div>
                    <div className="text-start fs14" style={{whiteSpace : 'pre'}}>{sheets[id].sheetContent || ' '}</div>
                </Col>
                <Col sm='2' xs='2'>{TANG_OPTIONS[sheets[id].sheetType] || '-'}</Col>
                <Col>
                    {isSetting && typeof onSettingClick === 'function' ? <Button variant="light" onClick={onSettingClick}>
                        {ICON_PP_SETTING} <span className="d-none d-md-inline fs14">ตั้งค่า</span>
                    </Button> : sheets[id].userName}
                </Col>
            </Row>
            <hr style={{marginBlock : '5px'}} />
        </>
    )
}

const SoundSoftwareOuter = () => {

    const [modalData, setModalData] = useState({...initialModalData});
    const { userSheets, sharedSheets, globalSheets, queryUserSheets, queryGlobalSheets } = useContext(SheetsContext);
    const { addSheetModal, editSheetModal } = modalData

    useEffect(() => {
        return () => {
            setModalData(initialModalData);
        }
    }, [])

    const onModalDataChange = (modalName, key, value) => setModalData(m => ({...m, [modalName] : {...m[modalName], [key] : value}}))

    const onEditSheetClick = (id) => {
        const { sheetName, sheetContent, shareType } = userSheets[id] || {};
        setModalData(m => ({...m, editSheetModal : {
            show : true, sheetName, sheetContent, shareType, sheetID : id,
        }}))
    }

    //useEffect(() => console.log(modalData) , [modalData])
    const onAddSheetCallback = async () => {
        await addSheet(modalData.addSheetModal);
        await queryUserSheets();
    }

    const onEditSheetCallback = async () => {
        const { sheetID, sheetName, sheetContent, shareType } = editSheetModal;
        await updateSheetData(sheetID, {sheetName, sheetContent, shareType});
        await queryUserSheets();
        await queryGlobalSheets();
    }

    const onDeleteSheetClick = async () => {
        const { sheetID } = editSheetModal;
        await deleteSheet(sheetID);
        alert('ลบเอกสารสำเร็จ')
        setModalData({...initialModalData})
        await queryUserSheets();
        await queryGlobalSheets();
    }

    return (
        <>
            <SuperHeader title='พร้อมเล่น' />
            <FormModal title='เพิ่มเอกสาร' show={addSheetModal.show} resetData={() => setModalData({...initialModalData})} onSubmitCallback={onAddSheetCallback} >
                <TextForm title='ชื่อเอกสาร' placeholder='เช่น ค้างคาวกินกล้วย' value={addSheetModal.sheetName} onChange={e => onModalDataChange('addSheetModal', 'sheetName', e.target.value)}/>
                <TextForm title='รายละเอียด' placeholder='เช่น ผู้แต่ง (ไม่บังคับ)' value={addSheetModal.sheetContent} onChange={e => onModalDataChange('addSheetModal', 'sheetContent', e.target.value)}/>
                <SelectForm 
                    title='เครื่องดนตรี' options={TANG_OPTIONS} value={addSheetModal.sheetType} 
                    onChange={e => onModalDataChange('addSheetModal', 'sheetType', parseInt(e.target.value))}
                />
            </FormModal>
            <FormModal title='แก้ไขเอกสาร' show={editSheetModal.show} resetData={() => setModalData({...initialModalData})} onSubmitCallback={onEditSheetCallback} >
                <TextForm title='ชื่อเอกสาร' placeholder='เช่น ค้างคาวกินกล้วย' value={editSheetModal.sheetName} onChange={e => onModalDataChange('editSheetModal', 'sheetName', e.target.value)}/>
                <TextForm title='รายละเอียด' placeholder='เช่น ผู้แต่ง (ไม่บังคับ)' value={editSheetModal.sheetContent} onChange={e => onModalDataChange('editSheetModal', 'sheetContent', e.target.value)}/>
                <SelectForm 
                    title='ประเภท' options={ShareOptions} value={editSheetModal.shareType} 
                    onChange={e => onModalDataChange('editSheetModal', 'shareType', parseInt(e.target.value))}
                />
                <hr />
                <div className="text-center"><Button variant="danger" onClick={onDeleteSheetClick} >ลบเอกสาร</Button></div>
            </FormModal>
            <div className="container" style={{maxWidth: 1000}}>

                <RoundContainer>
                    <Row bsPrefix="row align-items-center">
                        <Col><h5>เอกสารของฉัน</h5></Col>
                        <Col bsPrefix="col col-auto text-end">
                            <Button variant="primary" onClick={() => onModalDataChange('addSheetModal', 'show', true)}>เพิ่ม</Button>        
                        </Col>
                    </Row>
                    <br />
                    <Row bsPrefix="row text-center bg-light text-bold pt-2 pb-2 mb-2" hidden={!userSheets}>
                        <Col sm='8' xs='6'>ชื่อเอกสาร</Col>
                        <Col sm='2' xs='2'>ทาง</Col>
                        <Col>ตั้งค่า</Col>
                    </Row>
                    {userSheets ? Object.keys(userSheets).map((id, index) => <SheetItem key={index} sheets={userSheets} id={id} isSetting={true} onSettingClick={() => onEditSheetClick(id)}/>) : <div />}
                </RoundContainer>
                <br />

                <RoundContainer>
                    <h5>เอกสารจากลิงก์ที่เคยเปิด</h5>
                    <Row bsPrefix="row text-center bg-light text-bold pt-2 pb-2" hidden={!sharedSheets}>
                        <Col sm='8' xs='6'>ชื่อเอกสาร</Col>
                        <Col sm='2' xs='2'>ทาง</Col>
                        <Col>จาก</Col>
                    </Row>
                    {sharedSheets ? Object.keys(sharedSheets).map((id, index) => <SheetItem key={index} sheets={sharedSheets} id={id} />) : <div />}
                </RoundContainer>
                <br />

                <RoundContainer>
                    <h5>เอกสารสาธารณะ</h5>
                    <Row bsPrefix="row text-center bg-light text-bold pt-2 pb-2" hidden={!globalSheets}>
                        <Col sm='8' xs='6'>ชื่อเอกสาร</Col>
                        <Col sm='2' xs='2'>ทาง</Col>
                        <Col>จาก</Col>
                    </Row>
                    {globalSheets ? Object.keys(globalSheets).map((id, index) => <SheetItem key={index} sheets={globalSheets} id={id} />) : <div />}
                </RoundContainer>
            </div>
        </>

    )
}

export default SoundSoftwareOuter;