import React, { useContext, useEffect } from "react";
import { MicContext } from "../../providers/MicProvider";
import { PlayingContext } from "../../providers/PlayingProvider";
import { inputStream, setInputStream } from "../constant";

const Panel = (props) => {

    const {micEnable, setMicEnable, setMicDisable, getMicStream } = useContext(MicContext);
    const {isReady, setReady, isPlaying } = useContext(PlayingContext);

    useEffect(() => {
        const btnMic = document.getElementById("btn-mic");
        const icon = document.getElementById('mic-icon');
        if (micEnable) {
            btnMic.className = "btn btn-info rounded-circle btn-lg"
            icon.className = "fa fa-microphone";
        }
        else {
            btnMic.className = "btn btn-outline-danger rounded-circle btn-lg"
            icon.className = "fa fa-microphone-slash";
        }
        //btnMic.disabled = isPlaying;
    }, [micEnable, isPlaying])

    useEffect(() => {
        const btnReady = document.getElementById("btn-ready");
        const icon = document.getElementById('ready-icon');
        if (isReady) {
            btnReady.className = "btn btn-success btn-lg rounded-circle";
            icon.className = "fa fa-hand-stop-o";
        }
        else {
            btnReady.className = "btn btn-outline-danger btn-lg rounded-circle";
            icon.className = "fa fa-hand-stop-o";
        }
        btnReady.disabled = isPlaying;
        
    }, [isReady, isPlaying])



    const btnMicClick = async() => {
        const btnMic = document.getElementById("btn-mic");
        btnMic.disabled = true;
        if (!micEnable) {
            getMicStream()
            .then(s => setInputStream(s))
            .then(() => setMicEnable())
            //.then(s => setMicEnable(s))
            .then(() => btnMic.disabled = false)
            /*.then(() => {
                let recorder = inputStream ? new MediaRecorder(inputStream , {audioBitsPerSecond : 88200}) : undefined;
                console.log(recorder)
            })*/
            .catch(e => console.log(e))
        }
        else {
            if (inputStream) inputStream.getTracks().forEach(track => track.stop());
            //await micStream.getTracks().forEach(track => {track.stop()})
            /*.then(() => setMicDisable())
            .then(() => btnMic.disabled = false)
            .catch(e => console.log(e))*/
            setMicDisable();
            btnMic.disabled = false;
        }
    }

    const btnReadyClick = () => {
        if (!isReady) {
            setReady(true);
        }
        else {
            setReady(false);
        }
    }

    return !props.vertical ? (
        <div className="row">
            <div className="col">
                <button id="btn-ready" onClick={btnReadyClick} className="btn btn-outline-danger btn-lg rounded-circle"
                 data-toggle="tooltip" data-placement="bottom" title="กดเพื่อบอกคนอื่นว่าเราพร้อมแล้ว หรือไม่พร้อม">
                    <i id="ready-icon" className="fa fa-hand-stop-o" style={{pointerEvents: 'none'}}></i>
                </button>
            </div>
            <div className="col">
                <button id="btn-mic" onClick={btnMicClick} className="btn btn-outline-danger btn-lg rounded-circle"
                 data-toggle="tooltip" data-placement="bottom" title="กดเพื่อเปิด/ปิดไมค์ของเรา">
                    <i id="mic-icon" className="fa fa-microphone-slash" style={{pointerEvents: 'none'}}></i>
                </button>
            </div>
        </div>
    ) : (
        <div className="text-center">
            <button id="btn-ready" onClick={btnReadyClick} className="btn btn-outline-danger btn-lg rounded-circle">
                <i id="ready-icon" className="fa fa-hand-stop-o" style={{pointerEvents: 'none'}}></i>
            </button>
            <br />
            <br />
            <button id="btn-mic" onClick={btnMicClick} className="btn btn-outline-danger btn-lg rounded-circle">
                <i id="mic-icon" className="fa fa-microphone-slash" style={{pointerEvents: 'none'}}></i>
            </button>
        </div>
    )
}

export default Panel;