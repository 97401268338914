import { Button, Form, Modal } from "react-bootstrap";
//import { initialModalData } from "../const";

const FormModal = props => {

    //required
    const { show, resetData, children, onSubmitCallback } = props;
    //optional
    const { size, title, txtClose, txtSubmit, onShow } = props;

    const onModalHide = () => {
        resetData()
    }

    const onConfirmClick = e => {
        e.preventDefault();
        onSubmitCallback();
        resetData();
    }

    return (
        <Modal show={show} size={size || 'lg'} onHide={onModalHide} onShow={onShow || (() => {})}>
            <Form>
                <Modal.Header>
                    <div>{title}</div>
                </Modal.Header>
                
                <Modal.Body>{children}</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={onModalHide}>{txtClose || 'ปิด'}</Button>
                    <Button variant="primary" type='submit' onClick={onConfirmClick}>{txtSubmit || 'ยืนยัน'}</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default FormModal;

/*const FormModal = props => {

    //required
    const { modalObj, show, setShow, children, onSubmitCallback } = props;

    //optional
    const { size, title, txtClose, txtSubmit } = props;

    //const onModalHide = () => setShow(m => ({...m, [show] : {...m[show], show : false}}))

    const onModalHide = () => {
        setShow(m => ({...m, [show] : initialModalData[show]}))
    }

    const onConfirmClick = e => {
        e.preventDefault();
        onSubmitCallback();
        setShow(m => ({...m, [show] : initialModalData[show]}))
    }

    return (
        <Modal show={show && modalObj && modalObj[show] ? modalObj[show].show : false} size={size || 'lg'} onHide={onModalHide}>
            <Form>
                <Modal.Header>
                    <div>{title}</div>
                </Modal.Header>
                
                <Modal.Body>{children}</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={onModalHide}>{txtClose || 'ปิด'}</Button>
                    <Button variant="primary" type='submit' onClick={onConfirmClick}>{txtSubmit || 'ยืนยัน'}</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default FormModal;*/