import { useContext, useEffect, useState } from "react";
import SuperHeader from "../layout/SuperHeader";
import RoundContainer from '../layout/RoundContainer';
import { Button, Row, Col, Form } from "react-bootstrap";
import { positionOptions } from "../constant";
import { SelectForm, TextForm } from "../layout/FormItem";
import { UpdateSingleUser } from "../../databases/users/updateUser";
import { AuthContext } from "../../providers/AuthContext";
import { checkUserName } from "../../databases/users/getUserName";
import ConfirmModal from "../layout/ConfirmModal";
import { deleteUser } from "../../databases/users/deleteUser";

const userDetail = {
    userName : 'ชื่อผู้ใช้งาน',
    email : 'อีเมล',
    firstName : 'ชื่อจริง',
    lastName : 'นามสกุล',
    birthYear : 'ปีเกิด',
    location : 'องค์กร',
    position : 'สถานะ',
}

const initialTypingData = {
    userName : '',
    email : '',
    firstName : '',
    lastName : '',
    birthYear : '',
    location : '',
    position : '',
}

const UserPage = () => {

    //const [userData, setUserData] = useState(userInitialState);
    const { userData, resetUserData } = useContext(AuthContext)
    const [isEditing, setEditing] = useState(false);
    const [typingData, setTypingData] = useState(initialTypingData);
    const [isNameOK, setNameOK] = useState(true);
    const [deleteUserModal, setDeleteUserModal] = useState(false)

    //const { firstName, lastName, birthYear, location, position, email, userName } = userData;

    useEffect(() => {
        return () => {
            setEditing(false)
            setTypingData(initialTypingData)
            setNameOK(true);
            setDeleteUserModal(false);
        }
    }, []);

    useEffect(() => {
        if (isEditing) {
            setTypingData({...userData})
        }
    }, [isEditing, userData])

    const onFormChange = (key, value) => {
        setTypingData(d => ({...d, [key] : value}))
        if (key === 'userName') {
            checkUserName(value).then(isOK => setNameOK(isOK))
        }
    }

    const onSubmit = async e => {
        e.preventDefault();
        let data = {...typingData}
        for (const k in data) {
            if (data[k] === userData[k]) delete data[k]
        }
        UpdateSingleUser(data)
        .then(() => {
            resetUserData();
            setEditing(false);
        })
        .catch(e => {
            alert(e)
        })
    }

    return (
        <>
            <ConfirmModal show={deleteUserModal} size='md' onModalHide={() => setDeleteUserModal(false)} title='ยืนยันที่จะลบหรือไม่' onSubmitCallback={deleteUser} >
                ข้อมูลที่เคยให้ บัญชีผู้ใช้งาน และโน้ตที่เขียนไว้จะถูกลบทั้งหมด
            </ConfirmModal>
            <SuperHeader title='ข้อมูลผู้ใช้งาน'/>
            <div className="container" style={{maxWidth : '600px'}}>
                <RoundContainer>
                    <div className="text-end">
                        <Button variant="warning" hidden={isEditing} onClick={() => setEditing(true)}>แก้ไข</Button>
                        <Button variant="danger" hidden={!isEditing} onClick={() => setEditing(false)}>ยกเลิก</Button>
                        <Button variant="primary" type='submit' hidden={!isEditing} disabled={!isNameOK} onClick={onSubmit}>ยืนยัน</Button>
                    </div>
                    <br />
                    <Form onSubmit={onSubmit} >
                    <div style={{fontSize : '13px'}} className="text-danger" hidden={isNameOK}>ชื่อ {typingData.userName} มีการใช้งานแล้ว !</div>
                    {Object.keys(userDetail).map((k, index) => (
                        <div key={index}>
                            <Row bsPrefix="row align-items-center">
                                <Col sm='6' xs='12' style={{fontWeight : 'bold'}}>{userDetail[k]}</Col>
                                <Col hidden={!isEditing}>
                                    {k !== 'position' ? 
                                        <TextForm noMargin={true} disabled={k === 'email'} value={typingData[k]} onChange={e => onFormChange(k, e.target.value)}/> 
                                        : <SelectForm noMargin={true} value={typingData[k]} onChange={e => onFormChange(k, parseInt(e.target.value))} options={positionOptions}/>}
                                </Col>
                                <Col hidden={isEditing}>{k !== 'position' ? userData[k] : positionOptions[userData[k]]}</Col>
                            </Row>
                            <hr />
                        </div>
                    ))}
                    </Form>
                    <br />
                    <div className="text-center">
                        <Button variant="danger" onClick={() => setDeleteUserModal(true)}>ลบบัญชี</Button>
                    </div>
                </RoundContainer>
            </div>
        </>
    )
}

export default UserPage;