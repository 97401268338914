import socket from './socket-io2';
export default socket;

/*import { Howl, Howler } from 'howler';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import socketIOClient from 'socket.io-client';
import { inputStream, ching, chab, getNaTabTime, playNaTab, playingRhythm } from '../components/constant';

//const socket = socketIOClient("http://localhost:9000", { transports : ['websocket'], autoConnect: false });
const socket = socketIOClient("https://thai-music-band-socket-server1.herokuapp.com/", { transports : ['websocket'], autoConnect: false });

//const socket = socketIOClient(process.env.REACT_APP_SERVER_URL, { transports : ['websocket'], autoConnect: false });

const bdRoom0 = getComputedStyle(document.documentElement).getPropertyValue('--bdRoom0');
const bgRoom1 = getComputedStyle(document.documentElement).getPropertyValue('--bgRoom1');
const bdRoom1 = getComputedStyle(document.documentElement).getPropertyValue('--bdRoom1');
const bgActiveRoom1 = getComputedStyle(document.documentElement).getPropertyValue('--bgaRoom1');
const bgRoom2 = getComputedStyle(document.documentElement).getPropertyValue('--bgRoom2');
const bdRoom2 = getComputedStyle(document.documentElement).getPropertyValue('--bdRoom2');
const bgActiveRoom2 = getComputedStyle(document.documentElement).getPropertyValue('--bgaRoom2');
const bdActive = getComputedStyle(document.documentElement).getPropertyValue('--bdaRoom');

const setRoomActive = (room, setActive, roomType) => {
    //const borderWidth = '3px';
    //const activeColor = '#FF0000';
    //const inActiveColor = '#c8c8c8';
    const bR = `${setActive ? '6px' : '3px'} solid ${setActive ? (bdActive) : (roomType === 1 ? bdRoom2 : bdRoom1 )}`;
    //const col = setActive ? activeColor : roomType === 1 ? inActiveColor2 : inActiveColor1;
    //const col = setActive ? (roomType === 1 ? bgActiveRoom2 : bgActiveRoom1) : (roomType === 1 ? bgRoom2 : bgRoom1 );
    try {
        document.getElementById(`room${room}`).style.borderRight = bR;
        //document.getElementById(`room${room}`).style.background = col;
    }
    catch (e) {console.log(e)}
}

const setRoomSmoothActive = (room, roomType, speed) => {
    const emn = document.getElementById(`room${room + 1}`);

    if (roomType === 0) {
        emn.style.borderRight = '5px solid ' + bdActive;
        setTimeout(() => {
            emn.style.borderRight = '3px solid ' + (roomType === 1 ? bdRoom2 : bdRoom1)
        }, speed)
    }
    else if (roomType === 1) {
        emn.style.background = roomType === 1 ? bgActiveRoom2 : bgActiveRoom1;
        setTimeout(() => {
            emn.style.background = roomType === 1 ? bgRoom2 : bgRoom1
        }, speed)
    }
    else {
        emn.style.borderRight = '5px solid ' + bdActive;
        setTimeout(() => {
            emn.style.borderRight = '3px solid ' + bdRoom0;
        }, speed)
    }

}

//https://stackoverflow.com/questions/51224040/concatenate-two-audio-blobs-javascript
//blobArray -> Howl
const mergeAudio = (blobArray) => {
    console.log(blobArray);
    const blob = new Blob([blobArray], {type: 'audio/mpeg'});
    return new Howl({
        src: [URL.createObjectURL(blob)],
        format: [Howler._codecs.mp3 ? 'mp3' : Howler._codecs.webm ? 'webm' : Howler._codecs.dolby ? 'dolby' : 'mp3'], 
        html5: true ,
        onplayerror: (soundId, errorMessage) => socket.emit('sendChatMessage', errorMessage),
        onloaderror: (soundId, errorMessage) => socket.emit('sendChatMessage', errorMessage)
    })
}

//var blobBuffer = {};
var sound = {};

socket.on('onPlaying', async (line, speed, rate, naTab, users) => {

    
    socket.on('receiveBlob', (id, l, b) => {
        //blobBuffer[l] = {...blobBuffer[l], [id] : b }
        const blob = new Blob(b, {type: 'audio/mpeg'})
        let url = URL.createObjectURL(blob);
        sound[l] = {...sound[l], [id] : new Howl({
            src: [url], 
            format: [Howler._codecs.webm ? 'webm' : Howler._codecs.dolby ? 'dolby' : 'mp3'], 
            html5: true ,
            onplayerror: (soundId, errorMessage) => socket.emit('sendChatMessage', 'PLAY ERROR: ' + errorMessage),
            onloaderror: (soundId, errorMessage) => socket.emit('sendChatMessage', 'LOAD ERROR: ' + errorMessage)
        })}
        //new Howl({src: [url], format: ['ogg']});
        console.log(sound);
    })

    //No codec support for selected audio sources.

    //const mic = isMicEnable(users, socket.id);
    //const micChild = await database.ref(`users/${socket.id}/isMicEnable`).get();
    //const mic = micChild.val();
    //const stream = mic ? await navigator.mediaDevices.getUserMedia({audio: inputDevice ? {deviceId: inputDevice} : true, video: false}) : undefined;
    //const recorder = stream ? new MediaRecorder(stream , {audioBitsPerSecond : 128000 }) : undefined;
    let room = 0;
    //let k = 1;
    const lastRoom = (2 * line + 1) * 8 + 4;
    const naTabRoom = parseInt(getNaTabTime(naTab, speed)/speed);
    //let chunks = [];
    const p = new Promise(r => {
        const run = () => {                    
            //setRoomActive(room + 1, true, room + 1 <= (line + 1) * 8 ? 0 : 1);
            //setRoomActive(room, false, room <= (line + 1) * 8 ? 0 : 1);
            //ching.volume(chingVolume);
            //chab.volume(chingVolume);
            
            setRoomSmoothActive(room, room < 8 ? 2 : room < (line + 1) * 8 ? 0 : 1, speed);
            room++;
            switch(playingRhythm) {
                case 0: default:
                    break;
                case 1:
                    if ((room  - 8 ) % naTabRoom === 0 && room >= 8 && room < (line + 1) * 8) playNaTab(naTab, speed); 
                    if (room <= (line + 1) * 8) {
                        
                        switch(rate) {
                            case 1:
                                if (room !== (line + 1) * 8) setTimeout(() => ching.play(), speed /2);
                                ching.stop();
                                chab.play();
                                break;
                            case 2:
                                if (room % 2 === 1) {
                                    ching.play();
                                    setTimeout(() => ching.stop(), speed);
                                }
                                else if (room%2 === 0) chab.play();
                                break;
                            case 3 :
                                if (room % 4 === 2) {
                                    ching.play(); 
                                    setTimeout(() => ching.stop(), speed * 2);
                                }
                                else if (room % 4 === 0) chab.play();
                                break;
                            default:
                                break;
                        }
                    }
                    break;
                case 2:
                    if ((room  - 8 ) % naTabRoom === 0 && room >= 8 && room < (2 * line + 1) * 8) playNaTab(naTab, speed); 
                    if (room <= (2 * line + 1) * 8) {
                        switch(rate) {
                            case 1:
                                if (room !== (2 * line + 1) * 8) setTimeout(() => ching.play(), speed /2);
                                ching.stop();
                                chab.play();
                                break;
                            case 2:
                                if (room % 2 === 1) {
                                    ching.play();
                                    setTimeout(() => ching.stop(), speed);
                                }
                                else if (room%2 === 0) chab.play();
                                break;
                            case 3 :
                                if (room % 4 === 2) {
                                    ching.play(); 
                                    setTimeout(() => ching.stop(), speed * 2);
                                }
                                else if (room % 4 === 0) chab.play();
                                break;
                            default:
                                break;
                        }
                    }
                    break;

            }

            
            switch (room) {
                case lastRoom + 1:
                    setRoomActive(lastRoom, false, 1);
                    clearInterval(interval);
                    //if (stream) stream.getTracks().forEach(track => {track.stop()});
                    r();
                    break;
                default:
                    break;
            }
            // if (room % 8 === 1 && room <= (2 * line + 1) * 8) {
            //     let l = (room - 1)/8;
            //     if (l <= line) {
            //         console.log(`start line ${l}`)
            //         //let recorder = stream ? new MediaRecorder(stream , {audioBitsPerSecond : 128000 }) : undefined;
            //         let recorder = inputStream ? new MediaRecorder(inputStream , {audioBitsPerSecond : 128000 }) : undefined;
            //         let chunks = [];
            //         if (recorder) {
            //             recorder.start();
            //             recorder.ondataavailable = (e) => chunks.push(e.data);
            //             recorder.onstop = () => socket.emit('sendBlob', l, chunks);
            //             setTimeout(() => recorder.stop(), speed * 8);
            //         }
            //     }
            //     else {
            //         if (sound[l - line]) Object.values(sound[l - line]).forEach(s => s.play());
            //     }
            // }
            if (room % 4 === 1 && room >= 8 && room <= (2 * line + 1) * 8) {
                let r = (room - 1)/4 - 1;
                if (r <= line * 2) {
                    let recorder = inputStream ? new MediaRecorder(inputStream , {audioBitsPerSecond : 88200,}) : undefined;
                    let chunks = [];
                    try {
                        if (recorder) {
                            recorder.start();
                            recorder.ondataavailable = (e) => chunks.push(e.data);
                            recorder.onstop = () => socket.emit('sendBlob', r, chunks);
                            setTimeout(() => {
                                if (inputStream && recorder && recorder.state !== 'inactive') recorder.stop()
                            } , speed * 4);
                        }
                    }
                    catch(e) {console.log(e.message);}
                    // if (recorder) {
                    //     recorder.start();
                    //     recorder.ondataavailable = (e) => chunks.push(e.data);
                    //     recorder.onstop = () => socket.emit('sendBlob', r, chunks);
                    //     setTimeout(() => recorder.stop(), speed * 4);
                    // }



                    // let recorder = inputStream ? new RecordRTC(inputStream, recorderOptions) : undefined;
                    // try {
                    //     if (recorder) {
                    //         recorder.setRecordingDuration(speed * 4, () => {
                    //             if (inputStream && recorder ) recorder.getBlob().arrayBuffer().then(b => {
                    //                 socket.emit('sendBlob', r, b);
                    //             })
                    //             .catch(e => console.log(e.message))
                    //         })
                    //         recorder.startRecording();
                    //         console.log(recorder);
                    //         setTimeout(() => {
                    //             if (inputStream && recorder ) recorder.stopRecording(async () => {
                    //                 let b = await recorder.getBlob().arrayBuffer();
                                    
                    //             })
                    //         }, speed * 4)
                    //         //setTimeout(() => recorder.stopRecording(() => socket.emit('sendBlob', r, recorder.getBlob())), speed * 4)
                    //     }
                    // }
                    // catch(e) {console.log(e.message);}
                    
                }
                else {
                    //console.log(sound[parseInt(r - line * 2)]);
                    if (sound[parseInt(r - line * 2)]) Object.values(sound[parseInt(r - line * 2)]).forEach(s => {s.play(); console.log(s);});
                    //if (sound[k]) sound[k].play();
                    //if (blobBuffer[r - line * 2]) mergeAudio(Object.values(blobBuffer[r - line * 2])).play();
                    //setTimeout(() => k++, speed)
                }
            }
            //else if (room % 4 === 0 && room >= (line + 1) * 8 && room <= (2 * line + 1) * 8) {
                //if (blobBuffer[k]) console.log(blobBuffer[k]); console.log(Object.values(blobBuffer[k]));
                //if (blobBuffer[k] !== undefined && blobBuffer[k] !== null) sound[k] = mergeAudio(Object.values(blobBuffer[k]));

                //sound[k] = mergeAudio(Object.values(blobBuffer[k]));
            //}
        }
        const interval = setInterval(run, speed);
    })
    p.then(() => {
        socket.emit('endPlaying');
        console.log('fin');
        sound = {};
        const btnReady = document.getElementById("btn-ready");
        const icon = document.getElementById('ready-icon');
        if (btnReady) btnReady.disabled = true;
        setTimeout(() => {
            if (btnReady) btnReady.className = "btn btn-outline-danger btn-lg rounded-circle"; btnReady.disabled = false;
            if (icon) icon.className = "fa fa-hand-stop-o";
        }, 2000);
        
    })
})

// const recorderOptions = {
//     type: 'audio',
//     recorderType: StereoAudioRecorder, 
//     //mimeType: 'audio/webm', 
//     desiredSampRate: 44100,
// }

export default socket;*/


/*socket.on('receiveBlob', (id, l, b) => {
    //For MediaRecorder
    //const blob = new Blob(b, {type: 'audio/mpeg'})

    //For RecordRTC
    const blob = new Blob([b], {type: 'audio/mpeg'})

    let url = URL.createObjectURL(blob);
    sound[l] = {...sound[l], [id] : new Howl({
        src: [url], 
        format: [Howler._codecs.webm ? 'webm' : Howler._codecs.dolby ? 'dolby' : 'mp3'], 
        html5: true ,
        //onplayerror: (soundId, errorMessage) => socket.emit('sendServerMessage', 'PLAY ERROR: ' + errorMessage),
        //onloaderror: (soundId, errorMessage) => socket.emit('sendServerMessage', 'LOAD ERROR: ' + errorMessage)
    })};
    console.log(sound);
})*/

/*socket.on('receiveBlob2', b => {
    if (!recorderNum || recorderNum === 0) database.ref('recorderNum').get().then(v => recorderNum = v.val())
    const blob = new Blob([b], {type: 'audio/mpeg'});
    const url = URL.createObjectURL(blob);
    recordedSound = [...recordedSound, new Howl({
        src: [url], 
        format: [Howler._codecs.webm ? 'webm' : Howler._codecs.dolby ? 'dolby' : 'mp3'], 
        html5: true ,
    })]
    recordedSound = [...recordedSound, url];
})*/

/*if (recorderNum && recordedSound !== [] && recorderNum === recordedSound.length) {
                clearInterval(sit);
                let output = [];
                recordedSound.forEach(s => output = [...output, new Howl({
                    src: [s],
                    format: ['mp3'],
                    html5: true,
                })])
                resolve(output);
                crunker.fetchAudio(...recordedSound)
                    .then(buffers => crunker.mergeAudio(buffers))
                    .then(merged => crunker.export(merged, 'audio/mp3'))
                    .then(output => new Howl(({
                        src: [output.url],
                        format: ['mp3'],
                        html5: true,
                        onplayerror: (soundId, errorMessage) => socket.emit('sendServerMessage', 'PLAY ERROR: ' + errorMessage),
                        onloaderror: (soundId, errorMessage) => socket.emit('sendServerMessage', 'LOAD ERROR: ' + errorMessage)
                    })))
                    .then(output => resolve(output))
                    .catch(e => reject(`เกิดข้อผิดพลาด: นามสกุลไฟล์เสียงไม่รองรับ ${e}`))
}*/

/*crunker.fetchAudio(...superBlob)
                .then(buffers => crunker.mergeAudio(buffers))
                .then(merged => crunker.export(merged, 'audio/mp3'))
                .then(output => setSuperAudio(output))
                .then(() => recordNumRef.current = null)
                .then(() => audioNumRef.current = 0)
                .catch(e => console.log(e.message));*/