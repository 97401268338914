import { auth, firestore } from "../firebase";


const addUser = async userData => {
    //const doc = await firestore.collection('users').add({...userData, uid : auth.currentUser.uid})
    if (auth.currentUser) {
        await firestore.collection('users').doc(auth.currentUser.uid).set({...userData});
        return auth.currentUser.uid
    }
    else {
        //const doc = await firestore.collection('users').add({...userData})
        //return doc.id
        return ''
    }
}

export default addUser;