import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PagesContext } from "../../../providers/PagesProvider";
import socket from "../../../server/socket-io";
import { Ting } from "../../../sounds/EffectSound";

const HOME_ICON = "fa fa-home";
const CHAT_ICON = "fa fa-comments-o";
const SETTING_ICON = "fa fa-gear";
const ABOUT_ICON = "fa fa-code";
const HELP_ICON = "fa fa-question-circle-o";
//const COMMUNITY_ICON = "fa fa-users";
//const SUPPORT_ICON = "fa fa-trophy";
const NOTE_ICON = "fa fa-pencil-square-o";
const SOUND_ICON = "fa fa-music";
const SIGN_OUT_ICON = 'fa fa-sign-out';

const NavbarItem = (linkTo, icon) => {
    const {setPage} = useContext(PagesContext);
    
    const touchStart = (e) => {
        Ting.play()
        const em = document.getElementById(e.target.id);
        em.className = "btn btn-outline-primary";
        setTimeout(() => em.className = "btn btn-primary", 200);
    }

    return (
        <div style={{paddingTop: "33px"}}>
            <button className="btn btn-primary" style={{borderRadius: "50%", width: "67px", height: "67px"}} 
            onClick={() => setPage(linkTo)} onTouchStart={touchStart}>
                <i className={`${icon} fa-2x`}/>
            </button>
        </div>
    )
}

const TabletNavbar = () => {
    const { PAGE_HOME, PAGE_CHAT, PAGE_HELP, PAGE_ABOUT, PAGE_SETTING, PAGE_NOTE, PAGE_SOUND, setPage} = useContext(PagesContext);
    const navigate = useNavigate()
    const onPPOut = () => {
        setPage(PAGE_HOME);
        socket.disconnect();
        Ting.play(); 
        navigate('/');
    }

    return(
        <div>
            {NavbarItem(PAGE_HOME, HOME_ICON)}
            {NavbarItem(PAGE_CHAT, CHAT_ICON)}
            {NavbarItem(PAGE_NOTE, NOTE_ICON)}
            {NavbarItem(PAGE_SOUND, SOUND_ICON)}
            {NavbarItem(PAGE_SETTING, SETTING_ICON)}
            {NavbarItem(PAGE_HELP, HELP_ICON)}
            {/*NavbarItem(PAGE_COMMUNITY, COMMUNITY_ICON)}
            {NavbarItem(PAGE_SUPPORT, SUPPORT_ICON)*/}
            {NavbarItem(PAGE_ABOUT, ABOUT_ICON)}
            <div style={{paddingTop: "33px"}}>
                <button className="btn btn-danger" style={{borderRadius: "50%", width: "67px", height: "67px"}} 
                onClick={onPPOut}>
                    <i className={`${SIGN_OUT_ICON} fa-2x`}/>
                </button>
            </div>
            
        </div>
    )
}

export default TabletNavbar;