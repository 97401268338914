import React, { createContext, useReducer } from "react";

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_ROOM":
            return {...state, [action.payload.roomNum] : action.payload.value}
        case "CLEAR_ROOM":
            return {}
        default:
            break;
    }
}

export const NoteContext = createContext({});

export const NoteProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, {});
    const setRoom = (roomNum, value) => {
        dispatch({ type: "SET_ROOM", payload : {roomNum: roomNum, value: value}})
    }
    const clearAll = () => dispatch({type: "CLEAR_ROOM"})
    const getRoom = roomNum => {
        return state[roomNum] || ""
    }

    return (
        <NoteContext.Provider value={{getRoom, setRoom, clearAll}}>
            {children}
        </NoteContext.Provider>
    )
}