import { Howl } from "howler";
import socket from '../server/socket-io';

export const chab = new Howl({
    src: ['chab.wav'],
    volume: 0.25,
    onplayerror: (soundId, errorMessage) => socket.emit('sendServerMessage', 'PLAY ERROR: ' + errorMessage),
    onloaderror: (soundId, errorMessage) => socket.emit('sendServerMessage', 'LOAD ERROR: ' + errorMessage)
});

export const ching = new Howl({
    src: ['ching.wav'],
    volume: 0.25,
});

export const C5 = new Howl({
    src: ['C5.wav'],
    volume: 0.5,
})

export let chingVolume = 0.25;
export const setChingVolume = (v) => {
    chingVolume = v;
}

export let effectVolume = 0.5;
export const setEffectVolume = (v) => {
    effectVolume = v;
}

export const Tumm = new Howl({
    src: ['Tumm.wav'],
    volume: 0.5,
})

export const Ting = new Howl({
    src: ['Ting.wav'],
    volume: 0.5,
})

export const Choo = new Howl({
    src: ['Choo.wav'],
    volume: 0.5,
})

export const Chaa = new Howl({
    src: ['Chaa.wav'],
    volume: 0.5,
})

export const SoundEffectFunction = () => {
    const elementArray = [...document.getElementsByClassName('btn')]
    const noSoundBtn = ["btn-send-chat-message", "btn-test-running", "btn-test-audio-1", "btn-start-playing"]
    elementArray.forEach(e => {
        if (!noSoundBtn.find(id => e.id === id)) {
            e.onclick = () => chab.play();
            e.ontouchstart = () => chab.play();
        }
    });
}