import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { database } from "../../databases/firebase";
import { PlayingContext } from "../../providers/PlayingProvider";
import { filesToOneFile } from "../../server/audioFunctions";
import socket from "../../server/socket-io";
import { ICON_DOWNLOAD } from "../icons";


const SoundPanel = () => {
    const [showModal, setShowModal] = useState(false);
    const [fileName, setFileName] = useState('');
    const [superBlob, setSuperBlob] = useState([]);
    const [superAudio, setSuperAudio] = useState([]);

    const { isPlaying } = useContext(PlayingContext);

    const recordNumRef = useRef(null);
    const audioRef = useRef({});
    const audioNumRef = useRef(0);

    useEffect(() => {
        socket.on('receiveLargeBuffer2', (id, b) => {
            if (!recordNumRef.current) database.ref('recorderNum2').get().then(v => {
                recordNumRef.current = v.val();
            })
            audioRef.current[id] = audioRef.current[id] ? [...audioRef.current[id], b] : [b];
        })

        socket.on('receiveLargeBuffer2Finish', () => {
            audioNumRef.current++;
            if (recordNumRef.current && audioNumRef.current === recordNumRef.current) {
                Object.values(audioRef.current).forEach(b => {
                    const blob = new Blob(b, {type: 'audio/mpeg'});
                    const url = URL.createObjectURL(blob);
                    setSuperBlob(a => [...a, url]);
                })
            }
        });
    }, []);

    useEffect(() => {
        if (superBlob !== [] && audioNumRef.current === recordNumRef.current) setTimeout(async() => {
            const output = await filesToOneFile(...superBlob);
            setSuperAudio(output);
            recordNumRef.current = null;
            audioNumRef.current = null;
        }, 1000);
    }, [superBlob]);

    useEffect(() => {
        if (superAudio) document.getElementById('soundpanel-superaudio').setAttribute('src', superAudio.url);
    }, [superAudio])

    useEffect(() => {
        if (isPlaying) setTimeout(() => {
            setSuperBlob([]);
            recordNumRef.current = null;
            audioRef.current = {};
            audioNumRef.current = 0;
        }, 100);
    }, [isPlaying])

    const onDownloadClick = () => {
        try {
            if (superAudio.blob) downloadSound(superAudio.blob, fileName || 'song');//crunker.download(superAudio.blob, fileName || 'song');
        }
        catch (e) {
            console.log(e);
        }
        finally {
            let ip = document.getElementById('soundpanel-input-filename');
            setTimeout(() => {
                setShowModal(false);
                setFileName('');
                if (ip) ip.value = '';
            }, 1000);
        }
    }

    const downloadSound = (_blob, _filename) => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = URL.createObjectURL(_blob);
        a.download = `${_filename}.${_blob.type.split('/')[1]}`;
        a.click();
        return a;
    }

    return(
        <div>
            <Modal show={showModal} size="sm" onHide={() => setShowModal(false)}>
                <Modal.Body >
                    <form>
                        <label>ชื่อไฟล์</label>
                        <input type="text" id="soundpanel-input-filename" className="form-control" onChange={e => setFileName(e.target.value)}></input>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn btn-outline-danger" onClick={() => setShowModal(false)}>ยกเลิก</div>
                    <div className="btn btn-primary" onClick={onDownloadClick}>ดาวน์โหลด</div>
                </Modal.Footer>
            </Modal>



            <div className="row text-center" style={{alignItems: 'center'}}>
                <div className="col-10">
                    <audio id='soundpanel-superaudio' style={{width: '100%'}} controls playsInline/>
                    </div>
                <div className="col-2"><button className="btn rounded-circle btn-primary" disabled={superAudio.url === undefined} onClick={() => setShowModal(true)}>{ICON_DOWNLOAD}</button></div>
            </div>
        </div>
    )
}

export default SoundPanel;