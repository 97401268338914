
import { getNaTabTime, playNaTab, playingRhythm } from '../components/constant';
import { ching, chab } from '../sounds/EffectSound';


const bdRoom0 = getComputedStyle(document.documentElement).getPropertyValue('--bdRoom0');
const bdRoom1 = getComputedStyle(document.documentElement).getPropertyValue('--bdRoom1');
const bgRoom2 = getComputedStyle(document.documentElement).getPropertyValue('--bgRoom2');
const bgActiveRoom2 = getComputedStyle(document.documentElement).getPropertyValue('--bgaRoom2');
const bdActive = getComputedStyle(document.documentElement).getPropertyValue('--bdaRoom');

const setRoomActive = (room, roomType, speed) => {
    const em = document.getElementById(`room${room}`);
    switch (roomType) {
        case 0: 
            em.style.borderRight = '5px solid ' + bdActive;
            setTimeout(() => em.style.borderRight = '3px solid ' + bdRoom0, speed);
            break;
        case 1:
            em.style.borderRight = '5px solid ' + bdActive;
            setTimeout(() => em.style.borderRight = '3px solid ' + bdRoom1, speed);
            break;
        case 2:
            em.style.background = bgActiveRoom2;
            setTimeout(() => em.style.background = bgRoom2, speed);
            break;
        default:
            break;
    }
}

const playRhythm = (room, line, rate, speed, naTab, round) => {
    const naTabRoom = parseInt(getNaTabTime(naTab, speed)/speed);
    const lastPlayingRoom = (line + 1) * 8;

    if ((room  - 8 ) % naTabRoom === 0 
        && room >= 8 
        && playingRhythm >= round 
        && (room < lastPlayingRoom)) playNaTab(naTab, speed); 

    if (room <= lastPlayingRoom && playingRhythm >= round) {
        switch (rate) {
            case 1:
                if (room !== lastPlayingRoom) setTimeout(() => ching.play(), speed /2);
                ching.stop();
                chab.play();
                break;
            case 2:
                if (room % 2 === 1) {
                    ching.play();
                    setTimeout(() => ching.stop(), speed);
                }
                else if (room % 2 === 0) chab.play();
                break;
            case 3:
                if (room % 4 === 2) {
                    ching.play(); 
                    setTimeout(() => ching.stop(), speed * 2);
                }
                else if (room % 4 === 0) chab.play();
                break;
            default:
                break;
        }
    }
}










/*const playRhythm = (room, line, rate, speed, naTab) => {
    const naTabRoom = parseInt(getNaTabTime(naTab, speed)/speed);
    const lastRecordingRoom = (line + 1) * 8;
    const lastPlayingRoom = (2 * line + 1) * 8;
    const lastPlayRhythm = playingRhythm === 2 ? lastPlayingRoom : lastRecordingRoom;
    switch (playingRhythm) {
        case 0: default:
            break;
        case 1: case 2:
            if ((room  - 8 ) % naTabRoom === 0 
                && room >= 8 
                && room < lastPlayRhythm) playNaTab(naTab, speed); 
            if (room <= lastPlayRhythm) {
                switch (rate) {
                    case 1:
                        if (room !== lastPlayRhythm) setTimeout(() => ching.play(), speed /2);
                        ching.stop();
                        chab.play();
                        break;
                    case 2:
                        if (room % 2 === 1) {
                            ching.play();
                            setTimeout(() => ching.stop(), speed);
                        }
                        else if (room % 2 === 0) chab.play();
                        break;
                    case 3:
                        if (room % 4 === 2) {
                            ching.play(); 
                            setTimeout(() => ching.stop(), speed * 2);
                        }
                        else if (room % 4 === 0) chab.play();
                        break;
                    default:
                        break;
                }
            }
            break;
    }
}*/


export {setRoomActive, playRhythm}
