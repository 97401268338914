//HOME PAGE
const ICON_CHECK = <i className='text-success fa fa-check' style={{pointerEvents: 'none'}} />
const ICON_WARN = <i className='text-warning fa fa-warning' style={{pointerEvents: 'none'}} />
const ICON_CLOSE = <i className='text-danger fa fa-close' style={{pointerEvents: 'none'}} />

//QUESTION ICON
const ICON_QUESTION = <i className="text-primary fa fa-question-circle fa-lg" />

//USER LIST ICON
const ICON_ADMIN = <i className="text-info fa fa-diamond" style={{paddingRight: "5px"}}></i>
const ICON_MICDISABLE = <i className="text-danger fa fa-microphone-slash"></i>

//SOUND RECORD PAGE
const ICON_DELETE = <i className='fa fa-trash' style={{pointerEvents: 'none'}} />
const ICON_REPEAT = <i className="fa fa-repeat" style={{pointerEvents: 'none'}} />
const ICON_PLAY = <i className="fa fa-play" style={{pointerEvents: 'none'}} />

//ABOUT PAGE
const ICON_SUPPORT = <i className="text-warning fa fa-trophy fa-lg" style={{paddingRight: "3px", paddingLeft: "3px"}}/>

//NOTE PAGE
const ICON_COPY = <i className='fa fa-copy' style={{pointerEvents: 'none'}} />
const ICON_PASTE = <i className='fa fa-paste' style={{pointerEvents: 'none'}} />
const ICON_SHARE = <i className='fa fa-share' style={{pointerEvents: 'none'}} />
const ICON_SAVE = <i className='fa fa-save' style={{pointerEvents: 'none'}} />
const ICON_DOWNLOAD = <i className='fa fa-download' style={{pointerEvents: 'none'}} />
const ICON_ARROW_RIGHT = <i className='fa fa-arrow-circle-right' style={{pointerEvents: 'none'}} />

//PP NAVBAR
export const ICON_PP_HOME = <i className='fa fa-home fa-lg' style={{pointerEvents: 'none'}} />
export const ICON_PP_SETTING = <i className='fa fa-gear fa-lg' style={{pointerEvents: 'none'}} />
export const ICON_PP_HELP = <i className='fa fa-question-circle-o fa-lg' style={{pointerEvents: 'none'}} />
export const ICON_PP_NOTE = <i className='fa fa-pencil-square-o fa-lg' style={{pointerEvents: 'none'}} />
export const ICON_PP_SOUND = <i className='fa fa-music fa-lg' style={{pointerEvents: 'none'}} />
export const ICON_PP_CHAT = <i className='fa fa fa-comments-o fa-lg' style={{pointerEvents: 'none'}} />
export const ICON_PP_BACK = <i className='fa fa-arrow-left fa-lg' style={{pointerEvents: 'none'}} />
//PP Panel
export const ICON_PP_MIC_OPEN = <i className='fa fa-microphone' style={{pointerEvents: 'none'}} />
export const ICON_PP_MIC_CLOSE = <i className='fa fa-microphone-slash' style={{pointerEvents: 'none'}} />
export const ICON_PP_HAND = <i className='fa fa-hand-stop-o' style={{pointerEvents: 'none'}} />

//SS PAGE
const sspageStyle = {
    fontSize: '16px', 
    paddingBlock: '5px', 
    pointerEvents: 'none'
}
const ssDropdownStyle = {
    fontSize: '14px',  
    pointerEvents: 'none',
    paddingRight : '8px',
    paddingBottom : '3px',
    verticalAlign: 'middle',
}

//soundsoftware page
export const ICON_ADDSHEET = <i className="fa fa-file-text" style={sspageStyle} aria-hidden="true"></i>
export const ICON_ADDTON = <i className="fa fa-plus-square" style={sspageStyle} aria-hidden="true"></i>
export const ICON_BARS = <i className="fa fa-file-text-o" style={sspageStyle} aria-hidden="true"></i>
export const ICON_SELECTTON = <i className="fa fa-hand-o-up" style={sspageStyle} aria-hidden="true"></i>
export const ICON_COPY_SELECT = <i className="fa fa-files-o" style={sspageStyle} aria-hidden="true"></i>
export const ICON_PASTE_SELECT = <i className="fa fa-clipboard" style={sspageStyle} aria-hidden="true"></i>
export const ICON_DELETE_SELECT = <i className="fa fa-trash-o" style={sspageStyle} aria-hidden="true"></i>
export const ICON_DOWNLOAD_SHEET = <i className="fa fa-download" style={sspageStyle} aria-hidden="true"></i>
export const ICON_SHARE_SHEET = <i className="fa fa-share-square-o" style={sspageStyle} aria-hidden="true"></i>
export const ICON_NOTE_UP = <i className="fa fa-arrow-up" style={sspageStyle} aria-hidden="true"></i>
export const ICON_NOTE_DOWN = <i className="fa fa-arrow-down" style={sspageStyle} aria-hidden="true"></i>
export const ICON_LOCK_SPEED = <i className="fa fa-lock" style={sspageStyle} aria-hidden="true"></i>
export const ICON_PLAY_NOTE = <i className="fa fa-play-circle-o" style={sspageStyle} aria-hidden="true"></i>
export const ICON_STOP_NOTE = <i className="fa fa-pause-circle-o" style={sspageStyle} aria-hidden="true"></i>
export const ICON_EDIT_NOTE = <i className="fa fa-pencil-square-o" style={sspageStyle} aria-hidden="true"></i>
export const ICON_EDIT_TON = <i className="fa fa-cog" style={sspageStyle} aria-hidden="true"></i>
export const ICON_COMMENT = <i className="fa fa-commenting-o" style={sspageStyle} aria-hidden="true"></i>
export const ICON_ERASE = <i className="fa fa-eraser" style={sspageStyle} aria-hidden="true"></i>
export const ICON_EXCHANGE = <i className="fa fa-exchange" style={sspageStyle} aria-hidden="true"></i>
export const ICON_KEYBOARD = <i className="fa fa-keyboard-o" style={sspageStyle} aria-hidden="true"></i>
export const ICON_SAVE_SHEET = <i className="fa fa-floppy-o fa-lg" style={sspageStyle} aria-hidden="true"></i>

export const ICON_DD_ADDTON = <i className="fa fa-plus-square" style={ssDropdownStyle} aria-hidden="true"></i>
export const ICON_DD_EDITTON = <i className="fa fa-cog" style={ssDropdownStyle} aria-hidden="true"></i>
export const ICON_DD_SAVE = <i className="fa fa-floppy-o" style={ssDropdownStyle} aria-hidden="true"></i>
export const ICON_DD_SHARE = <i className="fa fa-share-square-o" style={ssDropdownStyle} aria-hidden="true"></i>
export const ICON_DD_COMMENT = <i className="fa fa-commenting-o" style={ssDropdownStyle} aria-hidden="true"></i>
export const ICON_DD_KEYBOARD = <i className="fa fa-keyboard-o" style={ssDropdownStyle} aria-hidden="true"></i>

//SUPER HEADER PAGE
export const ICON_HEADER_HOME = <i className='fa fa-home fa-lg' style={{pointerEvents: 'none'}} />
export const ICON_HEADER_SIGNOUT = <i className='fa fa-sign-out fa-lg' style={{pointerEvents: 'none'}} />
export const ICON_HEADER_BARS = <i className='fa fa-bars fa-lg' style={{pointerEvents: 'none'}} />
export const ICON_HEADER_USER = <i className='fa fa-user fa-lg' style={{pointerEvents: 'none'}} />
export const ICON_HEADER_BACK = <i className='fa fa-arrow-left fa-lg' style={{pointerEvents: 'none'}} />
export const ICON_HEADER_SIGNIN = <i className='fa fa-sign-in fa-lg' style={{pointerEvents: 'none'}} />
export const ICON_HEADER_SETTING = <i className='fa fa-cog' style={{pointerEvents: 'none'}} />

export {ICON_ADMIN, ICON_MICDISABLE, ICON_DELETE, ICON_PLAY, ICON_REPEAT, ICON_SUPPORT, 
    ICON_COPY, ICON_PASTE, ICON_SHARE, ICON_SAVE, ICON_DOWNLOAD, ICON_ARROW_RIGHT,
    ICON_CHECK, ICON_WARN, ICON_CLOSE, ICON_QUESTION,
}