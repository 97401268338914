import { initialModalData, NatabName, CAN_EDIT, RuleOptions, FOCUS_NOTE, TANG_OPTIONS, FREQ,  } from "./const";
import FormModal from "./layouts/FormModal"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Col, Form, Row, Button, Modal } from 'react-bootstrap';
import { useContext, useEffect, useState } from "react";
import { SheetPlayingContext } from "../../providers/SheetPlayingContext";
import { SheetContext } from '../../providers/SheetContext';
import { NumberForm, RangeForm, SelectForm, TextForm } from "../layout/FormItem";
import { ICON_DELETE_SELECT } from "../icons";

export const AllModal = props => {

    const { tData, setTData, orderItem, setOrderItem, modalData, setModalData, gSheetType } = props;

    return (
        <>
            <AddTonModal setTData={setTData} setOrderItem={setOrderItem} modalData={modalData} setModalData={setModalData} gSheetType={gSheetType}/>
            <EditTonModal tData={tData} setTData={setTData} setOrderItem={setOrderItem} modalData={modalData} setModalData={setModalData} gSheetType={gSheetType}/>
            <FixedSpeedModal tData={tData} setTData={setTData} modalData={modalData} setModalData={setModalData} />
            <OrderTonModal modalData={modalData} setModalData={setModalData} setOrderItem={setOrderItem} />
            <ChanModal modalData={modalData} setModalData={setModalData} orderItem={orderItem} setOrderItem={setOrderItem} />
            <NatabModal modalData={modalData} setModalData={setModalData} orderItem={orderItem} setOrderItem={setOrderItem} />
            <RuleModal tData={tData} setTData={setTData} orderItem={orderItem} modalData={modalData} setModalData={setModalData} />
        </>
    )
}


export const AddTonModal = props => {

    const { setTData, setOrderItem, modalData, setModalData, gSheetType } = props;
    const { addTonModal } = modalData;
    const { setFType, setFId, setFRoom } = useContext(SheetContext);

    const onAddTonCallback = () => {
        const { tName, tLineNum, tRound } = modalData.addTonModal;
        let id = Date.now();
        setTData(d => {
            let tComment = {};
            for (let i = 0; i < tLineNum * 2; i++) {
                tComment[i] = '';
            }
            return {...d, [id] : {
                tName,
                tLineNum,
                tTang : gSheetType,
                tComment,
                tNote : {},
                tFixedSpeed : {},
                tRule : [],
            }}
        })
        setOrderItem(it => [...it, {tName : tName, tRound : parseInt(tRound), tId : id, tChing : 0, tTone : 'NO'}])
        setFType(FOCUS_NOTE)
        setFId(id);
        setFRoom(0);
    }

    const onModalDataChange = (modalName, valueName, value, isInt = false) => {
        let m = {...modalData};
        m[modalName][valueName] = !isInt ? value : parseInt(value);
        setModalData(m); 
    }

    return (
        <FormModal show={addTonModal.show} resetData={() => setModalData(m => ({...m, addTonModal : initialModalData.addTonModal}))} onSubmitCallback={onAddTonCallback}>
            <TextForm value={addTonModal.tName} onChange={e => onModalDataChange('addTonModal', 'tName', e.target.value)} title="ชื่อท่อน"/>
            <NumberForm modalName="addTonModal" valueName='tLineNum' modalData={modalData} setValue={setModalData} min={1} max={16} title="จำนวนบรรทัด"/>
            <NumberForm modalName="addTonModal" valueName='tRound' modalData={modalData} setValue={setModalData} min={1} max={8} title="จำนวนรอบ"/>
        </FormModal>
    )
}

export const EditTonModal = props => {

    const { tData, setTData, setOrderItem, modalData, setModalData, gSheetType } = props;
    const { editTonModal } = modalData;

    const onEditTonCallback = () => {
        const { tName, tLineNum, tTang, tId } = modalData.editTonModal;
        if (tName !== tData[tId].tName) setOrderItem(it => {
            const lists = [...it];
            let index = lists.findIndex(i => i.tId === tId);
            let [changingItem] = lists.splice(index, 1);
            lists.splice(index, 0, {...changingItem, tName})
            return lists;
        })
        setTData(d => {
            let data = {...d}
            if (tLineNum < d[tId].tLineNum) {
                for (let room in d[tId].tNote) {
                    if (room >= tLineNum * 8) {
                        delete data[tId].tNote[room]
                    }
                }
                for (let room in d[tId].tComment) {
                    if (room >= tLineNum * 2) {
                        delete data[tId].tComment[room]
                    }
                }
            }
            for (let i = 0; i < tLineNum * 2; i++) {
                if (!data[tId].tComment[i]) data[tId].tComment[i] = '';
            }
            if (tTang !== d[tId].tTang) data[tId].tNote = {};
            data[tId] = {...data[tId], tName, tLineNum, tTang : parseInt(tTang)}
            return data;
        })
    }

    const onModalDataChange = (modalName, valueName, value, isInt = false) => {
        let m = {...modalData};
        m[modalName][valueName] = !isInt ? value : parseInt(value);
        setModalData(m); 
    }

    return (
        <FormModal show={editTonModal.show} resetData={() => setModalData(m => ({...m, editTonModal : initialModalData.editTonModal}))} onSubmitCallback={onEditTonCallback}>
            <TextForm value={editTonModal.tName} onChange={e => onModalDataChange('editTonModal', 'tName', e.target.value)} title="ชื่อท่อน"/>
            <NumberForm modalName="editTonModal" valueName='tLineNum' modalData={modalData} setValue={setModalData} min={1} max={16} title="จำนวนบรรทัด"/>
            <SelectForm disabled={gSheetType !== null} value={editTonModal.tTang} onChange={e => onModalDataChange('editTonModal', 'tTang', e.target.value, true)} title="เครื่องดนตรี" options={TANG_OPTIONS}/>
        </FormModal>
    )
}

export const FixedSpeedModal = props => {
    const { tData, setTData, modalData, setModalData } = props;
    const { fixedSpeedModal } = modalData;
    const { sRoom, gFocusRoom } = useContext(SheetContext);
    const { fId } = gFocusRoom;

    const onFixedSpeedCallback = () => {
        const { isFixed, freq } = modalData.fixedSpeedModal;
        let data = {...tData};
        sRoom.forEach(room => {
            isFixed ? tData[fId].tFixedSpeed[room] = freq : delete tData[fId].tFixedSpeed[room];
        })
        setTData(data);
    }

    const onModalDataChange = (modalName, valueName, value, isInt = false) => {
        let m = {...modalData};
        m[modalName][valueName] = !isInt ? value : parseInt(value);
        setModalData(m); 
    }

    return (
        <FormModal show={fixedSpeedModal.show} resetData={() => setModalData(m => ({...m, fixedSpeedModal : initialModalData.fixedSpeedModal}))} onSubmitCallback={onFixedSpeedCallback}>
            <Row>
                <Col sm xs='12'>กำหนดความเร็ว (ในห้องที่เลือก) : </Col>
                <Col sm xs='12'>
                    <Form.Check 
                        type='radio' checked={modalData.fixedSpeedModal.isFixed} label=' ล็อกความเร็ว' name='form-check-isfixed' id='form-check-isfixed-true'
                        onChange={e => setModalData(g => ({...g, fixedSpeedModal : {...modalData.fixedSpeedModal, isFixed : e.target.checked}}))}
                    />
                </Col>
                <Col sm xs='12'>
                    <Form.Check 
                        type='radio' checked={!modalData.fixedSpeedModal.isFixed} label=' ไม่ล็อกความเร็ว' name='form-check-isfixed' id='form-check-isfixed-false'
                        onChange={e => setModalData(g => ({...g, fixedSpeedModal : {...modalData.fixedSpeedModal, isFixed : !e.target.checked}}))}
                    />
                </Col>
            </Row><br />
            <RangeForm 
                value={fixedSpeedModal.freq} onChange={e => onModalDataChange('fixedSpeedModal', 'freq', e.target.value, true)} 
                title={`ความเร็ว : ${fixedSpeedModal.freq} bpm`} min={FREQ.MIN} max={FREQ.MAX} hidden={!fixedSpeedModal.isFixed}
            />
        </FormModal>
    )
}

export const RuleModal = props => {

    const { tData, setTData, orderItem, modalData, setModalData } = props;
    const { addRuleModal } = modalData;
    const { gIsPlaying, gRight } = useContext(SheetContext)

    const onLineFromChange = e => {
        const val = parseInt(e.target.value)
        const to = (val === 0 || val >= addRuleModal.to) ? 0 : addRuleModal.to;
        setModalData(v => ({...v, addRuleModal : {...v.addRuleModal, from : val, to}}))
    }

    const onLineToChange = e => {
        const val = parseInt(e.target.value);
        const to = val <= addRuleModal.from ? 0 : val;
        setModalData(v => ({...v, addRuleModal : {...v.addRuleModal, to}}))
    }

    const onAddRuleCallback = () => {
        const { tId, tRound, from, to, rule } = addRuleModal || {};
        setTData(d => (  {...d, [tId] : {...d[tId], tRule : [...d[tId].tRule, {round : tRound, from, to, rule : parseInt(rule)}]}}  ))
    }

    const onDeleteRuleClick = (itemIndex, ruleIndex) => {
        let d = {...tData};
        let rule = [...d[orderItem[itemIndex].tId].tRule];
        rule.splice(ruleIndex, 1);
        d[orderItem[itemIndex].tId].tRule = rule;
        setTData(d);
    }

    const onModalDataChange = (modalName, valueName, value, isInt = false) => {
        let m = {...modalData};
        m[modalName][valueName] = !isInt ? value : parseInt(value);
        setModalData(m); 
    }

    const onModalHide = () => setModalData(m => ({...m, addRuleModal : initialModalData.addRuleModal}))

    return (
        <Modal show={addRuleModal.show} size='xl' onHide={onModalHide}>
            <Modal.Header>
                ข้อกำหนดพิเศษ
            </Modal.Header>
            <Modal.Body>
                <div className="d-none d-sm-block">
                    <Row bsPrefix="row text-center bg-light pt-2 pb-2 mb-3">
                        <Col>ท่อน</Col>
                        <Col>รอบที่</Col>
                        <Col>บรรทัดที่</Col>
                        <Col>ถึง (ไม่บังคับ)</Col>
                        <Col>ข้อกำหนด</Col>
                        <Col />
                    </Row>
                    {[...orderItem].map((it, index) => {
                        return (tData[it.tId] && tData[it.tId].tRule.length) ? tData[it.tId].tRule.map((r, i) => (
                            <div key={index + '-' + i} ><Row bsPrefix="row text-center">
                                <Col>{tData[it.tId].tName}</Col>
                                <Col>{r.round || 'ทุกรอบ'}</Col>
                                <Col>{r.from || 'ทุกบรรทัด'}</Col>
                                <Col>{r.to || '-'}</Col>
                                <Col>{RuleOptions[r.rule]}</Col>
                                <Col><Button disabled={gIsPlaying || gRight !== CAN_EDIT} variant="danger" onClick={() => onDeleteRuleClick(index, i)}>{ICON_DELETE_SELECT}</Button></Col>
                            </Row><hr /></div>
                        )) : <div key={index}/>
                    })}
                </div>
                <div className="d-sm-none">
                    <hr />
                    {[...orderItem].map((it, index) => {
                        return (tData[it.tId] && tData[it.tId].tRule.length) ? tData[it.tId].tRule.map((r, i) => (
                            <div key={index + '-' + i} >
                                <Row bsPrefix="row align-items-center">
                                    <Col><div style={{fontWeight: 'bold'}}>{tData[it.tId].tName}</div></Col>
                                    <Col xs='auto'>
                                        <Button disabled={gIsPlaying || gRight !== CAN_EDIT} variant="danger" onClick={() => onDeleteRuleClick(index, i)}>{ICON_DELETE_SELECT}</Button>
                                    </Col>
                                </Row>
                                <div>{r.round ? `รอบที่ ${r.round}` : 'ทุกรอบ'}{r.from ? ` บรรทัดที่ ${r.from}` : ' ทุกบรรทัด'}{r.to ? ` ถึง ${r.to}` : ''}</div>       
                                <div>{`<${RuleOptions[r.rule]}>`}</div>
                            <hr /></div>
                        )) : <div key={index}/>
                    })}
                </div>
                <br />
                เพิ่มข้อกำหนด
                <SelectForm 
                    value={addRuleModal.tId} onChange={e => onModalDataChange('addRuleModal', 'tId', e.target.value, true)} title="เลือกท่อน" 
                    options={[...orderItem].reduce((obj, it) => ({...obj, [it.tId] : it.tName}), {0 : '-'})}
                />
                <Form.Group className="mb-3">
                    <Form.Label>รอบที่</Form.Label>
                    <Form.Select value={addRuleModal.tRound} onChange={e => setModalData(v => ({...v, addRuleModal : {...v.addRuleModal, tRound : parseInt(e.target.value)}}))}>
                        <option value={0}>ทุกรอบ</option>
                        {[...Array(   (orderItem.find(it => it.tId === parseInt(addRuleModal.tId)) || {tRound : 2}).tRound )].map((v, i) => (
                            <option key={i + 1} value={i + 1}>{i + 1}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>บรรทัดที่</Form.Label>
                    <Row bsPrefix="row align-items-center">
                        <Col>
                            <Form.Select value={addRuleModal.from} onChange={onLineFromChange}>
                                <option value={0}>ทุกบรรทัด</option>
                                {[...Array((tData[addRuleModal.tId] || {tLineNum : 4}).tLineNum )].map((v, i) => (
                                    <option key={i + 1}>{i + 1}</option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col xs='auto' sm='auto'>ถึง</Col>
                        <Col>
                            <Form.Select disabled={addRuleModal.from === 0} value={addRuleModal.to} onChange={onLineToChange}>
                                <option value={0}>-</option>
                                {[...Array((tData[addRuleModal.tId] || {tLineNum : 4}).tLineNum )].map((v, i) => (
                                    <option key={i}>{i + 1}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                </Form.Group>
                <SelectForm 
                    value={addRuleModal.rule} 
                    onChange={e => onModalDataChange('addRuleModal', 'rule', e.target.value, true)} title="ข้อกำหนด" 
                    options={RuleOptions}
                />
                <div className="text-center">
                    <Button variant="primary" disabled={orderItem.length === 0 || addRuleModal.tId === 0} onClick={onAddRuleCallback}>เพิ่ม</Button>
                </div>
                          
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={onModalHide}>{'ปิด'}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export const OrderTonModal = props => {

    const { modalData, setModalData, setOrderItem } = props;
    const { orderTonModal } = modalData;
    const { show, tItems } = orderTonModal;

    const onModalDataChange = (k, v) => {
        let m = {...modalData};
        m.orderTonModal[k] = v;
        setModalData(m);
    }

    const onDragEnd = res => {
        if (res.destination) {
            const orderLists = [...tItems];
            const [changingItem] = orderLists.splice(res.source.index, 1);
            orderLists.splice(res.destination.index, 0, changingItem);
            onModalDataChange('tItems', [...orderLists]);
        }
    }

    const onRoundChange = (val, index) => {
        const lists = [...tItems];
        let [changingItem] = lists.splice(index, 1);
        lists.splice(index, 0, {...changingItem, tRound : parseInt(val)})
        onModalDataChange('tItems', [...lists]);
    }

    const onSubmit = () => {
        setOrderItem([...tItems]);
    }

    return (
        <FormModal show={show} title='จัดการการเล่น' onSubmitCallback={onSubmit} resetData={() => setModalData(m => ({...m, orderTonModal : initialModalData.orderTonModal}))}>
            <div>โปรดลากกล่องสีเทาเพื่อเรียงลำดับการเล่น จากบนลงล่าง</div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="order-ton-modal-droppable">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {tItems.map((item, index) => (
                                <Draggable key={'drag-' + index} draggableId={'drag-' + index} index={index}>
                                    {(provided, snapshot) => (

                                    
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{...provided.draggableProps.style , userSelect : 'none', padding : '10px', margin : '0 0 10px 0', background : snapshot.isDragging ? '#f0f0f0' : '#fafafa'}}
                                    >
                                    <Row bsPrefix='row align-items-center text-center'>
                                        <Col style={{textAlign: 'left'}}>{item.tName}</Col>
                                        <Col bsPrefix="col-auto" />
                                        <Col bsPrefix="col text-end">
                                            <Form.Select value={item.tRound} onChange={e => onRoundChange(e.target.value, index)}>
                                                {[...Array(9).keys()].map((v, i) => (
                                                    <option value={v} key={i}>{v} รอบ</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    
                                    </div>)}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </FormModal>
    )
}

export const ChanModal = props => {

    const { orderItem, modalData, setModalData } = props;
    const { chanModal } = modalData;
    const { show } = chanModal;

    const { pRhythm } = useContext(SheetPlayingContext);

    const [chan, setChan] = useState(0);
    const [custom, setCustom] = useState({});
    const [isSame, setSame] = useState(true);

    useEffect(() => {
        return () => {
            setChan(0);
            setCustom({});
            setSame(true);
        }
    }, [])


    const onShow = () => {
        const { ching } = pRhythm.current || {};
        setChan(ching.chan);
        setSame(ching.isSame);
        let c = {};
        [...orderItem].forEach(it => c = {...c, [it.tId] : ching.custom[it.tId] || 0})
        setCustom(c)
    }

    const onSubmit = () => {
        pRhythm.current = {
            ...pRhythm.current,
            ching : {
                chan, custom, isSame,
            }
        }
    }

    return (
        <FormModal show={show} onShow={onShow} title='กำหนดอัตราจังหวะ' resetData={() => setModalData(m => ({...m, chanModal : initialModalData.chanModal}))} onSubmitCallback={onSubmit}>
            <Form.Check 
                type='radio' checked={isSame} label=' เหมือนกันทุกท่อน' name='form-check-ching-new' id='form-check-ching-same-new' 
                onChange={e => setSame(e.target.checked)}
            />
            <Form.Check 
                type='radio' checked={!isSame} label=' กำหนดเอง' name='form-check-ching-new' id='form-check-ching-custom-new'
                onChange={e => setSame(!e.target.checked)}
            />
            <br />
            <Row hidden={!isSame}>
                <Col sm xs>ทุกท่อนมีอัตราจังหวะ : </Col>
                <Col>
                    <Form.Select value={chan} onChange={e => setChan(parseInt(e.target.value))}>
                        <option value={0} >-</option>
                        <option value={3} >สามชั้น</option>
                        <option value={2} >สองชั้น</option>
                        <option value={1} >ชั้นเดียว</option>
                    </Form.Select>
                </Col>
            </Row>

            <div hidden={isSame}>
                <Row bsPrefix="row text-center bg-light pt-2 pb-2 mb-3">
                    <Col>ท่อน</Col>
                    <Col>อัตราจังหวะ</Col>
                </Row>
                {Object.keys(custom) ? Object.keys(custom).map((tId, index) => (
                    <Row key={index}>
                        <Col>{([...orderItem].find(i => i.tId.toString() === tId) || {tName : '0'}).tName}</Col>
                        <Col>
                            <Form.Select value={custom[tId]} onChange={e => setCustom(c => ({...c, [tId] : parseInt(e.target.value)}))}>
                                <option value={0} >-</option>
                                <option value={3} >สามชั้น</option>
                                <option value={2} >สองชั้น</option>
                                <option value={1} >ชั้นเดียว</option>
                            </Form.Select>
                        </Col>
                    </Row>
                )) : <div />}
            </div>
        </FormModal>
    )
}

export const NatabModal = props => {

    const { orderItem, modalData, setModalData } = props;
    const { natabModal } = modalData;
    const { show } = natabModal;

    const { pRhythm } = useContext(SheetPlayingContext);

    const [natab, setNatab] = useState('NO');
    const [custom, setCustom] = useState({});
    const [isSame, setSame] = useState(true);

    useEffect(() => {
        return () => {
            setNatab('NO');
            setCustom({});
            setSame(true);
        }
    }, [])


    const onShow = () => {
        const { tone } = pRhythm.current || {};
        setNatab(tone.natab);
        setSame(tone.isSame);
        let c = {};
        [...orderItem].forEach(it => c = {...c, [it.tId] : tone.custom[it.tId] || 0})
        setCustom(c)
    }

    const onSubmit = () => {
        pRhythm.current = {
            ...pRhythm.current,
            tone : {
                natab, custom, isSame,
            }
        }
    }

    return (
        <FormModal show={show} onShow={onShow} title='กำหนดหน้าทับ' resetData={() => setModalData(m => ({...m, natabModal : initialModalData.natabModal}))} onSubmitCallback={onSubmit}>
            <Form.Check 
                type='radio' checked={isSame} label=' เหมือนกันทุกท่อน' name='form-check-tone-new' id='form-check-tone-same-new' 
                onChange={e => setSame(e.target.checked)}
            />
            <Form.Check 
                type='radio' checked={!isSame} label=' กำหนดเอง' name='form-check-tone-new' id='form-check-tone-custom-new'
                onChange={e => setSame(!e.target.checked)}
            />
            <br />
            <Row hidden={!isSame}>
                <Col sm xs>ทุกท่อนใช้หน้าทับ : </Col>
                <Col>
                    <Form.Select value={natab} onChange={e => setNatab(e.target.value)}>
                        {Object.keys(NatabName).map((natabId, index) => (
                            <option key={index} value={natabId}>{NatabName[natabId]}</option>
                        ))}
                    </Form.Select>
                </Col>
            </Row>

            <div hidden={isSame}>
                <Row bsPrefix="row text-center bg-light pt-2 pb-2 mb-3">
                    <Col>ท่อน</Col>
                    <Col>หน้าทับ</Col>
                </Row>
                {Object.keys(custom) ? Object.keys(custom).map((tId, index) => (
                    <Row key={index}>
                        <Col>{([...orderItem].find(i => i.tId.toString() === tId) || {tName : '0'}).tName}</Col>
                        <Col>
                            <Form.Select value={custom[tId]} onChange={e => setCustom(c => ({...c, [tId] : e.target.value}))}>
                                {Object.keys(NatabName).map((natabId, index) => (
                                    <option key={index} value={natabId}>{NatabName[natabId]}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                )) : <div />}
            </div>
        </FormModal>
    )
}
