import { FOCUS_NOTE, TANG_KHLUI, TANG_KONG } from "../const";
//import * as FluteSound from '../../../sounds/FluteSound';
//import * as KongSound from '../../../sounds/KongSound';




const OnNotePlay = async props => {
    const { tData, orderItem, sheetContext, soundsContext, sheetPlayingContext } = props;
    const { gFocusRoom, setPlaying, PositionRef, setFId, setFRoom, setFType } = sheetContext;
    const { CHING, CHAB, loadNatabSound, loadFluteSound, loadKongSound, FluteSound, fluteVolumeRef, KongSound, NATAB,  } = soundsContext;
    const { pSound, pFrequency, pIsPlaying, pContext, setFreq, pRhythm } = sheetPlayingContext;

    const { fId, fType, fRoom } = gFocusRoom;


    const playNote = (note, tang, time) => {    
        switch (tang) {
            case TANG_KONG:
                if (KongSound[note] && KongSound[note].current) {
                    setTimeout(() => {
                        pSound.current = KongSound[note].current;
                        pSound.current.play();
                    }, 0.3 * time)
                }
                break;
            case TANG_KHLUI: default:
                if (note === '/') {
                    if (pSound.current) {
                        setTimeout(() => {
                            pSound.current.fade(fluteVolumeRef.current, 0, 0.1 * time);
                        }, 0.20 * time)
                        setTimeout(() => {
                            pSound.current.stop();
                            pSound.current = null;
                        }, 0.3 * time);
                    }
                }
                else if (FluteSound[note] && FluteSound[note].current) {
                    if (pSound.current) {
                        pSound.current.fade(fluteVolumeRef.current, 0, 0.1 * time);
                        setTimeout(() => pSound.current.stop(), 0.1 * time);
                    }
                    setTimeout(() => {
                        pSound.current = FluteSound[note].current;
                        pSound.current.play();
                        pSound.current.fade(0, fluteVolumeRef.current, 25)
                    }, 0.3 * time)
                }
        }
    }
    
    const onChingPlay = (chan, room, i, time = 0) => {
        if (chan !== 0) setTimeout(() => {
            const powNum = Math.pow(2, chan - 1)
            if ((room % powNum) * 4 + i === powNum * 2 - 1) CHING.current.play();
            if ((room % powNum) * 4 + i === powNum * 4 - 1) {CHAB.current.play(); CHING.current.stop();}
        }, time)
        
    }
    
    const onTonePlay = (natab, room, i, time = 0) => {
        if (natab && natab !== 'NO') {
            let len = NATAB[natab].length;
            let index = (room * 4 + i) % len;
            if (NATAB[natab] && NATAB[natab][index]) {
                setTimeout(() => NATAB[natab][index].play(), time)
            }
        }
    }
    
    const onProcess = () => {
        return new Promise((resolve, reject) => {
            let gPlayingContext = {};
            const startIndex = fType !== FOCUS_NOTE ? 0 : orderItem.findIndex(v => v.tId === fId);
            orderItem.forEach((item, tonIndex) => {
                if (tonIndex >= startIndex) {
                    let { tId, tRound } = item;
                    let ton = [];
                    let sp = [];
                    for (let roomNum in tData[tId].tNote) {
                        if (tData[tId].tNote[roomNum]) {
                            let room = [];
                            if (tData[tId].tNote[roomNum][0]) room[0] = tData[tId].tNote[roomNum][0]
                            if (tData[tId].tNote[roomNum][1]) room[1] = tData[tId].tNote[roomNum][1]
                            //if (tData[tId].tFixedSpeed[roomNum]) fixedSpeed = {...fixedSpeed, [tonIndex - startIndex] : {...fixedSpeed[tonIndex-startIndex], [roomNum] : tData[tId].tFixedSpeed[roomNum]}}
                            if (room[0].length && room[0].length !== 4) reject(room[0])
                            ton[roomNum] = room;
                            if (tData[tId].tFixedSpeed[roomNum]) sp[roomNum] = tData[tId].tFixedSpeed[roomNum];
                        }
                    }
                    let ruleTon = [];
                    [...tData[tId].tRule].forEach(rule => {
                        if (rule.from === 0) {
                            for (let line = 1; line <= tData[tId].tLineNum; line++) {
                                if (tData[tId].tNote[(line - 1) * 8]) {
                                    ruleTon = [...ruleTon, {
                                        ...rule,
                                        repeat : (rule.rule > 1 && rule.rule <= 4) ? rule.rule - 1 : 0,
                                        from : line,
                                        to : line,
                                    }]
                                }
                            }
                        }
                        else {
                            ruleTon = [...ruleTon, {
                                ...rule, 
                                repeat : (rule.rule > 1 && rule.rule <= 4) ? rule.rule - 1 : 0,
                                to : rule.to === 0 ? rule.from : rule.to,
                            }]
                        }
                        
                    })
                    gPlayingContext = {...gPlayingContext, [tonIndex - startIndex] : {
                        noteTon : ton,
                        tang : tData[tId].tTang,
                        lineNum : tData[tId].tLineNum,
                        noteRound : tRound,
                        //chan : gRhythm.gChing.isSame ? gRhythm.gChing.chan : item.tChing,
                        //natab : gRhythm.gTone.isSame ? gRhythm.gTone.natab : item.tTone,
                        chan : pRhythm.current.ching.isSame ? pRhythm.current.ching.chan : (pRhythm.current.ching.custom[tId] || 0),
                        natab : pRhythm.current.tone.isSame ? pRhythm.current.tone.natab : (pRhythm.current.tone.custom[tId] || 'NO'),
                        fixedSpeed : sp,
                        ruleTon,
                        tonId : tId,
                    }}
                    
                    //setGNoteData(g => ({...g, gPlayingContext}));
                }
            })
            pContext.current = {...gPlayingContext}
            const startRoom = fType === FOCUS_NOTE && fRoom ? fRoom : 0;
            CHING.current.load();
            CHAB.current.load();
            loadNatabSound();
            loadFluteSound();
            loadKongSound();
    
            setPlaying(true)
            console.log('start!')
            resolve({ startRoom, gPlayingContext : pContext.current });
        })
        
    }
    
    const onPlay = async ( processData ) => {
        const { startRoom, gPlayingContext } = processData;
        pIsPlaying.current = true;
        const delay = ms => new Promise((rs, rj) => {
            let t = Date.now();
            setTimeout(() => pIsPlaying.current ? rs(Math.abs(ms - (Date.now() - t) )) : rj(), ms)
        })
        let errorTime = 0;
        return new Promise(async (resolve, reject) => {    
            let playingRhythm = true;
            let playingMain = true;
            
            songLoop : for (const ti in gPlayingContext) {
                const tonIndex = parseInt(ti)
                const { noteTon, tang, noteRound, chan, natab, fixedSpeed, ruleTon, lineNum, tonId } = gPlayingContext[tonIndex];
                for (let round = 0; round < noteRound; round++) {
                    let start = tonIndex === 0 && round === 0 ? startRoom : 0;
                    let ruleInRound = ruleTon.filter(v => (v.round === 0 || v.round - 1 === round)).map(v => {
                        v.repeat = (v.rule > 1 && v.rule <= 4) ? v.rule - 1 : 0;
                        return v;
                    }).sort((x, y) => {
                        return x.rule > y.rule ? 1 : x.rule < y.rule ? -1 : x.to > y.to ? 1 : x.to < y.to ? -1 : x.from > y.from ? -1 : 1;
                    })
                    playingMain = true;
                    
                    tonLoop : for (let room = start; room < lineNum * 8; room++) {
                        PositionRef.current = {id : tonId, room : room}
                        if (room % 8 === 0) {
                            for (let ruleIndex = 0; ruleIndex < ruleInRound.length; ruleIndex++) {
                                let Rule = ruleInRound[ruleIndex];
                                if ((Rule.from - 1) * 8 === room && Rule.rule === 0) {
                                    if (Rule.to === lineNum) break tonLoop;
                                    room += (Rule.to - Rule.from + 1) * 8;
                                }
                                else if ((Rule.from - 1) * 8 === room && Rule.rule === 1) {
                                    playingMain = false;
                                }
                            }
                        }
                        if (fixedSpeed[room]) setFreq(fixedSpeed[room])
                        if (noteTon[room]) {
                            PositionRef.current.smRef = document.getElementById(`room-sm-${tonId}-${FOCUS_NOTE}-${room}`);
                            PositionRef.current.xsRef = document.getElementById(`room-xs-${tonId}-${FOCUS_NOTE}-${room}`);
                            if (PositionRef.current.smRef) PositionRef.current.smRef.style.background = '#fffb8f'
                            if (PositionRef.current.xsRef) PositionRef.current.xsRef.style.background = '#fffb8f'
    
                            for (let i = 0; i < 4; i++) {
                            
                                let ms = (60/pFrequency.current * 1000)/4;
                                if (noteTon[room][0] && playingMain) playNote(noteTon[room][0].charAt(i), tang, ms);
                                if (noteTon[room][1] && playingMain) playNote(noteTon[room][1].charAt(i), tang, ms);
                                if (playingRhythm) {
                                    onChingPlay(chan, room, i, ms * 0.3);
                                    onTonePlay(natab, room, i, ms * 0.3);
                                }
                                errorTime = await delay(ms - errorTime).catch(() => {
                                    reject()
                                });
                                if (errorTime > 200) errorTime = 200;
                                if (!pIsPlaying.current) break songLoop;
                            }
                            if (PositionRef.current.smRef) PositionRef.current.smRef.style.background = 'unset'
                            if (PositionRef.current.xsRef) PositionRef.current.xsRef.style.background = 'unset'
                        }
                        if (room % 8 === 7) {
                            for (let ruleIndex = 0; ruleIndex < ruleInRound.length; ruleIndex++) {
                                let Rule = ruleInRound[ruleIndex];
                                if (Rule.to * 8 - 1 === room && Rule.rule === 1) {
                                    playingMain = true;
                                }
                                else if (Rule.to * 8 - 1 === room && Rule.repeat > 0 && Rule.from > 0) {
                                    Rule.repeat -= 1;
                                    room = (Rule.from - 1) * 8 - 1;
                                }
                            }
                        }
                        if (!pIsPlaying.current) break songLoop;
                    }
                    if (!pIsPlaying.current) break songLoop;
                }
                if (!pIsPlaying.current) break;
            }
            resolve();
        })
    }
    
    const onEnd = (delayTime = 100) => {
        
    
        return new Promise(r => {
            setFId(PositionRef.current.id)
            setFRoom(PositionRef.current.room)
            setFType(FOCUS_NOTE)
            if (PositionRef.current.smRef) PositionRef.current.smRef.style.background = 'unset'
            if (PositionRef.current.xsRef) PositionRef.current.xsRef.style.background = 'unset'
            pContext.current = {};
            setTimeout(() => {
                if (pSound.current) pSound.current.stop();
                pSound.current = null;
                CHING.current.unload();
                CHAB.current.unload();
                loadNatabSound(false);
                loadFluteSound(false);
                loadKongSound(false);
                if (PositionRef.current.smRef) PositionRef.current.smRef = null
                if (PositionRef.current.xsRef) PositionRef.current.xsRef = null
                console.log('fin!')
                setPlaying(false)
                r();
                
            }, delayTime || 100);
        })
    }
    
    try {
        const processData = await onProcess();
        await onPlay(processData)
        await onEnd((60/pFrequency.current * 1000));
    }
    catch (e) {
        await onEnd(100);
    }
}

export default OnNotePlay;