import React, { cloneElement } from "react"
import { MicProvider } from "./MicProvider";
import { NoteConvertProvider } from "./NoteConvertProvider";
import { NoteProvider } from "./NoteProvider";
import { PagesProvider } from "./PagesProvider";
import { PlayingProvider } from "./PlayingProvider";
import { SettingsProvider } from "./SettingsProvider"
import { UnitProvider } from "./UnitProvider";
import { AuthProvider } from './AuthContext';
import { SheetsProvider } from "./SheetsContext";
import { SoundsProvider } from "./SoundsContext";

function ProviderComposer({ contexts, children }) {
    return contexts.reduce(
        (kids, parent) =>
            cloneElement(parent, {
                children: kids
            }),
        children
    );
}

export default function ContextProvider({ children }) {
    return (
        <ProviderComposer
        // add providers to array of contexts
            contexts={[
                <AuthProvider />,
                <SettingsProvider />, 
                <SoundsProvider />,
                <PlayingProvider />,
                <MicProvider />,
                <PagesProvider />,
                <NoteProvider />,
                <UnitProvider />,
                <NoteConvertProvider />,
                <SheetsProvider />,
                ]}
            >
            {children}
        </ProviderComposer>
    )
}