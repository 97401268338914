import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Modal, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { PlayingContext } from "../../providers/PlayingProvider";
import { SettingsContext } from "../../providers/SettingsProvider";
import { UserContext } from "../../providers/UserProvider";
import socket from "../../server/socket-io";
import { setDelayTime } from "../constant";
import { ICON_QUESTION } from "../icons";


const Moving = () => {

    const {line, speed, rate, naTab} = useContext(SettingsContext);
    const { isPlaying , setPlaying } = useContext(PlayingContext);
    const { users, adminName } = useContext(UserContext);

    const [showModal, setShowModal] = useState(false);
    const [warningText, setWarningText] = useState('');

    const [_delayTime, _setDelayTime] = useState(0);

    useEffect(() => {
        const btnPlaying = document.getElementById('btn-start-playing');
        btnPlaying.disabled = (socket.id === adminName) ? isPlaying : true;
    }, [isPlaying, adminName]);

    const onStartClick = () => {
        const userArray = Object.values(users);
        if (userArray.length === 1 && !userArray[0].isMicEnable) {
            setWarningText('ตัวเองลืมเปิดไมค์จ้า จะเล่นไหม ?')
            setShowModal(true);
        }
        else {
            let muteUsers = [];
            userArray.forEach(u => !u.isMicEnable ? muteUsers = [...muteUsers, u.name] : '');
            if (muteUsers.length > 0) {
                setWarningText(`คุณ ${muteUsers.join(', ')} ไม่ได้เปิดไมค์จ้า จะเล่นไหม ?`)
                setShowModal(true);
            }
            else {
                onMoving();
            }
        }
    }

    const onMoving = () => {
        setShowModal(false);
        const btnMoving = document.getElementById('btn-start-playing');
        btnMoving.disabled = true;
        setPlaying(true);
        socket.emit('onStart', line, speed, rate, naTab, users);
    }

    const onDelayTimeChange = e => {
        setDelayTime(e.target.value);
        _setDelayTime(e.target.value);
    }

    const delayPopover = (
        <Popover>
            <Popover.Header as="h4">เวลาดีเลย์</Popover.Header>
            <Popover.Body>เมื่อเริ่มรอบที่ 2 เสียงของรอบแรกอาจจะช้ากว่าเสียงจังหวะ ท่านสามารถปรับเวลาดีเลย์เพื่อให้เสียงดังกล่าวตรงกัน (ประมาณ 100 กว่า ms) </Popover.Body>
        </Popover>
    )

    return(
        <div>
            {WarningModal(showModal, setShowModal, warningText, onMoving)}
            <Row bsPrefix="row align-items-center text-center">
                <Col sm='2'>
                    <button id="btn-start-playing" onClick={onStartClick} className="btn btn-success"
                        data-toggle="tooltip" data-placement="bottom" title="กดเพื่อเริ่มการอัดเสียง">เริ่ม!</button>
                </Col>
                
                <Col md='5' sm='4'>
                    เวลาดีเลย์ : {_delayTime} ms
                    <span style={{paddingLeft: "10px"}} hidden={process.env.NODE_ENV && process.env.NODE_ENV === 'development'}>
                        <OverlayTrigger placement="top" overlay={delayPopover}>
                            {ICON_QUESTION}
                        </OverlayTrigger>
                    </span>
                </Col>
                <Col bsPrefix="col sm-auto">
                    <Form.Range step={5} max={200} min={0} defaultValue={0} onChange={onDelayTimeChange} />
                </Col>
            </Row>
        </div>
    )
}

const WarningModal = (showModal, setShowModal, warningText = "", onMoving) => {
    return (
        <Modal show={showModal} size="md" onHide={() => setShowModal(false)}>
            <Modal.Body>
                {warningText} 
            </Modal.Body>
            <Modal.Footer>
                <div className="btn btn-primary" onClick={() => setShowModal(false)}>ยกเลิก</div>
                <div className="btn btn-danger" onClick={onMoving}>ฉันจะเล่น!</div>
            </Modal.Footer>
        </Modal>
    )
}

export default Moving;