import { createContext, useReducer } from "react";




const initialState = {
    convertType: 'NONE',
}

const reducer = (state, action) => {
    switch(action.type) {
        case 'SET_MET':
            return {convertType: action.payload}
        default:
            break;
    }
}

export const NoteConvertContext = createContext({});

export const NoteConvertProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { convertType } = state;
    const setConvertType = payload => dispatch({type: 'SET_MET', payload});
    const alpArray = ['-', 'ด', 'ร', 'ม', 'ฟ', 'ซ', 'ล', 'ท', 'ด', 'ร']
    const getConvertedNote = (n) => {
        switch(convertType) {
            case 'NONE':
                return n;
            case 'N2A':
                return !isNaN(n) && parseInt(n) <= 9 ? alpArray[parseInt(n)] : n;
            case 'A2N':
                return alpArray.indexOf(n) !== -1 ? alpArray.indexOf(n) : n;
            default:
                return n;
        }
    }

    const getNote = (note) => {
        let s = ""
        for (let i = 0; i < note.length; i++) {
            s += getConvertedNote(note[i])
        }
        return s;
    }

    return (
        <NoteConvertContext.Provider value={{convertType, setConvertType, getConvertedNote, getNote}}>
            {children}
        </NoteConvertContext.Provider>
    )
}