import { useContext, useEffect, useState } from "react";
import { database } from "../../databases/firebase";
import { UserContext } from "../../providers/UserProvider";
import socket from "../../server/socket-io";
import { C5 } from "../../sounds/EffectSound";

const Chat = (props) => {

    const [message, setMessage] = useState("");
    const [messageArray, setMessageArray] = useState([]);
    const { users, adminName} = useContext(UserContext);

    useEffect(() => {
        const inputBox = document.getElementById('inputBox');
        if (inputBox && inputBox.value === '!!') {
            inputBox.value = "!เซิร์ฟเอ๋ย "; 
            inputBox.className = "form-control text-success";
        }
        else if (inputBox) inputBox.className = message.indexOf('!เซิร์ฟเอ๋ย') === -1 ? "form-control" : "form-control text-success";
    }, [message])

    const sendServerMessage = m => {
        setTimeout(() => socket.emit('sendServerMessage', m), 1000);
    }


    const handleClick = (event) => {
        event.preventDefault();
        const inputBox = document.getElementById("inputBox");
        if (message.indexOf('!เซิร์ฟเอ๋ย ') !== -1) {
            if (message.indexOf('รีเซ') !== -1 || message.indexOf('reset') !== -1) {
                sendServerMessage("กำลังรีเซ็ตภายใน 3 วิ");
                setTimeout(() => socket.emit('resetServer'), 3000);
            }
            else if (message.indexOf('รีเฟรช') !== -1 || message.indexOf('refresh') !== -1) {
                sendServerMessage("กำลังรีเฟรชภายใน 3 วิ");
                setTimeout(() => window.location.reload(), 3000);
            }
            else if (adminName === socket.id && message.indexOf('ให้') !== -1){
                let f = false;
                if (users) Object.values(users).forEach(u => {
                    if (message.indexOf(u.name) !== -1) {
                        f = true;
                        setTimeout(() => database.ref('databaseSystem').update({adminName : u.id}), 3000);
                    }
                })
                f ? sendServerMessage("ได้เลย!") : sendServerMessage("ผู้ใดนะ ?");
            }
            else if (message.indexOf('ใคร') !== -1 || message.indexOf('ผู้ใด') !== -1) {
                const ua = Object.values(users);
                if (ua) {
                    let lucky = ua[ Math.floor(Math.random() * ua.length) ].name; 
                    sendServerMessage(`แน่นอน คุณ${lucky}ไงล่ะ!`);
                }
            } 
            setTimeout(() => {
                socket.emit('sendChatMessage', message);
                inputBox.value = "";
                setMessage("");
            }, 100);
        }
        else if (message) {
            socket.emit('sendChatMessage', message);
            inputBox.value = "";
            setMessage("");
        }
        
    }

    useEffect(() => {
        receiveChatMessage();
        return () => setMessageArray([]);
    }, []);

    const receiveChatMessage = () => {
        socket.on("playC5", () => C5.play());
        socket.on("receiveChatMessage", m => {
            setMessageArray(a => [...a, m]);
        })
        socket.on('receiveServerMessage', m => setMessageArray(a => [...a, m]));
    }

    useEffect(() => {
        const chatBox = document.getElementById("chatBox");
        chatBox.scrollTop = chatBox.scrollHeight;
    }, [messageArray])


    return (
        <div className="" style={{paddingBlock: "20px", height: props.h ? `${props.h}px` : "430px"}}>
            <div id="chatBox" className="overflow-auto" style={{height: props.h ? `${props.h - 100}px` : "330px", overflowY: "scroll", background: "#e6fffb", paddingInline: "20px"}} >
                {messageArray.map((v, i) => (
                    <div key={i} className={v.indexOf('!น้องเซิร์ฟ') !== -1 ? "text-warning": ""}>{v}</div>
                ))}
            </div>
            <form className="form-group pt-3">
                <div className="row">
                    <div className="col">
                        <input id="inputBox" type="text" onChange={m => setMessage(m.target.value)} className="form-control" autoComplete='off' />
                    </div>
                    <div className="col-auto">
                        <button type="submit" className="btn btn-primary" id="btn-send-chat-message" onClick={handleClick} >ส่ง</button>
                    </div>
                </div>
                
            </form>
        </div>
    )
}

export default Chat;