import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css";
import ProviderComposer from './providers/ProviderComposer';
import { UserProvider } from './providers/UserProvider';
import { NoteProvider } from './components/FluteOnline/NoteContext';


ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <NoteProvider>
      <ProviderComposer>
        <App />
      </ProviderComposer>
      </NoteProvider>
    </UserProvider>
  </React.StrictMode>
, document.getElementById('root'));