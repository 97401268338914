import { firestore } from "../firebase"
import { getUserIDByCurrentEmail } from "./getUserName";


export const UpdateSingleUser = async (data) => {
    try {
        const id = await getUserIDByCurrentEmail();
        const doc = firestore.collection('users').doc(id);
        if (!doc) throw new Error('ไม่พบรายชื่อ')
        return doc.update({...data})
    }
    catch (e) {
        console.log(e);
    }
}