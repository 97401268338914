import { Modal, Button } from "react-bootstrap"


const ConfirmModal = props => {

    const { show, children, onSubmitCallback, onModalHide } = props;
    const { size, title, txtClose, txtSubmit } = props;

    const onConfirmClick = e => {
        e.preventDefault();
        if (typeof onSubmitCallback === 'function') onSubmitCallback();
        onModalHide();
    }

    return (
        <Modal show={show || false} size={size || 'lg'} onHide={onModalHide}>
            <Modal.Header>
                <div>{title}</div>
            </Modal.Header>
            
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={onModalHide}>{txtClose || 'ยกเลิก'}</Button>
                <Button variant="primary" onClick={onConfirmClick}>{txtSubmit || 'ยืนยัน'}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmModal;