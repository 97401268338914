import { useContext } from "react";
import { SheetPlayingContext } from "../../providers/SheetPlayingContext";
import { RangeForm } from "../layout/FormItem";
import { FREQ } from "./const";




const RenderFreqBar = () => {

    const { freq, setFreq } = useContext(SheetPlayingContext);

    return (
        <RangeForm 
            value={freq} onChange={e => setFreq(e.target.value)} 
            title={`ความเร็ว : ${freq} bpm`} min={FREQ.MIN} max={FREQ.MAX} 
        />
    )
}

export default RenderFreqBar;