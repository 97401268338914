import React from "react";
import Chat from "../../layout/Chat";
import List from "../../layout/List";

const TabletListChat = () => {
    return (
        <div className="container-fluid" style={{background: "#f5f5f5"}}>
            <div className="row">
                <div className="col-5">
                    <div className="container-fluid" style={{padding: '20px', background: 'white', borderRadius: "20px"}}>
                        <h5 style={{fontWeight: 'bold'}}>เพื่อน ๆ</h5>
                        <List h={0.64 * window.screen.height}/>
                    </div>
                </div>
                <div className="col-7">
                    <div className="container-fluid" style={{padding: '20px', background: 'white', borderRadius: "20px"}}>
                    <h5 style={{fontWeight: 'bold'}}>ห้องแชท</h5>
                        <Chat h={0.64 * window.screen.height}/>
                    </div>
                </div>
            </div>
            
            
        </div>
    )
}

export default TabletListChat;