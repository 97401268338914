import { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import RoundContainer from "../layout/RoundContainer";
import SendLogData from "../../databases/SendLogData";
import SuperHeader from "../layout/SuperHeader";
import { SoundsContext } from "../../providers/SoundsContext";

const [STATUS_READY, STATUS_PLAYING, STATUS_STOPPING] = [0, 1, 2, 3]

const RhythmOnline = () => {

    const [mSpeed, setMSpeed] = useState(60);
    const [mRate, setMRate] = useState(2);
    const [mNaTab, setMNaTab] = useState('NO');
    const [mStatus, setMStatus] = useState(0);

    const { CHING, CHAB, NATAB, loadNatabSound } = useContext(SoundsContext);

    const naTabRef = useRef(NATAB.NO);
    const nextNaTabRef = useRef(null);

    const rateRef = useRef({
        now : 2,
        next : null,
    })

    const toneTime = useRef(250);
    const isChanging = useRef(false);
    const btnChange = useRef(null);
    const statusRef = useRef(STATUS_READY);

    useEffect(() => {
        return () => {
            setMNaTab('NO');
            setMSpeed(60);
            setMRate(2);
            setMStatus(0);
        }
    }, []);

    
    const onPlayClick = () => {
        const delay = ms => new Promise(res => setTimeout(() => {
            if (statusRef.current === STATUS_READY) {
                res(false)
            }
            else res(true)
        }, ms))
        const play = async () => {
            const powNum = Math.pow(2, rateRef.current.now);
            for (let tick = 0; tick < [...naTabRef.current].length; tick++) {
                if (rateRef.current.now) {
                    if (tick % (powNum * 2) === powNum - 1) CHING.current.play();
                    if (tick % (powNum * 2) === powNum * 2 - 1) {CHAB.current.play(); CHING.current.stop();}
                }
                if (naTabRef.current[tick]) naTabRef.current[tick].play();
                const res = await delay(toneTime.current)
                if (!res) break;
            }
            if (isChanging.current) {
                if (nextNaTabRef.current) {
                    naTabRef.current = nextNaTabRef.current;
                    nextNaTabRef.current = null;
                }
                if (rateRef.current.next) {
                    rateRef.current.now = rateRef.current.next;
                    rateRef.current.next = null;
                }
                btnChange.current.disabled = false;
                isChanging.current = false;
            }
            if (statusRef.current === STATUS_PLAYING) play();
            else {
                setMStatus(STATUS_READY)
                loadNatabSound(false);
                CHING.current.unload();
                CHAB.current.unload();
            }
        }
        CHING.current.load();
        CHAB.current.load();
        loadNatabSound();
        play();
    }

    useEffect(() => {
        if (mStatus === STATUS_PLAYING) SendLogData('playingTone')
    }, [mStatus]);

    useEffect(() => {
        toneTime.current = 250 * 60 / mSpeed;
    }, [mSpeed]);

    useEffect(() => {
        statusRef.current = mStatus;
    }, [mStatus])

    const onRateChange = e => {
        const rate = parseInt(e.target.value);
        setMRate(rate);
        switch (mStatus) {
            case STATUS_READY:
                rateRef.current.now = rate;
                break;
            case STATUS_PLAYING:
                rateRef.current.next = rate;
                break;
            default:
                break;
        }
    }

    const onNaTabChange = e => {
        setMNaTab(e.target.value);
        switch (mStatus) {
            case STATUS_READY:
                naTabRef.current = NATAB[e.target.value]
                break;
            case STATUS_PLAYING:
                nextNaTabRef.current = NATAB[e.target.value]
                break;
            default:
                break;
        }
    }

    const onChangeClick = () => {
        btnChange.current.disabled = true;
        isChanging.current = true;
    }

    return (
        <>
        <SuperHeader title='พร้อมจังหวะ' canSetVol={true}/>
        <div className="container" style={{maxWidth: '1000px'}}>
            <RoundContainer>
                <Row>
                    <Col><Form.Label>ความเร็ว (beats per minute: bpm)</Form.Label></Col>
                    <div className="col" style={{textAlign: 'right', alignItems: 'right'}}>{mSpeed} bpm</div>
                </Row>
                <Form.Range value={mSpeed} step={1} min={15} max={180} onChange={e => setMSpeed(parseFloat(e.target.value))}/>
            
                <hr />

                <Row>
                    <Col><Form.Label>{mStatus === STATUS_READY ? 'อัตราจังหวะ (ฉิ่ง)' : 'อัตราจังหวะที่ต้องการเปลี่ยน'}</Form.Label></Col>
                    <div className="col" style={{textAlign: 'right', alignItems: 'right'}}>{mRate === 0 ? 'ปิดเสียงฉิ่ง' : mRate === 1 ? 'ชั้นเดียว' : `${mRate} ชั้น`}</div>
                </Row>
                <Form.Range disabled={mStatus === STATUS_STOPPING} step={1} min={1} max={3} value={mRate} onChange={onRateChange}/>
            
                <hr />
                <Row>
                    <Col sm="3">{mStatus === STATUS_READY ? 'หน้าทับ (โทน-รำมะนา)' : 'หน้าทับที่ต้องการเปลี่ยน'}</Col>
                    <Col>
                        <select disabled={mStatus === STATUS_STOPPING} className="form-control" onChange={onNaTabChange}>
                            <option value="NO" defaultChecked>ปิดเสียงโทน-รำมะนา</option>
                            <option value="PK3">ปรบไก่ (3 ชั้น)</option>
                            <option value="PK2">ปรบไก่ (2 ชั้น)</option>
                            <option value="PK1">ปรบไก่ (ชั้นเดียว)</option>
                            <option value="SM3">สองไม้ (3 ชั้น)</option>
                            <option value="SM2">สองไม้ (2 ชั้น)</option>
                            <option value="SM1">สองไม้ (ชั้นเดียว)</option>
                            <option value="LM">ลูกหมด</option>
                            <option value="L2">ลาว (2 ชั้น)</option>
                            <option value="FR2">ฝรั่ง (2 ชั้น)</option>
                            <option value="KM3">เขมร (3 ชั้น)</option>
                            <option value="KM2">เขมร (2 ชั้น)</option>
                            <option value="KM1">เขมร (ชั้นเดียว)</option>
                        </select>
                    </Col>
                </Row>
                <hr />
            </RoundContainer>

            <br />

            <RoundContainer>
                <Row bsPrefix="row text-center">
                    <Col sm="4" hidden={mStatus !== STATUS_READY}>
                        <Button variant="primary" onClick={() => {setMStatus(STATUS_PLAYING); onPlayClick()}}>เริ่มเล่น</Button>
                        <br /><br />
                    </Col>
                    <Col sm="4" hidden={mStatus === STATUS_READY}>
                        <Button ref={btnChange} variant="primary" disabled={mNaTab === 'NO' || mStatus === STATUS_STOPPING} onClick={onChangeClick}>เปลี่ยนจังหวะและหน้าทับ</Button>
                        <br /><br />
                    </Col>
                    <Col sm="4">
                        <Button variant="danger" disabled={mStatus === STATUS_READY} onClick={() => setMStatus(STATUS_READY)}>หยุดทันที</Button>
                        <br /><br />
                    </Col>
                    <Col sm="4">
                        <Button variant="warning" disabled={mStatus !== STATUS_PLAYING || mNaTab === 'NO'} onClick={() => setMStatus(STATUS_STOPPING)}>หยุดเมื่อหมดหน้าทับ</Button>
                        <br /><br />
                    </Col>
                </Row>
            </RoundContainer>            
        </div>  
        </>
    )
}

export default RhythmOnline;




/*useEffect(() => {
        switch (mStatus) {
            case STATUS_PLAYING:
                const plus = () => {
                    if (naTabCount.current < naTabRef.current.length - 1){
                        naTabCount.current = naTabCount.current + 1;
                    }
                    else if (isStopping.current) {
                        naTabCount.current = 0;
                        setMStatus(STATUS_READY);
                        clearInterval(chingRef.current);
                        CHING.current.stop();
                        isStopping.current = false;
                    }
                    else if (isChanging.current) {
                        naTabCount.current = 0;
                        if (nextNaTabRef.current) {
                            naTabRef.current = nextNaTabRef.current;
                            nextNaTabRef.current = null;
                        }
                        if (rateRef.current.next) {
                            rateRef.current.now = rateRef.current.next;
                            rateRef.current.next = null;
                        }
                        btnChange.current.disabled = false;
                        isChanging.current = false;
                    }
                    else {
                        naTabCount.current = 0;
                    }
                }

                const play = () => {
                    if (chingRef.current) clearInterval(chingRef.current);
                    chingRef.current = setInterval(play, toneTime.current);
                    if (naTabRef.current) {
                        setTimeout(() => {
                            if (naTabRef.current[naTabCount.current] && mStatus !== STATUS_READY) naTabRef.current[naTabCount.current].play();
                            if ((naTabCount.current + 1) % Math.pow(2, rateRef.current.now + 1) === 0) {
                                CHING.current.stop();
                                if (mStatus !== STATUS_READY) CHAB.current.play();
                            }
                            if ((naTabCount.current + 1) % Math.pow(2, rateRef.current.now + 1) === Math.pow(2, rateRef.current.now)) {
                                if (mStatus !== STATUS_READY) CHING.current.play();
                            } 
                            plus();
                        }, toneTime.current);
                    }
                }
                naTabCount.current = 0;
                if (!naTabRef.current) {
                    const play = () => {
                        if (chingCount.current) CHING.current.play()
                        else {CHING.current.stop(); CHAB.current.play();}
                        chingCount.current = !chingCount.current;
                        setTimeout(() => {
                            if (statusRef.current === STATUS_PLAYING) play();
                        }, toneTime.current * Math.pow(rateRef.current.now, 2))
                    }
                    play();
                }
                else if (rateRef.current.now !== 0) {
                    NATAB.LoadToneSound();
                    chingRef.current = setInterval(play, toneTime.current);
                }
                break;
            case STATUS_READY: default:
                if (chingRef.current) {
                    clearInterval(chingRef.current);
                }
                CHING.current.stop();
                setTimeout(() => NATAB.UnloadToneSound(), 500);
                chingCount.current = true;
                isStopping.current = false;
                isChanging.current = false;
                break;
            case STATUS_STOPPING: 
                isStopping.current = true;
                break;
        }
    }, [mStatus]);*/
