import { memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { SheetContext } from "../../providers/SheetContext";
import { CAN_EDIT, FOCUS_COMMENT, FOCUS_NOTE, SakolToThaiNote, TANG_KONG } from "./const";
import { KeyPressContext } from '../../providers/KeyPressContext'
//import RenderNewRoom from "./RenderRoom";

const RenderComment = props => {
    const { roomId, val, onCommentChange } = props;
    const { gIsPlaying, gIsShowComment, setFRoom, setFType, setFId } = useContext(SheetContext)

    //const getCommentBg = (room) => (fId === tId && fRoom === room && fType === FOCUS_COMMENT) ? '#fffb8f' : gIsPlaying ? '#f5f5f5' : '#fafafa';
    const onCommentFocus = e => {
        const c = document.getElementById(e.target.id);
        c.style.background = '#fffb8f'
        onCommentClick(e);
    }
    const onCommentBlur = e => {
        const c = document.getElementById(e.target.id);
        c.style.background = '#fafafa'
    }

    const onCommentClick = e => {
        let ids = (e.target.id || 'c-0-0').split('-');
        setFRoom(parseInt(ids[1]))
        setFType(FOCUS_COMMENT);
        setFId(parseInt(ids[2]))
    }
    
    return gIsShowComment ? (
        <Form.Control 
            style={{background : '#fafafa'}}
            type='text' autoComplete="off" id={roomId} onFocus={onCommentFocus} onBlur={onCommentBlur}
            onChange={onCommentChange} value={val} disabled={gIsPlaying}
        />
    ) : (
        <div>{val}</div>
    )
}

const RenderRoom = memo( ({ room, roomNote, roomSpeed, tTang, isRoomFocus, isRoomSelect, tId, gIsPlaying, gRight, setFId, setFRoom, setFType, gIsSelectPress, setSRoomShift, size }) => {
    //const { room, roomNote, roomSpeed, tTang, isRoomFocus, isRoomSelect, tId } = props;
    
    const { shiftPress } = useContext(KeyPressContext);

    const roomRef = useRef(null);
    const roomClassName = useRef('');

    const [roomNoteState, setRoomNoteState] = useState(['', ''])
    useEffect(() => {
        setRoomNoteState(roomNote || ['', ''])
    }, [roomNote])
    useEffect(() => {
        let cn = 'note-col-div';
        if (room % 8 === 0) cn += ' note-0'
        if (room % 8 === 4) cn += ' note-4'
        roomClassName.current = cn;
    }, [room])

    console.log('rerender!')
    
    const background = useMemo(() => {
        if (gIsPlaying) {
            return '#f5f5f5'
        }
        else if (isRoomFocus) {
            return '#fffb8f'
        }
        else if (isRoomSelect) {
            return '#eaff8f'
        }
        else if (gRight !== CAN_EDIT) {
            return '#f5f5f5'
        }
        else {
            return 'white'
        }
    }, [isRoomFocus, isRoomSelect, gRight, gIsPlaying,/* sRoom, room, tId*/])
    
    
    const RenderSingleNote = (props => {
        const { value } = props;
        return (
            <Col bsPrefix="col single-note" >{SakolToThaiNote[value] || value}</Col>
        )
    })

    const onRoomClick = () => {
        if (!gIsPlaying) {
            if (gIsSelectPress || shiftPress.current) {
                setSRoomShift(room)
            }
            else {
                setFId(tId)
                setFRoom(room)
                setFType(FOCUS_NOTE)
            }
        }
    }

    return (
        <Col style={{paddingInline: 0, background}} onClick={() => onRoomClick()} ref={roomRef}>
            <div style={{padding : '0px', margin : '0'}} id={`room-${size || 'sm'}-${tId}-${FOCUS_NOTE}-${room}`} >
            <div style={{fontSize: '10px', marginTop : roomSpeed ? '-15px' : '0px'}}>{roomSpeed}</div>
            <div className={roomClassName.current}>
                {Array.isArray(roomNote) ? (
                    <Row>
                        <RenderSingleNote value={roomNoteState[0].charAt(0)} />
                        <RenderSingleNote value={roomNoteState[0].charAt(1)} />
                        <RenderSingleNote value={roomNoteState[0].charAt(2)} />
                        <RenderSingleNote value={roomNoteState[0].charAt(3)} />
                    </Row>
                ) : <div />}
            </div>
            {tTang === TANG_KONG ? ( <div className={roomClassName.current + ' note-kong-lower'}>
                {Array.isArray(roomNote) ? (
                    <Row>
                        <RenderSingleNote value={roomNoteState[1].charAt(0)} />
                        <RenderSingleNote value={roomNoteState[1].charAt(1)} />
                        <RenderSingleNote value={roomNoteState[1].charAt(2)} />
                        <RenderSingleNote value={roomNoteState[1].charAt(3)} />
                    </Row>
                ) : <div />}
            </div>
            ) : <div />}
            </div>
        </Col>
    )
}, (prev, next) => {
    
    const returnProps = ( 
        prev.room === next.room
        && prev.roomNote === next.roomNote 
        && prev.roomSpeed === next.roomSpeed
        && prev.isRoomFocus === next.isRoomFocus
        && prev.isRoomSelect === next.isRoomSelect
        && prev.gIsPlaying === next.gIsPlaying
        && prev.gRight === next.gRight
        && prev.gIsSelectPress === next.gIsSelectPress
        //&& prev.gFocusRoom.fRoom === next.gFocusRoom.fRoom
        //&& prev.gFocusRoom.fId === next.gFocusRoom.fId
        //&& prev.onRoomClick === next.onRoomClick

    )
    //if (prev.room === 0) console.log(prev, next, returnProps)
    return returnProps;
})




const RenderTon = /*memo(*/props => {
    const { 
        tonData, tId, setTData,
        orderItem,
    } = props || {};
    const { tLineNum, tComment, tTang, tNote, tFixedSpeed } = tonData || {};
    const { gFocusRoom,  setFRoom, setFType, setFId, gIsPlaying, gIsSelectPress, setSRoomShift, sRoom, gRight } = useContext(SheetContext)
    const { fRoom, fId, fType, } = gFocusRoom || {};
    
    const RoundText = {
        0 : 'ไม่เล่น',
        1 : 'ไม่กลับต้น',
        2 : 'กลับต้น',
    }
    const getRound = (orderItem.find(it => it.tId === tId) || {tRound : 2}).tRound;

    const onCommentChange = e => {
        let val = e.target.value
        let ids = (e.target.id || 'c-0').split('-');
        setTData(d => ({...d, [tId] : {...d[tId], tComment : {...d[tId].tComment, [parseInt(ids[1])] : val}}}))
    }



    return (
        <div>
            {[...Array(tLineNum).keys()].map((lineNum, i) => (
                <div key={i}>
                    <div className="d-none d-sm-block" >
                        <InputGroup style={{paddingInline: '20px'}}>
                            <RenderComment 
                                roomId={`comment-${lineNum * 2}-${tId}`} val={tComment[lineNum * 2]} 
                                onCommentChange={onCommentChange} 
                            />
                            <RenderComment 
                                roomId={`comment-${lineNum * 2 + 1}-${tId}`} val={tComment[lineNum * 2 + 1]} 
                                onCommentChange={onCommentChange} 
                            />
                        </InputGroup>
                        
                        <Row bsPrefix="row pt-3" style={{paddingInline: '32px'}}>
                            {[...Array(8).keys()].map(v => lineNum * 8 + v).map((room, i) => (
                                <RenderRoom 
                                    key={i} room={room} tId={tId} tTang={tTang} 
                                    roomNote={tNote[room]} roomSpeed={tFixedSpeed[room]} 
                                    isRoomFocus={fId === tId && fRoom === room && fType === FOCUS_NOTE}
                                    isRoomSelect={sRoom.includes(room) && fId === tId && sRoom.length > 1}
                                    gIsPlaying={gIsPlaying} gRight={gRight}
                                    setFId={setFId} setFRoom={setFRoom} setFType={setFType}
                                    gIsSelectPress={gIsSelectPress} setSRoomShift={setSRoomShift}
                                />
                            ))}
                            {/*[...Array(8).keys()].map((v, i) => (
                                <RenderNewRoom key={i} room={lineNum * 8 + v} tang={tTang} note={tNote[lineNum * 8 + v]} speed={tFixedSpeed[lineNum * 8 + v]} tonID={tId}/>
                            ))*/}
                        </Row>
                        <br />
                    </div>
                    <div className="d-sm-none">
                        <RenderComment 
                            roomId={`comment-${lineNum * 2}-${tId}`}  val={tComment[lineNum * 2]} 
                            onCommentChange={onCommentChange} 
                            />
                        <Row bsPrefix="row pt-3" style={{paddingInline: '12px'}}>
                            {[...Array(4).keys()].map(v => lineNum * 8 + v).map((room, i) => (
                                <RenderRoom 
                                    key={i} room={room} tId={tId} tTang={tTang} size={'xs'}
                                    roomNote={tNote[room]} roomSpeed={tFixedSpeed[room]} 
                                    isRoomFocus={fId === tId && fRoom === room && fType === FOCUS_NOTE}
                                    isRoomSelect={sRoom.includes(room) && fId === tId && sRoom.length > 1}
                                    gIsPlaying={gIsPlaying} gRight={gRight}
                                    setFId={setFId} setFRoom={setFRoom} setFType={setFType}
                                    gIsSelectPress={gIsSelectPress} setSRoomShift={setSRoomShift}
                                />
                            ))}
                        </Row>
                        <br />
                        <RenderComment 
                            roomId={`comment-${lineNum * 2 + 1}-${tId}`} val={tComment[lineNum * 2 + 1]} 
                            onCommentChange={onCommentChange} 
                        />
                        <Row bsPrefix="row pt-3" style={{paddingInline: '12px'}}>
                            {[...Array(4).keys()].map(v => lineNum * 8 + v + 4).map((room, i) => (
                                <RenderRoom 
                                    key={i} room={room} tId={tId} tTang={tTang} size={'xs'}
                                    roomNote={tNote[room]} roomSpeed={tFixedSpeed[room]} 
                                    isRoomFocus={fId === tId && fRoom === room && fType === FOCUS_NOTE}
                                    isRoomSelect={sRoom.includes(room) && fId === tId && sRoom.length > 1}
                                    gIsPlaying={gIsPlaying} gRight={gRight}
                                    setFId={setFId} setFRoom={setFRoom} setFType={setFType}
                                    gIsSelectPress={gIsSelectPress} setSRoomShift={setSRoomShift}
                                />
                            ))}
                        </Row>
                        <br />
                    </div>
                </div>
            ))}
            <div className="text-end" style={{paddingInline : '20px'}}>{RoundText[getRound] || `${getRound} รอบ`}</div>
            <br />
        </div>
    )
}//, (prev, next) => prev.tonData.tNote === next.tonData.tNote && prev.orderItem === next.orderItem && prev.tId === next.tId)

const RenderNote = props => {
    const { tData, setTData, orderItem } = props;
    
    return (
        <div>
            {orderItem.length !== 0 ? orderItem.map(({tId}, index) => (
                <div key={index}>
                    <h5 className="d-sm-block d-none" style={{paddingLeft : '20px'}}>{tData[tId] ? tData[tId].tName : ''}</h5>
                    <div className="d-sm-none">{tData[tId] ? tData[tId].tName : ''}</div>
                    <RenderTon 
                        tId={parseInt(tId)}
                        tonData={tData[tId]}
                        setTData={setTData} 
                        orderItem={orderItem} 
                    />
                </div>
            )) : <div />}
        </div>
    )
}//, (prev, next) => prev.tData === next.tData && prev.orderItem === next.orderItem)

export default RenderNote;



    //const { gFocusRoom, setFRoom, setFType, setFId } = useContext(SheetContext);
    //const { fId } = gFocusRoom;

    /*const setKey = (id) => {
        if (fId !== id) {
            if (tData[id]) {
                setFId(id)
                setFRoom(0);
                setFType(FOCUS_NOTE);
            }
        }
        else {
            setFType(FOCUS_NONE);
            setFId(null);
        }
    }

    const tonMapping = orderItem.length !== 0 ? orderItem.map(({tId}, index) => 
        <Accordion.Item key={index} eventKey={parseInt(tId)} >
            <Accordion.Header onClick={() => setKey(parseInt(tId))} >
                {tData[tId] ? tData[tId].tName : ''}
            </Accordion.Header>
            <Accordion.Body style={{paddingInline: 0}}>
                <RenderTon 
                    tId={parseInt(tId)}
                    tData={tData} setTData={setTData} 
                    orderItem={orderItem} 
                    gNoteData={gNoteData} setGNoteData={setGNoteData}
                />
            </Accordion.Body>
        </Accordion.Item>
    ) : <div />

    return (
        <Accordion activeKey={fId || null} style={{zIndex: -10}}>
            {tonMapping}
        </Accordion>
    )*/


    /*const EachRoom = props => {
        const { room } = props;    
        return (
            <Col style={{paddingInline: 0}}>
                <div style={{fontSize: '10px', marginTop : tFixedSpeed[room] ? '-15px' : '0px'}}>{tFixedSpeed[room]}</div>
                <EachSubRoom room={room} subRoom={0} />
                {tTang === TANG_KONG ? <EachSubRoom room={room} subRoom={1} /> : <div />}
            </Col>
        )
    }

    const EachSubRoom = props => {
        const { room, subRoom } = props;
        const roomRef = useRef(null);
        const onRoomClick = () => {
            if (gIsPlaying) {
                setGIsPlaying(false)
            }
            if (tId !== fId) {
                setFId(tId)
                setFRoom(room)
                setFType(FOCUS_NOTE)
            }
            else if (!gIsKeyPress.Shift && !gIsKeyPress.Control && !gIsSelectPress) {
                setFId(tId)
                setFRoom(room)
                setFType(FOCUS_NOTE)
            }
            else if (gIsKeyPress.Control && fRoom !== room && fType === FOCUS_NOTE) {
                if (gSelectRoom.includes(room)) {
                    setGSelectRoom([...gSelectRoom].filter(a => a !== room))
                }
                else {
                    setGSelectRoom([...gSelectRoom, room].sort())
                }
            }
            else if ((gIsKeyPress.Shift || gIsSelectPress) && fType === FOCUS_NOTE) {
                let len = Math.abs(room - fRoom) + 1;
                setGSelectRoom([...Array(len).keys()].map(i => i + Math.min(room, fRoom)))
            }
        }

        const bg = gIsPlaying ? '#f5f5f5' : (fId === tId && fRoom === room && fType === FOCUS_NOTE) ? '#fffb8f' : gSelectRoom.includes(room) && fId === tId ? '#eaff8f' : gRight !== CAN_EDIT ? '#f5f5f5' : 'white';
        const conditionClassName = room % 8 === 0 ? 'note-col-div note-0' : room % 8 === 4 ? 'note-col-div note-4' : 'note-col-div';

        return (
            <div 
                className={`${conditionClassName} ${subRoom !== 0 ? 'note-kong-lower' : ''}`} 
                style={{background : bg}} ref={roomRef}
                onClick={onRoomClick}
            >
                
                {tNote && Array.isArray(tNote[room]) ? (
                    <Row>
                        <RenderSingleNote value={tNote[room][subRoom].charAt(0)} tId={tId} room={room} subRoom={subRoom} char={0} />
                        <RenderSingleNote value={tNote[room][subRoom].charAt(1)} tId={tId} room={room} subRoom={subRoom} char={1}/>
                        <RenderSingleNote value={tNote[room][subRoom].charAt(2)} tId={tId} room={room} subRoom={subRoom} char={2}/>
                        <RenderSingleNote value={tNote[room][subRoom].charAt(3)} tId={tId} room={room} subRoom={subRoom} char={3}/>
                    </Row>
                ) : <div />}
            </div>
        )
    }*/
