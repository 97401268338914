import React, { useContext, useEffect, useState } from 'react';
import { database, auth } from '../../databases/firebase'
import { UserContext } from '../../providers/UserProvider';
import socket from '../../server/socket-io';
import RoomTablet from '../pp/tabletPages/RoomTablet'
import RoomComputer from '../pp/computerPages/RoomComputer';
import { Badge, Button, Col, Modal, Row } from 'react-bootstrap';
import { ICON_CHECK, ICON_CLOSE, ICON_WARN } from '../icons';
import RoundContainer from '../layout/RoundContainer';
import { chab } from '../../sounds/EffectSound';
import { useNavigate } from 'react-router-dom';
import SuperHeader from '../layout/SuperHeader';

//import Crunker from 'crunker'
//import FileType from 'file-type/browser';


const Home = () => {

    const [isLogin, setLogin] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const { userName, setUserName } = useContext(UserContext);
    const navigate = useNavigate();

    /*useEffect(() => {
        socket.on("disconnect", () => {
            setLogin(false);
            navigate('/');
            window.location.reload();
        });
        return () => {
            setLogin(false);
            navigate('/');
        }
    }, []);*/

    useEffect(() => {
        const onDisconnect = () => {
            setLogin(false);
            navigate('/');
            window.location.reload();
        }
        socket.on('disconnect', onDisconnect);
        return () => socket.off('disconnect', onDisconnect)
    })

    

    

    const handleSubmit = async(event) => {
        event.preventDefault();
        const btnSignIn = document.getElementById('btnSignIn');
        const inputUserName = document.getElementById('input-userName');
        const spinnerSignIn = document.getElementById('spinnerSignIn');
        btnSignIn.disabled = true;
        spinnerSignIn.hidden = false;
        setUserName(userName.trim())
        return new Promise((resolve, reject) => {
            
            if (userName === "") reject('โปรดใส่ชื่อ/ชื่อเล่นใด ๆ')
            else if (window.screen.width < 1000 && window.screen.height < 1000) reject('ขออภัย ระบบไม่รองรับ')
            else {
                socket.connect();
                let t = 0;
                const sit = setInterval(() => {
                    t += 100;
                    if (socket.connected) {
                        clearInterval(sit);
                        resolve();
                    }
                    if (t >= 8000) {
                        clearInterval(sit);
                        reject('เสียจัยด้วย ไม่สามารถเชื่อมต่อเซิร์ฟเวอร์ได้ :(');
                    }
                }, 100);
            }
        })
        .then(() => {
            socket.emit('userLogin', userName.trim(), res => {
                if (!res) {
                    alert("มีผู้ใช้งานชื่อนี้แล้ว")
                    socket.disconnect();
                    setTimeout(() => {btnSignIn.disabled = false; spinnerSignIn.hidden = true;}, 500);
                } 
                else {
                    setLogin(true); 
                    setTimeout(() => {btnSignIn.disabled = false; spinnerSignIn.hidden = true;}, 5000);
                    if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
                        setShowModal(true);
                        if (userName !== "อื๋อ" && userName !== "อื๋ออีกคล") database.ref(`history/${Date.now()}`).set({
                            userName,
                            loginTime : new Date().toLocaleString(),
                        });
                    }
                    navigate(`/pp?name=${userName}`)
                } 
            });
        })
        .catch((e) => {
            alert(e);
            event.target.value = "";
            inputUserName.value = "";
            socket.disconnect();
            btnSignIn.disabled = false;
            spinnerSignIn.hidden = true;
        })
    }

    

    return (
        <div>
            <SuperHeader />
            {!isLogin || socket.disconnected ? (
                <div className="container" style={{maxWidth: "1000px", background: "#f5f5f5"}}>
                    {infoModal(showInfoModal, setShowInfoModal)}
                    <RoundContainer>
                    <Row>
                        <Col sm="3">
                            <div className='text-center'>
                                <img src="./tonePic.png" style={{paddingBlock: '20px'}} height={150} alt="" />
                            </div>
                        </Col>
                        <Col sm="9">
                                <h5 style={{fontWeight: 'bold' }}>พร้อมเพลง</h5>
                                <div>ยินดีต้อนรับเข้าสู่พร้อมเพลง แพลตฟอร์มที่เราสามารถมาเล่นดนตรีไทยออนไลน์พร้อมกันได้</div>
                                <br />
                                <form>
                                    <div className="form-group">
                                        <label>คุณเป็นใครน่ะ (ชื่อเล่น)</label>
                                        <Row>
                                            <Col xs='12' lg='8' md='6'>
                                                <input type="text" id="input-userName" placeholder='!ปิดส่วนนี้ชั่วคราว!' disabled onChange={e => setUserName(e.target.value)} className="form-control" />
                                            </Col>
                                            <Col bsPrefix='col col-auto'>
                                                <button id="btnSignIn" className="btn btn-primary" onClick={handleSubmit} disabled={true}>
                                                    <span id="spinnerSignIn" hidden={true} className="spinner-border spinner-border-sm"></span> เข้าเว็บ
                                                </button>
                                            </Col>
                                            <Col bsPrefix='col col-auto'>
                                                <button className="btn btn-outline-danger" onClick={e => {e.preventDefault(); setShowInfoModal(true)}}> เข้าไม่ได้? </button>
                                            </Col>
                                        </Row>
                                    </div>
                                </form>
                        
                        </Col>
                    </Row>
                    </RoundContainer>
                    <br />
                    
                    <RoundContainer>
                        <Row>
                            <Col sm='9'>
                                
                                <h5 style={{fontWeight: 'bold'}}>พร้อมจังหวะ</h5>
                                <div>[เว็บเสริม] สามารถเปิดจังหวะเป็นเสียงฉิ่งและโทน-รำมะนา และควบคุมความช้า-เร็วได้</div>
                                <div>หมายเหตุ: รองรับแค่หน้าทับจำนวนหนึ่ง (กรณีที่อยากได้หน้าทับเพิ่ม โปรดติดต่อผู้จัดทำ)</div>
                                <br />
                                <div style={{textAlign: 'right'}}><Button variant='primary' onClick={() => navigate('/promchangwa')}>พร้อมจังหวะ</Button>
                                </div>
                                
                            </Col>
                            <Col sm='3'>
                                <div className='text-center'>
                                    <img src="./chingPic.png" style={{paddingBlock: '20px'}} height={130} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </RoundContainer>
                    <br />
                    <RoundContainer hidden={true}>
                        <Row>
                            <Col sm='3'>
                                <div className='text-center'>
                                    
                                </div>
                            </Col>
                            <Col sm='9'>
                                
                                <h5 style={{fontWeight: 'bold'}}>พร้อมเป่า</h5>
                                <div>[เว็บเสริม] สามารถพิมพ์โน้ตเพลงให้แพลตฟอร์มสร้างเสียงขลุ่ยตามโน้ตได้ รองรับเสียง ด ถึง ลํ</div>
                                <div>หมายเหตุ: อยู่ในระหว่างทดสอบ ผิดพลาดหรือมีข้อเสนอแนะใด ๆ โปรดติดต่อผู้จัดทำ</div>
                                <br />
                                <div style={{textAlign: 'left'}}><Button variant='primary' onClick={() => navigate('/prompao')}>พร้อมเป่า</Button>
                                </div>
                                
                            </Col>
                            
                        </Row>
                    </RoundContainer>
                    <br />
                    <RoundContainer>
                        <Row>
                            <Col sm='9'>
                                
                                <h5 style={{fontWeight: 'bold'}}>พร้อมเล่น <Badge bg="warning" text="dark">Update</Badge></h5>
                                <div>[เว็บเสริม] สามารถพิมพ์โน้ตเพลงให้แพลตฟอร์มเล่นตามโน้ตได้</div>
                                <div>หมายเหตุ: อยู่ในระหว่างทดสอบ ผิดพลาดหรือมีข้อเสนอแนะใด ๆ โปรดติดต่อผู้จัดทำ</div>
                                <br />
                                <div style={{textAlign: 'left'}}><Button variant='primary' disabled={!auth.currentUser} onClick={() => navigate('/software')}>พร้อมเล่น</Button>
                                </div>
                                
                            </Col>
                            <Col sm='3'>
                                <div className='text-center'>
                                    
                                </div>
                            </Col>
                            
                            
                        </Row>
                    </RoundContainer>
                    <br />
                    
                </div>
            ) : (
                <div>
                    {announceModal(showModal, setShowModal)}
                    { (window.screen.width < 1200) ? <RoomTablet /> : <RoomComputer /> } 
                </div>
            )}
        </div>
    )
}

export default Home;

const announceModal = (showModal, setShowModal) => {
    return (
        <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>ประกาศ</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                - รับบริจาคเสียงเครื่องจังหวะ เช่น กลองแขก กรับ หรือมีอะไรเสนอมาได้ค้าบ  <br />
                - รับบริจาคงานอาร์ทเวิร์ค เครื่องดนตรีไทย หรือที่เกี่ยวข้อง รูปสวย ๆ ของท่านช่วยเราได้ <br/>
            </Modal.Body>
            <Modal.Footer>
                <div className="btn btn-primary" onClick={() => {setShowModal(false); chab.play()}}>รับแซ่บ</div>
            </Modal.Footer>
        </Modal>
    )
}

const infoModal = (showModal, setShowModal) => {
    return (
        <Modal show={showModal} size="md" onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>เข้าเว็บไม่ได้?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                หมายเหตุ
                <div style={{paddingLeft: '10px'}}>
                    <div>- เซิร์ฟเวอร์จะหลับไป 30 นาทีหลังจากไม่มีใครใช้งาน หากเชื่อมต่อไม่ได้ กรุณาปลุกน้องโดยการลองใหม่สัก 2-3 รอบ</div>
                    <div>- โปรดอ่อนโยนกับน้องเวลาน้องล่ม ;-;</div>
                    <div>- เข้าไม่ได้จริง ๆ โปรดติดต่อผู้จัดทำ</div>
                </div>
                <br />

                การรองรับ
                <div style={{paddingLeft: '10px'}}>
                    <div className="text-success">{ICON_CHECK} PC ที่ใช้ Browser: Chrome, Firefox และ Microsoft Edge </div>
                    <div className="text-success">{ICON_CHECK} Tablet ที่ไม่ใช้ระบบปฏิบัติการ iOS </div>
                    <div style={{color: "#d4b106"}}>{ICON_WARN} Tablet ที่ใช้ระบบปฏิบัติการ iOS อาจจะมีปัญหากับการเล่นเสียงที่อัด (สามารถใช้งานฟังก์ชันอื่นได้ตามปกติ) </div>
                    <div className="text-danger">{ICON_CLOSE} ยังไม่รองรับอุปกรณ์ที่มีหน้าจอขนาดเล็ก เช่น Smart Phone  </div>
                    <div className="text-danger">{ICON_CLOSE} ยังไม่รองรับ Browser: Safari  </div>
                    <div className="text-danger">{ICON_CLOSE} ไม่รองรับ Browser: Internet Explorer  </div>
                </div>
                
                <br />

                ข้อมูลอุปกรณ์
                <div style={{paddingLeft: '10px'}}>
                    <div>- ขนาดหน้าจอ: {window.screen.width} x {window.screen.height}</div>
                    <div>- ระบบปฏิบัติการ: {osDetail()}</div>
                </div>
                <br />
            </Modal.Body>
            <Modal.Footer>
                <div className="btn btn-primary" onClick={() => {setShowModal(false)}}>รับแซ่บ</div>
            </Modal.Footer>
        </Modal>
    )
}



const osDetail = () => {
    const startIndex = navigator.userAgent.indexOf('(');
    const stopIndex = navigator.userAgent.indexOf(')');
    return navigator.userAgent.substring(startIndex + 1, stopIndex);
}

/*const LogInInfo = () => {
    return (
        <div style={{fontSize: '13px'}}>
            หมายเหตุ
            <div style={{paddingLeft: '10px'}}>
                <div>- เซิร์ฟเวอร์จะหลับไป 30 นาทีหลังจากไม่มีใครใช้งาน หากเชื่อมต่อไม่ได้ กรุณาปลุกน้องโดยการลองใหม่สัก 2-3 รอบ</div>
                <div>- โปรดอ่อนโยนกับน้องเวลาน้องล่ม ;-;</div>
            </div>
            <br />

            การรองรับ
            <div style={{paddingLeft: '10px'}}>
                <div className="text-success">{ICON_CHECK} PC ที่ใช้ Browser: Chrome, Firefox และ Microsoft Edge </div>
                <div className="text-success">{ICON_CHECK} Tablet ที่ไม่ใช้ระบบปฏิบัติการ iOS </div>
                <div style={{color: "#d4b106"}}>{ICON_WARN} Tablet ที่ใช้ระบบปฏิบัติการ iOS อาจจะมีปัญหากับการเล่นเสียงที่อัด (สามารถใช้งานฟังก์ชันอื่นได้ตามปกติ) </div>
                <div className="text-danger">{ICON_CLOSE} ยังไม่รองรับอุปกรณ์ที่มีหน้าจอขนาดเล็ก เช่น Smart Phone  </div>
                <div className="text-danger">{ICON_CLOSE} ยังไม่รองรับ Browser: Safari  </div>
                <div className="text-danger">{ICON_CLOSE} ไม่รองรับ Browser: Internet Explorer  </div>
            </div>
            
            <br />

            ข้อมูลอุปกรณ์
            <div style={{paddingLeft: '10px'}}>
                <div>- ขนาดหน้าจอ: {window.screen.width} x {window.screen.height}</div>
                <div>- ระบบปฏิบัติการ: {osDetail()}</div>
            </div>
            <br />


        </div>
    )
}*/





/*const onTestClick = async () => {
    let blob1 = await fetch('http://localhost:3000/chab.wav').then(r => r.blob());
    let blob2 = await fetch('http://localhost:3000/ching.wav').then(r => r.blob());
    let a1 = await blob1.arrayBuffer();
    let a2 = await blob2.arrayBuffer();
    
    const blob = new Blob([a1, a2], {type: 'audio/wav'})
    console.log(blob);
    const url = URL.createObjectURL(blob);
    new Howl({src: [url], format: ['wav']}).play();
    let crunker = new Crunker();
    let a = ['http://localhost:3000/chab.wav', 'http://localhost:3000/Tumm.wav', 'Chaa.wav']
    crunker.fetchAudio(...a)
        .then(buffers => {
            console.log(buffers);
            return crunker.mergeAudio(buffers);
        })
        .then(b => {
            crunker.play(b)
            return crunker.export(b, 'audio/mp3')
        })
        .then(async o => {
            console.log(o);
            console.log(await FileType.fromBlob(o.blob))
        })
}*/