import { Howl } from "howler";
import React, { createContext, useReducer, useRef } from "react";


export const SoundsContext = createContext({});

const initialState = {
    chingVolume : 0.25,
    toneVolume : 0.25,
    fluteVolume : 0.25,
    kongVolume : 0.25,
    showRhythmModal : false,
    showFluteModal : false,
    showKongModal : false,
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_CHING_VOL':
            return {...state, chingVolume : action.payload}
        case 'SET_TONE_VOL':
            return {...state, toneVolume : action.payload}
        case 'SET_FLUTE_VOL':
            return {...state, fluteVolume : action.payload}
        case 'SET_KONG_VOL':
            return {...state, kongVolume : action.payload}
        case 'SET_RHYTHM_MODAL':
            return {...state, showRhythmModal : action.payload}
        case 'SET_FLUTE_MODAL':
            return {...state, showFluteModal : action.payload}
        case 'SET_KONG_MODAL':
            return {...state, showKongModal : action.payload}
        default:
            break;
    }
}

export const SoundsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { chingVolume, toneVolume, fluteVolume, kongVolume, showRhythmModal, showFluteModal, showKongModal } = state;

    const setShowRhythmModal = payload => dispatch({ type : 'SET_RHYTHM_MODAL', payload })
    const setShowFluteModal = payload => dispatch({ type : 'SET_FLUTE_MODAL', payload })
    const setShowKongModal = payload => dispatch({ type : 'SET_KONG_MODAL', payload })

    const setChingVolume = payload => {
        const vol = parseFloat(payload)
        CHING.current.volume(vol)
        CHAB.current.volume(vol)
        dispatch({ type : 'SET_CHING_VOL', payload : vol })
    }

    const CHAB = useRef(new Howl({
        src: ['chab.wav'],
        volume: 0.25,
        preload: false,
    }));
    
    const CHING = useRef(new Howl({
        src: ['ching.wav'],
        volume: 0.25,
        preload: false,
    }));

    const setToneVolume = payload => {
        const vol = parseFloat(payload)
        TUMM.current.volume(vol);
        TING.current.volume(vol);
        CHOO.current.volume(vol);
        CHAA.current.volume(vol);
        dispatch({ type : 'SET_TONE_VOL', payload : vol })
    }

    const TUMM = useRef(new Howl({
        src: ['Tumm.wav'],
        volume: 0.25,
        preload: false,
    }));

    const TING = useRef(new Howl({
        src: ['Ting.wav'],
        volume: 0.25,
        preload: false,
    }));

    const CHOO = useRef(new Howl({
        src: ['Choo.wav'],
        volume: 0.25,
        preload: false,
    }));

    const CHAA = useRef(new Howl({
        src: ['Chaa.wav'],
        volume: 0.25,
        preload: false,
    }));

    const loadNatabSound = (isLoad = true) => {
        if (isLoad) {
            TUMM.current.load();
            TING.current.load();
            CHOO.current.load();
            CHAA.current.load();
        }
        else {
            TUMM.current.unload();
            TING.current.unload();
            CHOO.current.unload();
            CHAA.current.unload();
        }
    }

    

    const NATAB = {
        NO : [
            null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 
        ],
        L2 : [
            null, TING.current, null, CHOO.current, null, TING.current, null, TING.current, null, null, TING.current, TUMM.current, null, TING.current, null, TUMM.current
        ],
        PK3 : [
            null, TUMM.current, null, TING.current, null, CHOO.current, null, CHAA.current, null, CHOO.current, null, CHAA.current, null, CHOO.current, null, CHAA.current, 
            null, null, null, null, null, CHOO.current, null, CHAA.current, null, CHOO.current, null, CHAA.current, null, CHOO.current, null, CHAA.current,
            null, TING.current, null, TING.current, null, TUMM.current, null, TUMM.current, null, TING.current, null, TING.current, null, CHOO.current, null, CHAA.current,
            null, TING.current, null, TUMM.current, null, TING.current, null, TING.current, null, TUMM.current, null, TING.current, null, TING.current, null, TUMM.current, 
        ],
        PK2 : [
            null, TUMM.current, null, TING.current, null, CHOO.current, null, CHAA.current, null, CHOO.current, null, CHAA.current, null, CHOO.current, null, CHAA.current, 
            null, TING.current, null, TUMM.current, null, TING.current, null, TING.current, null, TUMM.current, null, TING.current, null, TING.current, null, TUMM.current, 
        ],
        PK1 : [
            null, null, TING.current, TUMM.current, null, TING.current, null, null, TING.current, TUMM.current, null, TING.current, null, TUMM.current, TING.current, TUMM.current
        ],
        SM3 : [
            null, TUMM.current, null, TING.current, null, CHOO.current, null, CHAA.current, null, CHOO.current, null, CHAA.current, null, CHOO.current, null, CHAA.current, 
            null, TING.current, null, TING.current, null, TUMM.current, TING.current, TUMM.current, null, TING.current, null, TING.current, null, TUMM.current, TING.current, TUMM.current, 
        ], 
        SM2 : [
            TING.current, null, CHOO.current, CHAA.current, TING.current, TING.current, null, TING.current, null, null, CHOO.current, CHAA.current, TING.current, TING.current, null, TUMM.current,
        ], 
        SM1 : [
            TING.current, null, CHOO.current, CHAA.current, TING.current, TING.current, null, TUMM.current,
        ],
        FR2 : [
            null, TING.current, TING.current, TING.current, null, TING.current, null, TUMM.current, null, TING.current, null, TUMM.current, null, TING.current, null, TUMM.current, 
        ],
        KM3 : [
            null, null, null, null, null, CHOO.current, null, CHAA.current, null, CHOO.current, null, CHAA.current, null, CHOO.current, null, CHAA.current, 
            null, null, null, null, null, CHOO.current, null, CHAA.current, null, CHOO.current, null, CHAA.current, null, TING.current, null, TUMM.current, 
            null, null, null, null, null, CHOO.current, null, CHAA.current, null, CHOO.current, null, CHAA.current, null, TING.current, null, TUMM.current, 
            null, CHOO.current, null, CHAA.current, null, TING.current, null, TUMM.current, null, TING.current, null, TUMM.current, null, CHOO.current, null, CHAA.current, 
        ],
        
        KM2 : [
            null, null, null, null, null, CHOO.current, null, CHAA.current, null, CHOO.current, null, CHAA.current, null, TING.current, null, TUMM.current, 
            null, CHOO.current, null, CHAA.current, null, TING.current, null, TUMM.current, null, TING.current, null, TUMM.current, null, CHOO.current, null, CHAA.current, 
        ],
        
        KM1 : [
            null, CHOO.current, null, CHAA.current, null, TING.current, null, TUMM.current, null, TING.current, null, TUMM.current, null, CHOO.current, null, CHAA.current, 
        ],
        LM : [
            null, null, null, null, TING.current, CHOO.current, null, TING.current, null, TING.current, null, CHOO.current, TING.current, CHOO.current, null, TING.current,
            null, TUMM.current, null, null, TING.current, TUMM.current, null, TING.current, TUMM.current, TING.current, null, TUMM.current, TING.current, CHOO.current, null, TING.current,
        ],
    }

    const fluteVolumeRef = useRef(0.25)
    const setFluteVolume = payload => {
        const vol = parseFloat(payload);
        fluteVolumeRef.current = vol;
        for (const s in FluteSound) FluteSound[s].current.volume(vol)
        dispatch({ type : 'SET_FLUTE_VOL', payload : vol })
    }

    const FluteSound = {
        c : useRef(new Howl({
            src: ['thaiFluteSound/c.wav'],
            volume: 0.25,
            onplayerror: (soundId, errorMessage) => alert(errorMessage),
            onloaderror: (soundId, errorMessage) => alert(errorMessage),
            preload: false,
        })),
        d : useRef(new Howl({
            src: ['thaiFluteSound/d.wav'],
            volume: 0.25,
            preload: false,
        })),
        e : useRef(new Howl({
            src: ['thaiFluteSound/e.wav'],
            volume: 0.25,
            preload: false,
        })),
        f : useRef(new Howl({
            src: ['thaiFluteSound/f.wav'],
            volume: 0.25,
            preload: false,
        })),
        g : useRef(new Howl({
            src: ['thaiFluteSound/g.wav'],
            volume: 0.25,
            preload: false,
        })),
        h : useRef(new Howl({
            src: ['thaiFluteSound/h.wav'],
            volume: 0.25,
            preload: false,
        })),
        i : useRef(new Howl({
            src: ['thaiFluteSound/i.wav'],
            volume: 0.25,
            preload: false,
        })),
        j : useRef(new Howl({
            src: ['thaiFluteSound/j.wav'],
            volume: 0.25,
            preload: false,
        })),
        k : useRef(new Howl({
            src: ['thaiFluteSound/k.wav'],
            volume: 0.25,
            preload: false,
        })),
        l : useRef(new Howl({
            src: ['thaiFluteSound/l.wav'],
            volume: 0.25,
            preload: false,
        })),
        m : useRef(new Howl({
            src: ['thaiFluteSound/m.wav'],
            volume: 0.25,
            preload: false,
        })),
        n : useRef(new Howl({
            src: ['thaiFluteSound/n.wav'],
            volume: 0.25,
            preload: false,
        })),
        o : useRef(new Howl({
            src: ['thaiFluteSound/o.wav'],
            volume: 0.25,
            preload: false,
        })),
    }

    const loadFluteSound = (isLoad = true) => {
        if (isLoad) {
            for (const s in FluteSound) FluteSound[s].current.load();
        }
        else {
            for (const s in FluteSound) FluteSound[s].current.unload();
        }
    }

    const kongVolumeRef = useRef(0.25);
    const setKongVolume = payload => {
        const vol = parseFloat(payload);
        kongVolumeRef.current = vol;
        for (const s in KongSound) KongSound[s].current.volume(vol);
        dispatch({ type : 'SET_KONG_VOL', payload : vol })
    }

    const KongSound = {
        x : useRef(new Howl({
            src: ['KongSound/e1.wav'],
            volume: 0.25,
            onplayerror: (soundId, errorMessage) => alert(errorMessage),
            onloaderror: (soundId, errorMessage) => alert(errorMessage),
            preload: false,
        })),
        y : useRef(new Howl({
            src: ['KongSound/f1.wav'],
            volume: 0.25,
            onplayerror: (soundId, errorMessage) => alert(errorMessage),
            onloaderror: (soundId, errorMessage) => alert(errorMessage),
            preload: false,
        })),
        z : useRef(new Howl({
            src: ['KongSound/g1.wav'],
            volume: 0.25,
            onplayerror: (soundId, errorMessage) => alert(errorMessage),
            onloaderror: (soundId, errorMessage) => alert(errorMessage),
            preload: false,
        })),
        a : useRef(new Howl({
            src: ['KongSound/a1.wav'],
            volume: 0.25,
            onplayerror: (soundId, errorMessage) => alert(errorMessage),
            onloaderror: (soundId, errorMessage) => alert(errorMessage),
            preload: false,
        })),
        b : useRef(new Howl({
            src: ['KongSound/b1.wav'],
            volume: 0.25,
            onplayerror: (soundId, errorMessage) => alert(errorMessage),
            onloaderror: (soundId, errorMessage) => alert(errorMessage),
            preload: false,
        })),
        c : useRef(new Howl({
            src: ['KongSound/c2.wav'],
            volume: 0.25,
            onplayerror: (soundId, errorMessage) => alert(errorMessage),
            onloaderror: (soundId, errorMessage) => alert(errorMessage),
            preload: false,
        })),
        d : useRef(new Howl({
            src: ['KongSound/d2.wav'],
            volume: 0.25,
            onplayerror: (soundId, errorMessage) => alert(errorMessage),
            onloaderror: (soundId, errorMessage) => alert(errorMessage),
            preload: false,
        })),
        e : useRef(new Howl({
            src: ['KongSound/e2.wav'],
            volume: 0.25,
            onplayerror: (soundId, errorMessage) => alert(errorMessage),
            onloaderror: (soundId, errorMessage) => alert(errorMessage),
            preload: false,
        })),
        f : useRef(new Howl({
            src: ['KongSound/f2.wav'],
            volume: 0.25,
            onplayerror: (soundId, errorMessage) => alert(errorMessage),
            onloaderror: (soundId, errorMessage) => alert(errorMessage),
            preload: false,
        })),
        g : useRef(new Howl({
           src: ['KongSound/g2.wav'],
            volume: 0.25,
            onplayerror: (soundId, errorMessage) => alert(errorMessage),
            onloaderror: (soundId, errorMessage) => alert(errorMessage),
            preload: false,
        })),
        h : useRef(new Howl({
            src: ['KongSound/a2.wav'],
            volume: 0.25,
            onplayerror: (soundId, errorMessage) => alert(errorMessage),
            onloaderror: (soundId, errorMessage) => alert(errorMessage),
            preload: false,
        })),
        i : useRef(new Howl({
            src: ['KongSound/b2.wav'],
            volume: 0.25,
            onplayerror: (soundId, errorMessage) => alert(errorMessage),
            onloaderror: (soundId, errorMessage) => alert(errorMessage),
            preload: false,
        })),
        j : useRef(new Howl({
            src: ['KongSound/c3.wav'],
            volume: 0.25,
            onplayerror: (soundId, errorMessage) => alert(errorMessage),
            onloaderror: (soundId, errorMessage) => alert(errorMessage),
            preload: false,
        })),
        k : useRef(new Howl({
            src: ['KongSound/d3.wav'],
            volume: 0.25,
            onplayerror: (soundId, errorMessage) => alert(errorMessage),
            onloaderror: (soundId, errorMessage) => alert(errorMessage),
            preload: false,
        })),
        l : useRef(new Howl({
            src: ['KongSound/e3.wav'],
            volume: 0.25,
            onplayerror: (soundId, errorMessage) => alert(errorMessage),
            onloaderror: (soundId, errorMessage) => alert(errorMessage),
            preload: false,
        })),
        m : useRef(new Howl({
            src: ['KongSound/f3.wav'],
            volume: 0.25,
            onplayerror: (soundId, errorMessage) => alert(errorMessage),
            onloaderror: (soundId, errorMessage) => alert(errorMessage),
            preload: false,
        })),
    }

    const loadKongSound = (isLoad = true) => {
        if (isLoad) {
            for (const s in KongSound) KongSound[s].current.load();
        }
        else {
            for (const s in KongSound) KongSound[s].current.unload();
        }
    }

    return (
        <SoundsContext.Provider value={{
            CHING, CHAB, chingVolume, setChingVolume,
            TUMM, TING, CHOO, CHAA, toneVolume, setToneVolume, NATAB, loadNatabSound,
            showRhythmModal, setShowRhythmModal,
            FluteSound, loadFluteSound, fluteVolume, setFluteVolume, showFluteModal, setShowFluteModal, fluteVolumeRef,
            KongSound, loadKongSound, kongVolume, setKongVolume, showKongModal, setShowKongModal, kongVolumeRef,

        }}>
            {children}
        </SoundsContext.Provider>
    )
}