import { onKeyChange } from "../actions/SelectFunction";
import { FOCUS_COMMENT, FOCUS_NOTE } from "../const";


const OnArrowKeyDown = props => {
    const { event, tData, setTData, sheetContext, keyPressContext } = props;
    const { gFocusRoom, setFRoom, sRoom, setSRoom } = sheetContext;
    const { shiftPress, controlPress } = keyPressContext;
    const { fId, fRoom, fType } = gFocusRoom || {};

    const roomPerLine = fType === FOCUS_COMMENT ? 2 : 8;
    const lastRoom = tData[fId].tLineNum * roomPerLine - 1;

    if (fType === FOCUS_NOTE) {
        switch (event.key) {
            case 'ArrowRight' :
                if (fRoom < lastRoom) {
                    if (shiftPress.current) {
                        if (Math.min(...sRoom) === fRoom && Math.max(...sRoom) < lastRoom) {
                            setSRoom([...sRoom, Math.max(...sRoom) + 1]);
                        }
                        else if (Math.min(...sRoom) < fRoom) {
                            setSRoom([...sRoom].filter(v => v > Math.min(...sRoom)))
                        }
                    }
                    else setFRoom(fRoom + 1)
                }
                break;
            case 'ArrowLeft':
                if (shiftPress.current) {
                    if (Math.max(...sRoom) > fRoom) {
                        setSRoom([...sRoom].filter(v => v < Math.max(...sRoom)));
                    }
                    else if (Math.max(...sRoom) === fRoom && Math.min(...sRoom) > 0) {
                        setSRoom([...sRoom, Math.min(...sRoom) - 1])
                    }
                }
                else if (fRoom > 0) setFRoom(fRoom - 1)
                break;
            case 'ArrowUp':
                if (shiftPress.current && controlPress.current) {
                    onKeyChange({ fId, tData, setTData, sRoom, isKeyUp : true })
                }
                else if (fRoom >= roomPerLine) setFRoom(fRoom - roomPerLine)
                break;
            case 'ArrowDown':
                if (shiftPress.current && controlPress.current) {
                    onKeyChange({ fId, tData, setTData, sRoom, isKeyUp : false })
                }
                else if (fRoom <= lastRoom - roomPerLine) setFRoom(fRoom + roomPerLine)
                break;
            default :
                break;
        }
    }
}

export default OnArrowKeyDown;