import React, { createContext, useEffect, useReducer, useRef } from "react";
import { database } from "../databases/firebase";
import socket from "../server/socket-io";

export const MicContext = createContext({});

const micReducer = (state, action) => {
    switch (action.type) {
        case "SET_ENABLE":
            return {
                ...state,
                micEnable: true,
            }
        case "SET_DISABLE":
            return {
                ...state,
                micEnable : false,
            }
        case "SET_INPUT":
            return {
                ...state,
                inputID: action.payload,
            }
        case "SET_OUTPUT":
            return {
                ...state,
                outputID: action.payload,
            }
        default:
            console.log('error');
            break;
    }
}

export const MicProvider = ({ children }) => {
    
    const [micState, micDispatch] = useReducer(micReducer, {enable: false, stream: undefined});
    const { micEnable, inputID, outputID } = micState;
    const setMicEnable = payload => micDispatch({type: "SET_ENABLE", payload});
    const setMicDisable = payload => micDispatch({type: "SET_DISABLE"}, payload);
    const setInputID = payload => micDispatch({type: "SET_INPUT", payload});
    const setOutputID = payload => micDispatch({type: "SET_OUTPUT", payload});
    
    const getMicStream = () => navigator.mediaDevices.getUserMedia({
        video : false,
        audio : inputDevice.current ? { deviceId : inputDevice.current } : true,
    })
    const inputDevice = useRef(null);
    const inputStream = useRef(null);

    
    useEffect(() => {
        if (socket.id) database.ref(`users/${socket.id}`).update({isMicEnable: micEnable})
    }, [micEnable])

    return (
        <MicContext.Provider value={{
            micEnable, setMicEnable, setMicDisable, inputID, outputID, setInputID, setOutputID, inputDevice, inputStream, getMicStream
        }}>
            {children}
        </MicContext.Provider>
    )
}