import { Howl } from 'howler';
import RecordRTC, { MediaStreamRecorder } from 'recordrtc';
import socketIOClient from 'socket.io-client';
import { delayTime, inputStream} from '../components/constant';
import { database } from '../databases/firebase';
import { playRhythm, setRoomActive } from './playingFunction';
import { webmToWav } from './audioFunctions';
import FileType from 'file-type/browser';

const socket = socketIOClient("https://thai-music-band-socket-server1.herokuapp.com/", { transports : ['websocket'], autoConnect: false });
//const socket = socketIOClient("http://localhost:9000", { transports : ['websocket'], autoConnect: false });

let recorderNum;
let recordedSound = [];
let sound = {};
let finishUser = 0;
let usedTime = 0;

socket.on('receiveLargeBuffer', (id, buf) => {
    sound[id] = sound[id] ? [...sound[id], buf] : [buf];
})

socket.on('receiveLargeBufferFinish', () => {
    finishUser++;
})

socket.on('onPlaying', async (line, speed, rate, naTab) => {
    try {
        const round1 = await runRound1(line, speed, rate, naTab);
        const mergedSound = await waitRecordedSound(round1);
        await runRound2(line, speed, rate, naTab, mergedSound);
    }
    catch (e) {
        alert(e);
    }
    finally {
        await endRecording();
    }
})

const runRound1 = (line, speed, rate, naTab) => {
    let room = 0;
    let recorder;
    const btnMic = document.getElementById("btn-mic");
    return new Promise(resolve => {
        const run = () => {
            room++;
            setRoomActive(room, room <= 8 ? 0 : 1, speed)
            playRhythm(room, line, rate, speed, naTab, 1);
            if (room === 7) btnMic.disabled = true;
            if (room === 8 && inputStream && inputStream.active) {
                try {
                    recorder = new RecordRTC(inputStream, recorderOptions);
                    recorder.startRecording();
                    socket.emit('addRecorderNum');
                }
                catch (e) { console.log(inputStream) }
            }
            else if (room === (line + 1) * 8) {
                database.ref('recorderNum').get().then(v => recorderNum = v.val());
            }
            else if (room >= (line + 1) * 8 + 2 ) {
                try {
                    usedTime -= Date.now();
                    if (recorder && inputStream.active) recorder.stopRecording(async () => {
                        //array buffer from 
                        // - RecordRTC: 88990
                        // - Crunker (mp3): 3444524
                        const blob = recorder.getBlob();
                        const audioType = await FileType.fromBlob(blob);
                        const mp3Mode = await database.ref('settings').child('mp3Mode').get().then(v => v.val());
                        if (audioType.ext === 'webm' && mp3Mode) {
                            const wavBlob = await webmToWav(blob);
                            await sendSliceSound(wavBlob, 1);
                        }
                        else {
                            await sendSliceSound(blob, 1);
                        }                        
                    })
                }
                catch (e) { console.log(e) }
                finally {
                    btnMic.disabled = false;
                    resolve(true);
                    clearInterval(interval);
                }
            }
        }
        const interval = setInterval(run, speed);
    })
}

const waitRecordedSound = (bool) => {
    return new Promise((resolve, reject) => {
        if (!bool) reject('เกิดข้อผิดพลาด');
        let t = 0;
        const sit = setInterval(() => {
            t += 1000;
            if (finishUser !== 0 && finishUser === recorderNum) {
                clearInterval(sit);
                Object.values(sound).forEach(b => {
                    const blob = new Blob(b, {type: 'audio/mpeg'});
                    const url = URL.createObjectURL(blob);
                    recordedSound = [...recordedSound, new Howl({
                        src: [url], 
                        format: ['mp3'], 
                        html5: true ,
                        onplayerror: (soundId, errorMessage) => socket.emit('sendServerMessage', 'PLAY ERROR: ' + errorMessage),
                        onloaderror: (soundId, errorMessage) => socket.emit('sendServerMessage', 'LOAD ERROR: ' + errorMessage)
                    })]
                })
                usedTime += Date.now();
                console.log(`เวลาที่ใช้ระหว่างรอบ = ${usedTime/1000}`);
                resolve(recordedSound);
            }
            if (t >= 10000 && recorderNum === 0) {
                clearInterval(sit);
                reject('เกิดข้อผิดพลาด: ไม่มีคนเปิดไมค์');
            }
            if (t >= 90000) {
                clearInterval(sit);
                reject('เกิดข้อผิดพลาด: ไม่สามารถดึงไฟล์เสียงมาเล่นได้');
            }
        }, 1000);
    })
}

const runRound2 = (line, speed, rate, naTab, mergedSound) => {
    let room = 4;
    let recorder;
    const btnMic = document.getElementById("btn-mic");
    return new Promise(resolve => {
        const run = () => {
            room++;
            setRoomActive(room, room <= 8 ? 0 : 2, speed)
            setTimeout(() => playRhythm(room, line, rate, speed, naTab, 2), delayTime);
            if (room === 7) btnMic.disabled = true;
            if (room === 8 && inputStream && inputStream.active) {
                try {
                    btnMic.disabled = true;
                    mergedSound.forEach(s => s.play());
                    recorder = new RecordRTC(inputStream, recorderOptions);
                    recorder.startRecording();
                    socket.emit('addRecorderNum2');
                }
                catch (e) { console.log(e) }
            }
            else if (room >= (line + 1) * 8 + 4 ) {
                try {
                    if (recorder && inputStream.active) recorder.stopRecording(async () => {
                        const blob = recorder.getBlob();
                        const audioType = await FileType.fromBlob(blob);
                        const mp3Mode = await database.ref('settings').child('mp3Mode').get().then(v => v.val());
                        if (audioType.ext === 'webm' && mp3Mode) {
                            const wavBlob = await webmToWav(blob);
                            await sendSliceSound(wavBlob, 2);
                        }
                        else {
                            await sendSliceSound(blob, 2);
                        }
                    })
                }
                catch (e) { console.log(e) }
                finally {
                    btnMic.disabled = false;
                    resolve();
                    clearInterval(interval);
                }
            }
        }
        const interval = setInterval(run, speed);
    })
}

const sendSliceSound = async (blob, round = 1) => {
    //385802 B/s
    const largeBuffer = await blob.arrayBuffer();
    const eventName = round === 2 ? 'sendLargeBuffer2' : 'sendLargeBuffer';
    let sendingIndex = 0;
    const sendingLength = 50000;
    console.log(largeBuffer);
    const sendingInterval = setInterval(() => {
        if (largeBuffer.slice(sendingIndex).byteLength > sendingLength){
            socket.emit(eventName, largeBuffer.slice(sendingIndex, sendingIndex + sendingLength));
            sendingIndex += sendingLength;
        }
        else {
            socket.emit(eventName, largeBuffer.slice(sendingIndex));
            clearInterval(sendingInterval);
            setTimeout(() => socket.emit(eventName + 'Finish'), 100);
        }
    }, 200);
}

const endRecording = () => {
    return new Promise((resolve) => {
        socket.emit('endPlaying');
        recordedSound = [];
        recorderNum = 0;
        sound = {};
        finishUser = 0;
        usedTime = 0;
        const btnReady = document.getElementById("btn-ready");
        if (btnReady) btnReady.disabled = true;
        setTimeout(() => {
            if (btnReady) btnReady.className = "btn btn-outline-danger btn-lg rounded-circle"; 
            btnReady.disabled = false;
            resolve();
        }, 2000);
    })
}


const recorderOptions = {
    type: 'audio',
    //recorderType: StereoAudioRecorder,
    recorderType: MediaStreamRecorder,
    audioBitsPerSecond : 44100,
    //sampleRate: 44100, 
    //bufferSize: 4096,
    disableLogs: true,
}

/*socket.on('onPlaying', async (line, speed, rate, naTab) => {
    let room = 0;
    let round = 1;
    let recordSection = 0;
    let playSection = 0;
    const lastPlayingRoom = (line + 1) * 8;
    const startPlayingRoom = 8;

    let superChunks = [];
    
    const p = new Promise(resolve => {
        const run = () => {
            room++;
            setRoomActive(room, room < 8 ? 0 : round, speed)
            playRhythm(room, line, rate, speed, naTab, round);

            if (round === 1) {
                if (room >= lastPlayingRoom) {
                    room = startPlayingRoom;
                    round = 2;
                    playSection++;
                    setTimeout(() => {
                        if (sound[playSection]) Object.values(sound[playSection]).forEach(s => s.play());
                    }, speed);
                    try {
                        if (inputStream) {
                            let superRecorder = new MediaRecorder(inputStream , {audioBitsPerSecond : 88200,});
                            superRecorder.ondataavailable = (e) => superChunks.push(e.data);
                            superRecorder.onstop = () => socket.emit('sendSuperBlob', superChunks); 
                            setTimeout(() => superRecorder.start(), speed/4);
                            
                            socket.emit('addRecorderNum');
                            //database.ref('recorderNum').transaction(n => n + 1);
                            console.log('start super rec');
                            setTimeout(() => {
                                if (superRecorder && superRecorder.state !== 'inactive') superRecorder.stop();
                            }, speed * (line + 0.75) * 8);
                        }
                    }
                    catch (e) {
                        console.log(e.message);
                    }
                    
                }
                else if (room % 4 === 1 && room >= startPlayingRoom) {
                    let startTime = Date.now();
                    let recorder = inputStream ? new MediaRecorder(inputStream , {audioBitsPerSecond : 88200,}) : undefined;
                    let chunks = [];
                    try {
                        if (recorder) {
                            recorder.start();
                            recorder.ondataavailable = (e) => chunks.push(e.data);
                            recorder.onstop = () => socket.emit('sendBlob', recordSection, chunks); 
                        }
                        setTimeout(() => {
                            recordSection++;
                            if (inputStream && recorder && recorder.state !== 'inactive') recorder.stop();
                        } , speed * 4);
                    }
                    catch (e) {
                        console.log(e.message)
                    }
                }
            }
            else if (round === 2) {                
                if (room > lastPlayingRoom + 4) {
                    clearInterval(interval);
                    resolve();
                }
                else if (room % 4 === 0) {
                    playSection++;
                    setTimeout(() => {
                        if (sound[playSection]) Object.values(sound[playSection]).forEach(s => s.play());
                    }, speed); //130
                    
                }
            }
        }
        const interval = setInterval(run, speed);
    })

    p.then(() => {
        socket.emit('endPlaying');
        sound = {};
    })
    .then(() => {
        const btnReady = document.getElementById("btn-ready");
        if (btnReady) btnReady.disabled = true;
        setTimeout(() => {
            if (btnReady) btnReady.className = "btn btn-outline-danger btn-lg rounded-circle"; 
            btnReady.disabled = false;
        }, 2000);
    })
    .catch(e => console.log(e.message));
})*/


export default socket;


