import { useContext } from "react";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { FOCUS, NoteContext, searchString } from "./NoteContext";




const NoteMobileKeyboard = () => {

    const { setFocus, isPlaying, noteRef, focusRoom, setFocusRoom, mLineNum, addNextNote } = useContext(NoteContext);

    const onMobileKeyClick = e => {
        const k = e.target.innerHTML;
        const val = noteRef.current[focusRoom];

        if (k === '---') {
            const charCount = val.value.length -  searchString(val.value, 'ํ');
            if (charCount >= 4) {
                if (focusRoom < mLineNum * 8 - 1) {
                    noteRef.current[focusRoom + 1].value = noteRef.current[focusRoom + 1].value + k
                    setFocus(FOCUS.RIGHT);
                }
                else {
                    setFocusRoom(0); 
                }
            }
            else if (val.value.length > 1) {
                noteRef.current[focusRoom].value = '----'
                if (focusRoom < mLineNum * 8 - 1) setFocus(FOCUS.RIGHT);
            }
            else {
                noteRef.current[focusRoom].value = noteRef.current[focusRoom].value + k
            }
        }
        else if (k === '----') {
            if (val.value === '') {
                noteRef.current[focusRoom].value = '----';
            }
            else if (focusRoom < mLineNum * 8 - 1) {
                noteRef.current[focusRoom + 1].value = '----';
                setFocus(FOCUS.RIGHT);
            }
        }
        else {
            addNextNote(focusRoom, k, mLineNum);
        }
        /*else if (focusRoom !== null && val) {
            const charCount = val.value.length -  searchString(val.value, 'ํ');
            if (charCount >= 4) {
                if (focusRoom < mLineNum * 8 - 1) {
                    noteRef.current[focusRoom + 1].value = noteRef.current[focusRoom + 1].value + k
                    setFocus(FOCUS.RIGHT);
                }
                else {
                    setFocusRoom(0); 
                }
            }
            else {
                noteRef.current[focusRoom].value = noteRef.current[focusRoom].value + k
            }
        }*/
    }

    const onMobileDelete = () => {
        const val = noteRef.current[focusRoom];
        if (val.value) {
            val.value = val.value.slice(0, val.value.length - 1);
        }
        else if (focusRoom !== 0) {
            noteRef.current[focusRoom - 1].value = '';
            setFocus(FOCUS.LEFT);

        }
    }

    return (
        <div className="container footer" style={{maxWidth : '1000px'}}>
            <div className="text-center">
                <div>เฉพาะอุปกรณ์สัมผัส</div>
                <ButtonGroup className="mb-3" style={{width: '100%'}}>
                    <Button style={mainKey} disabled={isPlaying} onClick={onMobileKeyClick}>-</Button>
                    <Button style={mainKey} disabled={isPlaying} onClick={onMobileKeyClick}>---</Button>
                    <Button style={subKey} disabled={isPlaying} onClick={onMobileKeyClick}>ลํ</Button>
                    <Button style={subKey} disabled={isPlaying} onClick={onMobileKeyClick}>ซํ</Button>
                    <Button style={subKey} disabled={isPlaying} onClick={onMobileKeyClick}>ฟํ</Button>
                    <Button style={subKey} disabled={isPlaying} onClick={onMobileKeyClick}>มํ</Button>
                    <Button style={subKey} disabled={isPlaying} onClick={onMobileKeyClick}>รํ</Button>
                    <Button style={mainKey} disabled={isPlaying} onClick={onMobileKeyClick}>/</Button>
                </ButtonGroup>

                <ButtonGroup  className="mb-3" style={{width: '100%'}}>
                    <Button style={subKey} disabled={isPlaying} onClick={onMobileKeyClick}>ด</Button>
                    <Button style={subKey} disabled={isPlaying} onClick={onMobileKeyClick}>ร</Button>
                    <Button style={subKey} disabled={isPlaying} onClick={onMobileKeyClick}>ม</Button>
                    <Button style={subKey} disabled={isPlaying} onClick={onMobileKeyClick}>ฟ</Button>
                    <Button style={subKey} disabled={isPlaying} onClick={onMobileKeyClick}>ซ</Button>
                    <Button style={subKey} disabled={isPlaying} onClick={onMobileKeyClick}>ล</Button>
                    <Button style={subKey} disabled={isPlaying} onClick={onMobileKeyClick}>ท</Button>
                    <Button style={subKey} disabled={isPlaying} onClick={onMobileKeyClick}>ดํ</Button>
                    
                </ButtonGroup>
                <div className="d-sm-none">
                    <Button style={mainKey} disabled={isPlaying}  onClick={onMobileKeyClick}>----</Button>
                    <Button style={subKey} disabled={isPlaying} onClick={() => setFocus(FOCUS.LEFT)} variant="light">{'<'}</Button>
                    <Button style={subKey} disabled={isPlaying} onClick={() => setFocus(FOCUS.RIGHT)} variant="light">{'>'}</Button>
                    <Button style={subKey} disabled={isPlaying} onClick={() => setFocus(FOCUS.UP)} variant="light">{'^'}</Button>
                    <Button style={subKey} disabled={isPlaying} onClick={() => setFocus(FOCUS.DOWN)} variant="light">{'v'}</Button>
                    <Button style={{borderColor: 'darkgrey', background: '#ff4d4f', width: window.screen.width > 600 ? window.screen.width / 12 : 'auto', marginLeft: '30px'}} disabled={isPlaying} onClick={onMobileDelete}>ลบ</Button>
                </div>
                <Row bsPrefix="row d-none d-sm-flex align-items-center" >
                    <Col>
                        <Button style={{...mainKey, width : '80%'}} disabled={isPlaying} onClick={onMobileKeyClick}>----</Button>
                    </Col>
                    <Col>
                        <Row >
                            <Col style={{paddingInline : '0px'}}/>
                            <Col style={{paddingInline : '0px'}}><Button style={arrowKey} disabled={isPlaying} onClick={() => setFocus(FOCUS.UP)} variant="light">{'^'}</Button></Col>
                            <Col style={{paddingInline : '0px'}}/>
                        </Row>
                        <Row>
                            <Col style={{paddingInline : '0px'}}><Button style={arrowKey} disabled={isPlaying} onClick={() => setFocus(FOCUS.LEFT)} variant="light">{'<'}</Button></Col>
                            <Col style={{paddingInline : '0px'}}><Button style={arrowKey} disabled={isPlaying} onClick={() => setFocus(FOCUS.DOWN)} variant="light">{'v'}</Button></Col>
                            <Col style={{paddingInline : '0px'}}><Button style={arrowKey} disabled={isPlaying} onClick={() => setFocus(FOCUS.RIGHT)} variant="light">{'>'}</Button></Col>
                        </Row>
                    </Col>
                    <Col>
                    <Button style={{borderColor: 'darkgrey', background: '#ff4d4f', width: window.screen.width > 600 ? window.screen.width / 12 : 'auto', marginLeft: '30px'}} disabled={isPlaying} onClick={onMobileDelete}>ลบ</Button>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

const mainKey = {
    borderColor: 'darkgrey', 
    background: '#d9d9d9', 
    color: 'black',
    //width: window.screen.width > 600 ? window.screen.width / 12 : 'auto'
}

const subKey = {
    borderColor: 'darkgrey',
    background: '#fafafa',
    color: 'black',
    //width: window.screen.width > 600 ? window.screen.width / 12 : 'auto'
}

const arrowKey = {
    borderColor: 'darkgrey',
    background: '#fafafa',
    color: 'black',
    width : '100%',
    paddingInline : '0px'
}

export default NoteMobileKeyboard;