import React, { useContext, useEffect, useState } from "react";
import { database } from "../../../databases/firebase";
import { SettingsContext } from "../../../providers/SettingsProvider";
import { UnitContext } from "../../../providers/UnitProvider";
import Moving from "../../layout/Moving";
import NoteLayout from "../../layout/NoteLayout";
import Panel from "../../layout/Panel";
import RoundContainer from "../../layout/RoundContainer";
import SoundPanel from "../../layout/SoundPanel";

const TabletMainRoom = () => {

    const [title, setTitle] = useState("");
    const {speed, rate} = useContext(SettingsContext);
    const { getValueAndUnit } = useContext(UnitContext);

    useEffect(() => {
        getAndSetTitle();
        return () => setTitle("");
    }, []);

    const getAndSetTitle = () => {
        database.ref('databaseSystem').child('title').on('value', child => {
            setTitle(child.val());
        })
    }

    return(
        <div>
            <div style={{/*height: window.screen.height * 0.62, background: 'black', marginBottom: '-100px'*/}}>
            <RoundContainer>
                <div className="row">
                    <div className="col-8">
                        <h3>{title || ""}</h3>
                    </div>
                    <div className="col-4">
                        <div style={{textAlign: "right"}}>
                            <div>{rate !== 1 ? ` อัตราจังหวะ ${rate} ชั้น ` : 'อัตราจังหวะชั้นเดียว'}</div>
                            <div>{getValueAndUnit(speed)}</div>
                        </div>
                    </div>
                </div>
                <hr />
                <NoteLayout h={window.screen.height * 0.42}/>
                <div className="row" style={{alignContent: 'end', marginTop: "-50px"}}>
                    <div className="col-10" />
                    <div className="col" style={{marginTop: "-50px"}}><Panel vertical={true} /></div>
                </div>
            </RoundContainer>
            </div>
            <br />
            <RoundContainer>
                <div className="row text-center" style={{alignItems: 'center'}}>
                    <div className="col-6"><Moving /></div>
                    <div className="col-6"><SoundPanel /></div>
                </div>
            </RoundContainer>
        </div>
    )
}

export default TabletMainRoom;