//import { useContext } from "react";
import { Button } from "react-bootstrap";
//import { PlayingContext } from "../../../providers/PlayingProvider";
import { ICON_HEADER_USER, ICON_PP_CHAT, ICON_PP_SOUND } from "../../icons";
import Chat from "../../layout/Chat";
import List from "../../layout/List";
//import MicButton from "../../layout/MicButton";
import RoundContainer from "../../layout/RoundContainer";
import SoundPanel from "../../layout/SoundPanel";

const [DISPLAY_FRIEND, DISPLAY_CHAT, DISPLAY_SOUND] = [...Array(3).keys()];

const ListChat = props => {
    const { mDisplaySiteBar, setMDisplaySiteBar } = props;
    //const { isReady, setReady, isPlaying } = useContext(PlayingContext);

    return (
        <>
            <RoundContainer>
                <Button onClick={() => setMDisplaySiteBar(DISPLAY_FRIEND)} variant={mDisplaySiteBar === DISPLAY_FRIEND ? 'round-info' : 'round-light'}>{ICON_HEADER_USER}</Button>
                <Button onClick={() => setMDisplaySiteBar(DISPLAY_CHAT)} variant={mDisplaySiteBar === DISPLAY_CHAT ? 'round-info' : 'round-light'}>{ICON_PP_CHAT}</Button>
                <Button onClick={() => setMDisplaySiteBar(DISPLAY_SOUND)} variant={mDisplaySiteBar === DISPLAY_SOUND ? 'round-info' : 'round-light'}>{ICON_PP_SOUND}</Button>

                <hr />
                <div hidden={mDisplaySiteBar !== DISPLAY_FRIEND}>
                    <List h={window.screen.height * 0.5}/>
                </div>
                <div hidden={mDisplaySiteBar !== DISPLAY_CHAT}>
                    <Chat h={window.screen.height * 0.5}/>
                </div>
                <div hidden={mDisplaySiteBar !== DISPLAY_SOUND}>
                    <SoundPanel />
                </div>
            </RoundContainer>
        </>
    )
}

export default ListChat;