import React, { createContext, useEffect, useReducer } from "react";
import { auth } from "../databases/firebase";
import { getGlobalSheet, getUserAllSheets, getUserSharedSheet } from "../databases/software/getData";
import { getUserNameByID } from "../databases/users/getUserName";

export const SheetsContext = createContext({});

const initialState = {
    userSheets : {},
    sharedSheets : {},
    globalSheets : {},
}

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_USER_SHEET":
            return {...state, userSheets : action.payload}
        case "SET_SHARE_SHEET":
            return {...state, sharedSheets : action.payload}
        case "SET_GLOBAL_SHEET":
            return {...state, globalSheets : action.payload}
        default:
            break;
    }
}

export const SheetsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { userSheets, sharedSheets, globalSheets } = state;
    const setUserSheets = payload => dispatch({ type: 'SET_USER_SHEET', payload });
    const setSharedSheets = payload => dispatch({ type: 'SET_SHARE_SHEET', payload });
    const setGlobalSheets = payload => dispatch({ type: 'SET_GLOBAL_SHEET', payload });

    const queryUserSheets = async () => {
        const res = await getUserAllSheets();
        dispatch({ type: 'SET_USER_SHEET', payload : res });
    }

    const querySharedSheets = async () => {
        const res = await getUserSharedSheet();
        let buffer = {...res}
        for (const id in res) {
            buffer[id].userName = await getUserNameByID(res[id].user)
        }
        dispatch({ type: 'SET_SHARE_SHEET', payload : buffer });
    }

    const queryGlobalSheets = async () => {
        const res = await getGlobalSheet();
        let buffer = {...res}
        for (const id in res) {
            buffer[id].userName = await getUserNameByID(res[id].user)
        }
        dispatch({ type: 'SET_GLOBAL_SHEET', payload : buffer });
    }


    useEffect(() => {
        auth.onAuthStateChanged(async () => {
            await queryUserSheets();
            await querySharedSheets();
            await queryGlobalSheets();
        })
        return () => {
            setUserSheets({});
            setSharedSheets({});
        }
    }, []);

    return (
        <SheetsContext.Provider value={{ 
            userSheets, sharedSheets, globalSheets, 
            setUserSheets, setSharedSheets, setGlobalSheets, 
            queryUserSheets, queryGlobalSheets, querySharedSheets, 
        }}>
            {children}
        </SheetsContext.Provider>
    )
}