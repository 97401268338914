import React, { createContext, useCallback, useContext, useEffect, useReducer, useRef } from "react";
import { FREQ } from "../components/SoundSoftware/const";
import { SheetContext } from "./SheetContext";


export const SheetPlayingContext = createContext({});

const initialState = {
    freq : FREQ.DEFAULT,
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_FREQ' :
            return {...state, freq : action.payload}
        default:
            break;
    }
}

export const SheetPlayingProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { freq } = state;
    const setFreq = useCallback(payload => dispatch({ type : 'SET_FREQ', payload }), []) 

    const { gIsPlaying } = useContext(SheetContext);
    
    const pSound = useRef(null);
    const pFrequency = useRef(FREQ.DEFAULT);
    const pIsPlaying = useRef(false);
    const pContext = useRef({});
    const pRhythm = useRef({
        ching : {
            isSame : true,
            chan : 0,
            custom : {},
        },
        tone : {
            isSame : true,
            natab : 'NO',
            custom : {},
        }
    })

    useEffect(() => {
        return () => {
            pIsPlaying.current = false;
            pContext.current = {};
            pFrequency.current = FREQ.DEFAULT;
            pSound.current = null;
            pRhythm.current = {
                ching : {
                    isSame : true,
                    chan : 0,
                    custom : {},
                },
                tone : {
                    isSame : true,
                    natab : 'NO',
                    custom : {},
                }
            }
        }
    }, [])

    useEffect(() => {
        pFrequency.current = freq;
    }, [freq])

    useEffect(() => {
        pIsPlaying.current = gIsPlaying;
    }, [gIsPlaying])

    return (
        <SheetPlayingContext.Provider value={{
            freq, setFreq,
            pSound, pFrequency, pIsPlaying, pContext, pRhythm,
        }}>
            {children}
        </SheetPlayingContext.Provider>
    )
}