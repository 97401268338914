import { useContext, useMemo } from "react";
import { Button, DropdownButton, Dropdown, Row, Col } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { onSaveCallback } from "../../databases/software/addData";
import { SheetContext } from "../../providers/SheetContext";
import { SheetPlayingContext } from "../../providers/SheetPlayingContext";
import { SoundsContext } from "../../providers/SoundsContext";
import { ICON_BARS, ICON_DD_ADDTON, ICON_DD_COMMENT, ICON_DD_EDITTON, ICON_DD_KEYBOARD, ICON_DD_SAVE, ICON_DD_SHARE, ICON_EDIT_NOTE, ICON_EDIT_TON, ICON_PLAY_NOTE, ICON_STOP_NOTE } from "../icons";
import SuperHeader from "../layout/SuperHeader";
import OnNotePlay from "./actions/OnNotePlay";
import { onKeyChange, onNoteDelete } from "./actions/SelectFunction";
import { CAN_EDIT, FOCUS_NONE, FOCUS_NOTE, NO_RIGHT, TANG_KHLUI, TANG_KONG } from "./const";




const RenderHeader = props => {

    const { gNoteData, tData, setTData, orderItem, setModalData, setOrderItem } = props;
    const { gSheetType } = gNoteData;
    const sheetContext = useContext(SheetContext);
    const soundsContext = useContext(SoundsContext);
    const sheetPlayingContext = useContext(SheetPlayingContext);
    const { setShowRhythmModal, setShowFluteModal, setShowKongModal } = soundsContext;
    const { gRight, setFType, setFId, gIsPlaying, setPlaying, gFocusRoom, onNoteCopy, onNotePaste, sRoom, gIsSelectPress, setSelectPress, gIsShowComment, setShowComment, gIsShowKeyboard, setShowKeyboard } = sheetContext;
    const { pRhythm, pFrequency, pIsPlaying } = sheetPlayingContext;
    const { fId, fType} = gFocusRoom;

    const [searchparams] = useSearchParams();
    const sheetID = searchparams.get('id');

    const onShareClick = async () => {
        if (sheetID) {
            const url = `www.พร้อมเพลง.com/ss?id=${sheetID}`
            await navigator.clipboard.writeText(url)
            alert(`คัดลอก "${url}" แล้ว ท่านสามารถส่งให้เพื่อนในแพลตฟอร์มไหนก็ได้`)
        }
    }

    const onNoteCut = () => {
        onNoteCopy(tData[fId].tNote)
        onNoteDelete({ fId, setTData, sRoom })
    }

    const DropdownTextItem = props => {
        const { onClick, title, shortCut, active, disabled, icon } = props;
        return (
            <Dropdown.Item className="edit-note" as='button' onClick={onClick} active={active || false} disabled={disabled || false} >
                <Row bsPrefix="row align-items-center">
                    <Col style={{color : !disabled ? 'black' : ''}}>
                        {icon}<span>{title}</span>
                    </Col>
                    <Col sm='auto' xs='auto' style={{color : '#8c8c8c'}}>{shortCut}</Col>
                </Row>
            </Dropdown.Item>
        )
    }

    const onAddTonClick = () => {
        setModalData(m => ({...m, addTonModal : {
            ...m.addTonModal, 
            show : true,
            tName : `ท่อน ${Object.keys(tData).length + 1}`,
            tTang : gSheetType || 0
        }}))
        setFType(FOCUS_NONE)
        setFId(null);
    }

    const onEditTonClick = (tId) => {
        const { tName, tTang, tLineNum } = {...tData[tId]} || {};
        setModalData(m => ({...m, editTonModal : {
            show : true,
            tId, tName, tLineNum, tTang : gSheetType || parseInt(tTang),
        }}))
    }

    const onEditOrderClick = () => {
        setModalData(m => ({...m, orderTonModal : {
            ...m.orderTonModal, 
            show : true,
            tItems : [...orderItem]
        }}))
        setFType(FOCUS_NONE)
        setFId(null);
    }

    const onDeleteTonClick = (deleteID) => {
        let o = [...orderItem]
        o.splice(o.findIndex(it => it.tId === deleteID), 1);
        setOrderItem(o)
        let d = {...tData};
        delete d[deleteID];
        setTData(d);
    }

    const onEditRhythmClick = () => setModalData(m => ({...m, chanModal : { show : true }}))
    const onEditNatabClick = () => setModalData(m => ({...m, natabModal : { show : true }}))
    const onEditRuleClick = () => setModalData(m => ({...m, addRuleModal : {...m.addRuleModal, show : true, tId : 0}}))

    const focusItem = useMemo(() => ([...orderItem].find(v => v.tId === fId)), [orderItem, fId])

    return (
        <SuperHeader title='พร้อมเล่น' isSSPage={true}>
            <DropdownButton className='d-inline' variant="round-light" title={ICON_BARS} disabled={gIsPlaying}>
                <div className="fs14">
                    <DropdownTextItem icon={ICON_DD_ADDTON} title='เพิ่มท่อน' onClick={onAddTonClick} disabled={gRight !== CAN_EDIT} />
                    
                    <Dropdown.Divider />
                    <DropdownTextItem icon={ICON_DD_EDITTON} title={`แก้ไข${focusItem ? focusItem.tName : ''}`} onClick={() => onEditTonClick(focusItem.tId)} disabled={gRight !== CAN_EDIT || !focusItem} />
                    <DropdownTextItem icon={ICON_DD_EDITTON} title={`ลบ${focusItem ? focusItem.tName : ''}`} onClick={() => onDeleteTonClick(focusItem.tId)} disabled={gRight !== CAN_EDIT || !focusItem} />
                    <Dropdown.Divider />
                    <DropdownTextItem icon={ICON_DD_SAVE} title='บันทึก' shortCut='Ctrl + s' disabled={gRight !== CAN_EDIT} onClick={() => onSaveCallback(gNoteData.gSheetID, {tData, pRhythm : pRhythm.current, pFrequency : pFrequency.current, orderItem})} />
                    <DropdownTextItem icon={ICON_DD_SHARE}  disabled={gRight === NO_RIGHT} title='แบ่งปันเป็นลิงก์' onClick={onShareClick} />
                    <Dropdown.Divider />
                    <DropdownTextItem disabled={gRight !== CAN_EDIT} icon={ICON_DD_COMMENT} title='แก้ไขคอมเมนต์' active={gIsShowComment} onClick={() => setShowComment(!gIsShowComment)} />
                    <DropdownTextItem disabled={isNaN(gNoteData.gSheetType) || fType !== FOCUS_NOTE || gRight !== CAN_EDIT} icon={ICON_DD_KEYBOARD} title='แสดงคีย์บอร์ด' active={gIsShowKeyboard} onClick={() => setShowKeyboard(!gIsShowKeyboard)} />
                </div>
            </DropdownButton>

            <DropdownButton className='d-inline' variant="round-light" title={ICON_EDIT_NOTE} disabled={gIsPlaying || gRight !== CAN_EDIT}>
                <div className="fs14">
                    <DropdownTextItem title='เลือกห้อง' shortCut='Shift+ลูกศร' active={gIsSelectPress} onClick={() => setSelectPress(!gIsSelectPress)} />
                    <Dropdown.Divider />
                    <DropdownTextItem disabled={sRoom < 1 && fType !== FOCUS_NOTE} title='ตัด' shortCut='Ctrl + x' onClick={onNoteCut} />
                    <DropdownTextItem disabled={sRoom < 1 && fType !== FOCUS_NOTE} title='คัดลอก' shortCut='Ctrl + c' onClick={() => onNoteCopy(tData[fId].tNote)} />
                    <DropdownTextItem disabled={sRoom < 1 && fType !== FOCUS_NOTE} title='วาง' shortCut='Ctrl + v' onClick={() => onNotePaste(tData[fId].tNote, tData[fId].tLineNum, v => setTData(d => ({...d, [fId] : {...d[fId], tNote : v }})))} />
                    <DropdownTextItem disabled={sRoom < 1 && fType !== FOCUS_NOTE} title='ลบ' shortCut='Delete' onClick={() => onNoteDelete({ fId, setTData, sRoom })} />
                    <Dropdown.Divider />
                    <DropdownTextItem disabled={sRoom < 1 && fType !== FOCUS_NOTE} title='คีย์ขึ้น' shortCut='Ctrl+Shift+Up' onClick={() => onKeyChange({ fId, tData, setTData, sRoom, isKeyUp : true })} />
                    <DropdownTextItem disabled={sRoom < 1 && fType !== FOCUS_NOTE} title='คีย์ลง' shortCut='Ctrl+Shift+Down' onClick={() => onKeyChange({ fId, tData, setTData, sRoom, isKeyUp : false })} />
                    <Dropdown.Divider />
                    <DropdownTextItem disabled={sRoom < 1 && fType !== FOCUS_NOTE} title='กำหนดความเร็ว' onClick={() => setModalData(g => ({...g, fixedSpeedModal : {...g.fixedSpeedModal, show : true}}))} />
                </div>
                
            </DropdownButton>

            <DropdownButton className='d-inline' variant="round-light" title={ICON_EDIT_TON}>
                <div className="fs14">
                    <DropdownTextItem title='ระดับเสียงขลุ่ย' disabled={gSheetType !== TANG_KHLUI} onClick={() => setShowFluteModal(true)} />
                    <DropdownTextItem title='ระดับเสียงฆ้อง' disabled={gSheetType !== TANG_KONG} onClick={() => setShowKongModal(true)} />
                    <DropdownTextItem title='ระดับเสียงจังหวะ' onClick={() => setShowRhythmModal(true)} />
                    <Dropdown.Divider />
                    <DropdownTextItem title='เรียงลำดับ/จำนวนรอบ' onClick={onEditOrderClick} disabled={gRight !== CAN_EDIT}/>
                    <DropdownTextItem title='อัตราจังหวะ' onClick={onEditRhythmClick} disabled={gRight !== CAN_EDIT}/>
                    <DropdownTextItem title='หน้าทับ' onClick={onEditNatabClick} disabled={gRight !== CAN_EDIT}/>
                    <DropdownTextItem title='ข้อกำหนดพิเศษ' onClick={onEditRuleClick} disabled={gRight !== CAN_EDIT}/>
                </div>
                
            </DropdownButton>

            <Button variant='round-light' hidden={gIsPlaying} disabled={gRight === NO_RIGHT} 
                onClick={() => OnNotePlay({tData, orderItem, sheetContext, soundsContext, sheetPlayingContext})}
            >
                {ICON_PLAY_NOTE}
            </Button>
            <Button variant='round-light' hidden={!gIsPlaying} disabled={gRight === NO_RIGHT} onClick={() => {setPlaying(false); pIsPlaying.current = false}}>
                {ICON_STOP_NOTE}
            </Button>
            
        </SuperHeader>
    )
}

export default RenderHeader;