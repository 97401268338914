import React, { useState, useContext } from "react";
import socket from "../../../server/socket-io";
import { ICON_SUPPORT } from "../../icons";
import RoundContainer from "../../layout/RoundContainer";
import { UserContext } from '../../../providers/UserProvider';
import { database } from "../../../databases/firebase";




const About = () => {
    const { userName } = useContext(UserContext)
    
    const initialData = {
        type: 'ข้อเสนอแนะ',
        contact: '',
        name: userName,
        content: '',
        device: '',
        browser: '',
    }

    const [contactData, setContactData] = useState(initialData);
    
    const supporter = {
        1 : {
            name: "ชนิดา พรหมประดิษฐ์ (ใบเฟิร์น)",
            role: "ที่ปรึกษาโครงการ"
        },
        2 : {
            name: "ณัฐวัชร์ เตชะนพดล (พี่เต)",
            role: "ผู้สนับสนุนโครงการ (เสียงฉิ่ง โทนรำมะนา)",
        },
        3 : {
            name: "โกญจนาท วิบูลย์เพ็ง (พี่เมฆ)",
            role: "ผู้ทดสอบระยะแรก",
        },
        4 : {
            name: "พุฒิพงศ์ เจืออุปถัมย์ (พี่ไปร์ท)",
            role: "ผู้ทดสอบระยะแรก",
        },
        5 : {
            name: "จิรัชญา เดชเลิศประยูร (พี่เค้ก)",
            role: "ผู้ทดสอบระยะแรก",
        },
        6 : {
            name : "แก้วสกุล สุพรรณชนะบุรี (มิ้ม)",
            role: "ผู้ทดสอบระยะแรก",
        },
        7 : {
            name : "พรรษชล วินิยกูล (ฝน)",
            role: "ผู้ทดสอบระยะแรก",
        },
        8 : {
            name : "ประภา ศรีวิไลฤทธิ์",
            role: "ผู้ทดสอบระยะแรก",
        },
        9 : {
            name: "มัทนา กุศลสิทธารถ (มินนี่)",
            role: "ผู้ทดสอบระยะแรก",
        },
        10 : {
            name: "กวินภพ ทองนาค (กวิน)",
            role: "ผู้ทดสอบระยะแรก",
        },
        11 : {
            name: "ปิติ มานุชานนท์",
            role: "ผู้ทดสอบระยะแรก",
        },
        12 : {
            name: "ชินาวัตร ประฐมธานี (เบียร์)",
            role: "ผู้ทดสอบระยะแรก",
        },
        13 : {
            name: "บุญยเกียรติ สะอาด (บุญ)",
            role: "ผู้ทดสอบระยะแรก",
        },
        14 : {
            name: "ศิรนภา ชัยประภา (ฟ้า)",
            role: "ผู้ทดสอบระยะแรก",
        },
    }

    const onSendClick = e => {
        e.preventDefault();
        if (contactData.content) {
            database.ref(`userContact/${contactData.name}-${Date.now()}`).set(contactData)
            .then(() => alert('เรียบร้อย')).catch(e => alert(`ไม่สามารถส่งข้อมูลได้: ${e.message}`))
        }
        else {
            alert('โปรดใส่ข้อความก่อน')
        }
    }




    return(
        <div>
            <RoundContainer>
                <h3 style={{fontWeight: "bold"}}>เกี่ยวกับ</h3>
            </RoundContainer>

            <br />

            <RoundContainer>
                <h5 className="containerHeader">{ICON_SUPPORT} ผู้สนับสนุน {ICON_SUPPORT}</h5>
                ขอขอบพระคุณผู้สนับสนุนทุกท่าน
                <div className="list-group pt-3">
                    {(supporter) ? Object.values(supporter).map((data, i) => (
                        <li key={i} className="list-group-item">
                            <div className="row">
                                <div className="col-1">
                                    {i + 1}.
                                </div>
                                <div className="col-5">
                                    {data.name}
                                </div>
                                <div className="col-6">
                                    {data.role}
                                </div>
                            </div>
                        </li>
                    )) : <div/>}
                </div>
            </RoundContainer>

            <br />

            <RoundContainer>
                <h5 className="containerHeader">ผู้จัดทํา</h5>
                นาย ศิรสิทธิ์ ศรีใส (อื๋อ)
                <br />ติดต่องานได้ทาง Facebook: ศิรสิทธิ์ ศรีใส หรือ Chanida Noey Prompradit
                <br /><br />
                สวัสดีครับ เนื่องจากช่วงนี้มีการ lockdown หรือห้ามการรวมกลุ่มกัน ทางผู้จัดทําจึงทดลองสร้างเว็บพร้อมเพลงขึ้นมาเพื่อให้พวกเราได้เล่นดนตรีด้วยกัน :D
            </RoundContainer>

            <br />

            <RoundContainer>
                <h5 className="containerHeader">มีอะไรอยากบอกทีมงานไหม ?</h5>
                <form>
                    <div className="row">
                        <div className="col-1">ประเภท</div>
                        <div className="col-4">
                            <select className="form-control" style={{marginTop: "-5px", marginLeft: "20px"}} onChange={e => setContactData({...contactData, type: e.target.value})}>
                                <option defaultChecked>ข้อเสนอแนะ</option>
                                <option>ปัญหา/บัคที่เจอ</option>
                                <option>ติชมทีมงาน-เว็บ</option>
                                <option>ช่วยสนับสนุน</option>
                            </select>
                        </div>
                        <div className="col-1" style={{marginLeft: "20px"}}>ติดต่อ</div>
                        <div className="col" style={{marginLeft: "30px"}}>
                            <input type="text" className="form-control" style={{marginTop: "-5px"}} onChange={e => setContactData({...contactData, contact: e.target.value})}/>
                        </div>
                    </div>
                    <div className="row" style={{paddingBlock: "10px"}} hidden={contactData.type !== "ปัญหา/บัคที่เจอ"}>
                        <div className="col-1">อุปกรณ์ </div>
                        <div className="col-4">
                            <input type="text" className="form-control" placeholder="เช่น iPad Pro gen99" style={{marginTop: "-5px", marginLeft: '20px'}} onChange={e => setContactData({...contactData, device: e.target.value})}/>
                        </div>
                        <div className="col-1" style={{marginLeft: "20px"}}>บราวเซอร์</div>
                        <div className="col" style={{marginLeft: "30px"}}>
                            <input type="text" className="form-control" placeholder="เช่น Safari" style={{marginTop: "-5px"}} onChange={e => setContactData({...contactData, browser: e.target.value})}/>
                        </div>
                    </div>
                    
                    
                    <div className="form-group pt-2">
                        <label>ข้อความ</label>
                        <textarea rows="5" type="text" className="form-control" onChange={e => setContactData({...contactData, content: e.target.value})}/>
                    </div>
                    <div className="text-center">
                        <br />
                        <button type="button" className="btn btn-primary" onClick={onSendClick}>ส่ง</button>
                    </div>
                </form>
            </RoundContainer>

            <br />

            <RoundContainer>
            <div className="text-center">
                <button className="btn btn-danger" onClick={() => socket.emit('resetServer')}>Reset Server</button> *กดในกรณีที่จำเป็นเท่านั้น!!
            </div>
            </RoundContainer>
            
            
            
        </div>
    )
}

export default About;