import { Howl } from "howler";
import socket from '../server/socket-io';

export const userInitialState = {
    firstName : '',
    lastName : '',
    userName : '',
    birthYear : '',
    location : '',
    position : 0,
    email : '',
}

export const positionOptions = {
    0 : '-',
    1 : 'นักเรียน',
    2 : 'นิสิต นักศึกษาทั่วไป',
    3 : 'นิสิต นักศึกษาที่กำลังศึกษาหรือฝึกสอนในสาขาดนตรี',
    4 : 'ครู อาจารย์ บุคลากรในสาขาดนตรี',
    5 : 'นักดนตรีอาชีพ',
    6 : 'ผู้สนใจทั่วไป',
    7 : 'ผู้ร่วมทดสอบระบบ',
}

let inputDevice;
const setInputDevice = (dev) => {
    inputDevice = dev;
}

let inputStream;
const setInputStream = (st) => {
    inputStream = st;
}

const chab = new Howl({
    src: ['chab.wav'],
    volume: 0.25,
    onplayerror: (soundId, errorMessage) => socket.emit('sendServerMessage', 'PLAY ERROR: ' + errorMessage),
    onloaderror: (soundId, errorMessage) => socket.emit('sendServerMessage', 'LOAD ERROR: ' + errorMessage)
});

const ching = new Howl({
    src: ['ching.wav'],
    volume: 0.25,
});

const C5 = new Howl({
    src: ['C5.wav'],
    volume: 0.5,
})

let chingVolume = 0.25;
const setChingVolume = (v) => {
    chingVolume = v;
}

let effectVolume = 0.5;
const setEffectVolume = (v) => {
    effectVolume = v;
}



const Tumm = new Howl({
    src: ['Tumm.wav'],
    volume: 0.5,
})

const Ting = new Howl({
    src: ['Ting.wav'],
    volume: 0.5,
})

const Choo = new Howl({
    src: ['Choo.wav'],
    volume: 0.5,
})

const Chaa = new Howl({
    src: ['Chaa.wav'],
    volume: 0.5,
})

let playingRhythm = 2;
const setPlayingRhythm = (v) => {
    playingRhythm = v;
}

//const crunker = new Crunker({ sampleRate: 44100 * 1.0956 });

let delayTime = 0;
const setDelayTime = v => delayTime = v;

const getNaTabTime = (naTab, speed) => {
    switch (naTab) {
        case 'PK3': case 'KM3':
            return speed * 16;
        case 'PK2': case 'SM3': case 'KM2':
            return speed * 8;
        case 'PK1': case 'SM2': case 'KM1': case 'L2': case 'FR2':
            return speed * 4;
        case 'SM1':
            return speed * 2;
        default:
            return speed * 4;
    }
}

const playNaTab = async (naTab, speed) => {
    const tick = speed/4;
    //const sleep = async (i) => await new Promise(r => setTimeout(() => r(), tick * i));
    const playSound = (s, t) => setTimeout(() => s.play(), t * tick); 
    switch (naTab) {
        case 'L2':
            playSound(Ting, 2);
            playSound(Choo, 4);
            playSound(Ting, 6);
            playSound(Ting, 8);
            playSound(Ting, 11);
            playSound(Tumm, 12);
            playSound(Ting, 14);
            playSound(Tumm, 16);
            break;
        case 'PK3':
            playSound(Tumm, 2);
            playSound(Ting, 4);
            playSound(Choo, 6);
            playSound(Chaa, 8);
            playSound(Choo, 10);
            playSound(Chaa, 12);
            playSound(Choo, 14);
            playSound(Chaa, 16);
            playSound(Choo, 22);
            playSound(Chaa, 24);
            playSound(Choo, 26);
            playSound(Chaa, 28);
            playSound(Choo, 30);
            playSound(Chaa, 32);
            playSound(Ting, 34);
            playSound(Ting, 36);
            playSound(Tumm, 38);
            playSound(Tumm, 40);
            playSound(Ting, 42);
            playSound(Ting, 44);
            playSound(Choo, 46);
            playSound(Chaa, 48);
            playSound(Ting, 50);
            playSound(Tumm, 52);
            playSound(Ting, 54);
            playSound(Ting, 56);
            playSound(Tumm, 58);
            playSound(Ting, 60);
            playSound(Ting, 62);
            playSound(Tumm, 64);

            break;
        case 'PK2':
            playSound(Tumm, 2);
            playSound(Ting, 4);
            playSound(Choo, 6);
            playSound(Chaa, 8);
            playSound(Choo, 10);
            playSound(Chaa, 12);
            playSound(Choo, 14);
            playSound(Chaa, 16);
            playSound(Ting, 18);
            playSound(Tumm, 20);
            playSound(Ting, 22);
            playSound(Ting, 24);
            playSound(Tumm, 26);
            playSound(Ting, 28);
            playSound(Ting, 30);
            playSound(Tumm, 32);
            break;
        case 'PK1':
            playSound(Ting, 3);
            playSound(Tumm, 4);
            playSound(Ting, 6);
            playSound(Ting, 9);
            playSound(Tumm, 10);
            playSound(Ting, 12);
            playSound(Tumm, 14);
            playSound(Ting, 15);
            playSound(Tumm, 16);
            break;
        case 'SM3':
            playSound(Tumm, 2);
            playSound(Ting, 4);
            playSound(Choo, 6);
            playSound(Chaa, 8);
            playSound(Choo, 10);
            playSound(Chaa, 12);
            playSound(Choo, 14);
            playSound(Chaa, 16);
            playSound(Ting, 18);
            playSound(Ting, 20);
            playSound(Tumm, 22);
            playSound(Ting, 23);
            playSound(Tumm, 24);
            playSound(Ting, 26);
            playSound(Ting, 28);
            playSound(Tumm, 30);
            playSound(Ting, 31);
            playSound(Tumm, 32);
            break;
        case 'SM2':
            playSound(Ting, 1);
            playSound(Choo, 3);
            playSound(Chaa, 4);
            playSound(Ting, 5);
            playSound(Ting, 6);
            playSound(Ting, 8);
            playSound(Choo, 11);
            playSound(Chaa, 12);
            playSound(Ting, 13);
            playSound(Ting, 14);
            playSound(Tumm, 16);
            break;
        case 'SM1':
            playSound(Ting, 1);
            playSound(Choo, 3);
            playSound(Chaa, 4);
            playSound(Ting, 5);
            playSound(Ting, 6);
            playSound(Tumm, 8);
            break;
        case 'FR2':
            playSound(Ting, 2);
            playSound(Ting, 3);
            playSound(Ting, 4);
            playSound(Ting, 6);
            playSound(Tumm, 8);
            playSound(Ting, 10);
            playSound(Tumm, 12);
            playSound(Ting, 14);
            playSound(Tumm, 16);
            break;
        case 'KM3':
            playSound(Choo, 6);
            playSound(Chaa, 8);
            playSound(Choo, 10);
            playSound(Chaa, 12);
            playSound(Choo, 14);
            playSound(Chaa, 16);
            playSound(Choo, 22);
            playSound(Chaa, 24);
            playSound(Choo, 26);
            playSound(Chaa, 28);
            playSound(Ting, 30);
            playSound(Tumm, 32);
            playSound(Choo, 38);
            playSound(Chaa, 40);
            playSound(Choo, 42);
            playSound(Chaa, 44);
            playSound(Ting, 46);
            playSound(Tumm, 48);
            playSound(Choo, 50);
            playSound(Chaa, 52);
            playSound(Ting, 54);
            playSound(Tumm, 56);
            playSound(Ting, 58);
            playSound(Tumm, 60);
            playSound(Choo, 62);
            playSound(Chaa, 64);
            break;
        case 'KM2':
            playSound(Choo, 6);
            playSound(Chaa, 8);
            playSound(Choo, 10);
            playSound(Chaa, 12);
            playSound(Ting, 14);
            playSound(Tumm, 16);
            playSound(Choo, 18);
            playSound(Chaa, 20);
            playSound(Ting, 22);
            playSound(Tumm, 24);
            playSound(Ting, 26);
            playSound(Tumm, 28);
            playSound(Choo, 30);
            playSound(Chaa, 32);
            break;
        case 'KM1':
            playSound(Choo, 2);
            playSound(Chaa, 4);
            playSound(Ting, 6);
            playSound(Tumm, 8);
            playSound(Ting, 10);
            playSound(Tumm, 12);
            playSound(Choo, 14);
            playSound(Chaa, 16);
            break;
        default:
            break;
    }
}


export {inputDevice, setInputDevice, inputStream, setInputStream, playingRhythm, setPlayingRhythm,
    chingVolume, setChingVolume, ching, chab, C5, effectVolume, setEffectVolume, 
    Tumm, Ting, Choo, Chaa, playNaTab, getNaTabTime, delayTime, setDelayTime};