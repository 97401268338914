import { auth, firestore } from "../firebase"


export const getUserAllSheets = async () => {
    let data = {};
    if (auth.currentUser) {
        const snapshot = await firestore.collection('software').where('user', '==', auth.currentUser.uid).get();
        snapshot.forEach(doc => {
            if (doc.exists) {
                data = {...data, [doc.id] : doc.data()}
            }
        })
    }
    return data;
}

export const getSheetData = async (id) => {
    let data = {};
    const doc = await firestore.collection('software').doc(id).get();
    if (doc.exists) {
        const { tData } = doc.data();
        data = { ...doc.data()}
        let buffer = {};
        if (tData) for (const d in tData) {
            buffer = {...buffer, [parseInt(d)] : tData[d]}
        }
        data = {...data, tData : buffer}
    }
    return data;
}

export const getUserSharedSheet = async () => {
    /*let data = {};
    try {
        if (!auth.currentUser) throw new Error('NOT LOGIN');
        const snapshot = await firestore.collection('software').where('shareType', '==', 1).where('shareWith', 'array-contains', auth.currentUser.uid).get()
        .catch(e => {throw new Error('QUERY ERROR : ' + e)})
        if (snapshot.empty) throw new Error('NO DOCUMENT');
        snapshot.forEach(doc => {
            if (doc.exists && doc.data().user !== auth.currentUser.uid) {
                data = {...data, [doc.id] : doc.data()}
            }
        })
    }
    catch (e) {
        console.log(e)
    }
    finally {
        return data;
    }*/
    let data = {};
    if (auth.currentUser) {
        const snapshot = await firestore.collection('software').where('shareType', '==', 1).where('shareWith', 'array-contains', auth.currentUser.uid).get();
        snapshot.forEach(doc => {
            if (doc.exists && doc.data().user !== auth.currentUser.uid) {
                data = {...data, [doc.id] : doc.data()}
            }
        })
    }
    return data;
}

export const getGlobalSheet = async () => {
    let data = {};
    if (auth.currentUser) {
        const snapshot = await firestore.collection('software').where('shareType', '==', 2).get();
        snapshot.forEach(doc => {
            if (doc.exists/* && doc.data().user !== auth.currentUser.uid*/) {
                data = {...data, [doc.id] : doc.data()}
            }
        })
    }
    return data;
}