import { Form } from "react-bootstrap"

export const TextForm = props => {
    //reqiured
    const { value, onChange } = props;
    //optional
    const { title, placeholder, hidden, noMargin, disabled } = props;
    
    return (
        <Form.Group className={!noMargin ? 'mb-3' : ''} hidden={hidden || false}>
            <Form.Label hidden={noMargin || false}>{title || ''}</Form.Label>
            <Form.Control type="text" placeholder={placeholder || ''} value={value} onChange={onChange} disabled={disabled || false}/>
        </Form.Group>
    )
}


export const NumberForm = props => {
    //reqiured
    const { modalName, valueName, setValue, modalData } = props;
    //optional
    const { title, placeholder, min, max } = props;
    return (
        <Form.Group className="mb-3">
            <Form.Label>{title}</Form.Label>
            <Form.Control 
                type="number" 
                min={min} max={max} 
                value={modalData[modalName][valueName]}
                placeholder={placeholder || ''} 
                onChange={e => setValue(v => ({...v, [modalName] : {...v[modalName], [valueName] : parseInt(e.target.value) }}))} 
            />
        </Form.Group>
    )
}

export const SelectForm = props => {
    //reqiured
    const { value, onChange, options } = props;
    //optional
    const { title, noMargin, disabled } = props;

    const mappingOptions = () => {
        if (Array.isArray(options)) {
            return options.map((o, i) => (<option key={i} value={o}>{o}</option>))
        }
        else if (typeof options === 'object') {
            return Object.keys(options).map((k, i) => (<option key={i} value={k}>{options[k]}</option>))
        }
        else {
            return <option>-</option>
        }
    }

    return (
        <Form.Group className={!noMargin ? 'mb-3' : ''}>
            <Form.Label hidden={noMargin || false}>{title}</Form.Label>
            <Form.Select value={value} onChange={onChange} disabled={disabled || false}>{mappingOptions()}</Form.Select>
        </Form.Group>
    )
}

export const RangeForm = props => {
    //reqiured
    const { value, onChange } = props;
    //optional
    const { title, min, max, step, hidden } = props;
    return (
        <Form.Group className="mb-3" hidden={hidden || false}>
            <Form.Label>{title}</Form.Label>
            <Form.Range onFocus={e => e.target.blur()} min={min} max={max} step={step || 1} value={value} onChange={onChange} />
        </Form.Group>
    )
}