import { ChangeRoomNoteOctave } from './NoteConverter';



/*export const onNoteCopy = props => {
    const { tData, fId, gNoteData, setGClipboard, setGSelectRoom } = props;
    const { gSelectRoom, gFocusRoom } = gNoteData;
    let cb = {};
    const minRoom = Math.min(...gSelectRoom);
    gSelectRoom.forEach(v => {
        if (tData[fId].tNote[v]) {
            cb = {...cb, [v - minRoom] : [...tData[fId].tNote[v]]}
        }
    })
    setGClipboard(cb);
    setGSelectRoom([gFocusRoom.fRoom])
}*/

/*export const onNotePaste = props => {
    const { tData, fId, setTData, gSelectRoom, gClipboard } = props;
    let buffer = {...tData[fId].tNote};
    if (gSelectRoom.length === 1) {
        for (const copyRoom in gClipboard) {
            let pasteRoom = parseInt(copyRoom) + parseInt(gSelectRoom[0])
            if (pasteRoom < tData[fId].tLineNum * 8) {
                buffer = {...buffer, [pasteRoom] : [...gClipboard[copyRoom]]}
            }
        }
    }
    //console.log(buffer)
    setTData(d => ({...d, [fId] : {...d[fId], tNote : buffer }}))
}*/

export const onNoteDelete = props => {
    const { fId, setTData, sRoom } = props;
    setTData(d => {
        let deletedData = {...d};
        sRoom.forEach(v => {
            if (deletedData[fId].tNote[v]) delete deletedData[fId].tNote[v];
        })
        return deletedData;
    })
}

/*export const onNoteCut = props => {
    const { tData, fId, setTData, gNoteData, setGClipboard, setGSelectRoom } = props;
    const { gSelectRoom } = gNoteData
    onNoteCopy({tData, fId, gNoteData, setGClipboard, setGSelectRoom});
    onNoteDelete({fId, setTData, gSelectRoom});
}*/

export const onKeyChange = props => {
    const { fId, tData, setTData, sRoom, isKeyUp } = props;
    let octaveValue = isKeyUp ? 1 : -1;
    let notes = {...tData[fId].tNote};
    sRoom.forEach(room => {
        if (notes[room] && notes[room].length) {
            let data = []
            if (notes[room][0]) data[0] = ChangeRoomNoteOctave(notes[room][0], tData[fId].tTang, octaveValue);
            if (notes[room][1]) data[1] = ChangeRoomNoteOctave(notes[room][1], tData[fId].tTang, octaveValue);
            setTData(d => ({...d, [fId] : {...d[fId], tNote : {...d[fId].tNote, [room] : data} } }))
        }
        
    })
}