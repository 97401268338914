import { useState } from "react";
import { Badge, ButtonGroup, Form } from "react-bootstrap";
import RoundContainer from "../../layout/RoundContainer";


const COPY_ICON = 'fa fa-copy';
const SHARE_ICON = 'fa fa-share'
const PASTE_ICON = 'fa fa-paste';
const DELETE_ICON = 'fa fa-trash';
const DOWNLOAD_ICON = 'fa fa-download';
const SAVE_ICON = 'fa fa-save'

const Help = () => {

    const [subPage, setSubpage] = useState(0);

    const RoomDes = (props) => {
        return(
            <div className="row pt-1 pb-1">
                <div className="col-3">
                    <div className={props.cn}/>
                </div>
                <div className="col">
                    {props.des}
                </div>
            </div>
        )
    }

    const NoteButton = (props) => {
        return (
            <div className="row pb-3">
                <div className="col-3">
                    <button className={`btn ${props.cn || "btn-light"}`} style={{marginTop: "-6px"}}><i className={props.icon} /> {props.name || ""} </button>
                </div>
                <div className="col">
                    {props.des || ""}
                </div>
            </div>
        )
    }

    const NoteShortKey = (props) => {
        return (
            
            <div className="row text-center pb-3">
                <div className="col-4 h5">
                    {props.k ? props.k.map((v, i) => (<div key={i} style={{display: "inline", paddingLeft: "3px"}}> <Badge bg="secondary">{v} </Badge></div>)) : <div />}
                </div>
                <div className="col" style={{textAlign: "left"}}>
                    {props.v}
                </div>
            </div>
        )
    }

    const ServerCommand = (props) => {
        return (
            <div className={props.isHeader ? "row pt-3 pb-3 bg-light text-center" : "row pb-1 pt-2"} style={{fontWeight: props.isHeader ? 'bold' : 'normal'}}>
                <div className={props.isHeader ? 'col-4' : "col-4 text-success"}>
                    {props.cmd}
                </div>
                <div className="col-4">
                    {props.des}
                </div>
                <div className={props.isHeader ? 'col-4' : "col-4 text-success"}>
                    {props.ex}
                </div>
            </div>
        )
    }

    const AdminSettingItem = (props) => {
        return (
            <div className={`row ${props.isHeader ? 'bg-light text-center pt-3 pb-3' : 'pt-2 pb-1'}`} style={{fontWeight: props.isHeader ? 'bold' : 'normal'}}>
                <div className="col-3" style={{textAlign: 'center'}}>
                    {props.name}
                </div>
                <div className="col-3" style={{textAlign: 'center'}}>
                    {props.def}
                </div>
                <div className="col-6">
                    {props.des}
                </div>
            </div>
        )
    }

    return(
        <div>
            <RoundContainer>
                <h3 className="containerHeader">คู่มือ</h3>
                <div className="text-center">
                <ButtonGroup size="md">
                    <button className="btn btn-outline-primary" onClick={() => setSubpage(0)}>เกี่ยวกับแพลตฟอร์มนี้</button>
                    <button className="btn btn-outline-primary" onClick={() => setSubpage(1)}>การเล่น</button>
                    <button className="btn btn-outline-primary" onClick={() => setSubpage(2)}>แอดมิน</button>
                    <button className="btn btn-outline-primary" onClick={() => setSubpage(3)}>พิมพ์โน้ต</button>
                    <button className="btn btn-outline-primary" onClick={() => setSubpage(4)}>เทียบเสียง</button>
                    <button className="btn btn-outline-primary" onClick={() => setSubpage(5)}>ห้องแชท</button>
                </ButtonGroup>
                </div>
            </RoundContainer>
            <br />
            <div hidden={subPage !== 0}>
                <RoundContainer>
                    <h5 className="containerHeader">เกี่ยวกับพร้อมเพลง</h5>
                    <p>เป็นเว็บแอพพลิเคชันที่สามารถให้นักดนตรีไทย เล่นดนตรีได้พร้อมกัน โดยไม่เกิดการดีเลย์</p>
                    <p>
                        การดีเลย์ของเสียงเกิดขึ้นจากเวลาที่เสียงส่งจากอุปกรณ์หนึ่ง ไปยังอีกอุปกรณ์ 
                        การลดเวลาเดินทางของเสียงให้พอเล่นดนตรีพร้อมกันได้ แทบจะเป็นไปไม่ได้ด้วยเทคโนโลยีปัจจุบัน 
                        ทำให้การเล่นดนตรีในแพลตฟอร์มอื่น ๆ ทำได้ลำบาก
                    </p>
                    <p>
                        โดยทั่วไปแล้ว เพลงในดนตรีไทยจะเล่น 2 รอบ แพลตฟอร์มนี้จึงสร้างมาให้ <br />
                        - การเล่นรอบแรก จะเป็นการอัดเสียงเปล่า (ท่านจะไม่ได้ยินผู้อื่น ยกเว้นเสียงจังหวะ) <br />
                        - การเล่นรอบที่สอง ท่านจะได้ยินเสียงรอบแรกของผู้อื่น<br />
                        ด้วยหลักการนี้ จะทำให้เราเล่นดนตรีพร้อมกันได้
                    </p>
                </RoundContainer>
            </div>


            <div hidden={subPage !== 1}>
                <RoundContainer>
                    <h5 className="containerHeader">การเล่น</h5>
                    <NoteButton name="เริ่ม!" cn="btn btn-success" des="1. แอดมินกดปุ่มเริ่ม" />
                    <RoomDes des="2. จังหวะจะเริ่มเล่น โดยมีอินโทรให้ 1 บรรทัด ระหว่างอินโทรไม่ต้องเล่น" cn="each-room0"/>
                    <RoomDes des="3. ในรอบแรก จะอัดเสียงคนที่เปิดไมค์" cn="each-room1"/>
                    <RoomDes des="4. ในรอบสอง จะเล่นเสียงที่อัดรอบแรก และอัดเสียงเก็บไว้" cn="each-room1"/>
                    <NoteButton icon="fa fa-download" cn="btn btn-primary rounded-circle" des="5. สักครู่ ในหน้าหลักจะมีไฟล์ที่อัดไว้ในรอบสองให้เล่นและสามารถกดดาวน์โหลดได้ "/>
                </RoundContainer>
                <br />
                <RoundContainer>
                    <h5 className="containerHeader">ปุ่มยกมือ และปุ่มไมค์</h5>
                    <NoteButton icon="fa fa-hand-stop-o" cn="btn btn-success btn-lg rounded-circle" des="กดเพื่อบอกแอดมินว่าเราพร้อม หรือไม่พร้อม ไม่มีผลต่อการอัดเสียง" />
                    <NoteButton icon="fa fa-microphone" cn="btn btn-info rounded-circle btn-lg" des="เปิดไมค์เพื่ออัดเสียง และสามารถปิดไมค์เมื่อไม่ต้องการอัดเสียง ไม่สามารถเปิดปิดเมื่อเกินบรรทัดอินโทร" />
                </RoundContainer>
                <br />
                <RoundContainer>
                    <h5 className="containerHeader">เวลาดีเลย์</h5>
                    <div className="row pb-3">
                        <div className="col-3">
                            <Form.Range />
                        </div>
                        <div className="col">
                            เมื่อเริ่มรอบที่ 2 เสียงของรอบแรกอาจจะช้ากว่าเสียงจังหวะ ท่านสามารถปรับเวลาดีเลย์เพื่อให้เสียงดังกล่าวตรงกัน (ประมาณ 100 กว่า ms)
                        </div>
                    </div>
                </RoundContainer>
            </div>


            <div hidden={subPage !== 2}>
                <RoundContainer>
                    <h5 className="containerHeader">แอดมิน</h5>
                    <div>เมื่อต้องการเริ่ม แอดมินสามารถกดปุ่มเริ่มเพื่อเริ่มเล่นได้ โปรดตรวจสอบให้แน่ใจว่าไม่มีคนที่ต้องการอัดเสียงและปิดไมค์อยู่</div>
                </RoundContainer>
                <br />
                <RoundContainer>
                    <h5 className="containerHeader">การตั้งค่าของแอดมิน</h5>
                    <AdminSettingItem name="รายการ" des="รายละเอียด" def="ค่าเริ่มต้น" isHeader={true} />
                    <AdminSettingItem name="ชื่อเพลง" def='-' des="ชื่อเพลงจะปรากฏบนหน้าหลักและหน้าเขียนโน้ต ไม่มีผลต่อการเล่น" />
                    <AdminSettingItem name="จำนวนบรรทัด" def='2' des="จำนวนบรรทัดที่อัดในแต่ละรอบ" />
                    <AdminSettingItem name="อัตราจังหวะ" def='2 ชั้น' des="อัตราจังหวะของการตีฉิ่ง ไม่มีผลต่อความเร็ว" />
                    <AdminSettingItem name="ความเร็ว-ช้า" def='1.00 วินาที/ห้อง' des="ความเร็วหรือช้าในการเล่น" />
                    <AdminSettingItem name="หน้าทับ" def='-' des="หน้าทับที่เล่น โปรดปรับให้ตรงกับอัตราจังหวะ" />
                    <AdminSettingItem name="โหมด iOS" def='ปิด' des="โปรดเปิดโหมดนี้เมื่อมีคนใช้อุปกรณ์ iOS (เสียงจะโหลดนานกว่าปกติ)" />
                </RoundContainer>
                <br />
                <RoundContainer>
                    <h5 className="containerHeader">เพื่อไม่ให้แอดมินเหนื่อยเกินไป</h5>
                    <div> - เมื่อท่านพร้อม ควรจะกดปุ่มยกมือ แอดมินจะได้ทราบและกดเริ่ม</div>{boldText('')}
                    <div> - ท่านสามารถช่วยแนะนำค่าความเร็วให้กับแอดมินได้ โดยไปที่ {boldText('ตั้งค่า')} {"->"} {boldText('ทดสอบจังหวะ')} {"->"} {boldText('ทำการทดสอบความเร็ว')}</div>
                    <div> - ท่านสามารถช่วยเขียนโน้ตและแบ่งปันให้กับผู้อื่นได้ โดยไปที่ {boldText('เขียนโน้ต')} {"->"} {boldText('ทำการพิมพ์โน้ต')} {"->"} {boldText('แบ่งปันในแชท')}</div>
                </RoundContainer>
            </div>


            <div hidden={subPage !== 3}>
                <RoundContainer>
                    <h5 className="containerHeader">การพิมพ์</h5>
                    <p>- 1 ห้องจะพิมพ์ได้ 4 ตัวอักษรเท่านั้น และจะไม่สามารถพิมพ์เสียงสูงเสียงต่ำได้ (เช่น ซฺ หรือ ซํ)</p>
                    <p>- ขณะพิมพ์ สามารถเปลี่ยนโน้ตจากตัวเลขเป็นตัวอักษร (เช่น 5 เป็น ซ) หรือตัวอักษรเป็นตัวเลข (เช่น ซ เป็น 5) โดยการตั้งค่าข้างล่างหน้าเขียนโน้ต</p>
                    <p>- ท่านจะไม่เห็นโน้ตที่พิมพ์บนหน้าหลักจนกว่าจะมีการบันทึก</p>
                    <p>- คนอื่นจะไม่เห็นโน้ตของเรา ในกรณีที่อยากแชร์ เลือก {boldText('แบ่งปันในแชท')}</p>
                </RoundContainer>
                <br />
                <RoundContainer>
                    <h5 className="containerHeader">แถบเครื่องมือ</h5>
                    <NoteButton icon={SAVE_ICON} cn="btn-outline-primary" name="บันทึก" des="บันทึกโน้ตที่พิมพ์ลงระบบ ข้อมูลที่บันทึกจะแสดงบนหน้าหลัก" />
                    <NoteButton icon={SHARE_ICON} cn="btn-outline-primary" name="แบ่งปันในแชท" des="แชร์โน้ตที่พิมพ์ลงในห้องแชท" />
                    <NoteButton icon={COPY_ICON} cn="btn-outline-primary" name="คัดลอกทั้งหมด" des="คัดลอกโน้ตทั้งหมดลงในคลิปบอร์ดของคุณ" />
                    <NoteButton icon={PASTE_ICON} cn="btn-outline-primary" name="วาง" des="วางโน้ตทั้งหมดลงจากคลิปบอร์ดของคุณ" />
                    <NoteButton icon={DELETE_ICON} cn="btn-outline-primary" name="ลบหมด" des="ลบโน้ตทั้งหมดที่พิมพ์ไว้" />
                    <NoteButton icon={DOWNLOAD_ICON} cn="btn-outline-primary" name="ดาวน์โหลด" des="ดาวน์โหลดโน้ตที่พิมพ์ไว้เป็นไฟล์ .docx โปรดบันทึกก่อนดาวน์โหลด" />
                    <NoteButton icon={COPY_ICON} cn="btn-primary" des="คัดลอกโน้ตจากบรรทัดนั้น" />
                    <NoteButton icon={PASTE_ICON} cn="btn-secondary" des="วางโน้ตที่คัดลอกลงบรรทัดนั้น" />
                    <NoteButton icon={DELETE_ICON} cn="btn-danger" des="ลบโน้ตที่พิมพ์ในบรรทัดนั้น" />
                </RoundContainer>
                <br />
                <RoundContainer>
                    <h5 className="containerHeader">คีย์ลัด</h5>
                    <NoteShortKey k={['*', '/', ' Space ', 'ข']} v="-" />
                    <NoteShortKey k={['+']} v="--" />
                    <NoteShortKey k={['Tab']} v="---" />
                    <NoteShortKey k={['Enter']} v="---- *ในห้องที่ว่างเท่านั้น" />
                    <NoteShortKey k={['ว']} v="ซ" />
                    <NoteShortKey k={['^', 'v', '<', '>']} v="เคลื่อนที่ขึ้น ลง ซ้าย ขวา ตามลำดับ" />
                </RoundContainer>
                <br />
                <RoundContainer>
                    <h5 className="containerHeader">การพิมพ์โน้ตกับ Microsoft Word</h5>
                    สามารถคัดลอกตารางจาก Microsoft Word และเลือก วางทั้งหมด หรือวางทีละบรรทัดได้
                </RoundContainer>
                
            </div>



            <div hidden={subPage !== 4}>
                <RoundContainer>
                <h5 className="containerHeader">เทียบเสียง</h5>
                <NoteButton name="เริ่มอัดเสียง" cn="btn-outline-success" des="ทำการอัดเสียง" />
                <NoteButton name="หยุดอัดเสียง" cn="btn-danger" des="ทำการหยุดเสียง" />
                <NoteButton name="อัดเสียงใหม่" cn="btn-warning" des="ทำการอัดเสียงใหม่" />
                <NoteButton name="ส่ง" cn="btn-primary" des="แบ่งปันไฟล์เสียงที่อัดให้กับผู้อื่น" />
                <NoteButton icon="fa fa-repeat" cn="btn-danger" des="เมื่อเปิดฟังก์ชันนี้ และกดเล่นไฟล์เสียง จะทำการเล่นวนไปเรื่อย ๆ จนกว่าจะกดหยุดไฟล์เสียง" />
                </RoundContainer>
            </div>



            <div hidden={subPage !== 5}>
                <RoundContainer>
                <h5 className="containerHeader">คุยกับน้อง</h5>
                <p>การพิมพ์ "!!" หรือการพิมพ์ "!เซิร์ฟเอ๋ย" ในห้องแชท จะสามารถคุยกับน้องได้ โดยต่อด้วยข้อความต่อไปนี้</p>
                <ServerCommand cmd="ข้อความต่อจาก !เซิร์ฟเอ๋ย " des="คำอธิบาย" ex="ตัวอย่าง" isHeader={true}/>
                <ServerCommand cmd="มีคำว่า refresh หรือ รีเฟรช" des="ทำการรีเฟรชหน้า" ex="!เซิร์ฟเอ๋ย จงรีเฟรช"/>
                <ServerCommand cmd="มีคำว่า reset หรือ รีเซ็ต" des="ทำการรีเซ็ตเซิร์ฟเวอร์" ex="!เซิร์ฟเอ๋ย จงรีเซ็ตซะ" />
                <ServerCommand cmd="<ข้อความอะไรก็ได้>ให้<ชื่อ>" des="ทำการมอบตำแหน่งแอดมิน (เฉพาะแอดมินเท่านั้น)" ex="!เซิร์ฟเอ๋ย ขอมอบตำแหน่งให้เนย"/>
                <ServerCommand cmd="ใคร<ข้อความอะไรก็ได้>" des="ถามเซิร์ฟเวอร์ น้องจะสุ่มตอบชื่อคนที่อยู่ในห้อง" ex="!เซิร์ฟเอ๋ย ใครงามเลิศในปฐพี"/>
                </RoundContainer>
            </div>

            
        </div>
    )
}

const boldText = (t) => {
    return <span style={{fontWeight: 'bold'}}>{t}</span>
}

export default Help;