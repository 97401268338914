import { useContext } from "react";
import { Button, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { auth } from "../../databases/firebase";
import { AuthContext } from "../../providers/AuthContext";
import { PagesContext } from "../../providers/PagesProvider";
import { SoundsContext } from "../../providers/SoundsContext";
import socket from "../../server/socket-io";
import { Ting } from "../../sounds/EffectSound";
import { ICON_HEADER_BARS, ICON_HEADER_HOME, ICON_HEADER_SETTING, ICON_HEADER_SIGNIN, ICON_HEADER_SIGNOUT, ICON_HEADER_USER, ICON_PP_BACK, ICON_PP_CHAT, ICON_PP_HELP, ICON_PP_NOTE, ICON_PP_SETTING, ICON_PP_SOUND } from '../icons';
import { FluteVolumeModal, KongVolumeModal, SoundRhythmModal } from "./SoundRhythmModal";


const SuperHeader = props => {

    const { title, isPPPage, isSSPage, children, canSetVol } = props;

    const navigate = useNavigate();

    const { PAGE_HOME, setPage, PAGE_NOTE, PAGE_SETTING, PAGE_SOUND, PAGE_HELP, PAGE_CHAT } = useContext(PagesContext);
    const { isLogin, userName } = useContext(AuthContext);
    const { setShowRhythmModal } = useContext(SoundsContext)

    const onPPOut = () => {
        setPage(PAGE_HOME);
        socket.disconnect();
        Ting.play(); 
        navigate('/');
    }

    const DropdownTextItem = props => {
        const { onClick, title, shortCut, active, disabled, icon } = props;
        return (
            <Dropdown.Item className="edit-note" as='button' onClick={onClick} active={active || false} disabled={disabled || false} >
                <Row bsPrefix="row align-items-center">
                    <Col style={{color : !disabled ? 'black' : ''}}>
                        {icon}<span>{title}</span>
                    </Col>
                    <Col sm='auto' xs='auto' style={{color : '#8c8c8c'}}>{shortCut}</Col>
                </Row>
            </Dropdown.Item>
        )
    }

    return (
        <div className="container-fluid fixed-header" style={{background: 'white', height: 'fit-content', boxShadow: '0 3px 6px 0 #f0f0f0', marginBottom: '20px', paddingBlock: '5px', alignItems: 'center'}}>
            <Row bsPrefix="row align-items-center">
                <Col sm='auto' xs='auto'>
                    <img src="./tonePic.png" alt="" height='30px' style={{paddingBottom: '8px'}} ></img>
                    <span className="h4" style={{paddingLeft: '20px'}}>{title || 'พร้อมเพลง'}</span>
                    
                </Col>
                <Col bsPrefix="col sm-auto d-none d-sm-inline" />
                { isSSPage ? <Col bsPrefix="col d-sm-none" xs={{offset : 'auto'}} style={{textAlign : 'end'}}>
                    <Button variant='round-danger' hidden={!userName} onClick={() => navigate('/software')}>
                        {ICON_PP_BACK}
                    </Button>
                    <Button variant='round-light' hidden={userName} onClick={() => navigate('/')}>
                        {ICON_HEADER_HOME}
                    </Button>
                </Col> : <></> }
                <Col bsPrefix="col sm-auto text-end" hidden={isPPPage || isSSPage} >
                    {(userName ? `สวัสดี คุณ${userName}` : '')}
                </Col>
                <Col xs='12' sm='auto'>
                    <div style={{textAlign: 'right'}} hidden={isPPPage || isSSPage}>
                        <Button variant='round-light' onClick={() => navigate('/')}>
                            {ICON_HEADER_HOME}
                        </Button>
                        <Button variant='round-light' onClick={() => navigate('/about')}>
                            {ICON_HEADER_BARS}
                        </Button>
                        <Button variant='round-light' disabled={!userName} hidden={!isLogin} onClick={() => navigate('/userdata')}>
                            {ICON_HEADER_USER}
                        </Button>
                        <span hidden={!canSetVol}>
                        <DropdownButton className="d-inline" variant="round-light" title={ICON_HEADER_SETTING} >
                            <div className="fs14">
                                <DropdownTextItem title='ระดับเสียงจังหวะ' onClick={() => setShowRhythmModal(true)} />
                            </div>
                        </DropdownButton>
                        </span>
                        
                        <Button variant='round-light' hidden={isLogin} onClick={() => navigate('/signin')}>
                            {ICON_HEADER_SIGNIN}
                        </Button>
                        <Button variant='round-danger' hidden={!isLogin} onClick={() => auth.signOut().then(() => navigate('/'))}>
                            {ICON_HEADER_SIGNOUT}
                        </Button>
                    </div>
                    <div style={{textAlign: 'right'}} hidden={!isSSPage}>
                        {children}
                        <div className="d-none d-sm-inline">
                        <Button variant='round-danger' hidden={!userName} onClick={() => navigate('/software')}>
                            {ICON_PP_BACK}
                        </Button>
                        <Button variant='round-light' hidden={userName} onClick={() => navigate('/')}>
                            {ICON_HEADER_HOME}
                        </Button>
                        </div>
                        
                    </div>
                    <div style={{textAlign: 'right'}} hidden={!isPPPage}>
                        <PpButton page={PAGE_HOME} icon={ICON_HEADER_HOME} />
                        <span className="d-md-none"><PpButton page={PAGE_CHAT} icon={ICON_PP_CHAT} /></span>
                        <PpButton page={PAGE_NOTE} icon={ICON_PP_NOTE} />
                        <PpButton page={PAGE_SETTING} icon={ICON_PP_SETTING} />
                        <PpButton page={PAGE_SOUND} icon={ICON_PP_SOUND} />
                        <PpButton page={PAGE_HELP} icon={ICON_PP_HELP} />
                        <Button variant='round-danger' onClick={onPPOut}>
                            {ICON_PP_BACK}
                        </Button>
                    </div>
                </Col>
            </Row>
            <SoundRhythmModal />
            <FluteVolumeModal />
            <KongVolumeModal />
        </div>
    )
}

const PpButton = props => {

    const { icon } = props;
    const { page, setPage } = useContext(PagesContext);

    return (
        <Button variant={props.page === page ? 'round-info' : 'round-light'} onClick={() => setPage(props.page)}>
            {icon}
        </Button>
    )
}

export default SuperHeader;