import React, { useContext, useEffect, useState } from "react";
import Chat from "../../layout/Chat";
import List from "../../layout/List";
import NoteLayout from "../../layout/NoteLayout";
import Panel from "../../layout/Panel";
import Moving from "../../layout/Moving";
import { SettingsContext } from "../../../providers/SettingsProvider";
import { database } from "../../../databases/firebase";
import { UnitContext } from "../../../providers/UnitProvider";
import { SoundEffectFunction } from "../../../sounds/EffectSound";
import RoundContainer from '../../layout/RoundContainer'
import SoundPanel from "../../layout/SoundPanel";

const RoomLarge = () => {

    
    const {speed, rate} = useContext(SettingsContext);
    const [activeBtn, setActiveBtn] = useState('btn-userList');
    const [previousBtn, setPreviousBtn] = useState('btn-chat');
    const [title, setTitle] = useState('');
    const { getValueAndUnit } = useContext(UnitContext)

    useEffect(() => {
        const showActive = document.getElementById("show-" + activeBtn.substring(4));
        const showPrevious = document.getElementById("show-" + previousBtn.substring(4));
        const btnActive = document.getElementById(activeBtn);
        const btnPrevious = document.getElementById(previousBtn);

        btnActive.disabled = true;
        btnActive.className = "btn btn-info"
        showActive.hidden = false;
        
        btnPrevious.disabled = false;
        btnPrevious.className = "btn btn-outline-info";
        showPrevious.hidden = true;

    }, [activeBtn, previousBtn]);

    const btnClick = (btn) => {
        setPreviousBtn(activeBtn);
        setActiveBtn(btn.target.id);
    }

    useEffect(() => SoundEffectFunction(), []); 

    useEffect(() => {
        getAndSetTitle();
        return () => setTitle("");
    }, []);

    const getAndSetTitle = () => {
        database.ref('databaseSystem').child('title').on('value', child => {
            setTitle(child.val());
        })
    }

    return(
        <div>
            <div className="row" style={{width: "1100px"}}> 
                <div className="col-8" style={{/*minWidth: "720px"*/}}>
                    <RoundContainer>
                    <div className="row align-items-end">
                        <div className="col-8">
                            <h3>{title}</h3>
                        </div>
                        <div className="col-4">
                            <div style={{textAlign: "right"}}>
                                <div>{rate !== 1 ? ` อัตราจังหวะ ${rate} ชั้น ` : 'อัตราจังหวะชั้นเดียว'}</div>
                                <div>{getValueAndUnit(speed)}</div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <NoteLayout h={window.screen.height * 0.45} />
                    </RoundContainer>
                </div>
                <div className="col-4" style={{/*minWidth: "380px"*/}}>
                    <RoundContainer>
                    <div className="btn-group">
                        <button id="btn-userList" type="button" className="btn btn-info" onClick={btnClick}>เพื่อน ๆ</button>
                        <button id="btn-chat" type="button" className="btn btn-outline-info" onClick={btnClick}>ห้องแชท</button>
                    </div>
                    <div id="show-userList">
                        <List h={window.screen.height * 0.5}/>
                    </div>
                    <div id="show-chat">
                        <Chat h={window.screen.height * 0.5}/>
                    </div>
                    </RoundContainer>
                </div>
            </div>

            <div style={{paddingTop: "20px" ,marginLeft: "0px", width: "1080px"}}>
                <RoundContainer>
                    <div className="row" style={{alignItems: 'center'}}>
                        <div className="col-2"><Panel /></div>
                        <div className="col-5"><Moving /></div>
                        <div className="col-5"><SoundPanel /></div>
                    </div>
                </RoundContainer>
            </div>
        </div>
    )
}

export default RoomLarge;