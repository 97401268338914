import { TANG_KONG, NoteTypingKey, OctaveTypingKey, FOCUS_NOTE, ControlNote2, FOCUS_COMMENT, FOCUS_NONE } from "../const";
import { onNoteDelete } from "../actions/SelectFunction";
import { ChangeSingleNoteOctave } from "../actions/NoteConverter";


const OnKeyDown = props => {
    const { event, key, tData, setTData, setRoom, sheetContext, keyPressContext } = props;
    const { gFocusRoom, setFRoom, setFType, sRoom, setSRoom, onNoteCopy, onNotePaste } = sheetContext;
    const { controlPress } = keyPressContext;
    const { fId, fRoom, fType } = gFocusRoom;    

    const Room = (r, s = 0) => {
        if (tData && tData[fId] && tData[fId].tNote && tData[fId].tNote[r]) {
            return tData[fId].tNote[r][s]
        }
        else {
            return ''
        }
    }
    const RoomLastChar = (r, s = 0) => {
        let room = Room(r, s);
        if (!room) {
            return {};
        }
        else if (room.charAt(room.length - 1) === 'ํ' || room.charAt(room.length - 1) === 'ฺ') {
            return {
                pureKey : room.charAt(room.length - 2),
                octave : room.charAt(room.length - 1),
                combinedKey : room.substring(room.length - 2)
            }
        }
        else {
            return {
                pureKey : room.charAt(room.length - 1),
                octave : '',
                combinedKey : room.charAt(room.length - 1),
            }
        }
    }

    const RoomLastCharToKey = (r, s = 0, k='-') => {
        let room = Room(r, s);
        let lastChar = RoomLastChar(r, s);
        return !lastChar.octave ? room.substring(0, room.length - 1) + k : room.substring(0, room.length - 2) + k
    }

    const DeleteRoom = (r) => {
        let d = {...tData};
        delete d[fId].tNote[r];
        setTData(d);
    }

    const NoteNum = (r, s = 0) => {
        const room = Room(r, s) || '';
        let c = 0;
        for (let i = 0; i < room.length; i++) {
            if (room.charAt(i) !== 'ํ' && room.charAt(i) !== 'ฺ') c++
        }
        return c;
    }

    const AddNote = (k, r, s = 0) => {
        let addedRoom = r;
        while (NoteNum(addedRoom, s ) >= 4 && addedRoom <= lastRoom) {
            addedRoom++;
        }
        if (addedRoom <= lastRoom) {
            let addedData = tData[fId].tNote[addedRoom] || [];
            if (tang !== TANG_KONG) {
                //addedData[0] = (addedData[0] || '') + k;
                addedData = [(addedData[0] || '') + k]
            }
            else {
                addedData = [(addedData[0] || '') + k, (addedData[1] || '') + '-']
            }
            if (addedRoom !== fRoom) setFRoom(addedRoom)//setFocusRoom({...focusRoom, fRoom : addedRoom})
            setRoom(addedRoom, addedData)
        }
    }



    const AddNoteFromNumber = (k, r) => {
        if (tData[fId].tNote[r] && tData[fId].tNote[r][0]) {
            let upperData = tData[fId].tNote[r][0];
            let dataLastCharIndex = ControlNote2[TANG_KONG].indexOf(RoomLastChar(r).pureKey)
            if (k <= 8 && k > 1 && dataLastCharIndex !== -1 && dataLastCharIndex - k >= 0 && ControlNote2[TANG_KONG][dataLastCharIndex - k + 1]) {
                let lowerData = tData[fId].tNote[r][1];
                lowerData = RoomLastCharToKey(r, 1, ControlNote2[TANG_KONG][dataLastCharIndex - k + 1]);
                setTData(d => ({...d, [fId] : {...d[fId], tNote : {...d[fId].tNote, [r] : [upperData, lowerData]}}}))
            }            
        }
    }

    const SwitchNoteInSubRoom = (r, s = undefined) => {
        if (tData[fId].tNote[r] && tData[fId].tNote[r][0] && tData[fId].tNote[r][1]) {
            let upperData = tData[fId].tNote[r][0];
            let lowerData = tData[fId].tNote[r][1];
            let lastChar = [RoomLastChar(r, 0).combinedKey, RoomLastChar(r, 1).combinedKey]
            if (lastChar[0] === '-' || lastChar[1] === '-') {
                upperData = RoomLastCharToKey(r, 0, lastChar[1]);
                lowerData = RoomLastCharToKey(r, 1, lastChar[0]);
            }
            else if (s === 1) {
                upperData = RoomLastCharToKey(r, 0, '-');
            }
            else {
                lowerData = RoomLastCharToKey(r, 1, '-');
            }
            setTData(d => ({...d, [fId] : {...d[fId], tNote : {...d[fId].tNote, [r] : [upperData, lowerData]}}}))
        }
        
    }

    const AddOctave = (k, r) => {
        let addedData = [...(tData[fId].tNote[r] || [])];
        let octaveValue = k === 'ฺ' ? -7 : 7;
        let subRoom = addedData[0] && !ControlNote2[tang].includes(RoomLastChar(r, 1).pureKey) ? 0 : addedData[1] && !ControlNote2[tang].includes(RoomLastChar(r, 0).pureKey) ? 1 : undefined;
        if (subRoom !== undefined) {
            let lastChar = RoomLastChar(r, subRoom).pureKey
            addedData[subRoom] = RoomLastCharToKey(r, subRoom, ChangeSingleNoteOctave(lastChar, tang, octaveValue));
            setTData(d => ({...d, [fId] : {...d[fId], tNote : {...d[fId].tNote, [r] : addedData}}}))
        }
    }

    const DeleteNote = (r) => {
        if (tData[fId].tNote[r]) {
            let deletedData = [...tData[fId].tNote[r]]
            if (deletedData[0]) deletedData[0] = deletedData[0].substring(0, deletedData[0].length - 1)
            if (deletedData[1]) deletedData[1] = deletedData[1].substring(0, deletedData[1].length - 1)
            if (deletedData[0] || deletedData[1]) {
                setRoom(r, deletedData)
            }
            else {
                DeleteRoom(r)
            }
        }
        else if (fRoom > 0) {
            if (tData[fId].tNote[r - 1]) DeleteRoom(r - 1)
            setFRoom(r - 1)
        }
    }

    const DeleteNotes = () => {
        sRoom.forEach(r => {
            DeleteRoom(r)
        })
    }

    const AddThreeDash = (r) => {
        let addedData = tData[fId].tNote[r] || ['']
        switch (addedData[0].length) {
            case 0 :
                setTData(d => ({...d, [fId] : {...d[fId], tNote : {...d[fId].tNote, [r] : tang !== TANG_KONG ? ['---'] : ['---', '---']}}}));
                break;
            case 1 : case 2 : case 3 :
                let addString = '-'.repeat(4 - addedData[0].length);
                if (addedData[0]) addedData[0] += addString;
                if (addedData[1]) addedData[1] += addString;
                setTData(d => ({...d, [fId] : {...d[fId], tNote : {...d[fId].tNote, [r] : addedData}}}));
                if (fRoom < lastRoom) setFRoom(r + 1)
                break;
            default :
                if (fRoom < lastRoom) {
                    setFRoom(r + 1)
                    if (!tData[fId].tNote[r + 1]) AddThreeDash(r + 1);
                }
                break;
        }
    }

    const lastRoom = tData[fId].tLineNum * 8 - 1;
    //const lastCharIndex = tData[fId].tLineNum * 32 - 1;
    const tang = tData[fId].tTang;

    if (fType === FOCUS_NOTE) {
        switch (key) {
            case "Backspace": case "Delete":
                event.preventDefault();
                if (sRoom.length <= 1) DeleteNote(fRoom)
                else DeleteNotes();
                break;
            case "Tab" :
                event.preventDefault();
                if (tang !== TANG_KONG) {
                    AddThreeDash(fRoom)
                }
                else if (tang === TANG_KONG) {
                    const lastChar = [RoomLastChar(fRoom, 0).pureKey, RoomLastChar(fRoom, 1).pureKey]
                    if ((lastChar[0] === '-' && ControlNote2[TANG_KONG].includes(lastChar[1]))  ||  (lastChar[1] === '-' && ControlNote2[TANG_KONG].includes(lastChar[0]))) {
                        SwitchNoteInSubRoom(fRoom)
                    }
                    else {
                        AddThreeDash(fRoom)
                    }
                }
                break;
            case ' ' : case 'Space':
                event.preventDefault();
                AddNote('-', fRoom);
                break;
            case 'Escape':
                if (fType === FOCUS_NOTE) {
                    event.preventDefault();
                    if (sRoom.length > 1) setSRoom([fRoom])
                    else setFType(FOCUS_NONE)
                }
                else if (fType === FOCUS_COMMENT) setFType(FOCUS_NONE)
                break;
            case "0": case "z": case "ผ":
                if (tang === TANG_KONG) SwitchNoteInSubRoom(fRoom, 0);
                break;
            case "1": case "x": case "ป":
                if (controlPress.current) {
                    event.preventDefault();
                    onNoteCopy(tData[fId].tNote)
                    onNoteDelete({ fId, setTData, sRoom })
                }
                else if (tang === TANG_KONG) SwitchNoteInSubRoom(fRoom, 1);
                break;
            case "d": case "D": case "ก": case "ฏ":
                event.preventDefault();
                onNoteDelete({ fId, setTData, sRoom })
                break;
            case "c": case "C": case "ฉ": case "แ":
                event.preventDefault();
                if (controlPress.current) {
                    //onNoteCopy({ tData, fId, gNoteData, setGClipboard, setGSelectRoom })
                    onNoteCopy(tData[fId].tNote)
                }
                break;
            case "v": case "V": case "อ": case "ฮ":
                event.preventDefault();
                if (controlPress.current) {
                    //onAddNoteToClipboard();
                    //onNotePaste({ tData, fId, setTData, gSelectRoom, gClipboard })
                    onNotePaste(tData[fId].tNote, tData[fId].tLineNum, v => setTData(d => ({...d, [fId] : {...d[fId], tNote : v }})))
                }
                break;
            case "ๆ": case "q": case "Q":
                event.preventDefault();
                if (controlPress.current) {
                    console.log(sRoom)
                }
                break;
            default:
                if (tang === TANG_KONG) {
                    if (!isNaN(key)){
                        AddNoteFromNumber(parseInt(key), fRoom);
                    }
                    else if (NoteTypingKey[key] === '/') {
                        AddNote('-', fRoom)
                    }
                    else if (NoteTypingKey[key]) {
                        AddNote(NoteTypingKey[key], fRoom)
                    }
                    else if (OctaveTypingKey[key]) {
                        AddOctave(OctaveTypingKey[key], fRoom)
                    }
                }
                else {
                    if (NoteTypingKey[key]) {
                        AddNote(NoteTypingKey[key], fRoom)
                    }
                    else if (OctaveTypingKey[key]) {
                        AddOctave(OctaveTypingKey[key], fRoom)
                    }
                }
                break;
        }
    }


}

export default OnKeyDown;