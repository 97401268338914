import { useContext, useRef } from "react";
import { Button } from "react-bootstrap";
import { MicContext } from "../../providers/MicProvider";
import { ICON_PP_MIC_CLOSE, ICON_PP_MIC_OPEN } from "../icons";

const MicButton = () => {

    const btnMicRef = useRef(null);
    const { micEnable, setMicEnable, setMicDisable, inputStream, getMicStream } = useContext(MicContext);

    const btnMicClick = async e => {
        try {
            btnMicRef.current.disabled = true;
            if (!micEnable) {
                const stream = await getMicStream();
                inputStream.current = stream;
                setMicEnable();      
            }
            else {
                if (inputStream.current) inputStream.current.getTracks().forEach(track => track.stop());
                setMicDisable();
            }
        }
        catch (e) {
            console.log(e)
        }
        finally {
            btnMicRef.current.disabled = false;
        }
    }


    return (
        <Button title="กดเพื่อเปิด/ปิดไมค์ของเรา" variant={micEnable ? 'round-success' : 'round-danger'} ref={btnMicRef} onClick={btnMicClick}>
            {micEnable ? ICON_PP_MIC_OPEN : ICON_PP_MIC_CLOSE}
        </Button>
    )

}

export default MicButton;