import React, { createContext, useEffect, useReducer } from "react";
import { database } from "../databases/firebase";

export const SettingsContext = createContext({});

const initialState = {
    line : 2,
    speed: 1000,
    rate: 2,
    naTab: '-',
    mp3Mode: false,
}

const settingsReducer = (state, action) => {
    switch (action.type) {
        case "SET_LINE":
            return {
                ...state,
                line : action.payload,
            }
        case "SET_SPEED":
            return {
                ...state, 
                speed : action.payload,
            }
        case "SET_RATE":
            return {
                ...state,
                rate : action.payload,
            }
        case "SET_NATAB":
            return {
                ...state,
                naTab : action.payload,
            }
        case "SET_MP3MODE":
            return {
                ...state,
                mp3Mode : action.payload,
            }
        default:
            console.log('error');
            break;
    }
}



export const SettingsProvider = ({ children }) => {
    const [settingsState, settingsDispatch] = useReducer(settingsReducer, initialState);
    const { line, speed, rate, naTab, mp3Mode } = settingsState;
    const setDBLine = payload => {
        settingsDispatch({type : "SET_LINE", payload});
    }
    const setDBSpeed = payload => {
        settingsDispatch({type : "SET_SPEED", payload});
    }
    const setDBRate = payload => settingsDispatch({type: "SET_RATE", payload});
    const setDBNaTab = payload => settingsDispatch({type: "SET_NATAB", payload});
    const setDBMp3Mode = payload => settingsDispatch({type: "SET_MP3MODE", payload});

    const setLine = l => database.ref('settings').update({line : l});
    const setSpeed = s => database.ref('settings').update({speed : s});
    const setRate = r => database.ref('settings').update({rate: r});
    const setNaTab = t => database.ref('settings').update({naTab: t});
    const setMp3Mode = m => database.ref('settings').update({mp3Mode: m});

    useEffect(() => {
        database.ref('settings').child('line').on('value', child => {
            setDBLine(child.val());
        })
        database.ref('settings').child('speed').on('value', child => {
            setDBSpeed(child.val());
        })
        database.ref('settings').child('rate').on('value', child => {
            setDBRate(child.val());
        })
        database.ref('settings').child('naTab').on('value', child => setDBNaTab(child.val()))
        database.ref('settings').child('mp3Mode').on('value', child => setDBMp3Mode(child.val()))
    }, []);

    //

    return (
        <SettingsContext.Provider value={{line, speed, rate , naTab, mp3Mode ,setLine, setSpeed, setRate, setNaTab, setMp3Mode}}>
            {children}
        </SettingsContext.Provider>
    )

}



//export {SettingsContext, SettingsProvider};