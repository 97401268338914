import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PagesContext } from "../../providers/PagesProvider";
import socket from "../../server/socket-io";
import { Ting } from "../../sounds/EffectSound";

const HOME_ICON = "fa fa-home";
const SETTING_ICON = "fa fa-gear";
//const ABOUT_ICON = "fa fa-code";
const HELP_ICON = "fa fa-question-circle-o";
//const COMMUNITY_ICON = "fa fa-users";
//const SUPPORT_ICON = "fa fa-trophy";
const NOTE_ICON = "fa fa-pencil-square-o";
const SOUND_ICON = "fa fa-music";
const SIGN_OUT_ICON = 'fa fa-sign-out';

const Navbar = () => {
    const navigate = useNavigate();

    const { PAGE_HOME, PAGE_HELP, PAGE_SETTING, PAGE_NOTE, PAGE_SOUND, setPage } = useContext(PagesContext);

    const onPPOut = () => {
        setPage(PAGE_HOME);
        socket.disconnect();
        Ting.play(); 
        navigate('/');
    }
 
    return(
        <nav className="navbar navbar-expand-lg justify-content-center " style={{fontSize: "16px", background: "white", fontWeight: "bold", padding: "0px"}}>
            <ul className="navbar-nav">
                {NavbarItem("หน้าหลัก", PAGE_HOME, HOME_ICON)}
                {NavbarItem("เขียนโน้ต", PAGE_NOTE, NOTE_ICON)}
                {NavbarItem("ตั้งค่า", PAGE_SETTING, SETTING_ICON)}
                {NavbarItem("เสียง", PAGE_SOUND, SOUND_ICON)}
                {NavbarItem("คู่มือ", PAGE_HELP, HELP_ICON)}
                {/*NavbarItem("เกี่ยวกับ", PAGE_ABOUT, ABOUT_ICON)*/}
                <li className="nav-item" style={{ padding:"10px" ,width: "160px", textAlign: "center", background: '#cf1322'}}>
                    <div style={{color: "white", cursor : 'pointer'}}  onClick={onPPOut}>
                        <i className={`${SIGN_OUT_ICON} fa-lg`} style={{paddingRight: "10px"}}/>ออก
                    </div>
                </li>
            </ul>
        </nav>
    )
}

const NavbarItem = (name, linkTo, icon) => {
    const {page, setPage} = useContext(PagesContext);
    return (
        <li className="nav-item" style={{ padding:"10px" ,width: "160px", textAlign: "center", background: (page === linkTo) ? "#2f54eb" : "white"}}>
            <div style={{color: (page === linkTo) ? "white" : "#096dd9", cursor : 'pointer'}}  onClick={() => {setPage(linkTo); Ting.play()}}>
                <i className={`${icon} fa-lg`} style={{paddingRight: "10px"}}/>
                {name}
            </div>
        </li>
    )
}

export default Navbar;