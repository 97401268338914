import { Modal } from "react-bootstrap";
import { chab } from "../../sounds/EffectSound";

export const announceModal = (showModal, setShowModal) => {
    return (
        <Modal show={showModal} size="xl" onHide={() => setShowModal(false)}>
            <Modal.Body>
                <div style={{fontWeight: 'bold'}}>สิ่งนี้คืออะไร ?</div> 
                แพลตฟอร์มที่จะสร้างเสียงขลุ่ยตามโน้ตที่เราพิมพ์ <br /><br />

                <div style={{fontWeight: 'bold'}}>วิธีใช้</div> 
                1. ปรับจำนวนบรรทัดตามต้องการ<br />
                2. ปรับความเร็วในหน่วย bpm (สามารถปรับระหว่างเล่นได้)<br />
                3. พิมพ์โน้ตตามช่อง รองรับ ด ร ม ถึง ลํ [ไม่รองรับเสียงต่ำ] <br />
                4. กด 'เป่า' ได้เยย<br /><br />
                

                <div style={{fontWeight: 'bold'}}>แป้นพิมพ์ลัด (ใช้ได้บางอุปกรณ์เท่านั้น)</div> 
                1. กด ว เพื่อพิมพ์ ซ แทนได้ <br />
                2. กด  ั หรือ + เพื่อพิมพ์  ํ แทนได้ <br />
                3. กด Tab เพื่อพิมพ์ --- <br />
                4. กด Enter เพื่อพิมพ์ ---- <br />
                5. กดปุ่มลูกศรซ้าย ขวา บน ล่าง เพื่อเลื่อนเคอเซอร์ไปตามที่ต้องการ <br />
                6. กด 1-9 เพื่อพิมพ์ ด - รํ แทนได้<br />
                7. กด - เพื่อทำการหยุดเสียง<br /><br />

            </Modal.Body>
            <Modal.Footer>
                <div className="btn btn-primary" onClick={() => {setShowModal(false); chab.play()}}>รับแซ่บ</div>
            </Modal.Footer>
        </Modal>
    )
}

export const fileNameModal = (showModal, setShowModal, setFileName, onDownloadClick) => {
    return (
        <Modal show={showModal} size="sm" onHide={() => setShowModal(false)}>
            <Modal.Body >
                <form>
                    <label>ชื่อไฟล์</label>
                    <input type="text" id="soundpanel-input-filename" className="form-control" onChange={e => setFileName(e.target.value)}></input>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <div className="btn btn-outline-danger" onClick={() => setShowModal(false)}>ยกเลิก</div>
                <div className="btn btn-primary" onClick={onDownloadClick}>ดาวน์โหลด</div>
            </Modal.Footer>
        </Modal>
    )
}