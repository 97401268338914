import React, { createContext, useEffect, useMemo, useReducer, useRef } from "react";

const initialState = {
    focusRoom : 0,
    mLineNum : 4,
    mSpeed : 60,
    isPlaying : false,
}

export const FOCUS = {
    LEFT : 0,
    RIGHT : 1,
    UP : 2,
    DOWN : 3,
}

export const searchString = (str, s) => {
    let c = 0;
    for (let i = 0; i < str.length; i++) {
        if (str.charAt(i) === s) c++
    }
    return c;
}

export const isCharOver = (val) => {
    const charCount = val.length -  searchString(val, 'ํ');
    return charCount >= 4;
}

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_ROOM":
            return {
                ...state,
                focusRoom : action.payload,
            }
        case "SET_FOCUS" :
            let n = state.focusRoom;
            let mLineNum = state.mLineNum;
            switch (action.payload) {
                case FOCUS.LEFT:
                    return { ...state, focusRoom : n === 0 ? mLineNum * 8 - 1 : n - 1 }
                case FOCUS.RIGHT:
                    return { ...state, focusRoom : n < mLineNum * 8 - 1 ? n + 1 : 0 }
                case FOCUS.UP:
                    return { ...state, focusRoom : n < 8 ? n : n - 8 }
                case FOCUS.DOWN:
                    return { ...state, focusRoom : n < mLineNum * 8 - 8 ? n + 8 : n }
                default:
                    return { ...state }
            }
        case "SET_LINENUM":
            return {
                ...state,
                mLineNum : action.payload,
            }
        case "SET_SPEED":
            return {
                ...state,
                mSpeed : action.payload,
            }
        case "SET_PLAYING":
            return {
                ...state,
                isPlaying : action.payload,
            }
        default:
            break;
    }
}

export const NoteContext = createContext({});

export const NoteProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { focusRoom, mLineNum, mSpeed, isPlaying } = state;

    const setFocusRoom = payload => dispatch({type : 'SET_ROOM', payload});
    const setMLineNum = payload => dispatch({type : 'SET_LINENUM', payload});
    const setMSpeed = payload => dispatch({type : 'SET_SPEED', payload});
    const setPlaying = payload => dispatch({type : 'SET_PLAYING', payload});
    const setFocus = payload => dispatch({type : 'SET_FOCUS', payload});

    const noteRef = useRef([]);
    const tickNum = useRef(0);
    
    
    const addNextNote = (room, val, lineNum) => {
        const lastRoom = lineNum * 8;
        const lastChar = noteRef.current[room] ? noteRef.current[room].value.charAt(noteRef.current[room].value.length - 1) : '';

        const searchString = (str, s) => {
            let c = 0;
            for (let i = 0; i < str.length; i++) {
                if (str.charAt(i) === s) c++
            }
            return c;
        }
        
        const isCharOver = (val) => {
            const charCount = val.length -  searchString(val, 'ํ');
            return charCount >= 4;
        }

        if (val === 'ํ') {
            if (lastChar !== 'ํ' && lastChar !== '/' && lastChar !== '-') {
                noteRef.current[room].value = noteRef.current[room].value + val;
            }
        }
        else {
            let i = 0;
            while (isCharOver(noteRef.current[room + i].value) && room + i < lastRoom ) {
                i++;
            }
            if (room + i < lastRoom) {
                noteRef.current[room + i].value = noteRef.current[room + i].value + val;
                dispatch({type : 'SET_ROOM', payload : room + i});
            }
            else {
                dispatch({type : 'SET_ROOM', payload : 0});
            }
        }
    }
    
    useEffect(() => {
        return () => {
            setFocusRoom(null);
            setMLineNum(4);
            setMSpeed(60);
            setPlaying(false);
        }
    }, []);

    const value = useMemo(() => ({
        focusRoom, setFocusRoom, setFocus,
        mLineNum, setMLineNum,
        mSpeed, setMSpeed,
        isPlaying, setPlaying,
        noteRef, addNextNote, 
        tickNum,
    }), [focusRoom, mLineNum, mSpeed, isPlaying, noteRef, tickNum])


    return (
        <NoteContext.Provider value={value}>
            {children}
        </NoteContext.Provider>
    )
}