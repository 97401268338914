import { auth, firestore } from "../firebase";


export const getUserNameByEmail = async (email) => {
    return new Promise( async (resolve, reject) => {
        const snapshot = await firestore.collection('users').where('email', '==', email).get().catch(e => reject(e))
        if (snapshot) {
            snapshot.forEach(doc => {
                if (doc.exists && doc.data().userName) {
                    resolve(doc.data().userName)
                }
            })
        }
        resolve(false)
    })
}

export const getUserNameByID = async (id) => {
    return new Promise( async (resolve, reject) => {
        const doc = await firestore.collection('users').doc(id).get().catch(e => reject(e))
        if (doc.exists && doc.data().userName) {
            resolve(doc.data().userName)
        }
        else {
            reject('NO USERNAME FOUND')
        }
    })
}

export const getUserDataByEmail = async (email) => {
    return new Promise( async (resolve, reject) => {
        const snapshot = await firestore.collection('users').where('email', '==', email).get().catch(e => reject(e))
        if (snapshot) {
            snapshot.forEach(doc => {
                if (doc.exists && doc.data().userName) {
                    resolve(doc.data())
                }
            })
        }
        resolve(false)
    })
}

export const getUserIDByCurrentEmail = () => {
    return new Promise(async (resolve, reject) => {
        if (auth.currentUser && auth.currentUser.email) {
            const snapshot = await firestore.collection('users').where('email', '==', auth.currentUser.email).get().catch(e => reject(e))
            if (snapshot) {
                snapshot.forEach(doc => {
                    if (doc.exists) {
                        resolve(doc.id)
                    }
                })
            }
            reject('USER NOT FOUND')
        }
        reject('NOT LOGIN')
    })
}

export const checkUserName = async (name) => {
    const snapshot = await firestore.collection('users').where('userName', '==', name).where('email', '!=', auth.currentUser.email).get();
    return snapshot.empty;
}