import { Document, Paragraph, AlignmentType, Table, TableRow, TableCell, WidthType } from "docx";

const docx = (line, title, getRoom) => {
    const tCell = (l, r) => {
        return new TableCell({
            width: {
                size: 12.5,
                type: WidthType.PERCENTAGE,
            },
            children: [new Paragraph({
                text: getRoom((l - 1) * 8 + r),
                style: 'normalParagraph',
                alignment: AlignmentType.CENTER,
            })],
        })
    }

    const tRow = (l) => {
        let children = [];
        for (let r = 1; r <= 8; r++) {
            children = [...children, tCell(l, r)];
        }
        return new TableRow({ children });
    }

    const noteTable = () => {
        let rows = [];
        for (let r = 1; r <= line; r++) {
            rows = [...rows, tRow(r)]
        }
        return new Table({ rows })
    }

    const doc = new Document({
        styles: {
            paragraphStyles: [
                {
                    id: 'normalParagraph',
                    name: 'normalParagraph',
                    basedOn: "Normal",
                    next: "Normal",
                    run: {
                        font : "TH Sarabun New",
                        size : 32,
                    }
                },
                {
                    id: 'headerParagraph',
                    name: 'headerParagraph',
                    basedOn: "Normal",
                    next: "Normal",
                    run: {
                        font : "TH Sarabun New",
                        size : 40,
                        bold : true
                    },
                }
            ]
        },
        sections: [{
            properties: {},
            children: [
                new Paragraph({
                    text: (title || 'โน้ตเพลง') + '\n',
                    alignment: AlignmentType.CENTER,
                    style: 'headerParagraph',
                }),
                new Paragraph({
                    text: '\n',
                    alignment: AlignmentType.CENTER,
                    style: 'normalParagraph',
                }),
                noteTable(),
            ],
        }]
    });

    return doc;
}

export default docx;