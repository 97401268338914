export const FREQ = {
    MIN : 15,
    MAX : 180,
    DEFAULT : 60,
}

export const initialModalData = {
    addSheetModal : {
        show : false,
        sheetName : '',
        sheetContent : '',
        sheetType : '',
    },
    addTonModal : {
        show : false,
        tName : '',
        tTang : 0,
        tLineNum : 4,
        tRound : 2,
    },
    editSheetModal : {
        show : false,
    },
    editTonModal : {
        show : false,
        tId : 1648788237709,
        tName : '',
        tTang : 0,
        tLineNum : 4,
    },
    fixedSpeedModal : {
        show : false,
        isFixed : true,
        freq : FREQ.DEFAULT,
    },
    addRuleModal : {
        show : false,
        tId : 0,
        tRound : 0,
        from : 0,
        to : 0,
        rule : 0,
    },
    orderTonModal : {
        show : false,
        tItems : [],
    },
    chanModal : {
        show : false,
    },
    natabModal : {
        show : false,
    },
    editRuleModal : {
        show : false,
    },
    downloadModal : {
        show : false,
    },
    shareModal : {
        show : false,
    },
}

export const [TANG_KONG, TANG_KHLUI, TANG_SOA_U] = [...Array(3).keys()]

export const TANG_OPTIONS = {
    [TANG_KONG] : 'ฆ้อง',
    [TANG_KHLUI] : 'ขลุ่ย',
    //[TANG_SOA_U] : 'ซออู้'
}

export const [LEFT, RIGHT, UP, DOWN] = [...Array(4).keys()]

export const [FOCUS_NONE, FOCUS_NOTE, FOCUS_COMMENT, ] = [...Array(3).keys()]


export const NoteTypingKey = {
    'ด' : 'c',
    'ร' : 'd',
    'ม' : 'e',
    'ฟ' : 'f',
    'ซ' : 'g',
    'ล' : 'h',
    'ท' : 'i',
    '1' : 'c',
    '2' : 'd',
    '3' : 'e',
    '4' : 'f',
    '5' : 'g',
    '6' : 'h',
    '7' : 'i',
    '8' : 'j',
    '9' : 'k',
    'ว' : 'g',
    '-' : '-',
    'Space' : '-',
    'ข' : '-',
    'ช' : 'g',
    '/' : '/',
    '?' : '/',
    'ฝ' : '/',
    'ฦ' : '/',
    '๑' : '/',
}

export const OctaveTypingKey = {
    '+' : 'ํ',
    '*' : 'ํ',
    'ํ' : 'ํ',
    'ั' : 'ํ',
    'ฺ' : 'ฺ',
    '.' : 'ฺ',
    'ิ' : 'ฺ',
    'ุ' : 'ฺ',
}

export const ControlNote = {
    [TANG_KONG] : ['มฺ', 'ฟฺ', 'ซฺ', 'ลฺ', 'ทฺ', 'ด', 'ร', 'ม', 'ฟ', 'ซ', 'ล', 'ท', 'ดํ', 'รํ', 'มํ', 'ฟํ'],
    [TANG_KHLUI] : ['ด', 'ร', 'ม', 'ฟ', 'ซ', 'ล', 'ท', 'ดํ', 'รํ', 'มํ', 'ฟํ', 'ซํ', 'ลํ'],
}

export const ControlNote2 = {
    [TANG_KONG] : ['x', 'y', 'z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm'],
    [TANG_KHLUI] : ['c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o'],
    ALL_NOTE : ['v', 'w', 'x', 'y', 'z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'o', 'p'],
    NO_OCTAVE_NOTE : ['c', 'd', 'e', 'f', 'g', 'h', 'i'],
    LOW_OCTAVE_NOTE : ['v', 'w', 'x', 'y', 'z', 'a', 'b'],
    HIGH_OCTAVE_NOTE : ['j', 'k', 'l', 'm', 'n', 'o', 'p'],
}

export const ThaiToSakolNote = {
    'ดฺ' : 'v',
    'รฺ' : 'w',
    'มฺ' : 'x',
    'ฟฺ' : 'y',
    'ซฺ' : 'z',
    'ลฺ' : 'a',
    'ทฺ' : 'b',

    'ด' : 'c',
    'ร' : 'd',
    'ม' : 'e',
    'ฟ' : 'f',
    'ซ' : 'g',
    'ล' : 'h',
    'ท' : 'i',

    'ดํ' : 'j',
    'รํ' : 'k',
    'มํ' : 'l',
    'ฟํ' : 'm',
    'ซํ' : 'n',
    'ลํ' : 'o',
    'ทํ' : 'p',
}

export const NatabName = {
    NO : '-',
    L2 : 'ลาว',
    PK3 : 'ปรบไก่ 3 ชั้น',
    PK2 : 'ปรบไก่ 2 ชั้น',
    PK1 : 'ปรบไก่ ชั้นเดียว',
    SM3 : 'สองไม้ 3 ชั้น',
    SM2 : 'สองไม้ 2 ชั้น',
    SM1 : 'สองไม้ ชั้นเดียว',
    KM3 : 'เขมร 3 ชั้น',
    KM2 : 'เขมร 2 ชั้น',
    KM1 : 'เขมร ชั้นเดียว',
    LM : 'ลูกหมด',
    FR : 'ฝรั่ง',
}

export const RuleOptions = {
    0 : 'ไม่เล่น, ข้าม',
    1 : 'เล่นแต่เสียงจังหวะ',
    2 : 'เล่น 2 รอบ',
    3 : 'เล่น 3 รอบ',
    4 : 'เล่น 4 รอบ',
}

export const ShareOptions = {
    0 : 'ส่วนตัว - ผู้อื่นดูไม่ได้',
    1 : 'แบ่งปัน - ทุกคนที่มีลิงก์',
    2 : 'สาธารณะ - ทุกคนดูได้',
}

export const [NO_RIGHT, CAN_VIEW, CAN_EDIT] = [...Array(3).keys()]

export const SakolToThaiNote = Object.fromEntries(Object.entries(ThaiToSakolNote).map(([k, v]) => [v, k]))