import { database } from "./firebase";

const SendLogData = async (pageType) => {
    if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
        const dateString = `${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear() + 543}`
        const dbRef = database.ref(`${pageType}/${dateString}`)
        const snapshot = await dbRef.get();
        const val = snapshot.val();
        if (!isNaN(val)) {
            dbRef.transaction(n => n + 1);
        }
        else {
            dbRef.set(1);
        }
    }
}

export default SendLogData;