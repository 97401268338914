import React, { useContext } from 'react';
import { PagesContext } from '../../../providers/PagesProvider';
import Navbar from '../../layout/Navbar';
import About from '../pages/About';
import Help from '../pages/Help';
import RoomLarge from './RoomLarge';
import Settings from '../pages/Settings';
import Note from '../pages/Note';
import SoundRecord from '../pages/SoundRecord';



const RoomComputer = () => {

    const containerStyle = {
        overflowY: "scroll", 
        borderRadius: "20px", 
        height: `${0.75 * window.screen.height}px`,
        background: "#f5f5f5",
    }

    const {page, PAGE_HOME, PAGE_SETTING, PAGE_HELP, PAGE_ABOUT, PAGE_NOTE, PAGE_SOUND} = useContext(PagesContext);

    return (
        <div>
            <Navbar />
            <div className="container overflow-auto" hidden={page !== PAGE_HOME} style={{...containerStyle, maxWidth: "1200px"}}>
                <div hidden={page !== PAGE_HOME}> <RoomLarge /> </div>
            </div>
            <div className="container overflow-auto" hidden={page === PAGE_HOME} style={{...containerStyle, maxWidth: "800px"}}>
                <div hidden={page !== PAGE_NOTE}> <Note /> </div>
                <div hidden={page !== PAGE_SETTING}> <Settings /> </div>
                <div hidden={page !== PAGE_SOUND}> <SoundRecord /> </div>
                <div hidden={page !== PAGE_HELP}> <Help /> </div>
                <div hidden={page !== PAGE_ABOUT}> <About /> </div>
            </div>
        </div>
    )
}

export default RoomComputer;