import { createContext, useReducer } from "react";




const initialState = {
    unit: 'spr',
}

const reducer = (state, action) => {
    switch(action.type) {
        case 'SET_SPR':
            return {unit : 'spr'}
        case 'SET_HZ':
            return {unit: 'hz'}
        case 'SET_BPM':
            return {unit: 'bpm'}
        case 'SET_UNIT':
            return {unit: action.payload}
        default:
            break;
    }
}

export const UnitContext = createContext({});

export const UnitProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { unit } = state;
    const setUnit = payload => dispatch({type: 'SET_UNIT', payload});
    const getValueAndUnit = (sp) => {
        switch(unit) {
            case 'spr':
                return `${(sp/1000).toFixed(2)} วินาทีต่อห้อง`
            case 'hz':
                return `${(1000/sp).toFixed(2)} เฮิรตซ์ (Hz)`
            case 'spl':
                return `${(sp * 8/1000).toFixed(1)} วินาทีต่อบรรทัด`
            case 'bpm':
                return `${(1000/sp * 60).toFixed(1)} bpm`
            default:
                break;
        }
    }
    return (
        <UnitContext.Provider value={{unit, setUnit, getValueAndUnit}}>
            {children}
        </UnitContext.Provider>
    )
}