import { isCharOver, FOCUS } from "./NoteContext";

const NoteChange = {
    '1' : 'ด',
    '2' : 'ร',
    '3' : 'ม',
    '4' : 'ฟ',
    '5' : 'ซ',
    '6' : 'ล',
    '7' : 'ท',
    '8' : 'ดํ',
    '9' : 'รํ',
    'ว' : 'ซ',
    '+' : 'ํ',
    '*' : 'ํ',
    'ั' : 'ํ',
    'ฺ' : 'ํ',
    '.' : '-',
    'Space' : '-',
    'Shift' : '-',
    'ข' : '-',
    'ช' : 'ซ',
}

const KeyboardEvent = (e, noteRef, addNextNote, mLineNum, focusRoom, setFocus, setFocusRoom ) => {
    const addNote = (room, val) => noteRef.current[room].value = noteRef.current[room].value + val;
    let currentRoom = noteRef.current[focusRoom]
    switch(e.key) {
        case 'ArrowRight' :
            setFocus(FOCUS.RIGHT)
            break;
        case 'ArrowLeft':
            setFocus(FOCUS.LEFT)
            break;
        case 'ArrowUp':
            setFocus(FOCUS.UP)
            break;
        case 'ArrowDown':
            setFocus(FOCUS.DOWN)
            break;
        case "Tab":
            e.preventDefault();
            if (isCharOver(noteRef.current[focusRoom].value)) {
                if (focusRoom < mLineNum * 8 - 1) {
                    addNote(focusRoom + 1, '---');
                    setFocus(FOCUS.RIGHT);
                }
                else {
                    setFocusRoom(0); 
                }
            }
            else if (currentRoom.value.length > 1) {
                noteRef.current[focusRoom].value = '----'
                if (focusRoom < mLineNum * 8 - 1) setFocus(FOCUS.RIGHT);
            }
            else {
                addNote(focusRoom, '---');
            }
            break;
        case "Backspace": case "Delete":
            e.preventDefault();
            let val = noteRef.current[focusRoom];
            if (val.value) {
                val.value = val.value.slice(0, val.value.length - 1);
            }
            else if (focusRoom !== 0) {
                noteRef.current[focusRoom - 1].value = '';
                setFocus(FOCUS.LEFT);
    
            }
            break;
        case "Enter":
            if (currentRoom.value === '') {
                noteRef.current[focusRoom].value = '----';
            }
            else if (focusRoom < mLineNum * 8 - 1) {
                noteRef.current[focusRoom + 1].value = '----';
                setFocus(FOCUS.RIGHT);
            }
            break;
        case 'ด': case 'ร': case 'ม': case 'ฟ': case 'ซ': case 'ว': case 'ล': case 'ท': 
        case "1": case "2": case "3": case "4": case "5": case "6": case "7": case "8": case "9":
        case 'ํ': case "+": case "ั": case '*': case '.': case 'ฺ': case '-': case 'ข': case 'ช':
        case 'Space': case 'Shift': case '/':
            let k = NoteChange[e.key] || e.key;
            addNextNote(focusRoom, k, mLineNum);
            break;
        default:
            break;
    }
}

export default KeyboardEvent;