import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { NoteContext } from "../../providers/NoteProvider";
import { SettingsContext } from "../../providers/SettingsProvider";

//const normalRoomClass = "each-room1 text-center align-top";
//const redRoomClass = "each-room0 text-center align-top";

/*const EachLine = (props) => {
    const { line } = useContext(SettingsContext);
    const { getRoom } = useContext(NoteContext);
    return [1, 2, 3, 4, 5, 6, 7, 8].map((v, i) => (
        <div key={i} className={props.cn || normalRoomClass} id={`room${(props.lineNumber || 0) * 8 + v}`} hidden={props.lineNumber === line + 1 && v >= 5} >
            {props.lineNumber === 0 || props.lineNumber >= line + 1 ? <div /> : getRoom((props.lineNumber - 1) * 8 + v)}
        </div>
    ))
} */

const EachLine1 = props => {
    const { lineNumber } = props;
    //const cn = lineNumber === 0 ? redRoomClass : normalRoomClass;
    const getClassName = room => room % 8 === 1 ? 'note-col-div note-0' : room % 8 === 5 ? 'note-col-div note-4' : 'note-col-div';
    const { line } = useContext(SettingsContext);
    const { getRoom } = useContext(NoteContext);
    return [1, 2, 3, 4].map((v, i) => (
        <Col key={i} style={{paddingInline : '0'}}>
            <div className={getClassName(v)} id={`room${(lineNumber || 0) * 8 + v}`} hidden={lineNumber === line + 1 && v >= 5} >
                {lineNumber === 0 || lineNumber >= line + 1 ? <div /> : getRoom((props.lineNumber - 1) * 8 + v)}
            </div>
        </Col>
        
    ))
}

const EachLine2 = props => {
    const { lineNumber } = props;
    //const cn = lineNumber === 0 ? redRoomClass : normalRoomClass;
    const getClassName = room => room === 5 ? 'note-col-div note-4' : 'note-col-div';
    const { line } = useContext(SettingsContext);
    const { getRoom } = useContext(NoteContext);
    return [5, 6, 7, 8].map((v, i) => (
        <Col key={i} style={{paddingInline : '0'}}>
            <div className={getClassName(v)} id={`room${(lineNumber || 0) * 8 + v}`} hidden={lineNumber === line + 1 && v >= 5} >
                {lineNumber === 0 || lineNumber >= line + 1 ? <div /> : getRoom((props.lineNumber - 1) * 8 + v)}
            </div>
        </Col>
        
    ))
}

const ShowAllLine = () => {
    const { line } = useContext(SettingsContext);
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((l, i) => (
        <div key={i} hidden={l >= line + 2}>
            {/*<div className="col-auto" style={{width: "50px", textAlign: 'center'}}>
                {l === 0 ? 'นำ' : l > line ? 'ถอน' : l}
            </div>
            <div className="col">
                <EachLine lineNumber={l} cn={l === 0 ? redRoomClass : normalRoomClass}/>
            </div>*/}
            <div style={{paddingLeft : '5px'}}>{l === 0 ? ' นำ' : l > line ? ' ถอน' : l}</div>
            <Row >
                <Col sm='6' xs='12'>
                    <Row><EachLine1 lineNumber={l} /></Row>
                </Col>
                <Col sm='6' xs='12'>
                    <Row><EachLine2 lineNumber={l} /></Row>
                </Col>
            </Row>
        </div>
    ))
}

const NoteLayout = (props) => {
    return (
        <div /*className="container-fluid overflow-auto" style={{overflowY: "scroll", height: props.h ? `${props.h}px` : "390px", overflowX: "hidden"}}*/>
            <ShowAllLine />
            <br />
        </div>
    )
}
export default NoteLayout;





/*const NoteLayout = (props) => {
    const {line} = useContext(SettingsContext);
    const { getRoom } = useContext(NoteContext);
    

    const showLine = (lineNumber, roomType) => {
        let a = [];
        for (let i = 1; i <= lineNumber * 2; i++) a = [...a, i];
        return a.map((v, i) => (
            <div className="row" key={i}>
                <div className="col-auto" style={{width: "30px"}}>
                    {i + 1}
                </div>
                <div className="col">
                {eachLine(true, v, v <= lineNumber ? 0 : 1, roomType)}
                </div>
                
            </div>
        ))
    }

    const showLine2 = (lineNumber) => {
        let a = [];
        for (let i = 1; i <= lineNumber; i++) a = [...a, i];
        return a.map((v, i) => (
            <div className="row" key={i}>
                <div className="col-auto" style={{width: "30px"}}>
                    {i + 1}
                </div>
                <div className="col">
                    {eachLine2(v)}
                </div>
            </div>
        ))
    }

    const eachLine2 = (lineNumber) => {
        const a = [1, 2, 3, 4, 5, 6, 7, 8];
        return a.map((v, i) => (
            <div key={i} className="each-room1 text-center align-top" id={`room${lineNumber * 8 + v}`}>
                {getRoom((lineNumber - 1) * 8 + v)}
            </div>
        ))
    }

    
    const eachLine = (isFullLine, lineNumber, lineType, roomType) => {
        const cn = eachRoomClassName(lineType, roomType);
        const vs = (v) => ((v <= 4 && !isFullLine && lineType === 0 ) || (v > 4 && !isFullLine && lineType === 1))? "hidden" : "visible";
        const a = [1, 2, 3, 4, 5, 6, 7, 8];
        return a.map((v, i) => (
            <div key={i} className={cn} id={`room${lineNumber * 8 + v}`} style={{visibility: vs(v)}} >
                {isFullLine ? ( lineType === 1 ? getRoom((lineNumber - line - 1) * 8 + v) : getRoom((lineNumber - 1) * 8 + v) ) : ""}
            </div>
        ))
    }

    return (
        <div className="container-fluid overflow-auto" style={{overflowY: "scroll", height: props.h ? `${props.h}px` : "390px", overflowX: "hidden"}}>  
            <div className="row">
                <div className="col-auto" style={{width: "30px"}}>x</div>
                <div className="col">
                    {eachLine(true, 0, 2, props.roomSize)}
                </div>
            </div>
            
            {showLine(line, props.roomSize)}
            
            
            <div className="row">
                <div className="col-auto" style={{width: "30px"}}>x</div>
                <div className="col">
                    {eachLine(false, line + 1, 0, props.roomSize)}  
                </div>
            </div>  
            <br />  
        </div>
    )
}

const eachRoomClassName = (lineType, roomType) => {
    switch (roomType * 2 + lineType) {
        case 0:
            return "each-room-small1";
        case 1:
            return "each-room-small2";
        case 2:
            return "each-room1 text-center align-top";
        case 3:
            return "each-room2 text-center align-top";
        case 4:
            return "each-room0 text-center align-top";
        default:
            return "";
    }
}*/



