import { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { PlayingContext } from "../../../providers/PlayingProvider";
import { SettingsContext } from "../../../providers/SettingsProvider";
import { UnitContext } from "../../../providers/UnitProvider";
import { ICON_PP_HAND } from "../../icons";
import MicButton from "../../layout/MicButton";
import Moving from "../../layout/Moving";
import NoteLayout from "../../layout/NoteLayout";
import RoundContainer from "../../layout/RoundContainer";
import ListChat from "./ListChat";

const MainRoom = props => {
    const { mDisplaySiteBar, setMDisplaySiteBar } = props;
    const [title, setTitle] = useState('');
    const { getValueAndUnit } = useContext(UnitContext);
    const {speed, rate} = useContext(SettingsContext);
    const { isReady, setReady, isPlaying } = useContext(PlayingContext);

    useEffect(() => {
        return () => {
            setTitle('')
        }
    }, [])
    
    return (
        <>
        <div className="container" style={{maxWidth: "1200px"}}>
            <Row>
                <Col md='8' sm='12' style={{paddingInline : 0}}>
                    <RoundContainer res={true}>
                        <Row style={{paddingInline : '5px'}}>
                            <Col sm='12' md='8'><h3>{title}</h3></Col>
                            <Col sm='12' md='4'><div className="text-end">
                                <div>{rate !== 1 ? ` อัตราจังหวะ ${rate} ชั้น ` : 'อัตราจังหวะชั้นเดียว'}</div>
                                <div>{getValueAndUnit(speed)}</div>
                            </div></Col>
                        </Row>
                        <hr />
                        <NoteLayout h={window.screen.height * 0.45} />
                    </RoundContainer>
                </Col>
                <Col md='4'>
                    <div className="d-none d-md-block">
                        <ListChat mDisplaySiteBar={mDisplaySiteBar} setMDisplaySiteBar={setMDisplaySiteBar}/>
                    </div>
                </Col>
            </Row>
        </div>
        <br />
        <br />
        <br />
        <div className="d-sm-none"><br /><br /><br /></div>
        <div className="fixed-footer bg-white">
            <Row bsPrefix="row align-items-center justify-content-center" style={{padding: '10px'}}>
                <Col md='1' sm='2' xs='3'><MicButton /></Col>
                <Col md='1' sm='2' xs='3'>
                    <Button 
                            onClick={() => setReady(!isReady)} disabled={isPlaying} 
                            title="กดเพื่อบอกคนอื่นว่าเราพร้อมแล้ว หรือไม่พร้อม"
                            variant={isReady ? 'round-success' : 'round-danger'}
                        >
                            {ICON_PP_HAND}
                        </Button></Col>
                <Col sm='6' xs='6'><Moving /></Col>
                
            </Row>
        </div>    
            
        </>
    )
}

export default MainRoom;