import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './css/App.css';
import './css/Note.css';
import './css/Slider.css';
import './css/Note2.css';
import './css/RoundContainer.css';
import './css/tablet.css';
import './css/Dropdown.css'
import Home from './components/Home/Home';
import RhythmOnline from './components/RhythmOnline/RhythmOnline';
import FluteOnline from './components/FluteOnline/FluteOnline';
import SoundSoftware from './components/SoundSoftware/SoundSoftware';
import SoundSoftwareOuter from './components/SoundSoftwareOuter/SoundSoftwareOuter';
//import RoomTablet from './components/pp/tabletPages/RoomTablet';
//import RoomComputer from './components/pp/computerPages/RoomComputer';
import MainPP from './components/pp/pages/MainPP';
import About from './components/Home/About';
import SignInPage from './components/Home/SignIn';
import UserPage from './components/Home/UserPage';
import { SheetProvider } from './providers/SheetContext';
import { KeyPressProvider } from './providers/KeyPressContext';
import { SheetPlayingProvider } from './providers/SheetPlayingContext';
//import Test from './components/Test'

function App() {
  document.body.style.backgroundColor = "#f5f5f5";

  return (
    <div className='global-margin-top'>
      <BrowserRouter>
        <KeyPressProvider>
        <Routes>
          <Route exact path='/' element={<Home />}/>
          <Route exact path='/pp' element={<MainPP />} />
          <Route exact path='/promchangwa' element={<RhythmOnline />}/>
          <Route exact path='/prompao' element={<FluteOnline />}/>
          <Route exact path='/software' element={<SoundSoftwareOuter />}/>
          <Route exact path='/ss' element={<SheetProvider><SheetPlayingProvider><SoundSoftware /></SheetPlayingProvider></SheetProvider>}/>
          <Route exact path='/about' element={<About />}/>
          <Route exact path='/signin' element={<SignInPage />}/>
          <Route exact path='/userdata' element={<UserPage />}/>
          {/*<Route exact path='/test' component={Test}/>*/}
        </Routes>
        </KeyPressProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
