import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/firestore'


const firebaseConfig = {
    apiKey: "AIzaSyC0hhYQJCXGXkAvw9GREPDm5A3TjhY7Kd4",
    authDomain: "thaimusicband.firebaseapp.com",
    projectId: "thaimusicband",
    storageBucket: "thaimusicband.appspot.com",
    messagingSenderId: "6661628800",
    appId: "1:6661628800:web:e621ce946073f6af465765",
    databaseURL: "https://thaimusicband-default-rtdb.asia-southeast1.firebasedatabase.app/",
    measurementId: "G-0BK4QS3PND"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const auth = firebaseApp.auth();
const firestore = firebaseApp.firestore();
const database = firebaseApp.database();
const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();
const twitterProvider = new firebase.auth.TwitterAuthProvider();
const microsoftProvider = new firebase.auth.OAuthProvider('microsoft.com');

export { firebase, auth, firestore, database, googleProvider, facebookProvider, twitterProvider, microsoftProvider };