import { useContext, useEffect, useState } from "react";
import { Button, Form} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SelectForm, TextForm } from "../layout/FormItem";
import RoundContainer from "../layout/RoundContainer";
import { auth, googleProvider, microsoftProvider } from "../../databases/firebase";
import { AuthContext } from "../../providers/AuthContext";
import SuperHeader from "../layout/SuperHeader";
import addUser from "../../databases/users/addUser";
import { checkUserName, getUserNameByID } from "../../databases/users/getUserName";
import { userInitialState } from "../constant";
//import { onRegisterClick } from "./AuthFunction";


const positionOptions = {
    0 : '-',
    1 : 'นักเรียน',
    2 : 'นิสิต นักศึกษาทั่วไป',
    3 : 'นิสิต นักศึกษาที่กำลังศึกษาหรือฝึกสอนในสาขาดนตรี',
    4 : 'ครู อาจารย์ บุคลากรในสาขาดนตรี',
    5 : 'นักดนตรีอาชีพ',
    6 : 'ผู้สนใจทั่วไป',
    7 : 'ผู้ร่วมทดสอบระบบ',
}

const LOGIN_STATE = {
    NONE : 0,
    AUTH_READY : 1,
    FIRESTORE_READY : 2,
}

const SignInPage = () => {

    const navigate = useNavigate();
    const [userData, setUserData] = useState(userInitialState);
    const [accepted, setAccepted] = useState(false);
    const [loginState, setLoginState] = useState(LOGIN_STATE.NONE);
    const { isLogin, userName, resetUserData } = useContext(AuthContext);
    const [isNameOK, setNameOK] = useState(true);

    useEffect(() => {
        if (userName) {
            setLoginState(LOGIN_STATE.FIRESTORE_READY)
        }
        else if (isLogin) {
            setLoginState(LOGIN_STATE.AUTH_READY);
        }
        else {
            setLoginState(LOGIN_STATE.NONE);
        }
    }, [isLogin, userName]);

    useEffect(() => {
        return () => {
            setUserData({
                firstName : '',
                lastName : '',
                birthYear : '',
                location : '',
                position : 0,
                email : '',
                userName : '',
            });
            setAccepted(false);
            setLoginState(LOGIN_STATE.NONE)
            setNameOK(true);
        }
    }, []);

    const onTextFormChange = (key, value, isInt = false) => {
        setUserData(u => ({...u, [key] : !isInt ? value : parseInt(value)}))
    }

    const onUserNameChange = e => {
        setUserData(u => ({...u, userName : e.target.value}))
        checkUserName(e.target.value).then(isOK => {
            setNameOK(isOK); 
        })
    }

    const onSigninWithGoogleClick = async (provider) => {
        try {
            const result = await auth.signInWithPopup(provider);
            const user = result.user;
            console.log(user)
            if (user.displayName && user.displayName.split(' ').length > 1) {
                const [firstName, lastName] = user.displayName.split(' ');
                if (firstName) setUserData(u => ({...u, firstName}));
                if (lastName) setUserData(u => ({...u, lastName}));
            }
            setUserData(u => ({...u, email : user.email}));
            setLoginState(LOGIN_STATE.AUTH_READY);
        }
        catch (e) {
            console.log(e);
        }
    }

    const onSignOutClick = () => {
        auth.signOut().then(() => navigate('/'))
        
    }

    const onClick = () => {
        try {
            /*onRegisterClick(userData).then(() => {
                setRegisted(true);
                setUserData(userInitialState);
            })*/
            onFirstSignInClick(userData).then(id => {
                console.log(id);
                return getUserNameByID(id);
            })
            .then(userName => {
                resetUserData();
                //setUserName(userName);
                //console.log(userName)
                setLoginState(LOGIN_STATE.FIRESTORE_READY);
                setUserData(userInitialState);

            })
        }
        catch (e) {
            alert(e);
        }

    }

    return (
        <>
        <SuperHeader />
        <div className="container" style={{maxWidth : '1000px'}}>
            <div hidden={loginState !== LOGIN_STATE.NONE}>
                <RoundContainer>
                    <h5>เข้าสู่ระบบ</h5>
                    <br />
                    <Button variant="light" onClick={() => onSigninWithGoogleClick(googleProvider)}>เข้าสู่ระบบด้วยบัญชี Google/Gmail</Button>
                    <br />
                    <br />
                    <Button variant="light" onClick={() => onSigninWithGoogleClick(microsoftProvider)}>เข้าสู่ระบบด้วยบัญชี Microsoft</Button>
                    <br />
                </RoundContainer>
                <br />
            </div>
            <div hidden={loginState !== LOGIN_STATE.AUTH_READY}>
                <RoundContainer>
                    <h5>เข้าสู่ระบบครั้งแรก</h5>
                    <br />
                    <Form>
                        <TextForm title='อีเมล' value={userData.email} onChange={e => onTextFormChange('email', e.target.value)} />
                        <TextForm title='ชื่อจริง (ไม่ต้องมีคำนำหน้า)' value={userData.firstName} onChange={e => onTextFormChange('firstName', e.target.value)} />
                        <TextForm title='นามสกุล' value={userData.lastName} onChange={e => onTextFormChange('lastName', e.target.value)} />
                        <TextForm title='ชื่อเล่น/ชื่อเรียก/นามปากกา/ฉายา (ใช้แสดงบนเว็บ)' value={userData.userName} onChange={onUserNameChange} />
                        <div style={{fontSize : '13px'}} className="text-danger" hidden={isNameOK}>ชื่อ {userData.userName} มีการใช้งานแล้ว !</div>
                        <TextForm title='ปีเกิด (พ.ศ.)' value={userData.birthYear} onChange={e => onTextFormChange('birthYear', e.target.value)} />
                        <TextForm title='องค์กร (สถานศึกษา, วงดนตรีที่สังกัด, สถานที่ทำงาน)' value={userData.location} onChange={e => onTextFormChange('location', e.target.value)} />
                        <SelectForm title='สถานะ' value={userData.position} options={positionOptions} onChange={e => onTextFormChange('position', e.target.value, true)} />
                    </Form>
                    
                </RoundContainer>
                <br />
                <RoundContainer>
                    <div>
                        ทางผู้จัดทำขออนุญาตเก็บข้อมูลส่วนตัวเหล่านี้ ในการวิเคราะห์สถิติการเข้าเว็บ สถิติการใช้งานส่วนต่าง ๆ 
                        รวมถึงดูประสิทธิภาพการประมวลผลของเซิร์ฟเวอร์ ขอความร่วมมือผู้ใช้งานกรอกข้อมูลตามความเป็นจริง เพื่อเราจะได้ปรับปรุงต่อไป :)
                    </div>
                    <br />
                    <Form.Check onChange={e => setAccepted(e.target.checked)} id='register-accept-form' label={'ฉันเข้าใจแล้ว'}/>
                    <div className="text-center">
                        <Button variant='outline-danger' onClick={onSignOutClick}>กลับ</Button>
                        <span style={{paddingInline: '20px'}}></span>
                        <Button disabled={!accepted || !isNameOK} onClick={onClick}>เข้าสู่ระบบ</Button>
                    </div>
                </RoundContainer>
            </div>
            <div hidden={loginState !== LOGIN_STATE.FIRESTORE_READY}>
                <RoundContainer>
                    <h5>เข้าสู่ระบบเรียบร้อย!</h5>
                    <br />
                    <Button variant="primary" onClick={() => navigate('/')}>กลับหน้าหลัก</Button>
                </RoundContainer>
            </div>
            
        </div>
        </>

    )
}

const onFirstSignInClick = async (userData) => {
    return new Promise((resolve, reject) => {
        const { firstName, lastName, birthYear, location, position, email, userName } = userData;
        if (!(email || firstName || lastName || birthYear || location || position || userName)) reject('โปรดใส่ข้อมูลให้ครบ')
        if (isNaN(position)) reject('โปรดใส่สถานะ')
        resolve(userData);
    })
    .then(result => {
        return addUser(result)
    })
}

export default SignInPage;