import { Howl } from "howler";
import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { database } from "../../../databases/firebase";
import { MicContext } from "../../../providers/MicProvider";
import { PlayingContext } from "../../../providers/PlayingProvider";
import { SettingsContext } from "../../../providers/SettingsProvider";
import { UnitContext } from "../../../providers/UnitProvider";
import { UserContext } from "../../../providers/UserProvider";
import socket from "../../../server/socket-io";
import { playNaTab, getNaTabTime, setPlayingRhythm} from '../../constant';
import RoundContainer from "../../layout/RoundContainer";
import { ching, chab, C5, Tumm, Ting, Choo, Chaa, } from '../../../sounds/EffectSound';
import { useNavigate } from "react-router-dom";

let audioRec;
let testStream;

const Settings = () => {
    const {allVol, setAllVol, isPlaying} = useContext(PlayingContext);
    const { inputDevice, getMicStream } = useContext(MicContext);
    const [_chingVolume, _setChingVolume] = useState(0.25);
    const [_effectVolume, _setEffectVolume] = useState(0.5);
    const [_toneVolume, _setToneVolume] = useState(0.5);
    const { setUnit, getValueAndUnit } = useContext(UnitContext)
    const {adminName} = useContext(UserContext);
    const {line, speed, rate, mp3Mode, setLine, setSpeed, setRate, setNaTab, setMp3Mode} = useContext(SettingsContext);
    const [testSpeed, setTestSpeed] = useState(1000);
    const [testRate, setTestRate] = useState(2);
    const [testNaTab, setTestNaTab] = useState('-');
    const [isRunning, setRunning] = useState(false);
    const [int, setInt] = useState();
    const [intNaTab, setIntNaTab] = useState();

    const navigate = useNavigate()

    const [isAudioTesting, setAudioTesting] = useState(false);
    const {micEnable} = useContext(MicContext);
    const [ipOptions, setIpOptions] = useState([]);

    const setDisableClassName = () => {
        return isPlaying ? true
            : adminName !== socket.id ? true
            : false
    }

    const onChingVolumeChange = (e) => {
        _setChingVolume(e.target.value); 
        ching.volume(e.target.value); 
        chab.volume(e.target.value);
    }

    const onEffectVolumeChange = (e) => {
        _setEffectVolume(e.target.value);
        C5.volume(e.target.value);
    }

    const onToneVolumeChange = (e) => {
        _setToneVolume(e.target.value);
        Tumm.volume(e.target.value);
        Ting.volume(e.target.value);
        Choo.volume(e.target.value);
        Chaa.volume(e.target.value);
    }

    const handleClick = () => {
        if (!isRunning) {
            const chingInterval = testSpeed * Math.pow(2, testRate - 1);
            setRunning(true);
            setTimeout(() => ching.play(), chingInterval/2);
            setTimeout(() => {ching.stop(); chab.play();}, chingInterval);
            playNaTab(testNaTab, testSpeed);
            setInt(setInterval(() => {
                setTimeout(() => ching.play(), chingInterval/2);
                setTimeout(() => {ching.stop(); chab.play();}, chingInterval);
            }, chingInterval))
            setIntNaTab(setInterval(() => {
                if (testNaTab !== '-') playNaTab(testNaTab, testSpeed);
            }, getNaTabTime(testNaTab, testSpeed)));
        }
        else {
            setRunning(false);
            clearInterval(int);
            clearInterval(intNaTab);
        }
    }

    const startTestAudio = async e => {
        //setAudioTesting(!isAudioTesting);
        const btnTestAudio = document.getElementById('btn-test-audio-1');
        if (!isAudioTesting) {
            let chunks = [];
            btnTestAudio.disabled = true;
            getMicStream().then((st) => {
                testStream = st;
                audioRec = new MediaRecorder(st, {audioBitsPerSecond : 128000 })
                
            })
            .then(() => {
                audioRec.ondataavailable = function(e) {
                    chunks.push(e.data);
                }
                audioRec.onstop = async function(e) {
                    const blob = new Blob(chunks, { 'type' : 'audio/wav; codecs=opus' });

                    //console.log(await FileType.fromBlob(blob));

                    /*const floatTo16BitPCM = (arrayBuffer) => {
                        const ab = new Float32Array(arrayBuffer);
                        let output = new Int16Array(ab.length);
                        for (let i = 0; i < ab.length; i++) {
                          const s = Math.max(-1, Math.min(1, ab[i]));
                          output[i] = (s < 0 ? s * 0x8000 : s * 0x7FFF);
                        }
                        console.log(output);
                        return output;
                    }*/

                    /*let context = new AudioContext();
                    let source = context.createBufferSource();
                    context.decodeAudioData(await blob.arrayBuffer(), b => {
                        console.log(b);
                        source.buffer = b;
                        source.connect(context.destination);
                        console.log(source)
                    })*/
                    /*const decoder = new Lame({
                        'output': 'buffer',
                        "bitrate": 192,
                    }).setBuffer(floatTo16BitPCM(await blob.arrayBuffer()));
                    decoder.encode().then(async () => {
                        const buf = decoder.getBuffer();
                        console.log(await FileType.fromBuffer(buf))
                    })*/
                    const audioURL = window.URL.createObjectURL(blob);
                    const sound = new Howl({ src: [audioURL], format: ['wav'],})
                    sound.play();

                    

                    /*const playSound = async (ab) => {
                        console.log(ab);
                        const context = new AudioContext();
                        const source = context.createBufferSource();
                        console.log(source);
                        source.buffer = await context.decodeAudioData(ab);
                        source.connect(context.destination);
                        source.start(0);
                    }

                    let fileReader = new FileReader();

                    fileReader.onloadend = async () => {
                        await playSound(fileReader.result);
                    }
                    fileReader.readAsArrayBuffer(blob);*/
                    //setTimeout(() => console.log(arrayBuffer), 500);
                    //console.log(arrayBuffer);
                    /*const context = new AudioContext();
                    const source = context.createBufferSource();
                    source.buffer = await context.decodeAudioData(arrayBuffer);
                    source.connect(context.destination);
                    source.start(0);*/

                    
                    
                    
                    /*sound.once('end', () => {
                        const audioInput = document.getElementById('test-audio-1');
                        if (audioInput) audioInput.src = audioURL; 
                        //if (audioInput && outputID) audioInput.setSinkId(outputID); console.log(audioInput);
                    })*/
                }
            })
            .then(() => audioRec.start())
            .then(() => setAudioTesting(true))
            .catch(e => console.log(e))
            .finally(() => btnTestAudio.disabled = false)
            
        }
        else if (isAudioTesting && (audioRec ? audioRec.state === "recording" : false)) {
            audioRec.stop();
            setAudioTesting(false);
            if (!micEnable) await testStream.getTracks().forEach(track => {track.stop()});
        }
        
    }

    useEffect(() => {
        const btnTestAudio = document.getElementById('btn-test-audio-1');
        if (isAudioTesting) {
            btnTestAudio.className = "btn btn-danger";
            btnTestAudio.innerHTML = "หยุดอัดเสียง"
        }
        else {
            btnTestAudio.className = "btn btn-outline-success";
            btnTestAudio.innerHTML = "เริ่มอัดเสียง"
        }
    }, [isAudioTesting])

    const onTitleChange = e => {
        database.ref('databaseSystem').update({title: e.target.value})
    }

    useEffect(() => {
        document.getElementById('slider-test-speed').disabled = isRunning;
        document.getElementById('slider-test-rate').disabled = isRunning;
        const btnTestRunning = document.getElementById('btn-test-running');
        if (isRunning) {
            btnTestRunning.className = "btn btn-danger";
            btnTestRunning.innerHTML = "หยุด";
        }
        else {
            btnTestRunning.className = "btn btn-outline-success";
            btnTestRunning.innerHTML = "เริ่ม"
        }
    }, [isRunning])

    const inputRange = (title, inputID, step, min, max, def, changeHandle, showValue, isInt = false, disabled = false) => {
        return (
            <div>
                <div className="row text-center">
                    <div className="col-4" style={{textAlign: "right", paddingRight: "30px"}}>
                        {title}
                    </div>
                    <div className="col-4" >
                        <input id={inputID} type="range" step={step} min={min} max={max} className="slider form-control-range" 
                            defaultValue={def} onChange={e => !isInt ? changeHandle(e.target.value) : changeHandle(parseInt(e.target.value))} disabled={disabled}/>
                    </div>
                    <div className="col-4" style={{textAlign: "left", paddingLeft: "30px"}}>
                        {showValue}
                    </div>
                </div>
                <br />
            </div>
        )
    }

    const ColCheckItem = (props) => {
        return (
            <div className={`col-${props.size || 4} form-check`}>
                <label className="form-check-label">
                    <input type="radio" className="form-check-input" name={props.name} defaultChecked={props.dc || false} onClick={props.oc}/> {props.title}
                </label>
            </div>
        )
    }

    const SettingSlider = (ls, rs, inputProps) => {
        return (
            <div className="row text-center">
                <div className="col-4" style={{textAlign: "right", paddingRight: "30px"}}>{ls}</div>
                <div className="col-4">
                    <input type="range" className="slider form-control-range" 
                    id={inputProps.id || ''} step={inputProps.step} min={inputProps.min} max={inputProps.max} onChange={inputProps.onChange} defaultValue={inputProps.defaultValue}></input>
                </div>
                <div className="col-4" style={{textAlign: "left", paddingLeft: "30px"}}>{rs}</div>
            </div>
        )
    }

    useEffect(() => {
        renderOptions();
        return () => {
            setTestNaTab('-');
            setIpOptions([]);
            //setOpOptions([]);
        }
    }, []);

    const renderOptions = () => {
        navigator.mediaDevices.enumerateDevices().then(devices => {
            devices.forEach(dev => {
                if (dev.kind === "audiooutput"){
                    //setOpOptions(o => [...o, dev])
                }
                else if (dev.kind === "audioinput"){
                    setIpOptions(i => [...i, dev])
                }
            })
        })
    }

    /*const buildOpOptions = () => {
        return opOptions.map((dev, ind) => (<option key={ind} value={dev.deviceId}>{dev.label}</option>))
    }
    const onOpOptionsChange = (e) => {
        for (let d in opOptions) {

        }
    }*/
    const buildIpOptions = () => {
        return ipOptions.map((dev, ind) => (<option key={ind} value={dev.deviceId}>{dev.label || dev.deviceId}</option>))
    }

    return(
        <div>
            <RoundContainer>
                <h3 style={{fontWeight: "bold"}}>การตั้งค่า</h3>
            </RoundContainer>
            <br />

            <RoundContainer>
                <h5 className="containerHeader">ความดัง</h5>
                {SettingSlider('เสียงจังหวะ (ฉิ่ง)', `${(_chingVolume * 100).toFixed(0)} %`, {
                    step: 0.01, min: 0, max: 1, defaultValue: 0.25,
                    onChange: onChingVolumeChange,
                })}
                <br />
                {SettingSlider('เสียงแจ้งเตือนแชท', `${(_effectVolume * 100).toFixed(0)} %`, {
                    step: 0.01, min: 0, max: 1, defaultValue: 0.5,
                    onChange: onEffectVolumeChange,
                })}
                <br />
                {SettingSlider('เสียงโทน-รำมะนา', `${(_toneVolume * 100).toFixed(0)} %`, {
                    step: 0.01, min: 0, max: 1, defaultValue: 0.5,
                    onChange: onToneVolumeChange,
                })}
                <br />
                {SettingSlider('เสียงทั้งหมด', ` ${(allVol * 100).toFixed(0)} % `, {
                    step: 0.01, min: 0, max: 1, defaultValue: 1,
                    onChange: e => setAllVol(e.target.value),
                })}
            </RoundContainer>
            <br />

            {ipOptions ? <RoundContainer>
                <h5 className="containerHeader">อุปกรณ์</h5>
                <div className="row text-center">
                    <div className="col-3" style={{textAlign: "right", paddingRight: "30px"}}>
                        ไมโครโฟน
                    </div>
                    <div className="col-8">
                        <select className="form-control" onChange={e => inputDevice.current = e.target.value}>
                            {buildIpOptions()}
                        </select>
                    </div>
                </div>
            </RoundContainer> : <div/>}
            <br />

            <RoundContainer>
                <h5 className="containerHeader">หน่วย</h5>
                <div className="row text-center">
                    {ColCheckItem({size: 3, name: "radio-unit", title: "วินาทีต่อห้อง", oc: () => setUnit('spr'), dc: true})}
                    {ColCheckItem({size: 3, name: "radio-unit", title: "วินาทีต่อบรรทัด", oc: () => setUnit('spl')})}
                    {ColCheckItem({size: 3, name: "radio-unit", title: "เฮิรตซ์ (Hz)", oc: () => setUnit('hz')})}
                    {ColCheckItem({size: 3, name: "radio-unit", title: "bpm", oc: () => setUnit('bpm')})}
                </div>
            </RoundContainer>
            <br />

            <RoundContainer>
                <h5 className="containerHeader">เสียงจังหวะระหว่างเล่น</h5>
                <div className="row text-center">
                    {ColCheckItem({name: "radio-rhythm-sound", title: "ปิดเสียงทั้งหมด", oc: () => setPlayingRhythm(0)})}
                    {ColCheckItem({name: "radio-rhythm-sound", title: "เปิดเสียงรอบแรก", oc: () => setPlayingRhythm(1)})}
                    {ColCheckItem({name: "radio-rhythm-sound", title: "เปิดเสียงทั้งสองรอบ", oc: () => setPlayingRhythm(2), dc: true})}
                </div>
            </RoundContainer>
            <br />

            {/*<RoundContainer>
                <h5 className="containerHeader">การแปลงโน้ตขณะพิมพ์</h5>
                <div className="row text-center">
                    {ColCheckItem({name: "radio-notechange", title: "ไม่ต้อง", oc: () => setConvertType('NONE'), dc: true})}
                    {ColCheckItem({name: "radio-notechange", title: "ตัวเลข -> ตัวอักษร", oc: () => setConvertType('N2A')})}
                    {ColCheckItem({name: "radio-notechange", title: "ตัวอักษร -> ตัวเลข", oc: () => setConvertType('A2N')})}
                </div>
            </RoundContainer>
            <br />*/}

            <RoundContainer>
                <h5 className="containerHeader">ทดสอบจังหวะ</h5>
                {inputRange('ความเร็ว-ช้า', 'slider-test-speed', 10, 200, 2000, 1000, setTestSpeed, getValueAndUnit(testSpeed), true, isRunning)}
                {inputRange('อัตราจังหวะ (ฉิ่ง)', 'slider-test-rate', 1, 1, 3, 2, setTestRate, testRate !== 1 ? ` ${testRate} ชั้น ` : 'ชั้นเดียว', true, isRunning)}
                <div className="row text-center">
                    <div className="col-4" style={{textAlign: "right", paddingRight: "30px"}}>
                        หน้าทับ
                    </div>
                    <div className="col-4">
                        <select className="form-control" onChange={e => setTestNaTab(e.target.value)} disabled={isRunning}>
                            <option value="-" defaultChecked>-</option>
                            <option value="L2">ลาว (2 ชั้น)</option>
                            <option value="PK3">ปรบไก่ (3 ชั้น)</option>
                            <option value="PK2">ปรบไก่ (2 ชั้น)</option>
                            <option value="PK1">ปรบไก่ (ชั้นเดียว)</option>
                            <option value="SM3">สองไม้ (3 ชั้น)</option>
                            <option value="SM2">สองไม้ (2 ชั้น)</option>
                            <option value="SM1">สองไม้ (ชั้นเดียว)</option>
                            <option value="FR2">ฝรั่ง (2 ชั้น)</option>
                            <option value="KM3">เขมร (3 ชั้น)</option>
                            <option value="KM2">เขมร (2 ชั้น)</option>
                            <option value="KM1">เขมร (ชั้นเดียว)</option>
                        </select>
                    </div>
                    <div className="col-1" />
                </div>
                <div style={{fontSize: '12px', textAlign: 'center'}}>* เมื่อท่านหยุดและต้องการทดสอบจังหวะใหม่ โปรดรอให้จบหน้าทับก่อนกดเริ่ม</div>
                <br />
                <div className="text-center">
                    <button id="btn-test-running" onClick={handleClick} className="btn btn-outline-success">เริ่ม</button>
                </div>
            </RoundContainer>
            <br />

            <RoundContainer>
                <h5 className="containerHeader">ทดสอบเสียง/ไมค์</h5>
                <div className="row text-center">
                    <div className="col-3">
                        <button id="btn-test-audio-1" className="btn btn-outline-success" onClick={startTestAudio}>เริ่มอัดเสียง</button>
                    </div>
                    <div className="col-9">
                        <audio style={{width: "70%"}} hidden id="test-audio-1" controls playsInline />
                    </div>
                </div>
            </RoundContainer>
            <br />

            <RoundContainer>
                <h5 className="containerHeader">เฉพาะแอดมินห้อง</h5>
                <div className="row text-center" style={{opacity: (adminName === socket.id) ? 1 : 0.75}}>
                    <div className="col-3" style={{textAlign: "right", paddingRight: "30px"}}>
                        {` ชื่อเพลง`}
                    </div>
                    <div className="col-6">
                        <input disabled={setDisableClassName()} type="text" className="form-control" autoComplete='off' placeholder="เช่น ลาวดวงเดือน.."
                        onChange={onTitleChange}/>
                    </div>
                </div>
                <br />

                <div style={{opacity: (adminName === socket.id) ? 1 : 0.5}}>
                    {inputRange('ความเร็ว-ช้า', '', 10, 200, 2000, 1000, setSpeed, getValueAndUnit(speed), true, setDisableClassName())}
                    {inputRange('อัตราจังหวะ (ฉิ่ง)', '', 1, 1, 3, 2, setRate, rate !== 1 ? ` ${rate} ชั้น ` : 'ชั้นเดียว', true, setDisableClassName())}
                    {inputRange('จำนวนบรรทัดต่อรอบ', '', 1, 2, 9, 2, setLine, ` รอบละ ${line} บรรทัด `, true, setDisableClassName())}
                    <div className="row text-center">
                        <div className="col-4" style={{textAlign: "right", paddingRight: "30px"}}>
                            หน้าทับ
                        </div>
                        <div className="col-4">
                            <select className="form-control" onChange={e => setNaTab(e.target.value)} disabled={setDisableClassName()}>
                                <option value="-" defaultChecked>-</option>
                                <option value="L2">ลาว (2 ชั้น)</option>
                                <option value="PK3">ปรบไก่ (3 ชั้น)</option>
                                <option value="PK2">ปรบไก่ (2 ชั้น)</option>
                                <option value="PK1">ปรบไก่ (ชั้นเดียว)</option>
                                <option value="SM3">สองไม้ (3 ชั้น)</option>
                                <option value="SM2">สองไม้ (2 ชั้น)</option>
                                <option value="SM1">สองไม้ (ชั้นเดียว)</option>
                                <option value="FR2">ฝรั่ง (2 ชั้น)</option>
                                <option value="KM3">เขมร (3 ชั้น)</option>
                                <option value="KM2">เขมร (2 ชั้น)</option>
                                <option value="KM1">เขมร (ชั้นเดียว)</option>
                            </select>
                        </div>
                        <div className="col-1" />
                    </div>
                    <br />
                    <br />
                    <div className="row" style={{fontSize: "20px"}}>
                        <div className="col-2" />
                        <div className="col">
                        <Form.Check 
                            type="switch"
                            id="custom-switch"
                            label={(<div style={{fontSize: '16px'}}>โหมด iOS (กรณีที่เปิด อาจจะใช้เวลารอรอบสองนาน)</div>)}
                            onChange={() => setMp3Mode(!mp3Mode)}
                            disabled={setDisableClassName()}
                        />
                    </div>
                    </div>
                </div>

            </RoundContainer>
            <br />
            <RoundContainer>
            <div className="text-center">
                <button className="btn btn-danger" onClick={() => {socket.emit('resetServer'); navigate('/') }}>Reset Server</button> *กดในกรณีที่จำเป็นเท่านั้น!!
            </div>
            </RoundContainer>
            <br />

            
            </div>
    )
}

export default Settings;









            /*
            
            <h4>ความดัง</h4>
            {inputRange('เสียงจังหวะ (ฉิ่ง)', '', 0.05, 0, 1, 0.25, setChingVol, ` ${(chingVol * 100).toFixed(0)} % `)}
            {inputRange('เสียงทั้งหมด', '', 0.05, 0, 1, 1, setAllVol, ` ${(allVol * 100).toFixed(0)} % `)}
            <br />

            <h4>หน่วย</h4>
            <hr />
            <div className="row text-center">
                <div className="col-3 form-check">
                    <label className="form-check-label">
                        <input type="radio" className="form-check-input" name="radio-unit" defaultChecked={true} onClick={() => setUnit('spr')}/> วินาทีต่อห้อง
                    </label>
                </div>
                <div className="col-3 form-check">
                    <label className="form-check-label">
                        <input type="radio" className="form-check-input" name="radio-unit" onClick={() => setUnit('spl')}/> วินาทีต่อบรรทัด
                    </label>
                </div>
                <div className="col-3 form-check">
                    <label className="form-check-label">
                        <input type="radio" className="form-check-input" name="radio-unit" onClick={() => setUnit('hz')}/> เฮิรตซ์ (Hz)
                    </label>
                </div>
                <div className="col-3 form-check">
                    <label className="form-check-label">
                        <input type="radio" className="form-check-input" name="radio-unit" onClick={() => setUnit('bpm')}/> bpm
                    </label>
                </div>
            </div>
            <br />
            <br />

            <h4>การแปลงโน้ตอัตโนมัติ</h4>
            <hr />
            <div className="row text-center">
                <div className="col-4 form-check">
                    <label className="form-check-label">
                        <input type="radio" className="form-check-input" name="radio-notechange" defaultChecked={true} onClick={() => setConvertType('NONE')} /> ไม่ต้อง
                    </label>
                </div>
                <div className="col-4 form-check">
                    <label className="form-check-label">
                        <input type="radio" className="form-check-input" name="radio-notechange" onClick={() => setConvertType('N2A')} /> {`ตัวเลข -> ตัวอักษร`}
                    </label>
                </div>
                <div className="col-4 form-check">
                    <label className="form-check-label">
                        <input type="radio" className="form-check-input" name="radio-notechange" onClick={() => setConvertType('A2N')} /> {`ตัวอักษร -> ตัวเลข`}
                    </label>
                </div>
            </div>
            <br />
            <br />


            <h4>ทดสอบความเร็ว/จังหวะ</h4>
            <hr />
            {inputRange('ความช้า-เร็ว', 'slider-test-speed', 50, 200, 2000, 1000, setTestSpeed, getValueAndUnit(testSpeed), true)}
            {inputRange('อัตราจังหวะ', 'slider-test-rate', 1, 1, 3, 2, setTestRate, testRate !== 1 ? ` ${testRate} ชั้น ` : 'ชั้นเดียว', true)}
            <div className="text-center">
                <button onClick={handleClick} className="btn btn-success">เริ่ม/หยุด</button>
            </div>
            <br />

            <h4>เฉพาะแอดมินห้อง</h4>
            <hr />
            <div className="row text-center" style={{opacity: (adminName === socket.id) ? 1 : 0.75}}>
                <div className="col-3" style={{textAlign: "right", paddingRight: "30px"}}>
                    {` ชื่อเพลง`}
                </div>
                <div className="col-6">
                    <input disabled={setDisableClassName()} type="text" className="form-control" autoComplete='off' placeholder="เช่น ลาวดวงเดือน.."
                    onChange={onTitleChange}/>
                </div>
            </div>
            <br />

            <div style={{opacity: (adminName === socket.id) ? 1 : 0.5}}>
                {inputRange('ความช้า-เร็ว', '', 50, 200, 2000, 1000, setSpeed, getValueAndUnit(speed), true, setDisableClassName())}
                {inputRange('อัตราจังหวะ', '', 1, 1, 3, 2, setRate, rate !== 1 ? ` ${rate} ชั้น ` : 'ชั้นเดียว', true, setDisableClassName())}
                {inputRange('จำนวนบรรทัดต่อรอบ', '', 1, 2, 9, 2, setLine, ` รอบละ ${line} บรรทัด `, true, setDisableClassName())}
            </div>*/









/*<div className="row text-center">
                <div className="col-1" />
                <div className="col-3">
                    {` เสียงทั้งหมด `}
                </div>
                <div className="col-4" >
                    <input id="slider-ching-sound" type="range" step={0.05} min={0} max={1} className="slider form-control-range" 
                        defaultValue={1} onChange={e => setAllVol(e.target.value)} />
                </div>
                <div className="col-2" style={{textAlign: "left", paddingLeft: "30px"}}>
                    {` ${(allVol * 100).toFixed(0)} % `}
                </div>
            </div>
            <br />*/


            /*<div className="row text-center">
                <div className="col-1" />
                <div className="col-3">
                    {` ความช้า-เร็ว`}
                </div>
                <div className="col-4" style={{opacity: (adminName === socket.id) ? 1 : 0.25}} >
                    <input disabled={setDisableClassName()} type="range" step={50} min={200} max={2000} className="slider form-control-range" 
                        defaultValue={1000} onChange={e => setSpeed(parseInt(e.target.value))} />
                </div>
                <div className="col-3">
                    {getValueAndUnit(speed)}
                </div>
            </div>
            <br />
            
            <div className="row text-center">
                <div className="col-1" />
                <div className="col-3">
                    {` อัตราจังหวะ`}
                </div>
                <div className="col-4" style={{opacity: (adminName === socket.id) ? 1 : 0.25}} >
                    <input disabled={setDisableClassName()} type="range" step={1} min={1} max={3} className="slider form-control-range" 
                        defaultValue={2} onChange={e => setRate(parseInt(e.target.value))} />
                </div>
                <div className="col-3">
                    {rate !== 1 ? ` ${rate} ชั้น ` : 'ชั้นเดียว'}
                </div>
            </div>
            <br />

            

            <div className="row text-center">
                <div className="col-1" />
                <div className="col-3">
                    {` จํานวนบรรทัดต่อรอบ`}
                </div>
                <div className="col-4" style={{opacity: (adminName === socket.id) ? 1 : 0.25}} >
                    <input disabled={setDisableClassName()} type="range" step={1} min={2} max={9} className="slider form-control-range" 
                        defaultValue={2} onChange={e => setLine(parseInt(e.target.value))} />
                </div>
                <div className="col-3">
                    {` รอบละ ${line} บรรทัด `}
                </div>
            </div>*/




                        /*ipOptions ? settingContainer('อุปกรณ์', 
                <div>
                    <div className="row text-center">
                        <div className="col-3" style={{textAlign: "right", paddingRight: "30px"}}>
                            เสียงที่ออก
                        </div>
                        <div className="col-8">
                            <select className="form-control" onChange={e => {setOutputID(e.target.value)}}>
                                {buildOpOptions()}
                            </select>
                        </div>
                        <div className="col-1" />
                    </div>
                    <br />
                    <div className="row text-center">
                        <div className="col-3" style={{textAlign: "right", paddingRight: "30px"}}>
                            ไมโครโฟน
                        </div>
                        <div className="col-8">
                            <select className="form-control" onChange={e => setInputDevice(e.target.value) }>
                                {buildIpOptions()}
                            </select>
                        </div>
                    </div>
                </div>
            ) : <div />*/