import React, { useContext, useEffect, useState } from "react";
import { database } from "../../../databases/firebase";
import { NoteContext } from "../../../providers/NoteProvider";
import { SettingsContext } from "../../../providers/SettingsProvider";
import { NoteConvertContext } from "../../../providers/NoteConvertProvider";
import { Packer } from "docx";
import { saveAs } from "file-saver";
import docx from "../../layout/TableBuilder";
import { chab } from "../../../sounds/EffectSound";
import socket from "../../../server/socket-io";
import { ICON_COPY, ICON_DELETE, ICON_DOWNLOAD, ICON_PASTE, ICON_SAVE, ICON_SHARE } from "../../icons";
import RoundContainer from "../../layout/RoundContainer";
import { ButtonGroup, Modal } from "react-bootstrap";


const Note = () => {

    const { line } = useContext(SettingsContext);
    const { getRoom, setRoom, /*clearAll*/ } = useContext(NoteContext);
    const { getNote, setConvertType, convertType } = useContext(NoteConvertContext);
    const [title, setTitle] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [fileName, setFileName] = useState('')

    const ip = (l, r) => {
        let ele = document.getElementById(`note-input-${l}${r}`);
        return ele || '';
    }

    const nextIp = (l, r) => {
        return (r < 8) ? ip(l, r + 1) : (l < line) ? ip(l + 1, 1) : ip(1, 1);
    }

    const touchStart = e => {
        let em = document.getElementById(e.target.id);
        em.className = "btn btn-primary";
        chab.play();
    }

    const touchEnd = e => {
        document.getElementById(e.target.id).className = "btn btn-outline-primary";
    }

    const onInputKeyDown = e => {
        const l = parseInt(e.target.id.substring(11,12));
        const room = parseInt(e.target.id.substring(12,13));
        switch (e.key) {
            case "ArrowRight":
                moveInputRight(l, room);
                break;
            case "ArrowLeft":
                moveInputLeft(l, room);
                break;
            case "ArrowUp":
                if (l !== 1) document.getElementById(`note-input-${l - 1}${room}`).focus();
                break;
            case "ArrowDown":
                if (l !== line) document.getElementById(`note-input-${l + 1}${room}`).focus();
                break;
            case "Backspace": 
                if (e.target.value.length === 0) moveInputLeft(l, room);
                break;
            case "Delete":
                (e.target.value.length === 0) ? moveInputLeft(l, room) : ip(l, room).value = ip(l, room).value.substring(0, ip(l, room).value.length - 1)
                break;
            case "ข": case "*": case "/": case " ": case "-":
                e.preventDefault();
                if (ip(l, room).value.length < 4) ip(l, room).value += '-';
                if (ip(l, room).value.length >= 4) moveInputRight(l, room);
                break;
            case "Enter":
                e.preventDefault();
                if (!ip(l, room).value) ip(l, room).value = "----";
                moveInputRight(l, room);
                break;
            case "Tab":
                e.preventDefault();
                switch(ip(l, room).value.length) {
                    case 0:
                        ip(l, room).value = "---";
                        break;
                    case 1:
                        ip(l, room).value += "---";
                        moveInputRight(l, room);
                        break;
                    case 2:
                        ip(l, room).value += "--";
                        if (nextIp(l, room).value === "") nextIp(l, room).value = "-";
                        moveInputRight(l, room);
                        break;
                    case 3:
                        ip(l, room).value += "-";
                        if (nextIp(l, room).value === "") nextIp(l, room).value = "--";
                        moveInputRight(l, room);
                        break;
                    default:
                        moveInputRight(l, room);
                        break;
                }
                break;
            case "+":
                e.preventDefault();
                switch(ip(l, room).value.length) {
                    case 0: case 1:
                        ip(l, room).value += "--";
                        break;
                    case 2:
                        ip(l, room).value += "--";
                        moveInputRight(l, room);
                        break;
                    case 3:
                        ip(l, room).value += "-";
                        if (nextIp(l, room).value === "") nextIp(l, room).value = "-";
                        moveInputRight(l, room);
                        break;
                    default:
                        moveInputRight(l, room);
                        break;
                }
                break;
        
            default:
                e.preventDefault();
                if (e.key.length === 1 && ip(l, room).value.length < 4) {
                    ip(l, room).value += getNote(e.which === 186 ? 'ซ' : e.which === 32 ? '-' : e.key);
                    if (e.target.value.length >= 4) moveInputRight(l, room);
                }
                
                if (window.screen.width <= 1200) {
                    setTimeout(() => {
                        const replaceTanWor = convertType === 'A2N' ? '5' : 'ซ';
                        if (ip(l, room).value.indexOf('ว') !== -1 || ip(l, room).value.indexOf('ช') !== -1) {
                            ip(l, room).value = ip(l, room).value.replaceAll('ว', replaceTanWor).replaceAll('ช', replaceTanWor);
                        }
                        else if (ip(l, room).value.indexOf(' ') !== -1) {
                            ip(l, room).value = ip(l, room).value.replaceAll(' ', '-')
                        }
                        if (e.target.value.length >= 4) moveInputRight(l, room);
                    }, 50);
                }
                //console.log(ip(l, room).value);
                break;
        }        
    }

    const moveInputRight = (l, room) => {
        if (room !== 8) {
            document.getElementById(`note-input-${l}${room + 1}`).focus();
        }
        else if (l !== line) {
            document.getElementById(`note-input-${l + 1}${1}`).focus();
        }
        else if (ip(1, 1).value.length !== 4){
            document.getElementById(`note-input-${1}${1}`).focus();
        }
    }

    const moveInputLeft = (l, room) => {
        if (room !== 1) {
            document.getElementById(`note-input-${l}${room - 1}`).focus();
        }
        else if (l !== 1) {
            document.getElementById(`note-input-${l - 1}${8}`).focus();
        }
        else {
            document.getElementById(`note-input-${line}${8}`).focus();
        }
    }

    const eachLineInput = (l) => {
        return [1, 2, 3, 4, 5, 6, 7, 8].map((room, i) => (
            <input key={i} id={`note-input-${l}${room}`} type="text" className="form-control text-center" autoComplete="off"
             onKeyDown={onInputKeyDown} onPaste={onPasteFound}
            />
        ))  
    }

    const onDeleteClick = e => {
        const l = parseInt(e.target.id.substring(9,10));
        if (l === 0) {
            //clearAll();
            [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach(li => {
                [1, 2, 3, 4, 5, 6, 7, 8].forEach(r => {
                    document.getElementById(`note-input-${li}${r}`).value = "";
                })
            })
        }
        else if (l) {
            [1, 2, 3, 4, 5, 6, 7, 8].forEach(r => {
                document.getElementById(`note-input-${l}${r}`).value = "";
                //setRoom((l - 1) * 8 + r, "");
            })
        }
        
    }
    const onCopyClick = e => {
        
        const l = parseInt(e.target.id.substring(9,10));
        let clipString = "";
        if (l === 0) {
            for (let i = 1; i <= line; i++) {
                for (let j = 1; j <= 8; j++) {
                     clipString += ip(i, j).value.length < 4 ? "-NaN" : ip(i, j).value;
                }
            }
        }
        else if (l) [1, 2, 3, 4, 5, 6, 7, 8].forEach(r => clipString += (ip(l, r).value.length < 4) ?  "-NaN" : ip(l, r).value)
        navigator.clipboard.writeText(clipString)
        .then(() => alert(`คัดลอกโน้ต${l === 0 ? 'ทั้งหมด' : ` บรรทัดที่ ${l} `}สำเร็จ`))
        .catch(error => alert(`เกิดข้อผิดพลาด : ${error.message}`))
    }

    const onShareClick = e => {
        let clipString = "";
        for (let i = 1; i <= line; i++) {
            for (let j = 1; j <= 8; j++) {
                 clipString += ip(i, j).value.length < 4 ? "-NaN" : ip(i, j).value;
            }
        }
        socket.emit('sendChatMessage', clipString);
    }

    const onPasteClick = e => {
        const l = parseInt(e.target.id.substring(9,10));

        navigator.clipboard.readText().then(clipString => {
            clipString = clipString.trim().replaceAll("	", "").replaceAll(' ', '')
                .replaceAll("\n", "").replaceAll('\r', "")
                .replaceAll('ํ', '').replaceAll('ฺ', '')
                .replaceAll('|','').replaceAll('/','')
                .replaceAll('(', '').replaceAll(')','')
            if (l === 0) {
                for (let i = 1; i <= line; i++) {
                    for (let j = 1; j <= 8; j++) {
                        let clip = clipString.substring((i - 1) * 32, i * 32).substring((j - 1) * 4, j * 4);
                        if (clip !== "-NaN" && clip !== "") {
                            ip(i, j).value = clip;
                            //setRoom((i - 1) * 8 + j, clip);
                        } 
                    }
                }
            }
            else if (l) [1, 2, 3, 4, 5, 6, 7, 8].forEach(r => {
                let clip = clipString.substring((r - 1) * 4, r * 4);
                if (clip !== "-NaN") {
                    ip(l, r).value = clip;
                    //setRoom((l - 1) * 8 + r, clip);
                } 
            })
        })
        .catch(error => alert(`เกิดข้อผิดพลาด : ${error.message}`))



    }

    const onDownloadClick = () => {
        const downloadBtn = document.getElementById('note-download');
        downloadBtn.disabled = true;
        Packer.toBlob(docx(line, title, getRoom)).then(blob => {
            let name = `${fileName || 'song'}.docx`
            saveAs(blob, name);
            return `ดาวน์โหลด ${name} สําเร็จ`
        })
        .then(m => alert(m))
        .then(() => setShowModal(false))
        .catch(e => alert("ERROR: " + e.message))
        .finally(() => downloadBtn.disabled = false)
    }

    const onSaveClick = () => {
        /*const l = parseInt(e.target.id.substring(11,12));
        const room = parseInt(e.target.id.substring(12,13));
        //e.target.value.forEach(s => getConvertedNote(s));
        //e.nativeEvent.data = getNote(e.nativeEvent.data);
        setRoom((l - 1) * 8 + room, getNote(e.target.value));
        if (e.target.value.length >= 4) moveInputRight(l, room);
        console.log('.')*/
        for (let i = 1; i <= line; i++) {
            for (let j = 1; j <= 8; j++) {
                setRoom((i - 1) * 8 + j, ip(i, j).value);
            }
        }
        for (let i = line + 1; i < 10; i++) {
            for (let j = 1; j <= 8; j++) {
                setRoom((i - 1) * 8 + j, "");
            }
        }
    }

    const onPasteFound = (e) => {
        let l = parseInt(e.target.id.substring(11,12));
        let r = parseInt(e.target.id.substring(12,13));
        

        setTimeout(() => {
            let val = ip(l, r).value;
            const roomSteped = (val.length - val.length % 4) / 4 + (val.length % 4 !== 0 ? 0 : -1)
            let lastRoom = (r + roomSteped) % 8;
            let lastLine = l + ((r + roomSteped) - (r + roomSteped) % 8)/8;
            let pasteText = ip(l, r).value.trim().replaceAll("	", "").replaceAll(' ', '')
                .replaceAll("\n", "").replaceAll('\r', "")
                .replaceAll('ํ', '').replaceAll('ฺ', '')
                .replaceAll('|','').replaceAll('/','')
                .replaceAll('(', '').replaceAll(')','');
            
            let k = 0;
            for (let i = l; i <= lastLine; i++) {
                if (i > line) break;
                let t =  (i === lastLine) ? lastRoom : 8;
                for (let j = (i === l) ? r : 1; j <= t; j++) {
                    if (j > 8) break;
                    ip(i, j).value = pasteText.substring(k, k + 4);
                    k += 4;
                }
            }
        }, 100);
        //---ด---ร---ม---
    }

    useEffect(() => {
        getAndSetTitle();
        return () => setTitle("");
    }, []);

    const getAndSetTitle = () => {
        database.ref('databaseSystem').child('title').on('value', child => {
            setTitle(child.val());
        })
        //console.log(navigator.permissions);
        //console.log(document.execCommand);
    }


    const renderInput = () => {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9].map((l, i) => (
            <div key={i} className="row" hidden={l > line}>
                <div className="col-auto" style={{width: '600px'}}>
                    <div className="input-group pt-1">
                        {eachLineInput(l)}
                    </div>
                </div>
                <div className="col-auto pt-1 text-center" style={{width: '140px'}}>
                    <div className="btn-group">
                        <button onClick={onCopyClick} id={`note-cop-${l}`} className="btn btn-primary">{ICON_COPY}</button>
                        <button onClick={onPasteClick} id={`note-pas-${l}`} className="btn btn-secondary">{ICON_PASTE}</button>
                        <button onClick={onDeleteClick} id={`note-del-${l}`} className="btn btn-danger">{ICON_DELETE}</button>
                    </div>
                    
                </div>
            </div>
        ))
    }

    return (
        <div>

            <Modal show={showModal} size="sm" onHide={() => setShowModal(false)}>
                <Modal.Body >
                    <form>
                        <label>ชื่อไฟล์</label>
                        <input type="text" id="soundpage-input-filename" className="form-control" onChange={e => setFileName(e.target.value)}></input>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn btn-outline-danger" onClick={() => setShowModal(false)}>ยกเลิก</div>
                    <div className="btn btn-primary" onClick={onDownloadClick}>ดาวน์โหลด</div>
                </Modal.Footer>
            </Modal>

            <RoundContainer>
                <h3 className="containerHeader">เขียนโน้ต</h3>
                <div className="text-center">
                    <ButtonGroup>
                        <button onTouchStart={touchStart} onTouchEnd={touchEnd} onClick={onSaveClick} id={`note-save`} className="btn btn-outline-primary"> {ICON_SAVE} บันทึก</button>
                        <button onTouchStart={touchStart} onTouchEnd={touchEnd} onClick={onShareClick} id={`note-share`} className="btn btn-outline-primary"> {ICON_SHARE} แบ่งปันในแชท</button>
                        <button onTouchStart={touchStart} onTouchEnd={touchEnd} onClick={() => setShowModal(true)} id="note-download" className="btn btn-outline-primary"> {ICON_DOWNLOAD} ดาวน์โหลด</button>
                        <button onTouchStart={touchStart} onTouchEnd={touchEnd} onClick={onCopyClick} id={`note-cop-0`} className="btn btn-outline-primary"> {ICON_COPY} คัดลอกทั้งหมด</button>
                        <button onTouchStart={touchStart} onTouchEnd={touchEnd} onClick={onPasteClick} id={`note-pas-0`} className="btn btn-outline-primary"> {ICON_PASTE} วาง</button>
                        <button onTouchStart={touchStart} onTouchEnd={touchEnd} onClick={onDeleteClick} id={`note-del-0`} className="btn btn-outline-primary"> {ICON_DELETE} ลบหมด</button>
                    </ButtonGroup>
                </div>
            </RoundContainer>

            <br />

            <RoundContainer>
                <div className='row text-center'>
                    <div className="col-10 h4" style={{fontWeight: 'bold', paddingBlock: '10px'}}>{title || '[ชื่อเพลง]'}</div>
                    <div className="col-2"/>
                </div>
                {renderInput()}
            </RoundContainer>

            <br />

            <div className="row">
                <div className="col-5">
                    <RoundContainer>
                    <h5 className="containerHeader">การแปลงโน้ตขณะพิมพ์</h5>
                    <label className="form-check-label">
                        <input type="radio" className="form-check-input" name="radio-notechange" defaultChecked={true} onClick={() => setConvertType('NONE')} />  ไม่ต้อง
                    </label>
                    <br /><br />
                    <label className="form-check-label">
                        <input type="radio" className="form-check-input" name="radio-notechange" onClick={() => setConvertType('N2A')} />  {boldText('ตัวเลข')} {'->'} {boldText('ตัวอักษร')} (เช่น 5 {'->'} ซ)
                    </label>
                    <br /><br />
                    <label className="form-check-label">
                        <input type="radio" className="form-check-input" name="radio-notechange" onClick={() => setConvertType('A2N')} />  {boldText('ตัวอักษร')} {'->'} {boldText('ตัวเลข')} (เช่น ซ {'->'} 5)
                    </label>
                    <br /><br /><br />
                    </RoundContainer>
                </div>
                <div className="col">
                    <RoundContainer>
                        <h5 style={{fontWeight: 'bold'}}>ปุ่มลัด </h5>
                        <div style={{paddingLeft: '20px'}}>
                            <div>- กด {boldText('+')} เพื่อพิมพ์ {boldText('--')} </div>
                            <div>- กด {boldText('Tab')} เพื่อพิมพ์ {boldText('---')}</div>
                            <div>- กด {boldText('Enter')} เพื่อพิมพ์ {boldText('----')} ในห้องที่ว่าง</div>
                            <div>- กด {boldText('Space bar')} เพื่อพิมพ์ {boldText('-')} แทนปุ่ม {boldText('ลบ')}</div>
                            <div>- ไม่อยากกด {boldText('Shift')} เพื่อพิมพ์ {boldText('ซ')} บ่อย ๆ ใช่ไหมล่า ลองกด {boldText('ว')} แทนสิ</div>
                        </div>
                        <br />
                        <div style={{fontSize: '13px'}}>* ใช้ปุ่มลัดดังกล่าวได้บางอุปกรณ์เท่านั้น</div>
                    </RoundContainer>
                </div>
            </div>
            

            <br />

            <RoundContainer>
                <h5 style={{fontWeight: 'bold'}}>หมายเหตุ </h5>
                <div style={{paddingLeft: '20px'}}>
                    <div>- สามารถพิมพ์โน้ตตัวเลข หรือโน้ตตัวอักษรก็ได้</div>
                    <div>- กด {boldText('บันทึก')} เพื่อบันทึกโน้ตที่พิมพ์ลงหน้าหลัก</div>
                    <div>- ใน 1 ห้องพิมพ์ได้แค่ 4 ตัวอักษรเท่านั้น (ไม่รองรับเสียงสูง-ตํ่า เช่น ดํ/ดฺ) </div>
                    <div>- ก่อนดาวน์โหลดเป็นไฟล์ {boldText('MS Word')} อย่าลืมกด {boldText('บันทึก')} ก่อนนะ :)</div>
                    <div>- คนอื่นจะไม่เห็นโน้ตของเรา ในกรณีที่อยากแชร์ โปรดคลิก {boldText('แบ่งปันในแชท')} หรือจะ {boldText('คัดลอกทั้งหมด')} และวางในแพลตฟอร์มอื่น ๆ ก็ได้</div>
                </div>
                
            </RoundContainer>
            <br />
            
        </div>
    )
}

const boldText = (t) => {
    return <span style={{fontWeight: 'bold'}}>{t}</span>
}

export default Note;