import { useContext, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { SoundsContext } from "../../providers/SoundsContext";
import { RangeForm } from "./FormItem";


export const SoundRhythmModal = () => {

    const { chingVolume, toneVolume, setChingVolume, setToneVolume, showRhythmModal, setShowRhythmModal } = useContext(SoundsContext)
    const [vol, setVol] = useState({ching : 0.25, tone : 0.25});

    const onConfirmClick = e => {
        e.preventDefault();
        setChingVolume(vol.ching)
        setToneVolume(vol.tone)
        setShowRhythmModal(false)
    }

    const onModalShow = () => {
        setVol({
            ching : chingVolume,
            tone : toneVolume,
        })
    }

    return (
        <Modal show={showRhythmModal} onHide={() => setShowRhythmModal(false)} onShow={onModalShow}>
            <Form>
                <Modal.Header>
                    <div>ระดับเสียงเครื่องจังหวะ</div>
                </Modal.Header>
                
                <Modal.Body>
                    <RangeForm 
                        title={`ระดับเสียงฉิ่ง : ${Math.round(vol.ching * 100)}%`}
                        value={vol.ching} min={0} max={1} step={0.01}
                        onChange={e => setVol(v => ({...v, ching : parseFloat(e.target.value)}))} 
                    />
                    <RangeForm 
                        title={`ระดับเสียงโทน : ${Math.round(vol.tone * 100)}%`}
                        value={vol.tone} min={0} max={1} step={0.01}
                        onChange={e => setVol(v => ({...v, tone : parseFloat(e.target.value)}))} 
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShowRhythmModal(false)}>{'ปิด'}</Button>
                    <Button variant="primary" type='submit' onClick={onConfirmClick}>{'ยืนยัน'}</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export const FluteVolumeModal = () => {
    
    const { fluteVolume, setFluteVolume, showFluteModal, setShowFluteModal } = useContext(SoundsContext)
    const [vol, setVol] = useState(0.25);

    const onConfirmClick = e => {
        e.preventDefault();
        setFluteVolume(vol)
        setShowFluteModal(false)
    }

    return (
        <Modal show={showFluteModal} onHide={() => setShowFluteModal(false)} onShow={() => setVol(fluteVolume)}>
            <Form>
                <Modal.Body>
                    <RangeForm 
                        title={`ระดับเสียงขลุ่ย : ${Math.round(vol * 100)}%`}
                        value={vol} min={0} max={1} step={0.01}
                        onChange={e => setVol(parseFloat(e.target.value))} 
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShowFluteModal(false)}>{'ปิด'}</Button>
                    <Button variant="primary" type='submit' onClick={onConfirmClick}>{'ยืนยัน'}</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export const KongVolumeModal = () => {
    
    const { kongVolume, setKongVolume, showKongModal, setShowKongModal } = useContext(SoundsContext)
    const [vol, setVol] = useState(0.25);

    const onConfirmClick = e => {
        e.preventDefault();
        setKongVolume(vol)
        setShowKongModal(false)
    }

    return (
        <Modal show={showKongModal} onHide={() => setShowKongModal(false)} onShow={() => setVol(kongVolume)}>
            <Form>
                <Modal.Body>
                    <RangeForm 
                        title={`ระดับเสียงฆ้อง : ${Math.round(vol * 100)}%`}
                        value={vol} min={0} max={1} step={0.01}
                        onChange={e => setVol(parseFloat(e.target.value))} 
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShowKongModal(false)}>{'ปิด'}</Button>
                    <Button variant="primary" type='submit' onClick={onConfirmClick}>{'ยืนยัน'}</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}