import { Col, Row } from "react-bootstrap";
import { ICON_SUPPORT } from "../icons";
import RoundContainer from "../layout/RoundContainer";
import SuperHeader from "../layout/SuperHeader";



const About = () => {
    const supporter = {
        1 : {
            name: "ชนิดา พรหมประดิษฐ์ (ใบเฟิร์น)",
            role: "ที่ปรึกษาโครงการ"
        },
        2 : {
            name: "ณัฐวัชร์ เตชะนพดล (พี่เต)",
            role: "ผู้สนับสนุนโครงการ (เสียงฉิ่ง โทนรำมะนา)",
        },
        3 : {
            name: "โกญจนาท วิบูลย์เพ็ง (พี่เมฆ)",
            role: "ผู้ทดสอบระยะแรก",
        },
        4 : {
            name: "พุฒิพงศ์ เจืออุปถัมย์ (พี่ไปร์ท)",
            role: "ผู้ทดสอบระยะแรก",
        },
        5 : {
            name: "จิรัชญา เดชเลิศประยูร (พี่เค้ก)",
            role: "ผู้ทดสอบระยะแรก",
        },
        6 : {
            name : "แก้วสกุล สุพรรณชนะบุรี (มิ้ม)",
            role: "ผู้ทดสอบระยะแรก",
        },
        7 : {
            name : "พรรษชล วินิยกูล (ฝน)",
            role: "ผู้ทดสอบระยะแรก",
        },
        8 : {
            name : "ประภา ศรีวิไลฤทธิ์",
            role: "ผู้ทดสอบระยะแรก",
        },
        9 : {
            name: "มัทนา กุศลสิทธารถ (มินนี่)",
            role: "ผู้ทดสอบระยะแรก",
        },
        10 : {
            name: "กวินภพ ทองนาค (กวิน)",
            role: "ผู้ทดสอบระยะแรก",
        },
        11 : {
            name: "ปิติ มานุชานนท์",
            role: "ผู้ทดสอบระยะแรก",
        },
        12 : {
            name: "ชินาวัตร ประฐมธานี (เบียร์)",
            role: "ผู้ทดสอบระยะแรก",
        },
        13 : {
            name: "บุญยเกียรติ สะอาด (บุญ)",
            role: "ผู้ทดสอบระยะแรก",
        },
        14 : {
            name: "ศิรนภา ชัยประภา (ฟ้า)",
            role: "ผู้ทดสอบระยะแรก",
        },
    }

    return (
        <>
            <SuperHeader title='เกี่ยวกับ' />
            <div className="container" style={{maxWidth : '1000px'}}>
                <RoundContainer>
                    <h5 className="containerHeader">{ICON_SUPPORT} ผู้สนับสนุน {ICON_SUPPORT}</h5>
                    ขอขอบพระคุณผู้สนับสนุนทุกท่าน
                    <div className="list-group pt-3">
                        {(supporter) ? Object.values(supporter).map((data, i) => (
                            <li key={i} className="list-group-item">
                                <Row>
                                    <Col sm='1' xs='1'>{i + 1}.</Col>
                                    <Col sm='5' xs='11'>{data.name}</Col>
                                    <Col sm='6' xs='12'>{data.role}</Col>
                                </Row>
                            </li>
                        )) : <div/>}
                    </div>
                </RoundContainer>

            <br />

            <RoundContainer>
                <h5 className="containerHeader">ผู้จัดทํา</h5>
                นาย ศิรสิทธิ์ ศรีใส (อื๋อ)
                <br />ติดต่องานได้ทาง Facebook: ศิรสิทธิ์ ศรีใส หรือ Chanida Noey Prompradit
                <br /><br />
                สวัสดีครับ เนื่องจากช่วงนี้มีการ lockdown หรือห้ามการรวมกลุ่มกัน ทางผู้จัดทําจึงทดลองสร้างเว็บพร้อมเพลงขึ้นมาเพื่อให้พวกเราได้เล่นดนตรีด้วยกัน :D
            </RoundContainer>

            <br />            
        </div>
        </>
    )

}

export default About;