import React, { createContext, useEffect, useReducer } from "react";
import { userInitialState } from "../components/constant";
import { auth } from '../databases/firebase';
import { getUserDataByEmail, getUserNameByEmail } from "../databases/users/getUserName";

export const AuthContext = createContext({});

const initialState = {
    isLogin : null,
    userName : '',
    userData : userInitialState,
}

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_LOGIN":
            return {...state, isLogin : action.payload}
        case "SET_USERNAME":
            return {...state, userName : action.payload}
        case "SET_USERDATA":
            return {...state, userData : action.payload}
        default:
            break;
    }
}

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { isLogin, userName, userData } = state;
    const setLogin = payload => dispatch({ type: 'SET_LOGIN', payload });
    const setUserName = payload => dispatch({ type: 'SET_USERNAME', payload });
    const setUserData = payload => dispatch({ type: 'SET_USERDATA', payload });

    const resetUserData = (currentEmail = null) => {
        if (auth.currentUser) {
            let mail = currentEmail || auth.currentUser.email;
            getUserDataByEmail(mail).then(r => {
                setUserData({...r})
                setUserName(r.userName)
            })
        }
        
    }

    useEffect(() => {

        auth.onAuthStateChanged(user => {
            //user ? setLogin(true) : setLogin(false);
            if (!user) {
                setLogin(false);
                setUserName('');
                setUserData(userInitialState)
            }
            else {
                getUserNameByEmail(user.email).then(userName => setUserName(userName))
                getUserDataByEmail(user.email).then(r => setUserData({...r}))
                setLogin(true);
            }
        })

        return () => {
            setLogin(null);
            setUserName('');
        }
    }, []);

    useEffect(() => {
        const inputUserName = document.getElementById('input-userName');
        if (inputUserName) inputUserName.value = userName
    }, [userName]);
    
    return (
        <AuthContext.Provider value={{ isLogin, userName, setUserName, userData, resetUserData }}>
            {children}
        </AuthContext.Provider>
    )
}