import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import { ICON_ADMIN, ICON_MICDISABLE } from "../icons";


const List = (props) => {

    const {users, userName, adminName} = useContext(UserContext)


    return(
        <div className="" style={{paddingBlock: "20px", height: props.h ? `${props.h}px` : "430px"}}>
            <div className="overflow-auto" style={{height: props.h ? `${props.h - 50}px` : '380px', overflowY: "scroll"}} >
                <div className="list-group">
                    {(users) ? Object.values(users).map((data, i) => (
                        <li key={i} className={setClassName(data)}>
                            <div className="row">
                                <div className="col">
                                    {(adminName === data.id) 
                                        ? ICON_ADMIN
                                        : <div />
                                    }
                                    {` ${data.name} ${data.name === userName ? "(me) ": " "}`}
                                    
                                </div>
                                <div className="col-auto">
                                    {(data.isMicEnable) 
                                        ? <div />
                                        : ICON_MICDISABLE
                                    }
                                    
                                </div>
                            </div>
                        </li>
                    )) : <div/>}
                </div>
            </div>
        </div>
    )
}

const setClassName = (element) => {
    return (element.isReady) ? "list-group-item list-group-item-info" : "list-group-item";
}

export default List;