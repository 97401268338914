import { useContext, useMemo } from "react";
import { Col, FormControl, Row } from "react-bootstrap";
import { NoteContext } from "./NoteContext";

const LineNumBox = props => {
    const { lineNum, xs } = props;
    return (
        <div 
            className={`col-auto ${xs ? 'd-sm-none' : 'd-none d-sm-block'}`} 
            style={{paddingBlock: '10px', paddingInline: '0', width: xs ? '30px' : '36px', }}
        >
            <FormControl 
                className="text-center note-left" 
                readOnly={true}
                value={lineNum}
                style={{
                    background: '#e9ecef',
                    fontSize: xs ? '10px' : 'auto'
                }} />
        </div>
    )
}

const EachRoom = props => {
    const { room } = props;
    const { noteRef, isPlaying, focusRoom, setFocusRoom } = useContext(NoteContext);

    const backgroundColor = useMemo(() => {
        if (focusRoom === room) {
            return '#fffb8f';
        }
        else {
            return 'white'
        }
    }, [focusRoom, room]);

    return (
        <div className="col" style={{paddingBlock: '10px', paddingInline: '0'}}>
            <FormControl disabled={isPlaying} readOnly={true} 
                ref={e => noteRef.current[room] = e} 
                className={`my-note-col ${room === 3 ? 'note-right-4' : room === 7 ? 'note-right-8' : 'note-center'}`}
                onClick={() => setFocusRoom(room)} onFocus={e => e.target.blur()}
                style={{ background: backgroundColor}}/>
        </div>
    )
}

const RenderEachLine = props => {
    const { lineNum } = props;

    return (
        <Row bsPrefix="row text-center note-grid">
            <LineNumBox lineNum={lineNum + 1} />
            <Col bsPrefix="col-sm-5">
                <Row>
                    <LineNumBox lineNum={(lineNum + 1).toString() + '/1'} xs={true}/>
                    <EachRoom room={lineNum * 8 + 0} />
                    <EachRoom room={lineNum * 8 + 1} />
                    <EachRoom room={lineNum * 8 + 2} />
                    <EachRoom room={lineNum * 8 + 3} />
                </Row>
            </Col>
            <Col bsPrefix="col-sm-5">
                <Row >
                    <LineNumBox lineNum={(lineNum + 1) + '/2'} xs={true}/>
                    <EachRoom room={lineNum * 8 + 4} />
                    <EachRoom room={lineNum * 8 + 5} />
                    <EachRoom room={lineNum * 8 + 6} />
                    <EachRoom room={lineNum * 8 + 7} />
                </Row>
            </Col>
        </Row>
    )
}

const RenderAllLine = () => {

    const { mLineNum } = useContext(NoteContext);
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((v, i) => i).map((v, i) => (
        <div key={i} hidden={mLineNum < i + 1}>
            <RenderEachLine lineNum={v} />
        </div>
    ))
}

export default RenderAllLine;