import { ChangeSingleNoteOctave } from "../actions/NoteConverter";
import { FOCUS_NOTE, TANG_KONG } from "../const";



const OnMobileKeyDown = props => {
    const { key, tData, setTData, sheetContext } = props;
    const { gFocusRoom, setFRoom } = sheetContext;
    const { fId, fType, fRoom } = gFocusRoom;

    const lastRoom = tData[fId].tLineNum * 8 - 1;
    const tang = tData[fId].tTang;

    const Room = (r, s = 0) => {
        if (tData && tData[fId] && tData[fId].tNote && tData[fId].tNote[r]) {
            return tData[fId].tNote[r][s]
        }
        else {
            return ''
        }
    }

    const setRoom = (room, val) => {
        const d = {...tData}
        d[fId].tNote[room] = val;
        setTData(d)
    }

    const RoomLastChar = (r, s = 0) => Room(r, s).charAt(Room(r, s).length - 1);
    const RoomLastCharToKey = (r, s = 0, k='-') => Room(r, s).substring(0, Room(r, s).length - 1) + k;
    const DeleteRoom = (r) => {
        let d = {...tData};
        delete d[fId].tNote[r];
        setTData(d);
    }

    const AddNote = (r, s = 0) => {
        let addedRoom = r;
        while (Room(addedRoom, s).length >= 4 && addedRoom <= lastRoom) {
            addedRoom++;
            //console.log(Room(r, s).length)
        }
        console.log(addedRoom)
        if (addedRoom <= lastRoom) {
            let addedData = tData[fId].tNote[addedRoom] || [];
            if (tang !== TANG_KONG) {
                addedData = [(addedData[0] || '') + key]
            }
            else {
                addedData = [(addedData[0] || '') + key, (addedData[1] || '') + '-']
            }
            if (addedRoom !== fRoom) setFRoom(addedRoom)
            setTData(d => ({...d, [fId] : {...d[fId], tNote : {...d[fId].tNote, [addedRoom] : addedData}}}))
        }
    }

    const AddNoteFromNumber = (r) => {
        const k = parseInt(key)
        if (tData[fId].tNote[r] && tData[fId].tNote[r][0] && k <= 8 && k > 1) {
            let lastChar = RoomLastChar(r);
            let changeChar = ChangeSingleNoteOctave(lastChar, tang, k * -1 + 1);
            if (changeChar !== lastChar) {
                let upperData = tData[fId].tNote[r][0];
                let lowerData = RoomLastCharToKey(r, 1, changeChar);
                setTData(d => ({...d, [fId] : {...d[fId], tNote : {...d[fId].tNote, [r] : [upperData, lowerData]}}}))
            }   
        }
    }

    const SwitchNoteInSubRoom = (r, s = undefined) => {
        if (tData[fId].tNote[r] && tData[fId].tNote[r][0] && tData[fId].tNote[r][1]) {
            let upperData = tData[fId].tNote[r][0];
            let lowerData = tData[fId].tNote[r][1];
            let lastChar = [RoomLastChar(r, 0), RoomLastChar(r, 1)]
            if (lastChar[0] === '-' || lastChar[1] === '-') {
                upperData = RoomLastCharToKey(r, 0, lastChar[1]);
                lowerData = RoomLastCharToKey(r, 1, lastChar[0]);
            }
            else if (s === 1) {
                upperData = RoomLastCharToKey(r, 0, '-');
            }
            else {
                lowerData = RoomLastCharToKey(r, 1, '-');
            }
            setTData(d => ({...d, [fId] : {...d[fId], tNote : {...d[fId].tNote, [r] : [upperData, lowerData]}}}))
        }
    }

    const DeleteNote = (r) => {
        if (tData[fId].tNote[r]) {
            let deletedData = [...tData[fId].tNote[r]]
            if (deletedData[0]) deletedData[0] = deletedData[0].substring(0, deletedData[0].length - 1)
            if (deletedData[1]) deletedData[1] = deletedData[1].substring(0, deletedData[1].length - 1)
            if (deletedData[0] || deletedData[1]) {
                setRoom(r, deletedData)
            }
            else {
                DeleteRoom(r)
            }
        }
        else if (fRoom > 0) {
            if (tData[fId].tNote[r - 1]) DeleteRoom(r - 1)
            setFRoom(r - 1)
        }
    }

    const AddThreeDash = (r) => {
        let addedData = tData[fId].tNote[r] || ['']
        switch (addedData[0].length) {
            case 0 :
                setTData(d => ({...d, [fId] : {...d[fId], tNote : {...d[fId].tNote, [r] : tang !== TANG_KONG ? ['---'] : ['---', '---']}}}));
                break;
            case 1 : case 2 : case 3 :
                let addString = '-'.repeat(4 - addedData[0].length);
                if (addedData[0]) addedData[0] += addString;
                if (addedData[1]) addedData[1] += addString;
                setTData(d => ({...d, [fId] : {...d[fId], tNote : {...d[fId].tNote, [r] : addedData}}}));
                if (fRoom < lastRoom) setFRoom(r + 1)
                break;
            default :
                if (fRoom < lastRoom) {
                    setFRoom(r + 1)
                    if (!tData[fId].tNote[r + 1]) AddThreeDash(r + 1);
                }
                break;
        }
    }

    if (fType === FOCUS_NOTE) {
        switch (key) {
            case '---':
                AddThreeDash(fRoom);
                break;
            case 'Tab':
                SwitchNoteInSubRoom(fRoom);
                break;
            case 'Delete':
                DeleteNote(fRoom);
                break;
            default:
                if (tang === TANG_KONG && !isNaN(key)) {
                    AddNoteFromNumber(fRoom);
                }
                else {
                    AddNote(fRoom)
                }
                break;
        }
    }

}

export default OnMobileKeyDown;