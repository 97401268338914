import { FREQ } from "../../components/SoundSoftware/const";
import { auth, firestore, firebase } from "../firebase"



export const addSheet = async (data) => {
    const { sheetName, sheetContent, sheetType } = data;
    const doc = await firestore.collection('software').add({
        sheetName, sheetContent, sheetType,
        user : auth.currentUser.uid,
        createdTime : Date.now(),
        shareType : 0,
        tData : {},
        /*gRhythm : {
            gChing : {
                isSame : true,
                chan : 0,
            },
            gTone : {
                isSame : true,
                natab : 'NO',
            }
        },*/
        pFrequency : FREQ.DEFAULT,
        pRhythm : {
            ching : {
                isSame : true,
                chan : 0,
                custom : {},
            },
            tone : {
                isSame : true,
                natab : 'NO',
                custom : {},
            },
        },
        orderItem : [],
        shareWith : [],
    });
    return doc.id;
}

export const updateShareStatus = async (id, status) => {
    return await firestore.collection('software').doc(id).update({
        shareType : status,
    })
}

export const updateSheetData = async (id, updateData) => {
    return await firestore.collection('software').doc(id).update({
        ...updateData
    })
}

export const addShareUser = async (id, userID) => {
    const doc = await firestore.collection('software').doc(id).get();
    if (![...doc.data().shareWith].includes(userID)) {
        return await firestore.collection('software').doc(id).update({
            shareWith : firebase.firestore.FieldValue.arrayUnion(userID),
        })
    }
}

export const deleteSheet = async (id) => {
    return await firestore.collection('software').doc(id).delete()
}

export const onSaveCallback = async (id, updateData) => {
    try {
        await updateSheetData(id, updateData)
        alert('บันทึกสำเร็จ');
    }
    catch (e) {
        alert('เกิดข้อผิดพลาด')
    }
}