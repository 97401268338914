import { useContext, useEffect, useState } from "react";
import { PagesContext } from "../../../providers/PagesProvider";
//import Navbar from "../../layout/Navbar";
//import RoomLarge from "../computerPages/RoomLarge";

import About from "./About";
import Help from "./Help";
import Note from "./Note";
import Settings from "./Settings";
import SoundRecord from "./SoundRecord";

//import TabletNavbar from "../tabletPages/TabletNavBar";
//import TabletListChat from "../tabletPages/TabletListChat";
//import TabletMainRoom from "../tabletPages/TabletMainRoom";
import SuperHeader from "../../layout/SuperHeader";
import MainRoom from "./MainRoom";
import ListChat from "./ListChat";


const MainPP = () => {

    const computerContainerStyle = {
        //overflowY: "scroll", 
        borderRadius: "20px", 
        //height: `${0.75 * window.screen.height}px`,
        background: "#f5f5f5",
    }

    /*const tabletContainerStyle = {
        height: `${0.76 * window.screen.height}px`, 
        marginTop: "10px", 
        borderRadius: "20px",
        overflowY: "scroll",
        background: "#f5f5f5",
    }*/

    const {page, PAGE_HOME, PAGE_CHAT, PAGE_SETTING, PAGE_HELP, PAGE_ABOUT, PAGE_NOTE, PAGE_SOUND} = useContext(PagesContext);

    const [mDisplaySiteBar, setMDisplaySiteBar] = useState(0);

    useEffect(() => {
        return () => {
            setMDisplaySiteBar(0);
        }
    }, [])


    return (
        <>
            <SuperHeader isPPPage={true}/>
            <div className="d-sm-none"><br /></div>
            <div className="">
            <div hidden={page !== PAGE_HOME}><MainRoom mDisplaySiteBar={mDisplaySiteBar} setMDisplaySiteBar={setMDisplaySiteBar}/> </div>
                {/*<div className="container" hidden={page !== PAGE_HOME} style={{...computerContainerStyle, maxWidth: "1200px"}}>
                    <div hidden={page !== PAGE_HOME}> <RoomLarge /> </div>
                </div>*/}
                <div className="container" hidden={page === PAGE_HOME} style={{...computerContainerStyle, maxWidth: "800px"}}>
                    <div hidden={page !== PAGE_NOTE}> <Note /> </div>
                    <div hidden={page !== PAGE_SETTING}> <Settings /> </div>
                    <div hidden={page !== PAGE_SOUND}> <SoundRecord /> </div>
                    <div hidden={page !== PAGE_HELP}> <Help /> </div>
                    <div hidden={page !== PAGE_ABOUT}> <About /> </div>
                    <div hidden={page !== PAGE_CHAT}> <ListChat mDisplaySiteBar={mDisplaySiteBar} setMDisplaySiteBar={setMDisplaySiteBar}/> </div>
                </div>
            </div>
            {/*<div className="d-md-none d-sm-block">
                <Row>
                    <div className="col" >
                        <div className="overflow-auto" style={tabletContainerStyle}>
                            <div hidden={page !== PAGE_HOME}> <TabletMainRoom /></div>
                            <div hidden={page !== PAGE_CHAT}> <ListChat mDisplaySiteBar={mDisplaySiteBar} setMDisplaySiteBar={setMDisplaySiteBar}/> </div>
                            <div hidden={page !== PAGE_SETTING}> <Settings /> </div>
                            <div hidden={page !== PAGE_SOUND}> <SoundRecord /></div>
                            <div hidden={page !== PAGE_NOTE}> <Note /> </div>
                            <div hidden={page !== PAGE_HELP}> <Help /> </div>
                            <div hidden={page !== PAGE_ABOUT}> <About /> </div>
                        </div>
                    </div>
                </Row>
            </div>*/}
        </>
    )
}

export default MainPP;