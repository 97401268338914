import React, { useContext } from 'react';
import { PagesContext } from '../../../providers/PagesProvider';
import About from '../pages/About';
import Help from '../pages/Help';
import Note from '../pages/Note';
import Settings from '../pages/Settings';
import SoundRecord from '../pages/SoundRecord';
import TabletListChat from './TabletListChat';
import TabletMainRoom from './TabletMainRoom';
import TabletNavbar from './TabletNavBar';
//import TabletPanel from './TabletPanel';

const RoomTablet = () => {
    const {page, PAGE_HOME, PAGE_CHAT, PAGE_SETTING,PAGE_SOUND, PAGE_HELP, PAGE_ABOUT, PAGE_NOTE} = useContext(PagesContext);

    const containerStyle = {
        height: `${0.76 * window.screen.height}px`, 
        marginTop: "10px", 
        borderRadius: "20px",
        overflowY: "scroll",
        background: "#f5f5f5",
    }

    return(
        <div className="container-fluid" >
            <div className="row">
                <div className="col-auto overflow-auto" style={{overflowY: "scroll", textAlign: "center", height: `${0.76 * window.screen.height}px`, paddingInline: "16px"}}>
                    <TabletNavbar />
                </div>
                <div className="col" >
                    <div className="overflow-auto" style={containerStyle}>
                        <div hidden={page !== PAGE_HOME}> <TabletMainRoom /></div>
                        <div hidden={page !== PAGE_CHAT}> <TabletListChat /> </div>
                        <div hidden={page !== PAGE_SETTING}> <Settings /> </div>
                        <div hidden={page !== PAGE_SOUND}> <SoundRecord /></div>
                        <div hidden={page !== PAGE_NOTE}> <Note /> </div>
                        <div hidden={page !== PAGE_HELP}> <Help /> </div>
                        <div hidden={page !== PAGE_ABOUT}> <About /> </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RoomTablet;