import { auth, firestore, firebase } from "../firebase"


export const deleteUser = async () => {
    if (auth.currentUser) {
        const uid = auth.currentUser.uid;
        //let ssID = [];
        const ssRef = await firestore.collection('software').where('user', '==', uid).get();
        ssRef.forEach(async ss => {
            await firestore.collection('software').doc(ss.id).delete();
        })
        const shareRef = await firestore.collection('software').where('shareType', '==', 1).where('shareWith', 'array-contains', uid).get();
        shareRef.forEach(async ss => {
            await firestore.collection('software').doc(ss.id).update({
                shareWith : firebase.firestore.FieldValue.arrayRemove(uid)
            })
        })
        await firestore.collection('users').doc(uid).delete();
        await auth.currentUser.delete();
    }
}

