import { Howler } from "howler";
import React, { createContext, useEffect, useReducer } from "react";
import { database } from "../databases/firebase";
import socket from "../server/socket-io";

export const PlayingContext = createContext({});

const reducer = (state, action) => {
    switch(action.type) {
        case "SET_PLAYING":
            return {...state, isPlaying : action.payload}
        case "SET_READY":
            return {...state, isReady : action.payload}
        case "SET_CHING_VOL":
            return {...state, chingVol: action.payload}
        case "SET_ALL_VOL":
            return {...state, allVol: action.payload}
        default:
            console.log('error');
            break;
    }
}

export const PlayingProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, {isPlaying : false, isReady : false, chingVol: 0.25, allVol: 1});
    const { isPlaying, isReady, chingVol, allVol } = state;
    const setPlaying = payload => {
        dispatch({type: "SET_PLAYING", payload});
        database.ref(`databaseSystem`).update({isPlaying : payload});
    }
    const setReady = payload => {
        dispatch({type: "SET_READY", payload});
        //if (userName) database.ref(`users/${userName}`).update({isReady : payload});
        if (socket.id) database.ref(`users/${socket.id}`).update({isReady : payload});
    }
    const setChingVol = payload => {
        dispatch({type: "SET_CHING_VOL", payload});
        //if (userName) database.ref(`users/${userName}`).update({chingVol : payload});
        if (socket.id) database.ref(`users/${socket.id}`).update({chingVol : payload});
    }
    const setAllVol = payload => {
        dispatch({type: "SET_ALL_VOL", payload});
        Howler.volume(payload);
    }

    /*useEffect(() => {
        database.ref(`users/${userName}`).child('isReady').on('value', child => {
            setReady(child.val());
        })
    }, [userName]);

    useEffect(() => {
        database.ref(`users/${userName}`).child('chingVol').on('value', child => {
            setChingVol(child.val());
        })
    }, [userName]);*/

    useEffect(() => {
        if (socket.id) database.ref(`users/${socket.id}`).child('isReady').on('value', child => setReady(child.val()));
        if (socket.id) database.ref(`users/${socket.id}`).child('chingVol').on('value', child => setChingVol(child.val()));
    }, [])

    useEffect(() => {
        database.ref(`databaseSystem`).child('isPlaying').on('value', child => {
            setPlaying(child.val());
        })
        //console.log(Howler._codecs);
    }, [])

    useEffect(() => {
        if (!isPlaying) setTimeout(() => setReady(false), 1000);
    }, [isPlaying])


    return(
        <PlayingContext.Provider value={{isPlaying, setPlaying, isReady, setReady, chingVol, setChingVol, allVol, setAllVol}}>
            {children}
        </PlayingContext.Provider>
    )
}