import { ControlNote2, SakolToThaiNote, ThaiToSakolNote } from "../const";

const NoteSakol = {
    'ด' : 'c',
    'ร' : 'd',
    'ม' : 'e',
    'ฟ' : 'f',
    'ซ' : 'g',
    'ล' : 'h',
    'ท' : 'i',
    '-' : '-',
    '/' : '/',
}

export const Thai2Sakol = (noteThai) => {
    let buffer = '';
    for (let i = 0; i < noteThai.length; i++) {
        if (noteThai.charAt(i) === 'ํ' || noteThai.charAt(i) === 'ฺ') {
            if (ThaiToSakolNote[noteThai.charAt(i - 1) + noteThai.charAt(i)]) {
                buffer = buffer.substring(0, buffer.length - 1) + ThaiToSakolNote[noteThai.charAt(i - 1) + noteThai.charAt(i)]
            }
        }
        else {
            buffer = buffer + noteThai.charAt(i);
        }
    }
    let buffer2 = '';
    for (let i = 0; i < buffer.length; i++) {
        if (NoteSakol[buffer.charAt(i)]) {
            buffer2 = buffer2 + NoteSakol[buffer.charAt(i)]
        }
        else {
            buffer2 = buffer2 + buffer.charAt(i)
        }
    }
    return buffer2;
}

export const Sakol2Thai = (noteSakol) => {
    let buffer = '';
    for (let i = 0; i < noteSakol.length; i++) {
        buffer += SakolToThaiNote[noteSakol.charAt(i)] || noteSakol.charAt(i);
    }
    return buffer;
}

export const KeyShift = (note, tang, isKeyUp) => {
    //let sakol = Thai2Sakol(note);
    let sakol = note;
    const sakolArray = Object.values(ThaiToSakolNote);
    const shiftNum = isKeyUp ? 1 : -1;
    let buffer = '';
    for (let i = 0; i < sakol.length; i++) {
        
        const sakolIndex = sakolArray.findIndex(a => a === sakol.charAt(i));
        if (sakolIndex !== -1 && sakolArray[sakolIndex] && sakolArray[sakolIndex + shiftNum] && ControlNote2[tang].includes(sakolArray[sakolIndex + shiftNum])  ) {
            buffer += sakolArray[sakolIndex + shiftNum]
        }
        else {
            buffer += sakol.charAt(i)
        }
    }
    //return Sakol2Thai(buffer);
    return buffer;
}

export const ChangeSingleNoteOctave = (singleNote, tang, octaveValue) => {
    let noteIndex = ControlNote2[tang].indexOf(singleNote)
    let newIndex = noteIndex + octaveValue;
    if (noteIndex !== -1 && newIndex >= 0 && newIndex < ControlNote2[tang].length && ControlNote2[tang][newIndex]) {
        return ControlNote2[tang][newIndex]
    }
    else {
        return singleNote;
    }
}

export const ChangeRoomNoteOctave = (note, tang, octaveValue) => {
    let buffer = '';
    for (let i = 0; i < note.length; i++) {
        buffer += ChangeSingleNoteOctave(note.charAt(i), tang, octaveValue)
    }
    return buffer;
}