
const _sampleRate = 44100;
const _context = new (window.AudioContext || window.webkitAudioContext)({ sampleRate: _sampleRate, });

const fetchAudios = async (...filepaths) => {
    return await Promise.all(
        filepaths.map(async (filepath) => {
            const buffer = (filepath instanceof File) ? await filepath.arrayBuffer() : await fetch(filepath).then((response) => response.arrayBuffer());
            return await _context.decodeAudioData(buffer);
        })
    );
}

const mergeAudio = (buffers) => {
    const maxNumberOfChannels = (bf) => Math.max(...bf.map(b => b.numberOfChannels));
    const maxDuration = (bf) => Math.max(...bf.map(b => b.duration));
    
    const output = _context.createBuffer(maxNumberOfChannels(buffers), _sampleRate * maxDuration(buffers), _sampleRate);

    buffers.forEach((buffer) => {
        for (let channelNumber = 0; channelNumber < buffer.numberOfChannels; channelNumber++) {
            const outputData = output.getChannelData(channelNumber);
            const bufferData = buffer.getChannelData(channelNumber);

            for (let i = buffer.getChannelData(channelNumber).length - 1; i >= 0; i--) {
                outputData[i] += bufferData[i];
            }

            output.getChannelData(channelNumber).set(outputData);
        }
    });

    return output;
}

const webmToWav = async (blob) => {
    const audioBuf = await fetchAudio(blob);
    const float32Buf = bufferToFloat32Array(audioBuf);
    const dataview = writeWavHeader(float32Buf);
    return new Blob([dataview], {type: 'audio/wav'});
}

const filesToOneFile = async (filepaths) => {
    const buffers = await fetchAudios(filepaths);
    const merged = mergeAudio(buffers);
    const float32Buf = bufferToFloat32Array(merged);
    const dataview = writeWavHeader(float32Buf);
    const blob = new Blob([dataview], {type: 'audio/mp3'});
    return {
        url: URL.createObjectURL(blob),
        blob: blob,
    }
}



const fetchAudio = async (blob) => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)({
        sampleRate: 44100,
    });
    const buffer = await blob.arrayBuffer();
    return await audioContext.decodeAudioData(buffer);
}

const bufferToFloat32Array = (input) => {
    const buffer = input.getChannelData(0);
    const length = buffer.length * 2;
    const result = new Float32Array(length);
    //const result = new Float32Array(buffer.length);
    
    let index = 0;
    let inputIndex = 0;
    
    while (index < length) {
        result[index++] = buffer[inputIndex];
        result[index++] = buffer[inputIndex];
        inputIndex++;
    }
    return result;
}

const writeWavHeader = (buffer) => {
    const arrayBuffer = new ArrayBuffer(44 + buffer.length * 2);
    const view = new DataView(arrayBuffer);

    const writeString = (dataview, offset, header) => {
        for (let i = 0; i < header.length; i++) {
            dataview.setUint8(offset + i, header.charCodeAt(i));
        }
    }

    const floatTo16BitPCM = (dataview, buffer, offset) => {
        for (let i = 0; i < buffer.length; i++, offset += 2) {
          const tmp = Math.max(-1, Math.min(1, buffer[i]));
          dataview.setInt16(offset, tmp < 0 ? tmp * 0x8000 : tmp * 0x7fff, true);
        }
        return dataview;
    }

    const sampleRate = 44100;

    writeString(view, 0, 'RIFF');
    view.setUint32(4, 32 + buffer.length * 2, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, 2, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * 4, true);
    view.setUint16(32, 4, true);
    view.setUint16(34, 16, true);
    writeString(view, 36, 'data');
    view.setUint32(40, buffer.length * 2, true);

    return floatTo16BitPCM(view, buffer, 44);
}

export {webmToWav, filesToOneFile}