import { useContext, useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import RoundContainer from "../layout/RoundContainer";
import * as FluteSound from '../../sounds/FluteSound';
import { saveAs } from "file-saver";
import { NoteContext, searchString } from "./NoteContext";
import { announceModal, fileNameModal } from "./NoteModal";
import NoteMobileKeyboard from "./NoteMobileKeyboard";
import SendLogData from "../../databases/SendLogData";
import RenderAllLine from "./RenderAllLine";
import KeyboardEvent from "./KeyboardEvent";
import SuperHeader from "../layout/SuperHeader";

function isTouchDevice() {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.msMaxTouchPoints > 0));
}

const FluteOnline = () => {

    //const [focusRoom, setFocusRoom] = useState(0);
    //const [mLineNum, setMLineNum] = useState(4);
    //const [mSpeed, setMSpeed] = useState(60)
    //const [isPlaying, setPlaying] = useState(false);
    //const noteRef = useRef([]);
    //const tickNum = useRef(0);

    const [fileName, setFileName] = useState('');
    const [showFileModal, setShowFileModal] = useState(false);
    const [showModal, setShowModal] = useState(process.env.NODE_ENV !== 'development');
    
    const tickTime = useRef(250);
    
    const currentSound = useRef(null);

    const { 
        mLineNum, setMLineNum,
        mSpeed, setMSpeed,
        isPlaying, setPlaying,
        noteRef, tickNum,
        focusRoom, setFocus, setFocusRoom, addNextNote,
    } = useContext(NoteContext);
    
    useEffect(() => {
        for (const n in FluteSound) {
            FluteSound[n].load();
        }
        
        return () => {
            setFileName('');
            for (const n in FluteSound) {
                FluteSound[n].unload();
            }
        }
    }, [])

    useEffect(() => {
        tickTime.current = 250 * 60 / mSpeed;
    }, [mSpeed])

    
    
    useEffect(() => {
        
        const keyboardHandle = e => {
            //let t = Date.now() * -1;
            KeyboardEvent(e, noteRef, addNextNote, mLineNum, focusRoom, setFocus, setFocusRoom);
            //console.log(Date.now() + t)
        }
        document.addEventListener('keydown', keyboardHandle);
        return () => document.removeEventListener('keydown', keyboardHandle);
    }, [mLineNum, focusRoom, setFocusRoom, setFocus, noteRef, addNextNote]);
    

    const onStartClick = async () => {
        tickNum.current = -1;
        try {
            const noteArray = await onProcess();
            await onPlay(noteArray);
            await SendLogData('playingNote');
        }
        catch (e) {
            alert(e)
        }
        finally {
            setPlaying(false);
        }
    }

    const onProcess = () => {
        return new Promise((resolve, reject) => {
            let rArray = [];
            for (let index = 0; index < mLineNum * 8; index++) {
                let s = noteRef.current[index].value.replace(/\s/g, "")
                const highSound = ['ดํ', 'รํ', 'มํ', 'ฟํ', 'ซํ', 'ลํ', 'ทํ']
                const normalSound = ['ด', 'ร', 'ม', 'ฟ', 'ซ', 'ล', 'ท']
                const replaceAlp = ['c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', '-']
                s = s.replaceAll('ทํ', 'i');
                highSound.forEach((hs, i) => {
                    s = s.replaceAll(hs, replaceAlp[i + 7]);
                    
                })
                normalSound.forEach((ns, i) => {
                    s = s.replaceAll(ns, replaceAlp[i])
                })

                if (s.length === 4) {
                    for (let j = 0; j < 4; j++) {
                        if ('cdefghijklmnop-/'.indexOf(s.charAt(j)) === -1) {
                            reject(`เหมือนบรรทัดที่ ${(8 + index - index % 8) / 8} ห้องที่ ${(index % 8) + 1} มีสิ่งที่ไม่ใช่โน้ตน่ะ`)
                        }
                        else {
                            rArray = [...rArray, s.charAt(j)];
                        }
                    }    
                }
                else if (s.length === 0) {

                }
                else if (s.length !== 4) {
                    reject(`เหมือนบรรทัดที่ ${(8 + index - index % 8) / 8} ห้องที่ ${(index % 8) + 1} มีโน้ตเกินหรือขาดน่ะ`)
                }
            }
            setPlaying(true);
            resolve(rArray);
        })
        
    }

    const onPlay = noteArray => {
        return new Promise(resolve => {
            const playNote = t => {
                setTimeout(() => {
                    tickNum.current++;
                    if (tickNum.current < mLineNum * 32) {
                        if ('cdefghijklmno-'.indexOf(noteArray[tickNum.current]) !== -1 && noteArray[tickNum.current]) {
                            if (FluteSound[noteArray[tickNum.current]]) {
                                if (currentSound.current) {
                                    currentSound.current.fade(0.25, 0, 0.1 * t);
                                    setTimeout(() => {
                                        currentSound.current.stop()
                                    }, 0.1 * t)
                                }
                                
                                setTimeout(() => {
                                    currentSound.current = FluteSound[noteArray[tickNum.current]]
                                    currentSound.current.play();
                                    currentSound.current.fade(0, 0.25, 25);
                                }, 0.3 * t);                                
                            }
                        }
                        else if (noteArray[tickNum.current] === '/') {
                            if (currentSound.current) {
                                currentSound.current.fade(0.25, 0, 0.1 * t);
                                setTimeout(() => currentSound.current.stop(), 0.1 * t)
                            }
                        }
                        playNote(tickTime.current);
                    }
                    else {
                        setTimeout(() => {
                            if (currentSound.current) {
                                currentSound.current.stop();
                            }
                            resolve();
                        }, 3.5 * t)
                    }
                }, t)
            }
            playNote(tickTime.current);
        })
    }

    /*const onPlay2 = noteArray => {
        const delay = (ms) => new Promise(resolve => setTimeout(() => resolve(), ms));
        return new Promise(async resolve => {
            for (let n = 0; n < mLineNum * 32; n++) {
                let t = tickTime.current;
                if (noteArray[n] === '-') {
                    await delay(t);
                }
                else if (FluteSound[noteArray[n]]){
                    if (currentSound.current) {
                        currentSound.current.fade(0.25, 0, 0.1 * t);
                        await delay(0.1 * t)
                    }
                }



                playNote(t, n);
                
            }
            resolve();
            const playNote = (t, n) => {
                if ('cdefghijklmno-'.indexOf(noteArray[n]) !== -1 && noteArray[n]) {
                    if (FluteSound[noteArray[n]]) {
                        if (currentSound.current) {
                            currentSound.current.fade(0.25, 0, 0.1 * t);
                            setTimeout(() => currentSound.current.stop(), 0.1 * t)
                        }
                        currentSound.current = FluteSound[noteArray[n]]
                        setTimeout(() => {
                            currentSound.current.volume(0.25)
                            currentSound.current.play();
                            currentSound.current.fade(0, 0.25, 0.1 * t);
                        }, 0.30 * t);                                
                    }
                }
                else if (noteArray[n] === '/') {
                    if (currentSound.current) {
                        currentSound.current.fade(0.25, 0, 0.1 * t);
                        setTimeout(() => currentSound.current.stop(), 0.1 * t)
                    }
                }
            }
        })
    }*/

    const saveFile = async () => {
        return new Promise((resolve, reject) => {
            let data = '';

            for (let index = 0; index < mLineNum * 8; index++) {
                const val = noteRef.current[index].value;
                if (val) data += val + '|'
                if ((index + 1) % 8 === 0) data += '\n';
            }

            resolve(data);
        })
        .then(data => {
            const blob = new Blob([data], { type: 'text/plain' })
            saveAs(blob, `${fileName}.txt`);
        })
        .then(() => {
            alert('บันทึกไฟล์สำเร็จ')
        })
        .catch(e => {
            alert('เกิดข้อผิดพลาด')
        })
        .finally(() => {
            setFileName('');
            setTimeout(() => {
                setShowFileModal(false);
            }, 100)
        })
        

        
    }

    
    
    const openFileForm = useRef(null);
    const lineNumForm = useRef(null);

    const onFileChange = async () => {
        const readNoteFile = new Promise((resolve, reject) => {
            const file = openFileForm.current.files[0];
            if (!file) reject('เกิดข้อผิดพลาด: เปิดไฟล์ไม่ได้')
            const reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onerror = () => reject('เกิดข้อผิดพลาด: อ่านไฟล์ไม่ได้')
            reader.onload = (r) => {
                (r.target.result) ? resolve(r.target.result) : reject('เกิดข้อผิดพลาด: โหลดไฟล์ไม่สำเร็จ')
            }
        })

        try {
            const result = await readNoteFile;
            let lines = result.split('\n');
            lines.pop();
            if (!lines && lines.length > 16) throw new Error('เกิดข้อผิดพลาด: จำนวนบรรทัดมากเกินไป');
            setMLineNum(lines.length)
            lineNumForm.current.value = lines.length;
            lines.forEach((fLine, fLineNum) => {
                const rooms = fLine.split('|').slice(0, 8);
                rooms.forEach((fRoom, fRoomNum) => {
                    const charCount = fRoom.length -  searchString(fRoom, 'ํ');
                    if (charCount <= 4) noteRef.current[fLineNum * 8 + fRoomNum].value = fRoom;
                })
            })
        }
        catch (e) {
            alert(e);
        }
        finally {
            openFileForm.current.value = null;
        }
    }

    return (
        <>
        <SuperHeader title='พร้อมเป่า' />
        <div className="container" style={{maxWidth: '1000px'}}>
            {announceModal(showModal, setShowModal)}
            {fileNameModal(showFileModal, setShowFileModal, setFileName, saveFile)}
            {/*<RoundContainer>
                <Row>
                    <Col>
                        <h3 style={{fontWeight: "bold"}}>เขียนโน้ตให้น้องเป่า </h3>
                    </Col>
                    <Col bsPrefix="col col-auto">
                        <div style={{textAlign: 'right', paddingTop: '15px'}}><Link  to="/">กลับหน้าหลัก</Link></div>
                        
                    </Col>
                </Row>
            </RoundContainer>
            <br />*/}
            
            <RoundContainer>
                <Row>
                    <Col><Form.Label>จำนวนบรรทัด</Form.Label></Col>
                    <div className="col" style={{textAlign: 'right', alignItems: 'right'}} >{mLineNum} บรรทัด</div>
                </Row>
                <Form.Range onFocus={e => e.target.blur()} step={1} min={1} max={16} defaultValue={4} onChange={e => setMLineNum(parseInt(e.target.value))} disabled={isPlaying} ref={lineNumForm}/>
                <hr />
                <Row>
                    <Col><Form.Label>ความเร็ว (beats per minute: bpm)</Form.Label></Col>
                    <div className="col" style={{textAlign: 'right', alignItems: 'right'}}>{mSpeed} bpm</div>
                </Row>
                <Form.Range onFocus={e => e.target.blur()} step={1} min={15} max={120} defaultValue={60} onChange={e => setMSpeed(parseFloat(e.target.value))}/>
            
                <hr />
            
                <hr />
                <RenderAllLine />

                <Row>
                    <Col sm="auto">
                        <div className="mb-3">
                        <Button variant="primary" onClick={onStartClick} disabled={isPlaying}>เป่า</Button>
                        <button className="btn btn-info" style={{color: 'white', marginLeft: '30px'}} onClick={() => setShowModal(true)}>คือไรอะงง??</button>
                        </div>
                        
                        
                    </Col>
                    <Col>
                        <ButtonGroup >
                            <Button variant="success" onClick={() => setShowFileModal(true)} disabled={isPlaying}>บันทึก</Button>
                            <Button variant="outline-success" onClick={() => openFileForm.current.click()} disabled={isPlaying}>เปิดไฟล์</Button>
                        </ButtonGroup>
                        <Form.Control ref={openFileForm} type="file" style={{display: 'none'}} onChange={onFileChange} accept=".txt" disabled={isPlaying}/>
                    </Col>
                </Row>
            </RoundContainer>

            {isTouchDevice() ? <div><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /></div> : <br />}

            {isTouchDevice() ? <NoteMobileKeyboard /> : <div />}
        </div>
        </>

    )
}

export default FluteOnline;