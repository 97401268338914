import React, { createContext, useEffect, useReducer } from "react";
import { database } from "../databases/firebase";

export const UserContext = createContext({});

const userReducer = (state, action) => {
    switch (action.type) {
        case "SET_USERNAME":
            return {...state, userName : action.payload}
        case "SET_ADMINNAME":
            return {...state, adminName : action.payload}
        case "SET_USERS":
            return {...state, users: action.payload}
        default:
            console.log('error');
            break;
    }
}

export const UserProvider = ({ children }) => {
    const [nameState, nameDispatch] = useReducer(userReducer, {userName : "", adminName : "", users : {}});

    const { userName, adminName, users } = nameState;
    const setUserName = payload => nameDispatch({type: "SET_USERNAME", payload});
    const setAdminName = payload => nameDispatch({type: "SET_ADMINNAME", payload});
    const setUsers = payload => nameDispatch({type: "SET_USERS", payload});




    useEffect(() => {
        database.ref('databaseSystem/adminName').on('value', child => {
            setAdminName(child.val());
        })
        database.ref('users').on('value', child => {
            setUsers(child.val());
        })
        return () => {
            setUserName('');
            setAdminName('');
            setUsers({});
        }
        
    }, []);
    
    return (
        <UserContext.Provider value={{userName, setUserName, adminName, users, setUsers}}>
            {children}
        </UserContext.Provider>
    )
}