import { useContext, useMemo } from "react";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { SheetContext } from "../../providers/SheetContext";
import { ICON_ERASE, ICON_EXCHANGE, ICON_KEYBOARD } from "../icons";
import { ControlNote2, SakolToThaiNote, TANG_KONG } from "./const";
import OnMobileKeyDown from "./events/OnMobileKeyDown";



const RenderKeyboard = props => {

    const { tang, tData, setTData } = props;
    const sheetContext = useContext(SheetContext)
    const { gIsPlaying, gIsShowKeyboard, setShowKeyboard } = sheetContext;

    const HighOctaveNote = useMemo(() => ControlNote2.HIGH_OCTAVE_NOTE.filter(n => ControlNote2[tang].includes(n)), [tang]);
    const MiddleOctaveNote = useMemo(() => ControlNote2.NO_OCTAVE_NOTE.filter(n => ControlNote2[tang].includes(n)), [tang]);
    const LowOctaveNote = useMemo(() => ControlNote2.LOW_OCTAVE_NOTE.filter(n => ControlNote2[tang].includes(n)), [tang]);

    const onKeyClick = e => {
        let key = e.target.value;
        OnMobileKeyDown({key, tData, setTData, sheetContext});
    }

    return (
        <div className="container-fluid footer" hidden={gIsPlaying || !gIsShowKeyboard} style={{maxWidth: '100%'}} >
            <Row>
                <Col>
                    <ButtonGroup hidden={tang !== TANG_KONG} className="mb-3" style={{width: '100%'}}>
                        {[...Array(7).keys()].map(n => n + 2).map((num, index) => (
                            <Button onFocus={e => e.target.blur()} key={index} style={subKey} disabled={gIsPlaying} value={num} onClick={onKeyClick}>{num}</Button>
                        ))} 
                    </ButtonGroup>
                    <span>
                        <ButtonGroup className="mb-3" style={{width: `${(HighOctaveNote.length ) * 100 / 7}%`}}>
                            {HighOctaveNote.map((sakolNote, index) => (
                                <Button onFocus={e => e.target.blur()} key={index} style={subKey} disabled={gIsPlaying} value={sakolNote} onClick={onKeyClick}>{SakolToThaiNote[sakolNote]}</Button>
                            ))} 
                        </ButtonGroup>
                        <Button onFocus={e => e.target.blur()} className="mb-3" style={{...redKey, marginLeft : `${(6 - HighOctaveNote.length) / 7 * 100}%`}} disabled={gIsPlaying} value="Delete" onClick={onKeyClick}  >{ICON_ERASE}</Button>
                    </span>
                    
                    <ButtonGroup  className="mb-3" style={{width: '100%'}}>
                        {MiddleOctaveNote.map((sakolNote, index) => (
                            <Button onFocus={e => e.target.blur()} key={index} style={subKey} disabled={gIsPlaying} value={sakolNote} onClick={onKeyClick}>{SakolToThaiNote[sakolNote]}</Button>
                        ))} 
                    </ButtonGroup>
                    <ButtonGroup  className="mb-3" style={{width: `${LowOctaveNote.length * 100 / 7}%`, marginLeft: `${100 - (HighOctaveNote.length + 1) * 100 / 7}%`}}>
                        {LowOctaveNote.map((sakolNote, index) => (
                            <Button onFocus={e => e.target.blur()} key={index} style={subKey} disabled={gIsPlaying} value={sakolNote} onClick={onKeyClick}>{SakolToThaiNote[sakolNote]}</Button>
                        ))} 
                    </ButtonGroup>
                </Col>
                <Col xs='12' sm={{order : 'first', span : '3'}} style={{textAlign : 'center'}}>
                    <Row className="justify-content-md-center">
                        <Col sm='12' xs style={{paddingInline : 2}}>
                            <Button onFocus={e => e.target.blur()} className="main-key" style={{...mainKey, background: '#eaff8f'}} disabled={gIsPlaying} onClick={() => setShowKeyboard(false)}>{ICON_KEYBOARD}</Button>
                        </Col>
                        <Col sm='12' xs style={{paddingInline : 2}}>
                            <Button onFocus={e => e.target.blur()} className="main-key" style={mainKey} disabled={gIsPlaying} value="-" onClick={onKeyClick}>-</Button>
                        </Col>
                        <Col sm='12' xs style={{paddingInline : 2}}>
                            <Button onFocus={e => e.target.blur()} className="main-key" style={mainKey} disabled={gIsPlaying} value="---" onClick={onKeyClick}>---</Button>
                        </Col>
                        <Col sm='12' xs style={{paddingInline : 2}} hidden={tang === TANG_KONG}>
                            <Button onFocus={e => e.target.blur()} className="main-key" style={mainKey} disabled={gIsPlaying} value="/" onClick={onKeyClick} >/</Button>
                        </Col>
                        <Col sm='12' xs style={{paddingInline : 2}} hidden={tang !== TANG_KONG}>
                            <Button onFocus={e => e.target.blur()} className="main-key" style={mainKey} disabled={gIsPlaying} value="Tab" onClick={onKeyClick} >{ICON_EXCHANGE}</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/*<div className="text-center" hidden={gIsShowKeyboard}>
                <Button onFocus={e => e.target.blur()} style={{...mainKey, background: '#d9d9d9'}} disabled={gIsPlaying} onClick={() => setShowKeyboard(true)}>{ICON_KEYBOARD}</Button>
            </div>*/}
            <br />
        </div>
    )
}

export default RenderKeyboard;

const mainKey = {
    borderColor: 'darkgrey', 
    background: '#d9d9d9', 
    color: 'black',
    //width : '20%',
    marginInline : '5px'
    //width: window.screen.width > 600 ? window.screen.width / 12 : 'auto'
}

const subKey = {
    borderColor: 'darkgrey',
    background: '#fafafa',
    color: 'black',
}

const redKey = {
    borderColor: 'darkgrey', 
    background: '#ff4d4f',
    color: 'white',
    width : `${100/7}%`
}