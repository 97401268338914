import { Howl } from 'howler';


export const c = new Howl({
    src: ['thaiFluteSound/c.wav'],
    volume: 0.25,
    onplayerror: (soundId, errorMessage) => alert(errorMessage),
    onloaderror: (soundId, errorMessage) => alert(errorMessage),
    preload: false,
})

export const d = new Howl({
    src: ['thaiFluteSound/d.wav'],
    volume: 0.25,
    onplayerror: (soundId, errorMessage) => alert(errorMessage),
    onloaderror: (soundId, errorMessage) => alert(errorMessage),
    preload: false,
})

export const e = new Howl({
    src: ['thaiFluteSound/e.wav'],
    volume: 0.25,
    onplayerror: (soundId, errorMessage) => alert(errorMessage),
    onloaderror: (soundId, errorMessage) => alert(errorMessage),
    preload: false,
})

export const f = new Howl({
    src: ['thaiFluteSound/f.wav'],
    volume: 0.25,
    onplayerror: (soundId, errorMessage) => alert(errorMessage),
    onloaderror: (soundId, errorMessage) => alert(errorMessage),
    preload: false,
})

export const g = new Howl({
    src: ['thaiFluteSound/g.wav'],
    volume: 0.25,
    onplayerror: (soundId, errorMessage) => alert(errorMessage),
    onloaderror: (soundId, errorMessage) => alert(errorMessage),
    preload: false,
})

export const h = new Howl({
    src: ['thaiFluteSound/h.wav'],
    volume: 0.25,
    onplayerror: (soundId, errorMessage) => alert(errorMessage),
    onloaderror: (soundId, errorMessage) => alert(errorMessage),
    preload: false,
})

export const i = new Howl({
    src: ['thaiFluteSound/i.wav'],
    volume: 0.25,
    onplayerror: (soundId, errorMessage) => alert(errorMessage),
    onloaderror: (soundId, errorMessage) => alert(errorMessage),
    preload: false,
})

export const j = new Howl({
    src: ['thaiFluteSound/j.wav'],
    volume: 0.25,
    onplayerror: (soundId, errorMessage) => alert(errorMessage),
    onloaderror: (soundId, errorMessage) => alert(errorMessage),
    preload: false,
})

export const k = new Howl({
    src: ['thaiFluteSound/k.wav'],
    volume: 0.25,
    onplayerror: (soundId, errorMessage) => alert(errorMessage),
    onloaderror: (soundId, errorMessage) => alert(errorMessage),
    preload: false,
})

export const l = new Howl({
    src: ['thaiFluteSound/l.wav'],
    volume: 0.25,
    onplayerror: (soundId, errorMessage) => alert(errorMessage),
    onloaderror: (soundId, errorMessage) => alert(errorMessage),
    preload: false,
})

export const m = new Howl({
    src: ['thaiFluteSound/m.wav'],
    volume: 0.25,
    onplayerror: (soundId, errorMessage) => alert(errorMessage),
    onloaderror: (soundId, errorMessage) => alert(errorMessage),
    preload: false,
})

export const n = new Howl({
    src: ['thaiFluteSound/n.wav'],
    volume: 0.25,
    onplayerror: (soundId, errorMessage) => alert(errorMessage),
    onloaderror: (soundId, errorMessage) => alert(errorMessage),
    preload: false,
})

export const o = new Howl({
    src: ['thaiFluteSound/o.wav'],
    volume: 0.25,
    onplayerror: (soundId, errorMessage) => alert(errorMessage),
    onloaderror: (soundId, errorMessage) => alert(errorMessage),
    preload: false,
})
