import React, { createContext, useCallback, useEffect, useReducer, useRef } from "react";
import { CAN_EDIT, FOCUS_COMMENT, FOCUS_NONE, FOCUS_NOTE } from "../components/SoundSoftware/const";

export const SheetContext = createContext({});

const initialState = {
    gFocusRoom : {
        fType : FOCUS_NONE,
        fId : null,
        fRoom : 0,
        fIdRef : '',
    },
    gIsPlaying : false,
    gIsSelectPress : false,
    gIsShowComment : false,
    gIsShowKeyboard : false,
    //gIsShiftPress : false,
    //gIsControlPress : false,
    gClipboard : {},
    sRoom : [0],
    gRight : CAN_EDIT,
}

const reducer = (state, action) => {
    switch (action.type) {
        case "RESET" :
            return {...initialState}
        case "SET_FOCUS_ROOM" :
            return {...state, gFocusRoom : {...state.gFocusRoom, fRoom : action.payload}}
        case "SET_FOCUS_TYPE" :
            return {...state, gFocusRoom : {...state.gFocusRoom, fType : action.payload}}
        case "SET_FOCUS_ID" :
            return {...state, gFocusRoom : {...state.gFocusRoom, fId : action.payload}}
        case "SET_FOCUS_REF" :
            return {...state, gFocusRoom : {...state.gFocusRoom, fIdRef : action.payload}}

        case "SET_SELECT_ROOM" :
            return {...state, sRoom : action.payload}
        case "SET_SELECT_ROOM_CTRL" :
            const cRoom = action.payload;
            const sRoomArray = [...state.sRoom]
            return { ...state, sRoom : sRoomArray.includes(cRoom) ? sRoomArray.filter(a => a !== cRoom) : [...sRoomArray, cRoom].sort() }
        case 'SET_SELECT_ROOM_SHIFT':
            const shRoom = action.payload;
            const len = Math.abs(shRoom - state.gFocusRoom.fRoom) + 1;
            return { ...state, sRoom : [...Array(len).keys()].map(i => i + Math.min(shRoom, state.gFocusRoom.fRoom))}
        case 'SET_CB':
            return {...state, gClipboard : action.payload}

        case 'SET_PLAYING':
            return {...state, gIsPlaying : action.payload}
        case 'SET_SELECT_PRESS':
            return {...state, gIsSelectPress : action.payload}
        case 'SET_SHOW_COMMENT':
            return {...state, gIsShowComment : action.payload}
        case 'SET_SHOW_KEYBOARD':
            return {...state, gIsShowKeyboard : action.payload}

        /*case 'SET_SHIFT':
            return {...state, gIsShiftPress : action.payload}
        case 'SET_CONTROL':
            return {...state, gIsControlPress : action.payload}*/

        case 'SET_RIGHT' :
            return {...state, gRight : action.payload}
        default:
            break;
    }
}

export const SheetProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { gFocusRoom, sRoom, gIsPlaying, gIsSelectPress, gIsShowComment, gIsShowKeyboard, gClipboard, gRight } = state;
    const { fRoom, fType, fId, /*fIdRef */} = gFocusRoom;
    const NoteRef = useRef({});
    const NoteRef2 = useRef({});

    const PositionRef = useRef({
        id : null,
        room : null,
        smRef : null,
        xsRef : null,
    });

    const setFRoom = payload => dispatch({ type : 'SET_FOCUS_ROOM', payload })
    const setFType = payload => dispatch({ type : 'SET_FOCUS_TYPE', payload })
    const setFId = payload => dispatch({ type : 'SET_FOCUS_ID', payload })
    const setFRef = payload => dispatch({ type : 'SET_FOCUS_REF', payload })
    

    const setSRoom = payload => dispatch({ type : 'SET_SELECT_ROOM', payload })
    const setSRoomControl = useCallback(payload => dispatch({ type : 'SET_SELECT_ROOM_CTRL', payload }), [])
    const setSRoomShift = useCallback(payload => dispatch({ type : 'SET_SELECT_ROOM_SHIFT', payload }), [])

    const setClipboard = payload => dispatch({type : 'SET_CB', payload})

    const setPlaying = payload => dispatch({ type : 'SET_PLAYING', payload })
    const setSelectPress = payload => dispatch({ type : 'SET_SELECT_PRESS', payload })
    const setShowComment = payload => dispatch({ type : 'SET_SHOW_COMMENT', payload })
    const setShowKeyboard = payload => dispatch({ type : 'SET_SHOW_KEYBOARD', payload })

    const setRight = useCallback(payload => {
        dispatch({type : 'SET_RIGHT', payload})
    }, [])

    useEffect(() => {
        if (fType === FOCUS_NOTE) {
            dispatch({ type : 'SET_SELECT_ROOM', payload : [fRoom] })
        }
        else {
            dispatch({ type : 'SET_SELECT_ROOM', payload : [] })
            setShowKeyboard(false)
        }
        if (fType === FOCUS_COMMENT) {
            const nextElement = document.getElementById('comment-' + fRoom + '-' + fId);
            if (nextElement) {
                nextElement.focus();
            }
        }
    }, [fRoom, fType, fId])

    useEffect(() => {
        return () => dispatch({type : 'RESET'})
    }, [])

    const onNoteCopy = (tNote) => {
        let cb = {};
        const minRoom = Math.min(...sRoom);
        sRoom.forEach(v => {
            if (tNote[v]) {
                cb = {...cb, [v - minRoom] : [...tNote[v]]}
            }
        })
        setClipboard(cb)
    }

    const onNotePaste = (tNote, tLineNum, setTNote) => {
        let buffer = {...tNote};
        const minRoom = Math.min(...sRoom);
        const maxRoom = Math.max(...sRoom)
        if (sRoom.length === 1) {
            
            for (const copyRoom in gClipboard) {
                let pasteRoom = parseInt(copyRoom) + minRoom
                if (pasteRoom < tLineNum * 8) {
                    buffer = {...buffer, [pasteRoom] : [...gClipboard[copyRoom]]}
                }
            }
        }
        else if (sRoom.length > 1) {
            for (const copyRoom in gClipboard) {
                let pasteRoom = parseInt(copyRoom) + minRoom
                if (pasteRoom <= maxRoom) {
                    buffer = {...buffer, [pasteRoom] : [...gClipboard[copyRoom]]}
                }
            }
        }
        setTNote(buffer)
    }

    return (
        <SheetContext.Provider value={{
            gFocusRoom, sRoom, NoteRef, NoteRef2, setFRoom, setFId, setFType, setFRef, setSRoom, setSRoomControl, setSRoomShift,
            gIsPlaying, setPlaying,
            gIsSelectPress, setSelectPress,
            gIsShowComment, setShowComment,
            gIsShowKeyboard, setShowKeyboard,
            onNoteCopy, onNotePaste,
            gRight, setRight,
            PositionRef,
        }}>
            {children}
        </SheetContext.Provider>
    )
}