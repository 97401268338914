import React, { createContext, useEffect, useReducer } from "react";
import socket from "../server/socket-io";



export const PagesContext = createContext({});


const reducer = (state, action) => {
    switch(action.type) {
        case "SET_PAGE":
            return {page : action.payload}
        default :
            break;
    }
}

export const PagesProvider = ({ children }) => {

    const PAGE_HOME = "page_home";
    const PAGE_CHAT = "page_chat";
    const PAGE_SETTING = "page_setting";
    const PAGE_SOUND = "page_sound";
    const PAGE_HELP = "page_help";
    const PAGE_ABOUT = "page_about";
    const PAGE_SUPPORT = "page_support";
    const PAGE_COMMUNITY = "page_community";
    const PAGE_NOTE = "page_note";

    const [state, dispatch] = useReducer(reducer, {page: PAGE_HOME});
    const setPage = payload => dispatch({ type: "SET_PAGE", payload});
    const { page } = state;

    useEffect(() => {
        socket.on("disconnect", (reason) => {
            setPage(PAGE_HOME);
            reasonAlert(reason);
        });
        return () => {
            setPage(PAGE_HOME);
        }
    }, []);

    const reasonAlert = (r) => {
        switch(r) {
            case 'io server disconnect':
                alert('เซิร์ฟเวอร์ทําการรีเซ็ต');
                break;
            case 'ping timeout': case 'transport close': case 'transport error':
                alert('ไม่สามารถเชื่อมต่อเซิร์ฟเวอร์ได้ :(')
                break;
            default:
                break;
        }
    }

    return (
        <PagesContext.Provider value={{page, setPage, PAGE_HOME, PAGE_CHAT, PAGE_SETTING, PAGE_SOUND, PAGE_HELP, PAGE_ABOUT, PAGE_SUPPORT, PAGE_COMMUNITY, PAGE_NOTE}}>
            {children}
        </PagesContext.Provider>
    )

}